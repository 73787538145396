<div class="formbox">

    <div id="loading" *ngIf="load">
        <img id="loading-image" src="assets/img/loading2.gif" alt="Loading..." />
        <p>{{messageLoading}}</p>
    </div>

    <div class="formularioCabecera">
        <table style="margin-left: 4em; margin-right: 4em; width: 100%;">
            <tbody>
                <tr>
                    <td style="font-size: 2em; font-family: BigShouldersDisplayBold;">FIBRA - CREAR SUSCRIPCIÓN</td>
                    <td style="justify-content: flex-end; display: flex; align-items: center; height: 4em;">
                        <!-- __________________________REGRESAR__________________________ -->
                        <a class="" routerLink="/create-account" title="Volver a comprobar cobertura">
                            <div class="volverBtn" (click)="volverAtras()">
                            </div>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="divTabla">
        <div class="formularioCliente">
            <mat-grid-list cols="10" rowHeight="2.5em" style="width: 95%;">
                <mat-grid-tile [colspan]=5 [rowspan]=1 style="font-family: BigShouldersDisplayBold; background-color: #000; color: white;">
                    <label style="font-size: 1.5em;">CONTRATO</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=5 [rowspan]=1 style="font-family: BigShouldersDisplayBold; background-color: #000; color: white;">
                    <label style="font-size: 1.5em;">TARIFA</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <select name="contratos" class="selectGeneral" (change)="changeSelectContrato($event.target.value)">
                        <option value="0">Seleccione un Contrato:</option>
                        <option value={{d.contratoOficial}} *ngFor="let d of listContratos ">
                            {{d.contratoOficial}}
                        </option>
                    </select>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <select name="contratos" class="selectGeneral" (change)="changeRateSelect($event.target.value)" *ngIf="createSubcriptionModel.contratoOficial">
                        <option value="0">Seleccione un Tarifa:</option>
                        <option value={{d.codigo}}|{{d.tipoObjeto}} *ngFor="let d of listRates">{{d.descripcion}}
                        </option>
                    </select>
                </mat-grid-tile>

                <mat-grid-tile [colspan]=10 [rowspan]=1 class="alinearTI">
                </mat-grid-tile>
                <mat-grid-tile [colspan]=10 [rowspan]=1 style="font-family: BigShouldersDisplayBold; background-color: #000; color: white;">
                    <label style="font-size: 1.5em;">DATOS DEL USUARIO</label>
                </mat-grid-tile>

                <!-- __________________________________________DOCUMENTO-DNI__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <select name="tipoDocumento" id="tipoDocumento" (change)="changeSelectDocument($event.target.value)" class="selectGeneral">
                        <option value="-1">Seleccione documento</option>
                        <option value="NIF">DNI</option>
                        <option value="NIE">NIE</option>
                        <option value="PASAPORTE">Pasaporte</option>
                        <option value="TIE">Tarjeta de Residencia</option>
                        <option value="CIF">CIF</option>
                    </select>
                </mat-grid-tile>

                <!-- __________________________________________DNI/CIF/NIE__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <input type="text" name="documento" id="documento" [(ngModel)]="createSubcriptionModel.documento" maxlength="9" class="inputGeneral" placeholder="DNI/NIE/CIF">
                </mat-grid-tile>

                <!-- __________________________________________NOMBRE__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <input type="text" name="nombre" id="nombre" [(ngModel)]="createSubcriptionModel.nombre" class="inputGeneral" placeholder="NOMBRE">
                </mat-grid-tile>

                <!-- __________________________________________TELÉFONO__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <input type="text" name="telefono" id="telefono" [(ngModel)]="createSubcriptionModel.numeroTelefono" maxlength="9" class="inputGeneral" placeholder="TELÉFONO">
                </mat-grid-tile>

                <!-- __________________________________________1ºAPELLIDO__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <input type="text" name="primerApellido" id="primerApellido" [(ngModel)]="createSubcriptionModel.apellido1" class="inputGeneral" placeholder="1ºAPELLIDO">
                </mat-grid-tile>

                <!-- __________________________________________EMAIL__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <input type="email" name="email" id="email" [(ngModel)]="createSubcriptionModel.email" class="inputGeneral" placeholder="EMAIL">
                </mat-grid-tile>

                <!-- __________________________________________2ºAPELLIDO__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <input type="text" name="segundoApellido" id="segundoApellido" [(ngModel)]="createSubcriptionModel.apellido2" class="inputGeneral" placeholder="2ºAPELLIDO">
                </mat-grid-tile>
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                </mat-grid-tile>

                <mat-grid-tile [colspan]=10 [rowspan]=1 class="alinearTI">
                </mat-grid-tile>
                <mat-grid-tile [colspan]=10 [rowspan]=1 style="font-family: BigShouldersDisplayBold; background-color: #000; color: white;">
                    <label style="font-size: 1.5em;">DIRECCIÓN</label>
                </mat-grid-tile>

                <!-- __________________________________________PROVINCIA__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <input type="text" name="provincia" id="provincia" value="PROVINCIA: {{normaliceAddres.state}}" disabled="disabled" class="inputGeneral" placeholder="PROVINCIA">
                </mat-grid-tile>

                <!-- __________________________________________MUNICIPIO__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <input type="text" name="municipio" id="municipio" value="MUNICIPIO: {{normaliceAddres.county}}" disabled="disabled" class="inputGeneral" placeholder="MUNICIPIO">
                </mat-grid-tile>

                <!-- __________________________________________TIPO-VÍA__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <input type="text" name="tipovia" id="tipovia" value="TIPO VIA: {{typeViaString}}" disabled="disabled" class="inputGeneral">
                    <input type="hidden" name="tipovia_code" id="tipovia_code" value="{{normaliceAddres.additionalInfo.streetType}}" disabled="disabled" class="inputGeneral">
                </mat-grid-tile>

                <!-- __________________________________________UBICACIÓN__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <input type="text" name="calle" id="calle" value="CALLE: {{normaliceAddres.street}}" disabled="disabled" class="inputGeneral" placeholder="UBICACIÓN">
                </mat-grid-tile>

                <!-- __________________________________________CÓDIGO-POSTAL__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <input type="text" name="postalCode" id="postalCode" value="CÓDIGO POSTAL: {{normaliceAddres.postalCode}}" disabled="disabled" class="inputGeneral" placeholder="CÓDIGO POSTAL">
                </mat-grid-tile>

                <!-- __________________________________________NÚMERO__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <input type="text" name="numero" id="numero" value="NÚMERO: {{normaliceAddres.number}}" disabled="disabled" class="inputGeneral" placeholder="NÚMERO">
                </mat-grid-tile>

                <!-- __________________________________________PLANTA__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI" *ngIf="normaliceAddres.additionalInfo.floor">
                    <!--  [(ngModel)]="createSubcriptionModel.planta" -->
                    <input type="text" name="floor" id="floor" value="PLANTA: {{normaliceAddres.additionalInfo.floor}}" disabled="disabled" class="inputGeneral" placeholder="PLANTA">
                </mat-grid-tile>

                <!-- __________________________________________PUERTA__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI" *ngIf="normaliceAddres.additionalInfo.door">
                    <input type="text" name="door" id="door" value="PUERTA: {{normaliceAddres.additionalInfo.door}}" disabled="disabled" class="inputGeneral" placeholder="PUERTA">
                </mat-grid-tile>

                <!-- __________________________________________ESCALERA__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI" *ngIf="normaliceAddres.additionalInfo.staircaseNumber">
                    <input type="text" name="staircaseNumber" id="staircaseNumber" value="ESCALERA: {{normaliceAddres.additionalInfo.staircaseNumber}}" disabled="disabled" class="inputGeneral" placeholder="ESCALERA">
                </mat-grid-tile>

                <!-- __________________________________________EDIFICIO__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI" *ngIf="normaliceAddres.additionalInfo.buildingName">
                    <input type="text" name="buildingName" id="buildingName" value="EDIFICIO: {{normaliceAddres.additionalInfo.buildingName }}" disabled="disabled" class="inputGeneral" placeholder="EDIFICIO">
                </mat-grid-tile>

                <!-- __________________________________________NºAPARTAMENTO__________________________________________ -->
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI" *ngIf="normaliceAddres.additionalInfo.apartmentNumber">
                    <input type="text" name="apartmentNumber" id="apartmentNumber" value="NºAPARTAMENTO: {{normaliceAddres.additionalInfo.apartmentNumber }}" disabled="disabled" class="inputGeneral" placeholder="NºAPARTAMENTO">
                </mat-grid-tile>
                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI" *ngIf="normaliceAddres.additionalInfo.apartmentNumber">
                </mat-grid-tile>

                <mat-grid-tile [colspan]=10 [rowspan]=1 class="alinearTI">
                </mat-grid-tile>

                <!-- __________________________________________BOTÓN-FINAL__________________________________________ -->
                <mat-grid-tile [colspan]=10 [rowspan]=1 style="justify-content: center; align-items: center; display: flex;">
                    <button type="submit" class="submitBtn botonSecundario" (click)="createSubcription()">FINALIZAR
                        SUSCRIPCIÓN</button>
                </mat-grid-tile>

                <mat-grid-tile [colspan]=10 [rowspan]=1 class="alinearTI">
                </mat-grid-tile>
            </mat-grid-list>

            <div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
                <label>.</label>
            </div>
        </div>












        <!-- __________________________________________CONTRATOS__________________________________________ -->
        <!-- SELECTED CONTRATO -->
        <!-- <table class="box contratosBoxSelect">
            <thead>
                <legend>
                    <th>Contrato:</th>
                </legend>

            </thead>
            <tbody>
                <tr class="row">
                    <td>
                        <select name="contratos" class="contratosF"
                            (change)="changeSelectContrato($event.target.value)">
                            <option value="0">Seleccione un Contrato:</option>
                            <option value={{d.contratoOficial}} *ngFor="let d of listContratos ">
                                {{d.contratoOficial}}
                            </option>
                        </select>
                    </td>
                </tr>

            </tbody>

        </table> -->
        <!-- SELECTED TARIFA -->
        <!-- <table class="box tarifaBoxSelected" *ngIf="createSubcriptionModel.contratoOficial">

            <thead>
                <legend>
                    <th>Tarifa:</th>
                </legend>

            </thead>
            <tbody>
                <tr class="row">
                    <td>
                        <select name="contratos" class="contratosF" (change)="changeRateSelect($event.target.value)">
                            <option value="0">Seleccione un Tarifa:</option>
                            <option value={{d.codigo}}|{{d.tipoObjeto}} *ngFor="let d of listRates">{{d.descripcion}}
                            </option>
                        </select>
                    </td>
                </tr>

            </tbody>

        </table> -->


        <!-- DATA USER -->

        <!-- <table class="box userDataBox" *ngIf="createSubcriptionModel.codigoTarifa">

            <thead>
                <legend>
                    <th colspan="3"> Datos del Usuario:</th>
                </legend>
            </thead>
            <tbody>
                <tr class="row">
                    <td>
                        <label class="custom_label" for="tipoDocumento">Tipo de Documento:</label>
                        <select name="tipoDocumento" id="tipoDocumento"
                            (change)="changeSelectDocument($event.target.value)">
                            <option value="-1">Seleccione</option>
                            <option value="NIF">DNI</option>
                            <option value="NIE">NIE</option>
                            <option value="PASAPORTE">Pasaporte</option>
                            <option value="TIE">Tarjeta de Residencia</option>
                            <option value="CIF">CIF</option>
                        </select>
                        <div class="error" *ngIf="errorTypeDocumento">
                            <p class="error_message">{{errorTypeDocumento}}</p>
                        </div>
                    </td>
                    <td>
                        <label class="custom_label" for="documento">Documento:</label>
                        <input type="text" name="documento" id="documento"
                            [(ngModel)]="createSubcriptionModel.documento" maxlength="9">
                        <div class="error" *ngIf="errorDocumento">
                            <p class="error_message">{{errorDocumento}}</p>
                        </div>
                    </td>
                    <td>
                        <label class="custom_label" for="nombre">Nombre:</label>
                        <input type="text" name="nombre" id="nombre" [(ngModel)]="createSubcriptionModel.nombre">
                        <div class="error" *ngIf="errorNombre">
                            <p class="error_message">{{errorNombre}}</p>
                        </div>
                    </td>



                </tr>
                <tr class="row">
                    <td>
                        <label class="custom_label" for="primerApellido">Primer Apellido:</label>
                        <input type="text" name="primerApellido" id="primerApellido"
                            [(ngModel)]="createSubcriptionModel.apellido1">
                        <div class="error" *ngIf="errorApellido1">
                            <p class="error_message">{{errorApellido1}}</p>
                        </div>
                    </td>
                    <td>
                        <label for="segundoApellido">Segundo Apellido:</label>
                        <input type="text" name="segundoApellido" id="segundoApellido"
                            [(ngModel)]="createSubcriptionModel.apellido2">
                        <div class="error" *ngIf="errorApellido2">
                            <p class="error_message">{{errorApellido2}}</p>
                        </div>
                    </td>


                    <td> <label class="custom_label" for="telefono">Teléfono:</label>
                        <input type="text" name="telefono" id="telefono"
                            [(ngModel)]="createSubcriptionModel.numeroTelefono" maxlength="9">
                        <div class="error" *ngIf="errorTelefono">
                            <p class="error_message">{{errorTelefono}}</p>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td rowspan="3">
                        <label class="custom_label" for="email">Email:</label>
                        <input type="email" name="email" id="email" [(ngModel)]="createSubcriptionModel.email">
                        <div class="error" *ngIf="errorEmail">
                            <p class="error_message">{{errorEmail}}</p>
                        </div>
                    </td>
                </tr>
            </tbody>

        </table> -->


        <!-- DIRECCION -->
        <!-- <table class="box locationUserBox" *ngIf="createSubcriptionModel.codigoTarifa">

            <thead>
                <legend>
                    <th colspan="3">Dirección:</th>
                </legend>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <label for="provincia">Provincia:</label>
                        <input type="text" name="provincia" id="provincia" value="{{normaliceAddres.state}}"
                            disabled="disabled">
                    </td>
                    <td>
                        <label for="municipio">Municipio:</label>
                        <input type="text" name="municipio" id="municipio" value="{{normaliceAddres.county}}"
                            disabled="disabled">
                    </td>
                    <td>
                        <label for="postalCode">Código Postal:</label>
                        <input type="number" name="postalCode" id="postalCode" value="{{normaliceAddres.postalCode}}"
                            disabled="disabled">
                    </td>

                </tr>
                <tr>
                    <td>
                        <label for="tipovia"> Tipo de via:</label>
                        <input type="text" name="tipovia" id="tipovia" value="{{typeViaString}}" disabled="disabled">
                        <input type="hidden" name="tipovia_code" id="tipovia_code"
                            value="{{normaliceAddres.additionalInfo.streetType}}" disabled="disabled">
                    </td>
                    <td>
                        <label for="calle">Calle:</label>
                        <input type="text" name="calle" id="calle" value="{{normaliceAddres.street}}"
                            disabled="disabled">
                    </td>
                    <td>
                        <label for="numero">Numero:</label>
                        <input type="number" name="numero" id="numero" value="{{normaliceAddres.number}}"
                            disabled="disabled">

                    </td>

                </tr>
                <tr>

                    <td *ngIf="normaliceAddres.additionalInfo.floor">
                        <label for="floor">Planta:</label>
                        <input type="number" name="floor" id="floor" value="{{normaliceAddres.additionalInfo.floor}}"
                            disabled="disabled" [(ngModel)]="createSubcriptionModel.planta">
                    </td>
                    <td *ngIf="normaliceAddres.additionalInfo.door">
                        <label for="door">Puerta:</label>
                        <input type="text" name="door" id="door" value="{{normaliceAddres.additionalInfo.door}}"
                            disabled="disabled" [(ngModel)]="createSubcriptionModel.puerta">
                    </td>
                    <td *ngIf="normaliceAddres.additionalInfo.staircaseNumber">
                        <label for="door">Escalera:</label>
                        <input type="number" name="staircaseNumber" id="staircaseNumber"
                            value="{{normaliceAddres.additionalInfo.staircaseNumber}}" disabled="disabled"
                            [(ngModel)]="createSubcriptionModel.escalera">
                    </td>
                    <td *ngIf="normaliceAddres.additionalInfo.buildingName">
                        <label for="door">Edificio:</label>
                        <input type="text" name="buildingName" id="buildingName"
                            value="{{normaliceAddres.additionalInfo.buildingName }}" disabled="disabled"
                            [(ngModel)]="createSubcriptionModel.edificio">
                    </td>
                    <td *ngIf="normaliceAddres.additionalInfo.apartmentNumber">
                        <label for="door">Nº Apartamento:</label>
                        <input type="text" name="apartmentNumber" id="apartmentNumber"
                            value="{{normaliceAddres.additionalInfo.apartmentNumber }}" disabled="disabled">
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="submit_box">

                            <button type="submit" class="submitBtn" (click)="createSubcription()">Siguiente</button>

                        </div>
                    </td>
                </tr>
            </tbody>

        </table>


    </div>

</div> -->

        <div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
            <label>.</label>
        </div>