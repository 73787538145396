import { Component, OnInit,Inject} from '@angular/core';
import { ContratosService } from 'src/app/services/contratos.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { PeticionServiceService } from 'src/app/services/peticion-service.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-documentos',
  templateUrl: './lista-documentos.component.html',
  styleUrls: ['./lista-documentos.component.css']
})
export class ListaDocumentosComponent implements OnInit {
  listadoDocumentos:any=null
  dtOptions = {
    dom: 'Bfrtip',
    language: {
        url: '//cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
    },
    retrieve: true
    
  };  
  loading:boolean;
  private tiposBase64:any={
    pdf:'application/pdf;base64',
    jpg:'image/jpg;base64',
    jpeg:'image/jpeg;base64',
    png:'image/png;base64',
    bmp:'image/bmp;base64',
    tiff:'image/tiff;base64'
  }
  constructor(public contratosSvc:ContratosService,
    public dialogRef:MatDialogRef<ListaDocumentosComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private cookie: CookieService,
    private marcaBlancaSVC:PeticionServiceService
    ) { }

  ngOnInit(): void {
    this.loading=true
    this.listaDocumentos()
  }
  async listaDocumentos(){
    let data={
      "cod_tercero":this.cookie.get("tercero"),
      "cod_contrato":this.data.contrato
    }
    let url=environment.listarDocumentacionContratos;
    await this.marcaBlancaSVC.peticionMarca(url,"post",{},data,).then(
      (data:any)=>{
        this.listadoDocumentos=data
      }
    ).catch((error:any)=>{
      this.dialogRef.close();
      Swal.fire({
        title:"Error",
        icon:"error",
        html:this.showMultipleErrors(error.error.mensaje),
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Confirmar',
      });
    });
    this.loading=false
  }
  async visualizarDocumento(nombre:string,ext:string){
    const payload={
      "tercero": this.cookie.get('tercero'),
      "contrato": this.data.contrato,
      "nombre": nombre,
      "extension": ext  
    }
    await this.marcaBlancaSVC.peticionMarca(environment.obtenerDocumentoMarcaBlanca,'post',{},payload).then((data:any)=>{
      this.showFile(data.base64,this.tiposBase64[ext]);
    }).catch((error:any)=>{
      console.error(error);
      Swal.fire({
        title:"Error",
        icon:"error",
        html:this.showMultipleErrors(error.error.mensaje),
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Confirmar',
      });
    })
  }
  private showMultipleErrors(errores:any){
    if(typeof errores == "string"){
      return errores;
    }
    if(Array.isArray(errores)){
      let html="<ul>";
      errores.forEach((error)=>{
          html+="<li>"+error+"</li>";
      });
      html+="</ul>";
      return html;
    }
    let keys=Object.keys(errores);
    let html="<ul>";
    keys.forEach((key:string)=>{
      errores[key].forEach((error) => {
        html+="<li>"+error+"</li>";
      });
    });
    html+="</ul>";
    return html;
  }
  private showFile(base64:string,type:string){
    var byteCharacters = atob(base64);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: type });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL,'_blank');
  }
}
