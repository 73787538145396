<div class="formbox">
    <div class="wrapper_load" *ngIf="load">
        <img class="customLoad" src="assets/img/loading2.gif">
    </div>

    <div class="cabecera">
        LISTA DE SUSCRIPTORES MARCA BLANCA
    </div>

    <div class="divTabla">
        <div class="container" style="width: 95%; zoom:90%;">
            <div style="zoom: 90%;">
                <table class="table table-striped table-bordered table-sm row-border hover " datatable
                    [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead style="font-family: MontserratBold; text-transform: uppercase;">
                        <tr class="encabezadoTabla">
                            <th>Id contrato</th>
                            <th>DNI</th>
                            <th>Cliente</th>
                            <th>Provincia</th>
                            <th>Población</th>
                            <th>Dirección</th>
                            <th>Estado</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody style="font-family: MontserratRegular;word-break: break-word;">
                        <tr *ngFor="let ele of listado" class="cuerpoTabla">
                            <td>{{ele.idContrato}}</td>
                            <td>{{ele.Dni_Doc}}</td>
                            <td>{{ele.Cliente}}</td>
                            <td>{{ele.Provincia}}</td>
                            <td>{{ele.Poblacion}}</td>
                            <td [ngClass]="{'red-text':isCamdomiOne(ele) }">{{ele.Direccion}}</td>
                            <td>{{ele.Estado}}</td>
                            <td class="botonesAccion">
                                <button title="Servicios Activos"
                                    (click)="recuperarServicios(ele.idContrato,ele.Segmento,ele.Tercero,ele.Estado)">
                                    <i class="fas fa-puzzle-piece"></i>
                                </button>
                                <button (click)="mostrarIncidencias(ele.idContrato,
                                ele.CodUgv.CodProvincia,
                                ele.CodUgv.CodMunicipio,
                                ele.CodUgv.CodCalle,
                                ele.CodUgv.NroFinca,
                                ele.CodUgv.Bis,
                                ele.Tercero)" title="Incidencias"><i class="fas fa-list-ul"></i></button>
                                <button title="Confirmar Contrato" (click)="confirmarContrato(ele.idContrato)"
                                    [ngStyle]="(ele.Estado!='Temporal')?{'display':'none'}:{}">
                                    <i class="fas fa-check-square"></i>
                                </button>

                                <button title="Baja de contrato"
                                    [ngStyle]="(ele.Estado!='Activo')?{'display':'none'}:{}"
                                    (click)="bajaContrato(ele.idContrato)">
                                    <i class="fas fa-arrow-down"></i>
                                </button>

                                <button title="Diagnóstico de ONT" (click)="diagnosticoOnt(ele.Mac)"
                                    [style]="ele.Mac==''?{'display':'none'}:{}">
                                    <i class="fas fa-chart-line"></i>
                                </button>

                                <button (click)="listarDocumentos(ele.idContrato)" title="Listar contratos"><i
                                        class="fas fa-file-alt"></i></button>



                                <button title="Subir documentación" (click)="subirDocumentacion(ele.idContrato)"
                                    style="padding: 2%;">
                                    <i class="fas fa-file-invoice"></i>
                                </button>
                                <button title="Cambio domicilio" (click)="cambioDomicilio(ele)"
                                    [ngStyle]="(ele.Estado!='Activo')?{'display':'none'}:{'padding':'2%'}">
                                    <i class="fas fa-home"></i>
                                </button>
                       
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>* <span class="red-text">DIRECCIÓN </span>= cambio domicilio pendiente. </p>
            </div>
        </div>
    </div>
</div>