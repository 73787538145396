<div class="wrapper_content">
    <div id="datatableDirections" class="tableContainer" style="border-collapse: collapse; zoom: 90%;">
        <table class="table table-striped table-bordered table-sm row-border hover" datatable *ngIf="modalService.lislocales" [dtOptions]="dtOptions" id="dt">
            <thead class="tableHeader font-family: MontserratBold; text-transform: uppercase;">
                <tr>
                    <th>
                        Localidad(Provincia)
                    </th>
                    <th>
                        Calle
                    </th>
                    <th>
                        Número
                    </th>
                    <th>
                        Nº apartamento
                    </th>
                    <th>
                        Subúmero
                    </th>
                    <th>
                        Planta
                    </th>
                    <th>
                        Puerta
                    </th>
                    <th>
                        Escalera
                    </th>
                    <th>
                        N. Edificio
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let l of modalService.lislocales" class="local" (click)="modalService.localSelected(l)" [ngClass]="modalService.localSeleccionado==l?'localSeleccionado':'local'">
                    <td>
                        {{l.city.toUpperCase()}}({{l.state.toUpperCase()}})
                    </td>
                    <td>
                        {{l.street.toUpperCase()}}
                    </td>
                    <td>
                        {{l.number}}
                    </td>
                    <!-- apartmentNumber -->
                    <td>
                        {{(l.additionalInfo.apartmentNumber)?l.additionalInfo.apartmentNumber:'0'}}
                    </td>
                    <!-- subUnitNumber -->
                    <td>
                        {{(l.additionalInfo.subUnitNumber)?l.additionalInfo.subUnitNumber:''}}
                    </td>
                    <!-- floor -->
                    <td>
                        {{(l.additionalInfo.floor)?l.additionalInfo.floor:'0'}}
                    </td>
                    <!-- door -->
                    <td>
                        {{(l.additionalInfo.door)?l.additionalInfo.door:'0'}}
                    </td>
                    <!-- staircaseNumber -->
                    <td>
                        {{(l.additionalInfo.staircaseNumber)?l.additionalInfo.staircaseNumber:''}}
                    </td>
                    <!-- buildingName -->
                    <td>
                        {{(l.additionalInfo.buildingName)?l.additionalInfo.buildingName:''}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="btnContent">
        <input type="button" value="Cerrar" class="btnAccion cerrar" (click)="modalService.cerrarAccion()">
        <input type="button" value="Siguiente" class="btnAccion siguiente" (click)="modalService.getcoverageLaunch()">

    </div>

</div>