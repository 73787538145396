import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoadingServiceService } from '../loading-service.service';

@Component({
    selector: 'app-gestion-bono-component',
    templateUrl: './gestion-bono-component.component.html',
    styleUrls: ['./gestion-bono-component.component.css']
})
export class GestionBonoComponentComponent implements OnInit {
    //_______BONOS_______
    bono = {
        peticion: ''
    }
    Nohaybonos = false;

    bonusForPhone
    constructor(


        private http: HttpClient,
        private cookies: CookieService,
        public loadService: LoadingServiceService
    ) {}

    ngOnInit(): void {

        this.bonusForLine();
    }



    /**
     * Método encarghado de lanzar la activacion del bono para una determinada linea
     * @param bono parametro descripcion bono
     */
    async launchBono() {
        this.loadService.showloading();
        let select = document.getElementById('bonoForLine') as HTMLSelectElement
        var selectBonus = select.selectedIndex;
        var opcionBonus = select.options[selectBonus];
        var bonusSelectedString = (opcionBonus).text;

        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass")
        });

        const body = {
            "peticion": this.cookies.get("peticion"),
            "codTercero": this.cookies.get("tercero"),
            "bono": bonusSelectedString.trim()
        }
        const url = environment.activateBonus
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.loadService.hideloading();

                if (res) {
                    if (res.id != "E001") { //No existen errores
                        Swal.fire({
                            icon: "success",
                            text: res.des
                        })

                        $('#bonoForLine').val("0"); //restes select 
                    } else { //existen errores
                        Swal.fire({
                            icon: "warning",
                            text: res.des
                        })
                    }

                } else {
                    Swal.fire({
                        icon: "warning",
                        text: "No se ha sido posible activar el bono."
                    })
                }
            }, error => {
                this.loadService.hideloading();
                // console.log(error);
                Swal.fire({
                    icon: "warning",
                    text: "No se ha sido posible activar el bono."
                })
            })
        } catch {
            this.loadService.hideloading()
        }

    }




    /**
     * Método encargado de retornar la lista de bonos que puede activar una determinada linea
     */
    async bonusForLine() {


            await this.loadService.showloading()
            try {
                const headers = new HttpHeaders({
                    'user': this.cookies.get("user"),
                    'token': this.cookies.get("token"),
                });
                this.bono = {
                    "peticion": this.cookies.get("peticion")
                }
                const url = environment.bonusForLine
                this.http.post(url, this.bono, { headers }).subscribe((res: any) => {


                    this.bonusForPhone = null;
                    if (res.ObjetoModelo) {
                        this.bonusForPhone = []
                        res.ObjetoModelo.forEach(element => {

                            if (element.Descripción != "E/Bono 100 Min (MOVIL/OPE.COSTE)") { //---Quitar bono no es válido
                                this.bonusForPhone.push({ CodObjFact: element.CodObjFact, descripcion: element.Descripción })
                            };

                        });
                    }

                    this.loadService.hideloading()
                }, error => {
                    console.log(error);
                    this.loadService.hideloading()
                });
            } catch {
                this.loadService.hideloading()
            }
        }
        /**
         * Evento encargado de la selección del bono
         * @param value 
         */
    selectionBono(value) {





        Swal.fire({
            title: "¿Deseas activar el bono para la linea? ",
            icon: "question",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "ACTIVAR",
            cancelButtonText: "CANCELAR",

        }).then(result => {
            if (result.isConfirmed) {
                this.launchBono();


            }
        })
    }


}