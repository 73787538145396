<div *ngIf="load" id="loading">
    <mat-grid-list cols=3 rowHeight=40em>
        <mat-grid-tile>

        </mat-grid-tile>
        <mat-grid-tile>
            <img id="loading-image" src="assets/img/loading2.gif" alt="Loading..." />
        </mat-grid-tile>
        <mat-grid-tile>

        </mat-grid-tile>
    </mat-grid-list>

</div>

<div class="formbox">
    <div class="cabecera">
        <label>SERVICIOS MÓVILES (LINEA: {{numero}})</label>
    </div>
    <div class="divTabla">
        <div class="container" style="width: 95%; zoom:90%;">
            <!-- <img *ngIf="load" style="height: 30px;width: 30px; padding-top: 3px;margin: auto;display: flex;" src="assets/img/loading2.gif"> -->
            <table class="table table-striped table-bordered table-sm row-border hover" datatable
                [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="dt">
                <thead style="font-family: MontserratBold; text-transform: uppercase;">
                    <tr *ngIf="data!=null">
                        <th>Nombre</th>
                        <th>Estado</th>
                        <th>Telefono</th>
                        <th>Segundos</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody style="font-family: MontserratRegular;text-align: center;">
                    <tr *ngFor="let c of data">
                        <td>{{c.nombre}}</td>
                        <td>{{c.estado}}</td>
                        <td><input [hidden]="!tiposDeComando.includes(c.comando)" id="{{c.comando}}" type="text"
                                name="usuario" value={{c.parm1}} required="required" class="form-control" /><span
                                [hidden]="!tiposDeComando.includes(c.comando)"> Empieza por código de país: 34</span>
                        </td>
                        <td>

                            <select name="param2" id="{{c.param2 + c.nombre}}" *ngIf="c.parm2"
                                (change)="modificarsegundos(c)">
                                <option value="5" [selected]=" c.comando=='CFNRY' && c.parm2=='0'">0</option>
                                <option value="5" [selected]="c.parm2=='5'">5</option>
                                <option value="10" [selected]="c.parm2=='10'">10</option>
                                <option value="15" [selected]="c.parm2=='15'">15</option>
                                <option value="20" [selected]="c.parm2=='20'">20</option>
                                <option value="25" [selected]="c.parm2=='25'">25</option>
                                <option value="30" [selected]="c.parm2=='30'">30</option>
                                <option value="35" [selected]="c.parm2=='35'">35</option>
                                <option value="40" [selected]="c.parm2=='40'">40</option>
                                <option value="45" [selected]="c.parm2=='45'">45</option>
                                <option value="50" [selected]="c.parm2=='50'">50</option>
                                <option value="55" [selected]="c.parm2=='55'">55</option>

                            </select>
                        </td>
                        <td>
                            <mat-slide-toggle [checked]="c.estado=='S'"
                                (change)="modificarServicio($event,c.nombre,c.estado,c.parm1,c.parm2,c.comando)">
                            </mat-slide-toggle>
                        </td>
                    </tr>
                </tbody>


            </table>
            <div *ngIf="data">
                <button class="buttonConfirm" (click)="starUptadeServices()">APLICAR CAMBIOS</button>
            </div>


        </div>
    </div>

</div>

<div style="height: 15em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>