import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { user } from 'src/app/model/user';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-serviciosmovil',
    templateUrl: './serviciosmovil.component.html',
    styleUrls: ['./serviciosmovil.component.css']
})
export class ServiciosmovilComponent implements OnInit {
    uDetails: user;
    data; data2: any;
    peticion; numero: any;
    tiposDeComando: string[] = ["CFU", "CFNRY", "CFB", "CFNRC"];
    arrayServiciosACambiar = null;
    counterSBien = 0;
    counterSMal = 0;
    arrayServicosMal = [];
    dtOptions: any = {
        pagingType: 'full_numbers',
        pageLength: 20,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
            'copy', 'csv', 'excel', 'print'
        ],
        language: {
            url: '//cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true
    };
    dtTrigger = new Subject();
    public load: Boolean = false;
    tlf: any
    linea = {
        telefono: ''
    };
    constructor(private http: HttpClient,
        private auth: AuthService,
        private cookies: CookieService,) { }
    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngOnInit(): void {
        this.tlf = this.auth.tlf;
        this.uDetails = this.auth.uLoged;
        this.peticion = this.cookies.get("peticion");
        this.numero = this.cookies.get("numero");
        this.getServicios();
        this.arrayServiciosACambiar = [];
    }

    /**
     * Retorna un listado de servicios
     * @returns
     */
    async getServicios() {
        await this.showloading()
        let promise = new Promise<void>((resolve, reject) => {
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });
            let data = {
                peticion: this.peticion,
                telefonoMovil: this.numero
            }

            const url = environment.endpoint + environment.getServicioMovil;
            this.http.post(url, data, { headers }).toPromise().then(
                res => { // Success
                    if (this.data != undefined) { $('#dt').DataTable().destroy(); }

                    this.data = res;
                    for (var i = 0; i < this.data.length; i++) {
                        var con = this.data[i];
                    }
                    this.dtTrigger.next();
                    this.hideloading();
                    resolve();
                },
                msg => { // Error
                    reject(msg);
                    this.hideloading();
                    Swal.fire({
                        icon: 'error',
                        title: 'ERROR',
                        text: 'Se ha producido un error al cargar los servicios.',
                        footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                        background: '#F5F5F5',
                    });
                }
            );
        });
        return promise;
    }

    /**
     * Evento de modificación de servicio
     * @param $event
     * @param nombre
     * @param estado
     * @param parm1
     * @param parm2
     * @param comando
     */
    async modificarServicio($event: MatSlideToggleChange, nombre, estado, parm1, parm2, comando) {
 
        let param1 = this.tiposDeComando.includes(comando) ? $("#" + comando).val(): parm1;
        let seRepite: boolean;
        seRepite = false;
        let indexRepe;
        let body = {
            comando: comando,
            estado: estado,
            //param1: $("#" + comando).val(),
            param1: param1,
            param2: parm2,
            telefonoMovil: this.numero,
        }

        if (this.arrayServiciosACambiar.length < 1) {
            this.arrayServiciosACambiar.push(body);
        } else {
            for (let index = 0; index < this.arrayServiciosACambiar.length; index++) {
                if (this.arrayServiciosACambiar[index].comando == body.comando) {
                    seRepite = true;
                    indexRepe = index
                }
            }
            if (seRepite == true) {
                this.arrayServiciosACambiar.splice(indexRepe, 1);
            } else {
                this.arrayServiciosACambiar.push(body);
            }
        }

    }

    //_______________________________________________MOSTRAMOS EL LOADING
    showloading() {
        this.load = true
    }

    //_______________________________________________OCULTAMOS EL LOADING
    hideloading() {
        this.load = false
    }

    starUptadeServices() {
        if (this.arrayServiciosACambiar.length == 0) {
            Swal.fire({
                title: 'ERROR',
                text: "No se ha seleccionado ningún servicio para modificar.",
                icon: 'error',
                confirmButtonText: 'Deacuerdo'
            })
        } else {
            // let serviciosM = '';
            // if (this.arrayServiciosACambiar.length == 1) {
            //     serviciosM = this.arrayServiciosACambiar[0].comando.toString() + '.'
            // } else {
            //     for (let index = 0; index < this.arrayServiciosACambiar.length; index++) {
            //         if (index == this.arrayServiciosACambiar.length - 1) {
            //             serviciosM = serviciosM + this.arrayServiciosACambiar[index].comando.toString() + '.'
            //         } else if (index == this.arrayServiciosACambiar.length - 2) {
            //             serviciosM = serviciosM + this.arrayServiciosACambiar[index].comando.toString() + ' y '
            //         } else {
            //             serviciosM = serviciosM + this.arrayServiciosACambiar[index].comando.toString() + ', '
            //         }
            //     }
            // }
            let serviciosLog = '';
            if (this.arrayServiciosACambiar.length == 1) {
                serviciosLog = "Se va a modificar " + this.arrayServiciosACambiar.length.toString() + " servicio";
            } else {
                serviciosLog = "Se van a modificar " + this.arrayServiciosACambiar.length.toString() + " servicios";
            }

            Swal.fire({
                title: '¿DESEA CONTINUAR CON EL CAMBIO?',
                text: serviciosLog + ". En caso de pulsar en cancelar se restablecerá la lista.",
                icon: 'warning',

                confirmButtonColor: '#00b1aa',
                denyButtonColor: '#d33',
                confirmButtonText: 'MODIFICAR',
                denyButtonText: 'CANCELAR',
                showDenyButton: true,
            }).then((result) => {
                if (result.isConfirmed) { //Se lanzaran los servicios seleccionados
                    this.realizarCambios();
                } else {
                    this.arrayServiciosACambiar = [];
                    this.getServicios();
                }
            })
        }
    }

    realizarCambios() {
        this.arrayServiciosACambiar.forEach(async element => {
            if (element.estado == 'S') {
                element.estado = 'N';
            } else {
                element.estado = 'S';
            }
            let body = {
                comando: element.comando,
                estado: element.estado,
                parm1: element.param1,
                parm2: element.param2,
                telefonoMovil: element.telefonoMovil,
            }
            await this.runOneService(body);
        });
    }

    runOneService(body) {
        let promise = new Promise((resolve, reject) => {
            this.showloading();
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });

            const url = environment.endpoint + environment.modificarServicioMovil;
            this.http.post(url, body, { headers })
                .toPromise()
                .then(
                    (res: any) => { // Success
                        this.counterSBien++;
                     
                        let sumaCounter = this.counterSBien + this.counterSMal;
                        if (this.arrayServiciosACambiar.length == sumaCounter) {
                            this.hideloading();
                            this.terminarProceso();
                        }

                    },
                    (error: any) => { // Error
                        this.counterSMal++;
                        this.arrayServicosMal.push(body);
                        let sumaCounter = this.counterSBien + this.counterSMal;
                        if (this.arrayServiciosACambiar.length == sumaCounter) {
                            this.hideloading();
                            this.terminarProceso();
                        }
                    }
                );
        });
        return promise;
    }

    terminarProceso() {

        if (this.arrayServiciosACambiar.length == this.counterSBien) {
            Swal.fire(
                'PROCESO CORRECTO',
                'Todos los servicios se han modificado con exito.',
                'success'
            )
        } else if (this.counterSBien > 0 && this.counterSBien < this.arrayServiciosACambiar.length) {
            let serviciosMal = '';
            if (this.arrayServicosMal.length == 1) {
                serviciosMal = this.arrayServicosMal[0].comando.toString() + '.'
            } else {
                for (let index = 0; index < this.arrayServicosMal.length; index++) {
                    if (index == this.arrayServicosMal.length - 1) {
                        serviciosMal = serviciosMal + this.arrayServicosMal[index].comando.toString() + '.'
                    } else if (index == this.arrayServiciosACambiar.length - 2) {
                        serviciosMal = serviciosMal + this.arrayServicosMal[index].comando.toString() + ' y '
                    } else {
                        serviciosMal = serviciosMal + this.arrayServicosMal[index].comando.toString() + ', '
                    }
                }
            }
            Swal.fire(
                'NO SE HAN REALIZADO TODOS LOS CAMBIOS',
                'Los siguientes servicios no se han podido modificar: ' + serviciosMal,
                'warning'
            )
        } else {
            Swal.fire(
                'ERROR',
                'Los servicios no se han podido modificar.',
                'error'
            )
        }
        this.counterSMal = 0;
        this.counterSBien = 0;
        this.arrayServiciosACambiar = [];
        this.arrayServicosMal = [];
        this.getServicios();
    }

    async modificarsegundos(comando) {
        this.showloading();
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });

        const body = {
            "comando": comando.comando,
            "estado": comando.estado,
            "tercero": this.cookies.get("tercero"),
            "parm1": comando.parm1,
            "parm2": comando.parm2,
            "telefonoMovil": this.cookies.get("numero")
        }

        const url = environment.endpoint + environment.modificarServicioMovil;
        this.http.post(url, body, { headers }).subscribe(
            (res: any) => { // Success
                this.hideloading();
                Swal.fire(
                    'Modificado',
                    'Servicio modificado con exito',
                    'success'
                )
                // resolve();
            },
            (error: any) => { // Error
                this.arrayServiciosACambiar = []; //Se descartaran los cambios
                this.hideloading();
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'Se ha producido un error al modificar los servicios.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
            }
        )
    }
}
