<div class="wrapper_loading" *ngIf="this.loadService.load;else multisims">
    <img class="loading" src="assets/img/loading2.gif">
</div>

<ng-template #multisims>
    <table>
        <tr>
            <th>Pet_Principal</th>
            <th>Pet_MultiSim</th>
            <th>Iccid_MultiSim</th>
        </tr>
        <tr *ngFor="let sim of this.simService.multiSIM">
            <td>{{ sim.getPeticionPrincipal() }}</td>
            <td>{{ sim.getPeticionMultiSIM() }}</td>
            <td>{{ sim.getICC() }}</td>
        </tr>
    </table>
</ng-template>