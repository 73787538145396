//______________________________________________________________________FORMAT DATEPICKER________________________________________________________________________________________
export class AppDateAdapter extends NativeDateAdapter {
    getFirstDayOfWeek(): number {
        return 1;
    }
    format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'input') {

            let day: string = date.getDate().toString();
            day = +day < 10 ? '0' + day : day;
            let month: string = (date.getMonth() + 1).toString();
            month = +month < 10 ? '0' + month : month;
            let year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }else{
            let monthsYear = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", 
                "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
            let year = date.getFullYear();
            let mes = monthsYear[date.getMonth().toString()] 
            return `${mes} ${year}`;
        }
    }
}

export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import jsPDF from 'jspdf';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { user } from 'src/app/model/user';
import { AuthService } from 'src/app/services/auth.service';
import { RepositoryDocumentationService } from 'src/app/services/repository-Documentation.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { CambioTitularService } from './cambio-titular.service';

@Component({
    selector: 'app-create-client-fijo',
    templateUrl: './create-client-fijo.component.html',
    styleUrls: ['./create-client-fijo.component.css'],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class CreateClientFijoComponent implements OnInit {
    // @Input() resetFormSubject: Subject < boolean > = new Subject < boolean > ();
    @Input() datos: any; 
    con:any;
    poblacion;
    provincia;
    postcode;
    pais: any;
    title = 'rou';
    //Local Variable defined
    formattedaddress = " ";
    options = {
        componentRestrictions: {
            country: ["ES"]
        },
        types: ['address']
    }
    uDetails: user;
    public load: boolean = false;
    public userdata: Boolean = false;
    registroForm: FormGroup;
    data;
    data2;
    data3;
    data4;
    data5;
    data7: any;
    aux: any;
    nif = "";
    tiponif;
    idcliente;
    nombre;
    apellido1;
    apellido2;
    fechanac;
    tipocliente: any;
    direccion;
    cp;
    tipoCalle: any;
    contratoOficial;
    cuarto;
    bono: any;
    doc1;
    doc2;
    tipo1;
    tipo2;
    tipo3: any;
    nombre1;
    nombre2;
    contrato = {
        username: '',
        password: ''
    };
    ext1: any;
    ext2: any;
    currentTime = new Date().valueOf();
    clienteExists: boolean = false;
    paisModel;
    paisSelectedDisable = false;
    constructor(
        public cambioTitularServ: CambioTitularService,
        private sanitizer: DomSanitizer,
        private formBuilder: FormBuilder,
        private http: HttpClient,
        private auth: AuthService,
        private datePipe: DatePipe,
        private cookie: CookieService,
        private repositoryService: RepositoryDocumentationService
    ) { }

    ngOnInit(): void {
        this.clienteExists = false;
        this.getPais();
        this.initFormValidate();
        this.paisModel = "0"
        this.uDetails = this.auth.uLoged;
        this.con=this.datos.cttprv;  
    }

    getContratoSelected(value) {
        this.contratoOficial = value;
    }

    /**
     * Inicialización del contrato
     */
    initFormValidate() {
        this.registroForm = this.formBuilder.group({
            'nif': ['', [Validators.required]],
            'tiponif': ['', [Validators.required]],
            'nombre': ['', [Validators.required]],
            'apellido1': ['', [Validators.required]],
            'apellido2': ['', [Validators.required]],
            'fechanac': ['', [Validators.required]],
            'direccion': ['', [Validators.required]],
            'cp': ['', [Validators.required]],
            'tipocliente': ['', [Validators.required]],
            'poblacion': ['', [Validators.required]],
            'localidad': ['', [Validators.required]],
            'documents': ['', [Validators.required]],
            // 'paisSelected': ['', [Validators.required]],
            'conSub': ['', [Validators.required]]
        });
    }

    //__________________________________________CREANDO_USUARIO
    async setUsuario() {
        await this.cambioTitularServ.showloading();
        let promise = new Promise < void > ((resolve, reject) => {
            this.data = {
                nif: this.registroForm.get('nif').value,
                tiponif: this.registroForm.get('tiponif').value,
                idcliente: this.registroForm.get('idcliente').value,
                nombre: this.registroForm.get('nombre').value,
                apellido1: this.registroForm.get('apellido1').value,
                apellido2: this.registroForm.get('apellido2').value,
                fechanac: this.datePipe.transform(this.registroForm.get('fechanac').value, 'yyyyMMdd'),
                direccion: (this.registroForm.get('direccion').value + this.registroForm.get('cp').value + this.registroForm.get('tipoCalle').value),
                tipocliente: this.registroForm.get('tipocliente').value,
            }
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });

            const url = environment.endpointdatos + environment.setUsuarioFijo;
            try {
                this.http.post(url, this.data, { headers }).toPromise().then(
                    res => { // Success
                        resolve();
                        this.cambioTitularServ.hideloading();
                        Swal.fire(
                            'Completado',
                            'Usuario creado correctamente',
                            'success'
                        );
                    },
                    msg => { // Error
                        reject(msg);
                        this.cambioTitularServ.hideloading();
                        Swal.fire({
                            icon: 'error',
                            title: 'ERROR',
                            text: 'Compruebe la información introducida.',
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                            background: '#F5F5F5',
                        });
                    }
                );
            } catch {
                this.cambioTitularServ.hideloading();
            }
        });
        return promise;
    }

    //__________________________________________ENVIAR_DOCUMENTO
    setDoc(event, t: string) {
        const file = event.target.files[0];

        let fileSizeMb = (file.size / 1024) / 1024;
        if (fileSizeMb > 5) {
            Swal.fire({
                title: "ARCHIVO DEMASIADO GRANDE",
                icon: "warning",
                text: "El archivo supera el límite de 5Mb."
            });
            this.load = false;
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            switch (t) {
            case "Documentacion":
                this.doc1 = reader.result;
                this.nombre1 = file.name.split(".")[0];
                this.ext1 = file.name.split(".").pop();
                break;

            case "ContratoDeSubrrogacion":
                this.doc2 = reader.result;
                this.nombre2 = file.name.split(".")[0];
                this.ext2 = file.name.split(".").pop();
                break;
            }
        };
    }

    /**
     * Sube documentacion o crea el path si el cliente ha dado de alta a una linea movil
     */
    subirDocumentacionSiExisteAdjuntada(telefono: String) {
        let terceroOperador = this.cookie.get("tercero");
        if (this.doc1) {
            this.repositoryService.uplodaDocumentsFijo(this.doc1, this.ext1, terceroOperador, telefono, this.nombre1);
        }
        if (this.doc2) {
            this.repositoryService.uplodaDocumentsFijo(this.doc2, this.ext2, terceroOperador, telefono, this.nombre2);
        }
    }

    /**
     * Retorna la fecha actual en formato string DD-MM-AAAA
     * @returns 
     */
    dateNowToString() {
        let date = new Date();
        let mes = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        return date.getDate() + "_" + mes + "_" + date.getFullYear() + "_" + date.getHours() + "_" + date.getMinutes() + "_" + date.getSeconds();
    }

    //_________________________________VALIDADORES
    isNif(documento) {
        let lettersEnd = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];
        let isNif = false;
        if (documento.length == 9) {
            var letter = documento.substring(8, 9);
            var number = parseInt(documento.substring(0, 8));
            if (!isNaN(number) && isNaN(parseInt(letter))) {
                if (lettersEnd[number % 23] === letter) {
                    isNif = true;
                }
            }
        }
        return isNif;
    }
    /**
     * Validador de nie
     * @param documento 
     * @returns 
     */
    isNie(documento) {
        let isNie = false;
        let num = [];
        if (documento.match('^[XYZ][0-9][0-9][0-9][0-9][0-9][0-9][0-9][A-Z0-9]')) {
            for (let i = 0; i < 9; i++) {
                var aux = i;
                num[i] = documento.substr(i, aux++);
            }
            let letters = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];
            let dniTransform = documento.replaceAll('X', '0').replaceAll('Y', '1').replaceAll('Z', '2');
            if (num[8] === letters[parseInt(dniTransform.substring(0, 8)) % 23]) {
                isNie = true;
            }
        }
        return isNie;
    }

    /**
     * Validador de Cif
     * @param documento 
     * @returns 
     */
    validaCif(documento) {
        let isCif = false;
        if (documento == "") {
            return isCif;
        }
        let pares = 0;
        let impares = 0;
        let suma;
        let ultima;
        let unumero;
        let uletra = new Array("J", "A", "B", "C", "D", "E", "F", "G", "H", "I");
        let xxx;

        documento = documento.toUpperCase();

        let regular = new RegExp(/^[ABCDEFGHKLMNPQS]\d\d\d\d\d\d\d[0-9,A-J]$/g);
        if (!regular.exec(documento)) {}

        ultima = documento.substr(8, 1);

        for (var cont = 1; cont < 7; cont++) {
            xxx = (2 * parseInt(documento.substr(cont++, 1))).toString() + "0";
            impares += parseInt(xxx.substr(0, 1)) + parseInt(xxx.substr(1, 1));
            pares += parseInt(documento.substr(cont, 1));
        }
        xxx = (2 * parseInt(documento.substr(cont, 1))).toString() + "0";
        impares += parseInt(xxx.substr(0, 1)) + parseInt(xxx.substr(1, 1));

        suma = (pares + impares).toString();
        unumero = parseInt(suma.substr(suma.length - 1, 1));
        unumero = (10 - unumero).toString();
        if (unumero == 10) unumero = 0;

        if (ultima == unumero || ultima == uletra[unumero]) {
            isCif = true;
            return isCif;
        } else {
            return isCif;
        }
    }

    showloading() {
        this.load = true;
    }
    hideloading() {
        this.load = false;
    }

    //_________________________________CREAR_USUARIO_NIF/CIF/NIE
    crearUsuario() {
        if (this.registroForm.valid) {
            let valor = false;
            let nif = this.registroForm.get('nif').value;
            switch (this.registroForm.get('tiponif').value.toString().toUpperCase()) {
            case 'N':
            case 'C':
                if(this.isNif(nif) || this.isNie(nif) || this.validaCif(nif)){
                    valor = true;
                }
                break;

            case 'P':
                (nif && nif.length <= 30) ? valor = true : valor = false;
                break;

            default:
                break;
            }

            if (valor === true) {
                this.data = {
                    nif: this.registroForm.get('nif').value,
                    tiponif: this.registroForm.get('tiponif').value,
                    idcliente: this.cookie.get("tercero"),
                    nombre: this.registroForm.get('nombre').value,
                    apellido1: this.registroForm.get('apellido1').value,
                    apellido2: this.registroForm.get('apellido2').value,
                    fechanac: this.datePipe.transform(this.registroForm.get('fechanac').value, 'yyyyMMdd'),
                    direccion: this.registroForm.get('direccion').value,
                    codigopostal: this.registroForm.get('cp').value,
                    poblacion: this.registroForm.get('poblacion').value,
                    provincia: this.registroForm.get('localidad').value,
                    // direccion: this.direccion + this.cp,
                    tipocliente: this.registroForm.get('tipocliente').value,
                    pais: this.paisModel
                }
                const headers = new HttpHeaders({
                    'user': this.cookie.get("user"),
                    'token': this.cookie.get("token"),
                    'pass': this.cookie.get("pass")
                });
                this.showloading();

                const url = environment.endpointdatos + environment.setUsuarioFijo;
                this.http.post(url, this.data, { headers }).subscribe((res: any) => { // Success
                        this.hideloading();
                        this.data3 = res;

                        if (this.data3.error) {
                            if (this.data3.error.includes("Ya existe codigo para este cliente:")) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'ERROR',
                                    text: 'El usuario que intenta registrar ya existe.',
                                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                                    background: '#F5F5F5',
                                });
                                return;
                            }
                            Swal.fire({
                                icon: 'success',
                                title: 'COMPLETADO',
                                text: 'El usuario ' + this.data.nombre + ' ' + this.data.apellido1 + ' ' + this.data.apellido2 + ' ha sido registrado correctamente.',
                                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                                background: '#F5F5F5',
                            });
                        } else {
                            Swal.fire(
                                'Completado',
                                'Usuario creado correctamente.',
                                'success'
                            );
                        }
                    },
                    (error: any) => { // Error
                        this.hideloading();
                        Swal.fire({
                            icon: 'error',
                            title: 'ERROR',
                            text: 'Se ha producido un error al crear el usuario. Revise los campos y vuelva intentarlo.',
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                            background: '#F5F5F5',
                        });
                    }
                );
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'El documento de identidad no es válido.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                });
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'ERROR',
                text: 'Compruebe la información introducida. Rellene todos los campos.',
                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                background: '#F5F5F5',
            });
        }
    }

    addressChange(address: any) {
        if (address.address_components) {
            for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
                const componentType = component.types[0];
                switch (componentType) {
                case "postal_code":
                    this.postcode = `${component.long_name}`;
                    break;

                case "locality":
                    this.poblacion = `${component.long_name}`;
                    break;

                case "administrative_area_level_2":
                    this.provincia = `${component.long_name}`;
                    break;

                case "country":
                    this.pais = `${component.long_name}`;
                    break;
                }
            }
        }
    }

    async getUsuario() {        
        await this.showloading();
        let promise = new Promise < void > ((resolve, reject) => {
            this.data4 = {
                nif: this.registroForm.get('nif').value.toString().toUpperCase(),
                "tercero": this.cookie.get('tercero'),
                "contratoProvisional": this.con 
            }
            
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });

            const url = environment.endpoint + environment.getUsuario;
            try {
                this.http.post(url, this.data4, { headers }).toPromise().then(
                    res => {
                        this.data5 = res;
                        if (this.data5.id) {
                            this.hideloading();
                            Swal.fire({
                                icon: 'error',
                                title: 'ERROR',
                                text: 'Introduzca un documento válido.',
                                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                                background: '#F5F5F5',
                            });
                        } else {
                            let documento, tcliente;
                            if (this.data5.tipo == "F") {
                                documento = "N";
                            } else if (this.data5.tipo == "J") {
                                documento = "C";
                            } else if (this.data5.tipo == "E") {
                                documento = "P";
                            }else if (this.data5.tipo == "R") {
                                documento = "N"
                            }


                            if (this.data5.tcli == "R") {
                                tcliente = "R";
                            } else if (this.data5.tcli == "S") {
                                tcliente = "S";
                            } else if (this.data5.tcli == "A") {
                                tcliente = "A";
                            }

                            if (this.data5.fecha) {
                                var splitted = this.data5.fecha.split("/", 3);
                                var mes = splitted[1];
                                var dia = splitted[0];
                                var ano = splitted[2];
                                var formatmes = mes;
                                var formatdia = dia;
                                var formatano = ano;
                            }

                            this.paisModel = "34";
                            this.registroForm.patchValue({
                                apellido1: this.data5.ape1,
                                apellido2: this.data5.ape2,
                                nombre: this.data5.nom,
                                idcliente: this.data5.cod,
                                cp: this.data5.cp,
                                direccion: this.data5.dir,
                                fechanac: new Date(formatano + "/" + formatmes + "/" + formatdia),
                                tiponif: documento,
                                poblacion: this.data5.poblacion,
                                localidad: this.data5.localidad,
                                tipocliente: tcliente,
                                pais: this.data5.pais
                            })
                            let controls = ["nombre", "apellido1", "apellido2", "tiponif", "fechanac", "tipocliente", "direccion", "poblacion", "localidad", "cp"]
                            controls.forEach(element => {
                                this.registroForm[element].disable();
                            });
                            this.userdata = true;
                            this.paisSelectedDisable = true;
                            this.cookie.set("cuarto", this.data5.cod);
                            resolve();
                            this.clienteExists = true;
                            this.hideloading();
                        }
                    },
                    msg => { // Error
                        Swal.fire({
                            icon: 'error',
                            title: 'ERROR',
                            text: 'Se ha producido un error al cargar el usuario.',
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                            background: '#F5F5F5',
                        });

                        this.paisSelectedDisable = false;
                        reject(msg);
                        this.hideloading();
                    }
                );
            } catch {
                this.hideloading();
            }
        });
        return promise;
    }

    //_________________________________CARGAR DATOS DE PAÍS
    getPais() {
        this.showloading();
        let promise = new Promise < void > ((resolve, reject) => {
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });

            const url = environment.endpoint + environment.getPais;
            try {
                this.http.post(url, {}, { headers }).toPromise().then(
                    res => {
                        this.data7 = res;
                        for (var i = 0; i < this.data7.length; i++) {
                            var con = this.data7[i];
                        }
                        this.hideloading();
                        resolve();
                    },
                    msg => { // Error
                        reject(msg);
                        this.hideloading();
                        Swal.fire({
                            icon: 'error',
                            title: 'ERROR',
                            text: 'Se ha producido un error al cargar los países.',
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                            background: '#F5F5F5',
                        });
                    }
                );
            } catch {
                this.hideloading();
            }
        });
        return promise;
    }

    /**
     * Evento que se llevará a cabo para realizar el cambio
     */
    realizarCambio() {
        if (!this.clienteExists) {
            Swal.fire({
                icon: 'error',
                title: 'ERROR',
                text: 'No existe un cliente para realizar el cambio. Puede crear uno o bucar uno existente por su documento de identidad.',
                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                background: '#F5F5F5',
            });
            return;
        } else if (!this.doc1) {
            Swal.fire({
                icon: 'error',
                title: 'ERROR',
                text: 'Se debe adjuntar la documentación.',
                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                background: '#F5F5F5',
            });
            return;
        } else if (!this.doc2) {
            Swal.fire({
                icon: 'error',
                title: 'ERROR',
                text: 'Se debe adjuntar el documento de subrrogación.',
                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                background: '#F5F5F5',
            });
            return;
        } else { //Todo esta adjuntado y se puede realizar el cambio
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });

            const body = {
                "tercero": this.cookie.get("tercero"),
                "telefono": this.cookie.get("telefono"),
                "cuarto": this.cookie.get("cuarto")
            }

            this.cambioTitularServ.showloading();
            const url = environment.cambioTitularFijo;
            try {
                this.http.post(url, body, { headers }).subscribe((res: any) => {
                    this.cambioTitularServ.hideloading();
                    Swal.fire({
                        icon: 'success',
                        title: 'COMPLETADO',
                        text: "El cambio de titularidad se ha realizado con exito.",
                        footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                        background: '#F5F5F5',
                    });
                    this.subirDocumentacionSiExisteAdjuntada(this.cookie.get("telefono")); //Subir la documentacion
                    this.cambioTitularServ.buscar = false;
                    this.cambioTitularServ.findNumberFijo(this.cookie.get("telefono")); //reset Values
                    },
                    (error: any) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'ERROR',
                            text: error.error.des,
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                            background: '#F5F5F5',
                        });
                        this.cambioTitularServ.hideloading();
                    })
            } catch {
                this.cambioTitularServ.hideloading();
            }
        }
    }

    /**
     * Método encargado de retornar el contrato de subrrogacion
     */
    descargarContratoSub() {
        let link = document.createElement("a");
        link.download = "CONTRATO_DE_SUBROGACION.pdf";
        link.href = "assets/img/CONTRATO_DE_SUBROGACION.pdf";
        link.click();
    }
}