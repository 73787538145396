<div class="formbox">

    <div class="contentLoad" *ngIf="load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>
    <div class="form1">
        <label class="titlePage">GRUPO COMPARTIDO (MÓVIL) - ALTA</label>
        <!-- SELECT CONTRATO -->
        <div class="customRow">
            <label for="contratos">CONTRATO</label>
            <select class="form-control" name="contratos" class="contratoF" (change)="getlineas($event.target.value)">
                <option value="0">SELECCIONE UN CONTRATO:</option>
                <option value={{d.cof}}|{{d.con}} *ngFor="let d of data2">{{d.des}} - {{d.cof}} {{d.alias ? '('+d.alias+')' : ''
                    }}</option>
            </select>
        </div>

        <!-- SELECT TARIFA FAMILIAR -->
        <div class="customRow">
            <label for="tarifa">TARIFA FAMILIAR</label>
            <select class="form-control" name="tarifa" class="contratoF" [disabled]="tarifasFamiliares==null"
                (change)="selectTarifa($event.target.value)">
                <option value="0">SELECCIONE TARIFA FAMILIAR:</option>
                <option value={{d.des}} *ngFor="let d of tarifasFamiliares">{{d.nom}} </option>
            </select>
        </div>

        <div class="customRow" *ngIf="tarifasFamiliares">
            <label for="documento">BÚSQUEDA POR DNI:</label>
            <input type="text" class="documento" [(ngModel)]="documentoCliente" name="documento"  maxlength="9" minlength="9">
        </div>

        <button class="submit" (click)="buscarLineas()">Buscar Líneas</button>

        <div class="customRow2" *ngIf="lineasCliente">
            <mat-grid-list cols="2" rowHeight="2.5em" style="width: 100%;">
                <mat-grid-tile [colspan]=1 [rowspan]=1
                    style="background-color: #808080; color: white; font-family: MontserratBold;">LISTA LINEAS
                </mat-grid-tile>
                <mat-grid-tile [colspan]=1 [rowspan]=1
                    style="background-color: #808080; color: white; font-family: MontserratBold;">LINEAS A AÑADIR
                </mat-grid-tile>
                <mat-grid-tile [colspan]=1 [rowspan]=3 style="padding: 0.5em;" >
                    <div class="listLineas">
                        <table class="lineasCliente">
                            <tr *ngFor="let linea of lineasCliente" class="itemLineaCliente"
                                (click)="appendGroup(linea)" title="CLICK PARA AÑADIR LA LINEA: {{linea.telf}}" >
                                [{{linea.telf}}]--[{{linea.des}}]</tr>
                        </table>
                    </div>
                </mat-grid-tile>

                <mat-grid-tile [colspan]=1 [rowspan]=3 style="padding: 0.5em;">
                    <div class="listLineas">
                        <table class="lineasCliente">
                            <tr *ngFor="let linea of lineasAppendGroup" class="itemLineaCliente"
                                (click)="removeLinesGroup(linea)" title="CLICK PARA QUITAR LA LINEA: {{linea.telf}}">
                                [{{linea.telf}}]--[{{linea.des}}]
                            </tr>
                        </table>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </div>

        <div class="customRow" *ngIf="lineasAppendGroup" style="margin-top: 0.5em;">
            <button class="contratoF btnCreateGroup" (click)="launchCreateGroup()" *ngIf="lineasAppendGroup">
                Crear Grupo</button>
        </div>

    </div>

</div>

<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>