import { state } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { IpServiceService } from 'src/app/ip-service.service';
import { user } from 'src/app/model/user';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import {DeviceDetectorService} from 'ngx-device-detector';
import { platform } from 'os';
import { Platform } from '@angular/cdk/platform';
import { log } from 'console';

@Component({
    selector: 'app-first-form',
    templateUrl: './first-form.component.html',
    styleUrls: ['./first-form.component.css'],
})
export class FirstFormComponent implements OnInit {
    deviceInfo:any = null;
    idEquipo: any;
    usuario: string;
    clave: string;
    loginForm: FormGroup;
    ipAddress: string;
    public data: user;
    public load: Boolean = false;

    postData = {
        user: '',
        pass: '',
        ip: ''
    };
    url = environment.endpoint;
    json;

    constructor(public auth: AuthService,
        private http: HttpClient,
        public router: Router,
        private formBuilder: FormBuilder,
        private ip: IpServiceService,
        private cookies: CookieService,
        private deviceService: DeviceDetectorService) {}

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            'usuario': ['', [Validators.required]],
            'clave': ['', [Validators.required]]
        });
        this.getIP(); //Registramos la ip del cliente
    }

    private get header(): any {
        return {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        }
    }

    /**
     * En esta funcion realizamos una peticion post al servicio para recibir el usuario.
     * Introducimos el usuario y la clave con el formulario y añadimos la ip del cliente.
     */
    async login() {
        this.showloading();
            // this.data = {
            //   user: this.loginForm.get('usuario').value,
            //   pass: this.loginForm.get('clave').value,
            //   ip: this.ipAddress,
            //   token: 
            // }

        let body = {
            user: this.loginForm.get('usuario').value,
            pass: this.loginForm.get('clave').value,
            ip: this.ipAddress,
            token: environment.GENERICTOKEN // token generico de acceso a wholesale
        }

        if (this.loginForm.valid) {
            if (body.user == "30814831Y"){
                await this.sendDeviceData();
            }
            await this.auth.login(body);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'ERROR',
                text: 'Compruebe la información introducida. Rellene todos los campos.',
                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                background: '#F5F5F5',
            })
        }
        this.load = false;
    }
    async sendDeviceData(){
        this.deviceInfo = this.deviceService.getDeviceInfo();

        
        this.http.post("https://apimovil.ptvtelecom.com/api/prueba", this.deviceInfo)
        .subscribe(
        (data) =>{

        },
        (error)=>{

        });
    }

    /**
     * Obtenemos la direccion ip del cliente
     */
    getIP() {
        this.ip.getIPAddress().subscribe((res: any) => {
            this.ipAddress = res.ip;
            this.cookies.set("ip", this.ipAddress);
        });
    }
        //_______________________________________________MOSTRAMOS EL LOADING

    showloading() {
        this.load = true;
    }
        //_______________________________________________OCULTAMOS EL LOADING
    hideloading() {
        this.load = false;
    }

    /**
     * Evento encargado de mostrar el modal para el reinicio de la contraseña
     */
    // forgotPassw() {
    //     Swal.fire({
    //         title: "Recuperación de contraseña",
    //         showCancelButton: true,
    //         showConfirmButton: true,
    //         confirmButtonText: "Enviar mail de recuperación",
    //         cancelButtonText: "Cancelar la petición",
    //         html: `<lable for="dniInput"  class="swal2-label">Introduzca su Usuario</lable>
    //         <input name="dniInput" id="dniInput" class=" swal2-input dniModal" placeholder="INTRODUZCA SU USUARIO"  maxlength="9">`
    //     }).then(result => {
    //         if (result.isConfirmed) {
    //             this.resetPassRun();
    //         }
    //     })
    // }

    /**
     * Método encargado de lanzar la peticion de reseteo de la contraseña de un operador
     */
    async resetPassRun() {
        this.showloading();
        let nifOperador = $("#dniInput").val();

        nifOperador = nifOperador.toString();
        const headers = new HttpHeaders({
            'user': nifOperador,
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });
        const url = environment.resetPassword;
        try {
            this.http.post(url, null, { headers }).subscribe((res: any) => {
                this.hideloading();

                Swal.fire({
                    icon: 'success',
                    title: 'COMPLETADO',
                    // text: 'La nueva contraseña fué enviada a su email, porfavor revise su bandeja de entrada',
                    text: res.detalle,
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                });
            },
            error => {
                this.hideloading();
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: error.error.detalle,
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
            })
        } catch {
            this.hideloading();
        }
    }
}