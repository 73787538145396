<div class="topnav" *ngIf="getNavBar()">
    <div style="width: 100%;">
        <mat-grid-list cols=3 rowHeight=3em>
            <mat-grid-tile>
                <div style="position: absolute;left: 5px;">
                    <img (click)="goBack()" src="assets/img/NuevoD/backbutton.svg" class="volver" style="float: left;padding-right: 10px;">
                    <a (click)="movil.toggle() && fijo.close() && fibra.close()" class="mainItem"><label
                            class="mainItemT">MÓVIL</label></a>
                    <a (click)="fijo.toggle() && fibra.close() && movil.close()" class="mainItem"><label
                            class="mainItemT">FIJO</label></a>
                            <a *ngIf="muestraFibra" (click)="fibra.toggle() && fijo.close() && movil.close() && seleccionarTipo(tipoFibraSelecc) " class="mainItem"><label
                                class="mainItemT">FIBRA</label></a>
                    <a  *ngIf="usuario==doc" mat-list-item [routerLink]="'/invoices'" (click)="movil.close()">
                        <label class="mainItemT">FACTURACIÓN</label></a>
                </div>
            </mat-grid-tile>
            <mat-grid-tile>
                <img routerLink="/welcome" src="assets/img/NuevoD/logoPTV.svg"
                    (click)="fibra.close() && fijo.close() && movil.close()" class="logo">
            </mat-grid-tile>
            <mat-grid-tile>
                <div style="position: absolute;right: 5px;">
                    <img src="assets/img/NuevoD/iconoMenu.svg" class="burguer" mat-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" id="settingsButton" (click)="ajustesLaunch()">
                    <mat-menu #menu="matMenu" [hasBackdrop]="false">
                        <div (mouseleave)="menuTrigger.closeMenu()">
                            <ng-template matMenuContent>
                                <div mat-menu-item>
                                    <div (click)="updateLogotypeOperator()">Cambiar Logotipo</div>
                                </div>
                                <div mat-menu-item>
                                    <div (click)="logout()">Salir de la plataforma</div>
                                </div>
                            </ng-template>
                        </div>
                    </mat-menu>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>

</div>
<mat-sidenav-container>

    <div class="contentLoad" *ngIf="cdrService.loading ">
        <img class="loading" src="assets/img/loading2.gif">
    </div>
    <router-outlet></router-outlet>
    <!--MENU MOVIL------------------------------------------------------------------------------------------>
    <mat-sidenav #movil position="start" autoFocus="true">
        <mat-nav-list>
            <mat-accordion #accordion="matAccordion">

                <!-- [class.mat-elevation-z0]=" true "  -->
                <mat-expansion-panel dense style="width: 90%; " #mapanel="matExpansionPanel">

                    <mat-expansion-panel-header collapsedHeight="48px ">
                        <img src="assets/img/page.svg " class="icono "> Solicitudes
                    </mat-expansion-panel-header>
                    <mat-nav-list>
                        <a mat-list-item (click)="goToInitmovil() " dense
                            class="submenu "><img src="assets/img/editar.svg " class="icono ">Nueva Solicitud</a>
                        <!-- <a mat-list-item [routerLink]="'#'" (click)="movil.close() " dense class="submenu "><img src="assets/img/Upload.svg " class="icono ">Subir Documentos</a> -->
                        <a mat-list-item [routerLink]="'/viewDocs'" (click)="movil.close() && closeAllPanels() " dense
                            class="submenu "><img src="assets/img/archivo-pdf.svg " class="icono ">Ver Documentación</a>
                    </mat-nav-list>
                </mat-expansion-panel>
                <mat-expansion-panel [class.mat-elevation-z0]="true " dense style="width: 90%; ">

                    <mat-expansion-panel-header collapsedHeight="48px ">
                        <img src="assets/img/grupo-de-usuari.svg " class="icono "> Grupo Compartido
                    </mat-expansion-panel-header>
                    <mat-nav-list>
                        <a mat-list-item [routerLink]="'/shared-group'" (click)="movil.close() && closeAllPanels()"
                            dense class="submenu "><img src="assets/img/editar.svg " class="icono ">Alta</a>
                        <a mat-list-item [routerLink]="'/add-shared-group-page'"
                            (click)="movil.close() && closeAllPanels()" dense class="submenu "><img
                                src="assets/img/mas.svg " class="icono ">Añadir Números
                        </a>
                        <a mat-list-item [routerLink]="'/down-shared-group-page'"
                            (click)="movil.close() && closeAllPanels()" dense class="submenu "><img
                                src="assets/img/menos.svg " class="icono ">Baja Número
                        </a>
                        <a mat-list-item [routerLink]="'/list-shared-group-page'"
                            (click)="movil.close() && closeAllPanels()" dense class="submenu "><img
                                src="assets/img/lista.svg " class="icono ">Lista</a>
                    </mat-nav-list>
                </mat-expansion-panel>
                <a mat-list-item [routerLink]=" '/control-bajas-page' " (click)="movil.close() && closeAllPanels()"
                    class="menuoption "><img src="assets/img/flecha-hacia-abajo.svg " class="icono "> Control Bajas </a>

                <a mat-list-item [routerLink]=" '/gestion-portabilidades-page' "
                    (click)="movil.close() && closeAllPanels()" class="menuoption "><img src="assets/img/flecha.svg "
                        class="icono "> Gestión Portabilidades </a>
                <mat-expansion-panel [class.mat-elevation-z0]="true " dense style="width: 90%; ">

                    <mat-expansion-panel-header collapsedHeight="48px ">
                        <img src="assets/img/llamada-telefonica.svg " class="icono "> Líneas
                    </mat-expansion-panel-header>
                    <mat-nav-list>
                        <a mat-list-item [routerLink]="'/lineamovil' " (click)="movil.close() && closeAllPanels()" dense
                            class="submenu "><img src="assets/img/grupo.svg " class="icono ">Líneas Por Contrato</a>
                        <a mat-list-item [routerLink]="'/advanced-find'" (click)="movil.close() && closeAllPanels()"
                            dense class="submenu "><img src="assets/img/lupa.svg " class="icono ">Búsqueda Avanzada</a>
                    </mat-nav-list>
                </mat-expansion-panel>
                <!-- <a mat-list-item routerLink="/accioneslineamovil " (click)="movil.close() " class="menuoption "><img src="assets/img/euro.svg " class="icono "> Cambios Tarifas </a> -->
                <!-- <a mat-list-item routerLink="/publi1 " (click)="movil.close() " class="menuoption "><img src="assets/img/global.svg " class="icono "> Bonos Internacionales </a> -->
                <!-- <a mat-list-item [routerLink]=" '/activities' " (click)="movil.close() " class="menuoption "><img src="assets/img/diamante.svg " class="icono "> Bonos Comodín </a> -->
                <!-- <a mat-list-item [routerLink]=" '/activities' " (click)="movil.close() " class="menuoption "><img src="assets/img/cambiar.svg " class="icono "> Servicios </a> -->
                <a mat-list-item [routerLink]=" '/export-page' " (click)="movil.close() " class="menuoption "><img
                        src="assets/img/enviar.svg " class="icono "> Exportaciones </a>
                <!-- <a mat-list-item [routerLink]=" '/activities' " (click)="movil.close() " class="menuoption "><img src="assets/img/tarjeta.svg " class="icono "> Info SIM </a> -->
                <!-- <a mat-list-item [routerLink]=" '/activities' " (click)=" movil.close(); " class="menuoption "><img src="assets/img/icambiar.svg " class="icono "> Cambio SIM </a> -->
                <!-- <a mat-list-item [routerLink]=" '/activities' " (click)="movil.close() " class="menuoption "><img src="assets/img/usuario.svg " class="icono "> Titularidad </a> -->
                <a *ngIf="usuario==doc" mat-list-item (click)="launchCDR(); movil.close(); " class="menuoption "><img
                        src="assets/img/calculadora.svg " class="icono "> CDR </a>
                <mat-expansion-panel [class.mat-elevation-z0]="true " dense style="width: 90%; ">
                    <mat-expansion-panel-header collapsedHeight="48px ">
                        <img src="assets/img/megafono.svg " class="icono "> Control Riesgo
                    </mat-expansion-panel-header>
                    <mat-nav-list>
                        <a mat-list-item [routerLink]="'/findNumberRisk' " (click)="movil.close() " dense
                            class="submenu "><img src="assets/img/lupa.svg " class="icono ">Búsqueda Avanzada</a>
                        <a mat-list-item [routerLink]="'/listBlock' " (click)="movil.close() " dense
                            class="submenu "><img src="assets/img/servidor.svg " class="icono ">Listado Bloqueado</a>
                        <a mat-list-item [routerLink]="'/listControlRisk' " (click)="movil.close() " dense
                            class="submenu "><img src="assets/img/lista.svg " class="icono ">Listado</a>
                    </mat-nav-list>
                </mat-expansion-panel>
                <a *ngIf="usuario==doc" mat-list-item [routerLink]="'/invoices'" class="menuoption" (click)="movil.close() "><img
                        src="assets/img/cobertura.svg " class="icono "> Facturación </a>
                <!-- <a mat-list-item [routerLink]=" '/activities' " (click)="movil.close() " class="menuoption "><img src="assets/img/coronavirus.svg " class="icono "> Deuda Covid </a> -->
            </mat-accordion>
        </mat-nav-list>

    </mat-sidenav>

    <!--MENU FIJO------------------------------------------------------------------------------------------>

    <!--  <mat-sidenav #fijo position="end "> -->
    <mat-sidenav #fijo position="start" mode="over" autoFocus="true">
        <mat-nav-list>
            <mat-accordion #accordion="matAccordion">
                <mat-expansion-panel [class.mat-elevation-z0]="true " dense>
                    <mat-expansion-panel-header collapsedHeight="48px ">
                        <img src="assets/img/page.svg " class="icono "> Solicitudes
                    </mat-expansion-panel-header>
                    <mat-nav-list>
                        <a mat-list-item (click)="goToInitfijo()" dense class="submenu "><img
                                src="assets/img/editar.svg " class="icono ">Nueva Solicitud</a>
                        <a mat-list-item [routerLink]="'/verDocsFijo'" (click)="fijo.close() " dense
                            class="submenu "><img src="assets/img/archivo-pdf.svg " class="icono ">Ver/Subir
                            Documentación</a>
                    </mat-nav-list>
                </mat-expansion-panel>
                <mat-expansion-panel [class.mat-elevation-z0]="true " dense>

                    <mat-expansion-panel-header collapsedHeight="48px ">
                        <img src="assets/img/llamada-telefonica.svg " class="icono "> Líneas
                    </mat-expansion-panel-header>
                    <mat-nav-list>
                        <a mat-list-item [routerLink]="'/lineafija' " (click)="fijo.close() " dense
                            class="submenu "><img src="assets/img/grupo.svg " class="icono ">Líneas Por Contrato</a>
                        <a mat-list-item [routerLink]="'/advanced-find-fijo' " (click)="fijo.close() " dense
                            class="submenu "><img src="assets/img/lupa.svg " class="icono ">Búsqueda Avanzada</a>
                    </mat-nav-list>
                </mat-expansion-panel>
                <a mat-list-item [routerLink]=" '/exportaciones-fijo-page' " (click)="fijo.close() "
                    class="menuoption "><img src="assets/img/enviar.svg " class="icono "> Exportaciones </a>
                <!-- <a mat-list-item [routerLink]=" '/servicios-fijo-page' " (click)="fijo.close() " class="menuoption "><img src="assets/img/cambiar.svg " class="icono "> Servicios </a> -->
                <a *ngIf="usuario==doc" mat-list-item (click)="launchCDR(); fijo.close(); " class="menuoption "><img
                        src="assets/img/calculadora.svg " class="icono "> CDR </a>
                <a mat-list-item [routerLink]="'/gestrechazos-page' " (click)="fijo.close() " class="menuoption "><img
                        src="assets/img/rechazado.svg " class="icono "> Gestión de Rechazos </a>
                <a mat-list-item [routerLink]=" '/titularidad-fijo-page' " (click)="fijo.close() "
                    class="menuoption "><img src="assets/img/usuario.svg " class="icono "> Titularidad </a>

                <a mat-list-item [routerLink]=" '/gestportabilidadFijo-page'" (click)="fijo.close() "
                    class="menuoption "><img src="assets/img/flecha.svg " class="icono "> Gestión Portabilidades </a>
            </mat-accordion>
        </mat-nav-list>
    </mat-sidenav>



    <!--MENU FIBRA------------------------------------------------------------------------------------------>
    <mat-sidenav #fibra position="right" autoFocus="true">
        <mat-nav-list> 
            <a #indirec *ngIf="tipoIN" (click)="seleccionarTipo(1000)" class="menuoption rbTipoFibra"> Indirecta</a>
            <a #marca_blanca *ngIf="tipoMB" (click)="seleccionarTipo(1001)" class="menuoption rbTipoFibra"> Marca blanca</a>
            <a mat-list-item *ngIf="tipoFibraSelecc==1000 && tipoIN" [routerLink]="'/initFtth'" (click)="fibra.close() " class="menuoption" style="margin-top:14px;"> Crear Suscriptor</a>
            <a mat-list-item *ngIf="tipoFibraSelecc==1001 && tipoMB" [routerLink]="'/create-account-white-brand'" (click)="fibra.close() " class="menuoption" style="margin-top:14px;"> Crear Suscriptor</a>
            <a mat-list-item *ngIf="tipoFibraSelecc==1000 && tipoIN" [routerLink]="'/listFtth'" (click)="fibra.close() " class="menuoption"> Listar suscriptores</a>
            <a mat-list-item *ngIf="tipoFibraSelecc==1001 && tipoMB" [routerLink]="'/listSubMB'" (click)="fibra.close() " class="menuoption" > Listar suscriptores </a>
            <a mat-list-item *ngIf="tipoFibraSelecc==1001 && tipoMB" [routerLink]="'/listaAlbaranes'"  (click)="fibra.close() " class="menuoption" > Listar albaranes </a>
        </mat-nav-list>
    </mat-sidenav>



</mat-sidenav-container>