<div class="contentAux">
    <div class="content contentAviso" *ngIf="loadService.messageDocumentacion && !loadService.documentacionAdjuntada"
        style="background-color: #ed1c24;">
        <table>
            <tr>
                <th style="display: flex; justify-content: center; align-items: center;"><img
                        src="/assets/img/Bloquear.svg" class="bloquear"></th>
                <th style="justify-content: center; align-items: center;">
                    {{loadService.messageDocumentacion}}</th>
            </tr>
        </table>
    </div>
    <div class="content contentNewTarifa" *ngIf="newTarifa || loadService.cambioTarifaEnCurso"
        style="background-color: #5F5F5F;">
        <table>
            <tr>
                <th style="display: flex; justify-content: center; align-items: center;"><img
                        src="/assets/img/guion-32.svg" class="bloquear"></th>
                <th style=" justify-content: center; align-items: center;">
                    PENDIENTE DE CAMBIO [*{{ (newTarifa=="" || newTarifa==null)?loadService.newTarifa: newTarifa}}*]
                </th>
            </tr>
        </table>
    </div>
    <div class="content contentDobleGigas" *ngIf="dobleGb=='N' && esContfratoDistribuidor" (click)="launchDobleGigas()"
        title="CLICK PARA ACTIVAR DOBLE DE GIGAS EN ESTA LINEA" style="background-color: #00b1aa;">
        <table>
            <tr>
                <th style="display: flex; justify-content: center; align-items: center;"><img
                        src="/assets/img/checkBlanco.svg" class="bloquear"></th>
                <th style="justify-content: center; align-items: center;">
                    ACTIVACION DOBLE DE GIGAS</th>
            </tr>
        </table>
    </div>
</div>