<div *ngIf="load" id="loading">
    <mat-grid-list cols=3 rowHeight=40em>
        <mat-grid-tile>

        </mat-grid-tile>
        <mat-grid-tile>
            <img id="loading-image" src="assets/img/loading2.gif" alt="Loading..." />
        </mat-grid-tile>
        <mat-grid-tile>

        </mat-grid-tile>
    </mat-grid-list>

</div>

<div class="formbox">
    <div class="cabecera">
        FIJO - SERVICIOS
    </div>
    <div class="divTabla">
        <div class="container" style="width: 95%; zoom:90%;">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="dt">
                <thead style="font-family: MontserratBold;">
                    <tr *ngIf="data!=null">
                        <th>NOMBRE</th>
                        <th>ESTADO</th>
                        <th>TELÉFONO</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody style="font-family: MontserratRegular;text-align: center;">
                    <tr *ngFor="let c of data">
                        <td>{{c.nombre}}</td>
                        <td>{{c.estado}}</td>
                        <td><input type="text" name="usuario" value="{{c.parm1}}" required="required" class="form-control" /></td>
                        <td>
                            <mat-slide-toggle [checked]="c.estado=='S'" (change)="modificarServicio($event,c.nombre,c.estado,c.parm1,c.parm2,c.comando)">
                            </mat-slide-toggle>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>

<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>