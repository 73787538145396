<div class="formbox">
    <div class="wrapper_load" *ngIf="load">
        <img *ngIf="load" class="customLoad" src="assets/img/loading2.gif">
    </div>

    <div class="cabecera">
        LISTA DE SUSCRIPTORES
    </div>

    <div class="cabecera2">
        <select class="form-control" name="contratos" class="contratoSelect" (change)="selectOption($event.target.value)">
            <option value="0">SELECCIONE UN CONTRATO:</option>
            <option value="{{d.contratoOficial}}" *ngFor="let d of listSubcriptions">{{d.contratoOficial}}</option>
        </select>
    </div>

    <div class="divTabla" *ngIf="listSubContract">
        <div class="container" style="width: 95%; zoom:90%;">
            <div id="datatableFibra" *ngIf="listSubContract" style="zoom: 90%;">

                <table class="table table-striped table-bordered table-sm row-border hover " datatable [dtOptions]="dtOptions " id="dt " *ngIf="listSubContract ">
                    <thead style="font-family: MontserratBold; text-transform: uppercase; ">
                        <tr>
                            <th>Estado</th>
                            <th>Servicio</th>
                            <th>Fecha Alta</th>
                            <th>Documento</th>
                            <th>Nombre</th>
                            <th>Municipio</th>
                            <th>Dirección</th>
                            <th>Teléfono</th>
                            <th>Email</th>
                            <th>Identificador</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody style="font-family: MontserratRegular;word-break: break-word;text-align: center; " *ngIf="listSubContract">

                        <tr *ngFor=" let item of listSubContract ">
                            <td>
                                <mat-slide-toggle [checked]="(item.EstadoScrp=='D')?true:false " [attr.id]="item.NPeticion" (change)="cambiarEstadoUsuario($event,contratoOficialSelected,item.NPeticion,this.id) ">
                                </mat-slide-toggle>
                            </td>
                            <td>
                                <div class="rowTable ">{{item.DescObjeto}}</div>
                            </td>
                            <td>
                                <div class="rowTable ">{{(item.FechActiv=='0')?'PENDIENTE INSTALACIÓN':item.FechActiv}}</div>
                            </td>
                            <td>
                                <div class="rowTable ">{{item.DocumentoID}}</div>
                            </td>
                            <td>
                                <div class="rowTable ">{{item.Nombre}} {{item.Apellido1}} {{item.Apellido2}}</div>
                            </td>
                            <td>
                                <div class="rowTable ">{{item.Municipio}}</div>
                            </td>
                            <td>
                                <div class="rowTable ">{{item.TipoVia}}: {{item.DescripVia}} , {{strToNumber( item.NumeroVia)}}</div>
                            </td>
                            <td>
                                <div class="rowTable ">{{item.Telefono}}</div>
                            </td>
                            <td>
                                <div class="rowTable ">{{item.Email}}</div>
                            </td>
                            <td>
                                <div class="rowTable ">{{item.CodRefOrg}}</div>
                            </td>
                            <td>
                                <!-- Status Subcriptions -->
                                <button type="button " (click)="getOrderStatus(item.CodRefOrg) " class="orderStatus " title="Estado de la Suscripción ">

                                </button>
                                <!-- Status ONT -->
                                <button type="button " class="statusOtn " (click)="getStatusOnt(contratoOficialSelected,item.NPeticion) " title="Info ONT ">

                                </button>
                                <!-- Migration Speed -->
                                <button type="button " class="migrateSpeed " (click)="migrateSpeedPrepare(contratoOficialSelected,item.DescObjeto,item.NPeticion) " title="Migracion Rápida ">

                                </button>
                                <!-- DeleteSuscriber -->
                                <button type="button " class="deleteSuscriber " (click)="deleteSuscriber(contratoOficialSelected,item.NPeticion) " title="Cancelar Subcriptor ">

                                </button>

                                <!-- Status Subcriptions Orange -->

                                <button type="button " class="getSubcriptionStatusOrange " (click)="getSubcriptionStatusOrange(item.CodRefOrg,item.Ticket) " title="Comprobar estado de la subcripcción ">
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2; ">
        <label>.</label>
    </div>




</div>