<div class="wrapper_select_tarifaSelect">
    <label for="tarifaSelect">SELECCIONE UNA TARIFA PARA ESTA LÍNEA</label>
    <select class="form-control tarifaSelect" name="tarifaSelect" id="tarifaSelect" (change)="chageTarifa($event.target.value)">
        <option value="0">SELECCIONE UNA TARIFA:</option>
        <option value="{{d.des}}|{{d.cod}}" *ngFor="let d of listTarifas"> Tarifa( {{d.des}} ) <--> {{d.nom}}
        </option>
    </select>
</div>
<div class="wrapper_button_cambiarTarifa">
    <button type="submit" (click)="startUpdateTarifa()">EJECUTAR CAMBIO</button>
</div>