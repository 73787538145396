<div class="previewDocumentation" *ngIf="loadingService.archive">
    <div class="contedorBoton"><button type="button" (click)="cerrarDocumentacion()">
            <img src="assets/img/PTV/cerrar.png">
        </button></div>
    <div class="contenedorImg">
        <app-ver-documento [archiv]="loadingService.archive"></app-ver-documento>
    </div>
</div>

<div class="formbox">
    <div class="cabecera">
        MÓVIL - DOCUMENTACIÓN
    </div>
    <div class="cabecera2">
        <input type="tel" name="tel" maxlength="9" placeholder="INTRODUCE UN MÓVIL" minlength="9"
            class="form-control movilInput" oninput="this.value=this.value.replace(/[^0-9]/g,'');"
            (input)="findNumber($event.target.value)">
    </div>

    <div class="contentLoad" *ngIf="loadModal || loadingService.load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>

    <div *ngIf="listArchFromServe">
        <div class="wrapper_containerListArch" *ngIf="listArchFromServe">
            <div *ngFor="let archiv of listArchFromServe" class="rowArch">
                <div class="wrapper_infoNameArch">
                    <div id="{{archiv.nameFile}}" class="infoNameArch"
                        (click)="downLoadDocumentFromServer(archiv.nameFile)">{{archiv.nameFile}}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>