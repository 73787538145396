import { HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-ver-documento',
    templateUrl: './ver-documento.component.html',
    styleUrls: ['./ver-documento.component.css']
})
export class VerDocumentoComponent implements OnInit {
    @Input() archiv = {
        "base64": "",
        "name": "",
        "tipo": ""
    }
    constructor() {}

    ngOnInit(): void {}
}