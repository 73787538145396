import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InitComponent } from './pages/init/init.component';
import { LineafijaComponent } from './pages/lineafija/lineafija.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { GuardserviceGuard } from 'src/app/services/guardservice.guard';
import { ConsumofijoComponent } from './pages/consumofijo/consumofijo.component';
import { DetallelineafijaComponent } from './pages/detallelineafija/detallelineafija.component';
import { FirstFormComponent } from './pages/first-form/first-form.component';
import { Guardservice2Guard } from './services/guardservice2.guard';
import { LineamovilComponent } from './pages/lineamovil/lineamovil.component';
import { InitmovilComponent } from './pages/initmovil/initmovil.component';
import { AccioneslineamovilComponent } from './pages/accioneslineamovil/accioneslineamovil.component';
import { Publi1Component } from './pages/publicidad/publi1/publi1.component';
import { Publi2Component } from './pages/publicidad/publi2/publi2.component';
import { Publi3Component } from './pages/publicidad/publi3/publi3.component';
import { ServiciosComponent } from './pages/servicios/servicios.component';
import { ServiciosmovilComponent } from './pages/serviciosmovil/serviciosmovil.component';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { CreateSubcriptionComponent } from './pages/create-subcription/create-subcription.component';
import { CdrPageComponent } from './pages/cdr-page/cdr-page.component';
import { AdvancedFindComponent } from './pages/advanced-find/advanced-find.component';
import { SharedGroupComponent } from './pages/shared-group/shared-group.component';
import { ExportPageComponent } from './pages/export-page/export-page.component';
import { AddSharedGroupPageComponent } from './pages/add-shared-group-page/add-shared-group-page.component';
import { DownSharedGroupPageComponent } from './pages/down-shared-group-page/down-shared-group-page.component';
import { ListSharedGroupComponent } from './pages/list-shared-group/list-shared-group.component';
import { ControlBajasPageComponent } from './pages/control-bajas-page/control-bajas-page.component';
import { GestionPortabilidadesPageComponent } from './pages/gestion-portabilidades-page/gestion-portabilidades-page.component';

import { InvoicesComponent } from './pages/invoices/invoices.component';
import { ViewDocumentacionPageComponent } from './pages/view-documentacion-page/view-documentacion-page.component';
import { AdvancedFindRiesgoComponent } from './pages/advanced-find-riesgo/advanced-find-riesgo.component';
import { ListControlRiesgoComponent } from './pages/list-control-riesgo/list-control-riesgo.component';
import { ListBloqueadosComponent } from './pages/list-bloqueados/list-bloqueados.component';
import { GestionRechazosPageComponent } from './pages/gestion-rechazos-page/gestion-rechazos-page.component';
import { GestPortabilidadesFIjoPageComponent } from './pages/gest-portabilidades-fijo-page/gest-portabilidades-fijo-page.component';
// import { ServiciosFijoPageComponent } from './pages/servicios-fijo-page/servicios-fijo-page.component';
import { ExportFijoPageComponent } from './pages/export-fijo-page/export-fijo-page.component';
import { TitularidadFijosPageComponent } from './pages/titularidad-fijos-page/titularidad-fijos-page.component';
import { VerDocumentacionFijoPageComponent } from './pages/ver-documentacion-fijo-page/ver-documentacion-fijo-page.component';
import { UploadFijoDocsPageComponent } from './pages/upload-fijo-docs-page/upload-fijo-docs-page.component';
import { AdvancedFindFijoComponent } from './pages/advanced-find-fijo/advanced-find-fijo.component';
import { CreateAccountWhiteBrandComponent } from './pages/create-account-white-brand/create-account-white-brand.component';
import { CreateSubcriptionWhiteBrandComponent } from './pages/create-subcription-white-brand/create-subcription-white-brand.component';
import { CrearPedidosMarcaBlancaComponent } from './crear-pedidos-marca-blanca/crear-pedidos-marca-blanca.component';
import { ListaSuscriptoresMarcaBlancaComponent } from './pages/lista-suscriptores-marca-blanca/lista-suscriptores-marca-blanca.component';
import { ListaAlbaranesComponent } from './pages/lista-albaranes/lista-albaranes.component';


const routes: Routes = [

    {
        path: 'init',
        component: InitComponent,
        canActivate: [GuardserviceGuard]
    },

    {
        path: 'initmovil',
        component: InitmovilComponent,
        canActivate: [GuardserviceGuard]
    },
    {
        path: 'invoices',
        component: InvoicesComponent,
        canActivate: [GuardserviceGuard]
    },
    {
        path: 'welcome',
        component: WelcomeComponent,
        canActivate: [GuardserviceGuard]
    },
    {
        path: 'lineafija',
        component: LineafijaComponent,
        canActivate: [GuardserviceGuard]
    },
    {
        path: 'lineamovil',
        component: LineamovilComponent,
        canActivate: [GuardserviceGuard],
        // runGuardsAndResolvers: 'always'
    },
    {
        path: 'accioneslineamovil',
        component: AccioneslineamovilComponent,
        canActivate: [GuardserviceGuard]
    },
    {
        path: 'consumofijo',
        component: ConsumofijoComponent,
        canActivate: [GuardserviceGuard]
    },
    {
        path: 'detallelineafija',
        component: DetallelineafijaComponent,
        canActivate: [GuardserviceGuard]
    },
    {
        path: 'servicios',
        component: ServiciosComponent,
        canActivate: [GuardserviceGuard]
    },
    {
        path: 'serviciosmovil',
        component: ServiciosmovilComponent,
        canActivate: [GuardserviceGuard]
    },
    {
        path: 'publi1',
        component: Publi1Component,
        canActivate: [GuardserviceGuard]
    },
    {
        path: 'publi2',
        component: Publi2Component,
        canActivate: [GuardserviceGuard]
    },
    {
        path: 'publi3',
        component: Publi3Component,
        canActivate: [GuardserviceGuard]
    },
    {
        path: 'listFtth',
        component: ContactsComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'initFtth',
        component: CreateAccountComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'create-account-white-brand',
        component: CreateAccountWhiteBrandComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'create-subcription',
        component: CreateSubcriptionComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'create-subcription-white-brand',
        component: CreateSubcriptionWhiteBrandComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'cdr-page',
        component: CdrPageComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'advanced-find',
        component: AdvancedFindComponent,
        canActivate: [GuardserviceGuard]

    }, {
        path: 'advanced-find-fijo',
        component: AdvancedFindFijoComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'shared-group',
        component: SharedGroupComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'export-page',
        component: ExportPageComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'add-shared-group-page',
        component: AddSharedGroupPageComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'down-shared-group-page',
        component: DownSharedGroupPageComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'list-shared-group-page',
        component: ListSharedGroupComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'control-bajas-page',
        component: ControlBajasPageComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'gestion-portabilidades-page',
        component: GestionPortabilidadesPageComponent,

        canActivate: [GuardserviceGuard]

    },
    {
        path: 'viewDocs',
        component: ViewDocumentacionPageComponent,
        canActivate: [GuardserviceGuard]

    }, {
        path: 'uploadDocsFijo',
        component: UploadFijoDocsPageComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'findNumberRisk',
        component: AdvancedFindRiesgoComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'listControlRisk',
        component: ListControlRiesgoComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'listBlock',
        component: ListBloqueadosComponent,
        canActivate: [GuardserviceGuard]

    },
    {
        path: 'gestportabilidadFijo-page',
        component: GestPortabilidadesFIjoPageComponent,
        canActivate: [GuardserviceGuard]
    }, {
        path: 'verDocsFijo',
        component: VerDocumentacionFijoPageComponent,
        canActivate: [GuardserviceGuard],

    },
    {
        path: 'gestrechazos-page',
        component: GestionRechazosPageComponent,
        canActivate: [GuardserviceGuard],
        runGuardsAndResolvers: 'always',
    },
    // {
    //     path: 'servicios-fijo-page',
    //     component: ServiciosFijoPageComponent,
    //     canActivate: [GuardserviceGuard]
    // },
    {
        path: 'exportaciones-fijo-page',
        component: ExportFijoPageComponent,
        canActivate: [GuardserviceGuard]
    }, {
        path: 'titularidad-fijo-page',
        component: TitularidadFijosPageComponent,
        canActivate: [GuardserviceGuard]
    },
    {
        path: '',
        component: FirstFormComponent,
        canActivate: [Guardservice2Guard]
    },
    {
        path:'crear-pedidos-marca-blanca',
        component:CrearPedidosMarcaBlancaComponent,
        canActivate: [GuardserviceGuard]
    },
    // {
    //     path: '**',
    //     pathMatch: 'full',
    //     component: NotFountPageComponent,
    //     // canActivate: [Guardservice2Guard]
    // }
    {
        path:"listSubMB",
        component:ListaSuscriptoresMarcaBlancaComponent,
        canActivate: [GuardserviceGuard]
    },
    {
        path:"listaAlbaranes",
        component:ListaAlbaranesComponent,
        canActivate: [GuardserviceGuard]
    },
    {
        path:"descargarAlbaran/:id",
        component:ListaAlbaranesComponent,
        canActivate: [GuardserviceGuard]
    },


];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {}