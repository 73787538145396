<div class="contentLoad" *ngIf="cambioTitularServ.load">
    <img class="loading" src="assets/img/loading2.gif">
</div>
<div class="formbox">

    <form [formGroup]="advancedForm">

        <div class="cabecera">
            TITULARIDAD
        </div>
        <div class="cabecera2">
            <input type="tel" name="telf" maxlength="9" minlength="9" class="form-control " class="fijoInput" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="telf" (input)="cambioTitularServ.findNumberFijo($event.target.value)" placeholder="INTRODUCE UN FIJO">
        </div>
        <div class="formFijo" *ngIf="cambioTitularServ.data">
            <div id="datatableTitFijos" style="zoom: 90%;">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" id="dt" *ngIf="cambioTitularServ.data">
                    <thead style="font-family: MontserratBold;text-align: center;">
                        <tr>
                            <th>SIM</th>
                            <th>DESCRIPCION</th>
                            <th>VENTANA</th>
                            <th>HORA</th>
                            <th>TIPO</th>
                            <th>TELEFONO</th>
                            <th>PETICION</th>
                            <th>ESTADO</th>
                            <th>ESTADO LI.</th>
                            <th></th>

                        </tr>
                    </thead>
                    <tbody style="font-family: MontserratRegular;text-align: center;">
                        <tr>
                            <td>-</td>
                            <td>{{cambioTitularServ.data.des}}</td>
                            <td>{{(cambioTitularServ.data.ventana)?cambioTitularServ.data.ventana:'-'}}</td>
                            <td>{{timeTransform(cambioTitularServ.data.hora)}}</td>
                            <td>{{(cambioTitularServ.data.tipo)?cambioTitularServ.data.tipo:'-'}}</td>
                            <td>{{cambioTitularServ.data.telf}}</td>
                            <td>{{cambioTitularServ.data.pet}}</td>
                            <td>{{(cambioTitularServ.data.estado)?cambioTitularServ.data.estado:'-'}}</td>
                            <td>{{(cambioTitularServ.data.estaLi)?cambioTitularServ.data.estaLi:'-'}}</td>
                            <td><input type="button" value="CAMBIO DE TITULAR" class="cambioBtn" (click)="cambioTitularServ.buscarUsuario()"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>

    <app-create-client-fijo *ngIf="cambioTitularServ.buscar" [datos]="cambioTitularServ.data"></app-create-client-fijo>

</div>
<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>