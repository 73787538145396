import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-donete',
  templateUrl: './donete.component.html',
  styleUrls: ['./donete.component.css']
})
export class DoneteComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data2: any) { }

  ngOnInit(): void {
  }

  // Doughnut
  public doughnutChartLabels: string[] = ["Restante: " + this.data2.Balance, "Consumido: " + this.data2.Consumo];
  public demodoughnutChartData: number[] = [parseInt(this.balance()), parseInt(this.data2.Consumo)];
  public doughnutChartType: string = 'doughnut';

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  balance() {
    if (this.data2.Bono == "Ilimitado") {
      this.data2.Balance = "0"
    }
    return this.data2.Balance

  }


}
