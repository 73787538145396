<div class="formbox">
    <div class="cabecera">
        FIJO - LÍNEAS POR CONTRATO
    </div>
    <div class="cabecera2">
        <select class="form-control" name="contratos" class="contratoSelect" [(ngModel)]="selectContrato" #event (change)="selectOption($event.target.value)">
            <option value="0">SELECCIONE UN CONTRATO:</option>
            <option value={{d.Contrato}}|{{d.Tercero}} *ngFor="let d of listContratos">CONTRATO {{d.ContratoOficial}} {{d.Alias ? '('+d.Alias+')' : '' }}</option>
        </select>
    </div>

    <img *ngIf="load" style="height: 30px;width: 30px; padding-top: 3px;margin: auto;display: flex;" src="assets/img/loading2.gif">

    <div class="divTabla" *ngIf="data">
        <div class="container" style="width: 95%; zoom:90%;">
            <div id="datatableFijo" style="zoom: 90%;">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" id="dt" *ngIf="data" style="zoom: 77%;">
                    <thead style="font-family: MontserratBold; text-transform: uppercase;">
                        <tr>
                            <th>Petición</th>
                            <th>Aparato</th>
                            <th>Doc</th>
                            <th>Titular</th>
                            <th>Activación</th>
                            <th>Tipo</th>
                            <th>Estado</th>
                            <th>Número</th>
                            <th>Ventana</th>
                            <th>Hora</th>
                            <th>Bloqueo</th>
                            <th>Consumo</th>
                            <th>Detalles</th>
                            <th>Servicios</th>
                        </tr>
                    </thead>
                    <tbody style="font-family: MontserratRegular;text-align: center;">
                        <tr *ngFor="let c of data">
                            <td>{{c.pet}}</td>
                            <td>{{c.apar}}</td>
                            <td>{{c.doc}}</td>
                            <td>{{c.des}}</td>
                            <td>{{c.activacion}}</td>
                            <td>{{c.tipo}}</td>
                            <td>{{c.estaLi}}</td>
                            <td>{{c.telf}}</td>
                            <td>{{c.ventana}}</td>
                            <td>{{c.hora}}</td>
                            <td>
                                <button *ngIf="c.boton!='B'" type="button" (click)="getBloqueo(c.boton, c.telf)" class="desbloquear">
                                </button>
                                <button *ngIf="c.boton!='D'" type="button" (click)="getBloqueo(c.boton, c.telf)" class="bloquear">
                                </button>
                            </td>
                            <td>
                                <button type="button" (click)="getConsumo(c.telf)" class="consumoB">
                                </button>
                            </td>
                            <td>
                                <button type="button" (click)="getDetalle(c.telf)" class="detalleB">
                                </button>
                            </td>
                            <td>
                                <button type="button" (click)="getServicios(c.telf)" class="ServiceB">
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
        <label>.</label>
    </div>

</div>
