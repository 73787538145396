import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PeticionServiceService } from 'src/app/services/peticion-service.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-comentar-incidencia',
  templateUrl: './comentar-incidencia.component.html',
  styleUrls: ['./comentar-incidencia.component.css']
})
export class ComentarIncidenciaComponent implements OnInit {
  anotacion:string='';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private marcaBlanca:PeticionServiceService,
  ) { }

  ngOnInit(): void {
    
  }
  async addComment(){
    await this.marcaBlanca.peticionMarca(environment.grabarComentarioIncidenciasMarcaBlanca,'post',{},{
      "incidencia":""+parseInt(this.data.incidencia,10),
      "anotacion":this.anotacion,
    }).then((data:any)=>{
      Swal.fire({
        title:data.anotacion,
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Confirmar',
      });
    },
    (error:any)=>{
      Swal.fire({
        title:error.error.incidencia[0],
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Confirmar',
      });
    });
  }
}
