import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { user } from 'src/app/model/user';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-detallelineafija',
    templateUrl: './detallelineafija.component.html',
    styleUrls: ['./detallelineafija.component.css']
})
export class DetallelineafijaComponent implements OnDestroy, OnInit {
    dtOptions: any = {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
            'copy', 'csv', 'excel', 'print'
        ],
        language: {
            url: '//cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true
    };
    dtTrigger = new Subject();
    data: any;
    public load: Boolean = false;
    aux: any;
    uDetails: user;
    telefono = {
        telefono: ""
    };
    telf: any;
    constructor(private http: HttpClient,
        private auth: AuthService, private cookie: CookieService) {}

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }
    ngOnInit(): void {
        this.telf = this.auth.tlf;
        this.uDetails = this.auth.uLoged;
        this.getDetalles();
    }

    //__________________________________________Obteniendo detalles  
    getDetalles() {
        this.showloading()
        try {
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });
            this.telefono = {
                "telefono": String(this.telf) //957372153
            }
            const url = environment.endpointdatos + environment.getDetalleLineaFija;
            this.http.post(url, this.telefono, { headers }).subscribe((res: any) => {
                this.data = res;
                /* this.aux = this.data.linea;
                 for (var i = 0; i < this.aux.length; i++) {
                   var con = this.aux[i];
                   console.log(con);
                 }*/
                this.dtTrigger.next();
                this.hideloading()
            }, (error: any) => {
                this.hideloading()
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: error.error.Message,
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
            });
        } catch {
            this.hideloading()
        }
    }

    //_______________________________________________Loading
    showloading() {
        this.load = true
    }
    hideloading() {
        this.load = false
    }
}