<div class="formbox">
    <div class="contentLoad" *ngIf="load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>
    <div class="cabecera">
        FIJO - GESTIÓN DE PORTABILIDADES
    </div>
    <div class="cabecera2">
        <select class="form-control contratoSelect" name="contratos" (change)="changeContratos($event.target.value)" [disabled]="disbleSelect">
            <option value="0">SELECCIONE UN CONTRATO:</option>
            <option value={{d.ContratoOficial}}|{{d.Contrato}} *ngFor="let d of data2">OPERADOR TELEFONÍA FIJA CONTRATO {{d.ContratoOficial}} {{d.Alias ? '('+d.Alias+')' : '' }}</option>
        </select>
    </div>
    <div class="divTabla" *ngIf="data">
        <div id="dataTablePortaFijos" *ngIf="data" style="zoom: 90%;">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" id="dt">
                <thead style="font-family: MontserratBold;">
                    <tr>
                        <th>CLIENTE</th>
                        <th>DNI</th>
                        <th>PETICION</th>
                        <th>TELEFONO</th>
                        <th>FECHA</th>
                        <th>HORA</th>
                        <th>ESTADO</th>
                    </tr>
                </thead>
                <tbody style="font-family: MontserratRegular; text-align: center;" *ngIf="data">
                    <tr *ngFor="let c of data; let i= index">
                        <td>{{c.cliente}}</td>
                        <td>{{c.dni}}</td>
                        <td>{{c.peticion}}</td>
                        <td>{{c.telefono}}</td>
                        <td>{{c.fecha}}</td>
                        <td>{{c.hora}}</td>
                        <td [ngClass]="c.estado=='CANCELADA'? 'cancelada': c.estado=='ACEPTADA' ||c.estado=='SOLICITADA' ||c.estado=='TRAMITANDOSE' ?'aceptada':'rechazada'">
                            {{c.estado}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>
