import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SuscriptoresService {

  constructor(public http: HttpClient) { }


  listaSuscriptoresMarcaBlanca(terceroOpe:any,headers:any){
    let body={
      "terceroOpe":terceroOpe,
    }
    return this.http.post('http://127.0.0.1:8000/api/contratos/listarContratos',body,{headers})
  }
}
