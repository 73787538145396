import { Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { log } from 'console';
import { PeticionServiceService } from 'src/app/services/peticion-service.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-diagnostico-ont-modal',
  templateUrl: './diagnostico-ont-modal.component.html',
  styleUrls: ['./diagnostico-ont-modal.component.css']
})
export class DiagnosticoOntModalComponent implements OnInit {

  public loadModal: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data:any,
    private marcaBlanca:PeticionServiceService
  ) { }

  ngOnInit(): void {
    
  }
  accion(operacion:string){
    this.showloading();
    const payload={
      'mac':this.data.mac,
      "operacion":operacion,
    };
    this.marcaBlanca.peticionMarca(environment.diagnosticoONTMarcaBlanca,'post',{},payload).then((data:any)=>{
      this.hideloading();
      Swal.fire({
        title: data["0"],
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Confirmar',
      });
    }).catch((error:any)=>{
      this.hideloading();
      Swal.fire({
        title: error.error["0"],
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Confirmar',
      });
    });
  }

  comprobarNiveles(){
    const payload = {
      'mac': this.data.mac
    }
    this.showloading();
    this.marcaBlanca.peticionMarca(environment.nivelesOnt, 'post', {}, payload).then((data:any)=>{
      this.hideloading();
      let content = "<div style='text-align: left;'>";
      Object.entries(data.niveles).forEach(entry => {
        const [key, value] = entry;
        content+="<b>"+key+": </b>"+value+"</br>";
      });
      content+= "</div>";
      Swal.fire({
        title: "Niveles ONT",
        html: content,
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Cerrar'
      });
    }).catch((error:any)=>{
      this.hideloading();
      Swal.fire({
        title: "Niveles ONT",
        text: "Error: "+error.error.message,
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Cerrar'
      });
    });
  }


  showloading() {
    this.loadModal = true;
  }
  hideloading() {
    this.loadModal = false;
}

}
