<div class="formbox">
    <div class="contentLoad" *ngIf="loadModal ">
        <img class="loading" src="assets/img/loading2.gif">
    </div>
    <div class="container" style="width:100%;">
        <form [formGroup]="advancedForm">
            <div class="cabecera">
                MÓVIL - BÚSQUEDA AVANZADA
            </div>
            <div class="cabecera2">
                <input type="tel" name="telf" maxlength="9" minlength="9" class="form-control" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="telf" (input)="findNumber($event.target.value)" class="movilInput" placeholder="INTRODUCE UN MÓVIL">
            </div>
        </form>
    </div>

    <div class="formMovil" *ngIf="data">
        <div id="datatableAdvanced" class="form1" *ngIf="data" style="display: flex;justify-content: space-evenly; zoom: 90%;">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" id="dt" *ngIf="data">
                <thead style="font-family: MontserratBold; text-transform: uppercase;">
                    <tr>
                        <th>Petición</th>
                        <th>Activación</th>
                        <th>Tarifa</th>
                        <th>Titular</th>
                        <th>Dni</th>
                        <th>Número</th>
                        <!-- <th>Dirección Mac</th> -->
                        <!-- <th>Contrato PTV</th>
                        <th>Contrato Oficial</th> -->

                        <!-- <th>Estado de la linea</th> -->
                        <!-- <th>Ventana</th> -->
                        <th>Multisim</th>
                        <th>Hora</th>
                        <th>Nueva Tarifa</th>
                        <!-- <th>Estado</th> -->
                        <!-- <th>Tipo</th> -->
                        <th>Otras acciones</th>
                        <th>Servicios</th>
                    </tr>
                </thead>
                <tbody style="font-family: MontserratRegular; text-align: center;">
                    <tr>
                        <td>{{data.pet}}</td>
                        <td>{{data.fechAct}}</td>
                        <td>{{data.tarifa}}</td>
                        <td>{{data.des}}</td>
                        <td>{{data.doc}}</td>
                        <td>{{data.telf}}</td>
                        <!-- <td>{{data.mac}}</td> -->
                        <!-- <td>{{data.cttprv}}</td>
                        <td>{{data.cttof}}</td> -->

                        <!-- <td>{{data.estaLi}}</td> -->
                        <!-- <td>{{data.ventana}}</td> -->
                        <td>{{data.multiSim}}</td>
                        <td>{{timeTransform(data.hora)}}</td>
                        <td>{{data.nextTar}}</td>
                        <!-- <td>{{data.estaLi}}</td> -->
                        <!-- <td>{{data.tipo}}</td> -->
                        <td>
                            <!-- a falta de los nodos [FecActivacion, Cambio , MultiSIM y DobleGB] -->
                            <button type="button" (click)="setLinea(data.pet, '', data.tarifa, data.des, data.doc, data.telf,data.multiSim, '0', data.hora,data.dobleGB,data.nextTar,data.fechAct,data.cttof,data.cttprv,data.bloqrobo)" class="detalleB">
                            </button>
                        </td>
                        <td>
                            <button type="button" (click)="servicios(data.pet, data.telf)" class="detalleB">
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>