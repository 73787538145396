<div *ngIf="archiv" class="contDiv">

    <button type="button" *ngIf="archiv.tipo!='pdf'">
        <a href="{{archiv.base64}}" download="{{archiv.name}}">DESCARGAR</a>
    </button>

    <div *ngIf="archiv.tipo=='image'">
        <img [src]="archiv.base64" alt="">
    </div>

    <div *ngIf="archiv.tipo=='text'">
        {{archiv.base64}}
    </div>

    <div *ngIf="archiv.tipo=='pdf'">
        <embed [src]="archiv.base64" type="application/pdf" zoom="100%" frameborder="0" width="100%" height="100%" class="embedClass">
    </div>

</div>