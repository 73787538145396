import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoadingServiceService } from '../loading-service.service';
import { ContratosService } from 'src/app/services/contratos.service';

@Component({
    selector: 'app-notify-component',
    templateUrl: './notify-component.component.html',
    styleUrls: ['./notify-component.component.css']
})
export class NotifyComponentComponent implements OnInit {
    //___________________________INPUTS__________________________________
    @Input() esContfratoDistribuidor: boolean;
    @Input() documentacionAdjuntada: string;
    @Input() newTarifa: string;
    @Input() dobleGb: string;
    @Input() numero: string;
    //___________________________INPUTS__________________________________
    // public messageDocumentacion: string = null;
    load: boolean;
    constructor(
        private http: HttpClient,
        private cookies: CookieService,
        public loadService: LoadingServiceService,
        public contratosService: ContratosService
    ) { }

    ngOnInit(): void {
        this.lookDocumentacionFtp();
        this.contratosService.puedeDobleGiGas().subscribe((data: any) => {
            this.esContfratoDistribuidor = data.puedeDobleGigas ? true : false;
        });
    }

    /**
     * Encargado de lanzar la peticion de doble de gigas para una linea movil
     * 
     */
    launchDobleGigas() {
        Swal.fire({
            title: "ACTIVACIÓN DOBLE DE GIGAS",
            icon: "question",
            text: "¿DESEAS ACTIVAR EL DOBLE DE GIGAS PARA ESTA LÍNEA?",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                this.activarDobleDeGigas();
            }
        })
    }

    activarDobleDeGigas() {
        this.loadService.showloading();
        const headers = new HttpHeaders({
            'user': this.cookies.get('user'),
            'token': this.cookies.get('token'),
            'pass': this.cookies.get('pass'),
        });
        const body = {
            "telefono": this.numero
        }

        let url = environment.launchDobleGigas;

        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.loadService.hideloading();
                if (res.id) {
                    Swal.fire({
                        title: "ACTIVACIÓN DOBLE GB",
                        icon: 'success',
                        text: res.id,
                    });
                    this.dobleGb = null;
                } else {
                    Swal.fire({
                        title: "ACTIVACIÓN DOBLE GB",
                        icon: "warning",
                        text: res.Error,
                    })
                    this.dobleGb = null;
                }
            }, error => {
                this.loadService.hideloading();
                Swal.fire({
                    title: "ACTIVACIÓN DOBLE GB",
                    icon: "warning",
                    text: error.Error,
                });
            })
        } catch {
            this.loadService.hideloading();
        }
    }

    /**
     * Encargado de buscar documentos en el FTP 
     */
    async lookDocumentacionFtp() {
        const headers = new HttpHeaders({
            'user': this.cookies.get('user'),
            'token': this.cookies.get('token'),
            'pass': this.cookies.get('pass'),
        });
        const body = {
            "telefono": this.cookies.get('numero'),
            "tercero": this.cookies.get('tercero')
        }
        let creadaDespues = this.isLater(this.stringToDate(this.cookies.get("activacion")), "2021-12-02");

        try {
            let url = environment.getDocumentationNames;
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (res.length == 0 && creadaDespues) { //Si  no hay ningun archivo
                    this.loadService.messageDocumentacion = "¡PENDIENTE ADJUNTAR DOCUMENTACION!";
                    this.loadService.documentacionAdjuntada = false;
                } else {
                    this.loadService.documentacionAdjuntada = true;
                }

            }, error => {
            })
        } catch {
        }
    }

    private stringToDate(str: String) {
        return str.split("-")[2] + "-" + str.split("-")[1] + "-" + str.split("-")[0];
    }

    isLater(dateString1, dateString2) {
        return dateString1 >= dateString2;
    }
}