<div class="wrapper_content">

    <div id="dataCoincidencias" class="tableContainer" style="border-collapse: collapse;width: 80%;margin: auto;  background: #ebeff2; padding: 20px;">

        <table class="table table-striped table-bordered table-sm row-border hover" datatable *ngIf="modalService.listCoincidencias" [dtOptions]="dtOptions" id="dt">
            <thead class="tableHeader font-family: MontserratBold; text-transform: uppercase;">
                <tr>
                    <th>
                        TIPO
                    </th>
                    <th>
                        NOMBRE
                    </th>
                </tr>
            </thead>
            <tbody style="text-align: center;" *ngIf="modalService.listCoincidencias[0].Tipo!=undefined">
                <tr *ngFor="let coincidencia of  modalService.listCoincidencias" (click)="modalService.coincidenciaSeleccionada(coincidencia)" [ngClass]="modalService.calleSeleccionada==coincidencia? 'calleSelected': 'rowcalle'">
                    <td (click)="modalService.coincidenciaSeleccionada(coincidencia)">{{coincidencia.Tipo}}</td>
                    <td (click)="modalService.coincidenciaSeleccionada(coincidencia)">{{coincidencia.PTV}}</td>
                </tr>
            </tbody>
            <tbody style="text-align: center;" *ngIf="modalService.listCoincidencias[0].tipo!=undefined">
                <tr *ngFor="let coincidencia of  modalService.listCoincidencias" (click)="modalService.coincidenciaSeleccionada(coincidencia)" [ngClass]="modalService.calleSeleccionada==coincidencia? 'calleSelected': 'rowcalle'">
                    <td (click)="modalService.coincidenciaSeleccionada(coincidencia)">{{coincidencia.calle.substring(0, coincidencia.calle.indexOf(' '))}}</td>
                    <td (click)="modalService.coincidenciaSeleccionada(coincidencia)">{{coincidencia.calle.substring(coincidencia.calle.indexOf(' '))}}</td>
                </tr>
            </tbody>
        </table>
        <div class="wrapperButtons">
            <div class="button cancelar" (click)="cerrarModal()">CANCELAR</div>
            <div class="button siguiente" (click)="cerrarModal()">SIGUIENTE</div>
        </div>
    </div>
</div>