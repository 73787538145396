import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoadingServiceService } from '../loading-service.service';

@Component({
    selector: 'app-cambio-tarifa-component',
    templateUrl: './cambio-tarifa-component.component.html',
    styleUrls: ['./cambio-tarifa-component.component.css']
})
export class CambioTarifaComponentComponent implements OnInit {
    listTarifas
    newTarifaUpdate
    constructor(

        private http: HttpClient,
        private cookies: CookieService,
        public loadService: LoadingServiceService


    ) { }

    ngOnInit(): void {

        this.getTarifasMobile()
    }




    /**
     * Metodo encargado de traer desde el webservice la lista con todas las posibles tarifas
     */
    async getTarifasMobile() {

        await this.loadService.showloading()

        let contratoF = this.cookies.get('contratoF')
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });

        const body = {
            "tercero": this.cookies.get("tercero"),
            "contratoP": this.cookies.get("cprovisional")
        }
        this.listTarifas = []

        const url = environment.getTarifaMobile;
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                //  = res;
                res.forEach(element => {

                    if (element.des != "COSTE") {

                        this.listTarifas.push({


                            "des": element.des,
                            "cod": element.cod,
                            "nom": element.nom

                        })

                    }

                });
                // }


                this.loadService.hideloading()
            }, error => {
                this.loadService.hideloading()
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: error.error.message,
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })

                console.log(error);
            });
        } catch {
            this.loadService.hideloading()
        }






    }






    /**
     * Método encargado de capturar los cambios en el campo select al seleccionar otra tarifa 
     * @param $event value del options del campo select cambio tarifa
     */
    chageTarifa($event) {
        this.newTarifaUpdate = $event

    }
    //__________________________________UPDATETARIFA__________________________________
    /**
     * Método encargado de lanzar la peticion de modificación de la tarifa
     * 
     */
    async updateTarifa(newTarifa: string) {
        await this.loadService.showloading()


        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });
        newTarifa = newTarifa.split("|")[0];
        const body = {
            "codTercero": this.cookies.get("tercero"),
            "Tarifa": newTarifa,
            "peticion": this.cookies.get("peticion")
        }






        const url = environment.updateTarifa;
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.loadService.hideloading();
                Swal.fire({
                    title: "CAMBIO DE TARIFA",
                    icon: "success",
                    confirmButtonText: "Ok",
                    showConfirmButton: true,
                    text: res.des
                })
                // this.tarifa = newTarifa

                $('#tarifaSelect').val("0"); //Reset Select
                this.loadService.cambioTarifaEnCurso = true;
                this.loadService.newTarifa = newTarifa;
            },
                error => {
                    this.loadService.cambioTarifaEnCurso = false;
                    console.log(error);
                    this.loadService.hideloading();
                    Swal.fire({
                        title: "ERROR CAMBIO DE TARIFA",
                        icon: "error",
                        confirmButtonText: "Ok",
                        showConfirmButton: true,
                        text: error.error.des
                    })
                });
        } catch {
            this.loadService.hideloading()
        }
    }
    /**
     * Evento click del boton cambiar tarifa
     */
    startUpdateTarifa() {
        this.updateTarifa(this.newTarifaUpdate)
    }

}