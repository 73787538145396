<div class="formbox">
    <div class="contentLoad" *ngIf="loadModal ">
        <img class="loading" src="assets/img/loading2.gif">
    </div>
    <div class="container" style="width:100%;">
        <form [formGroup]="advancedForm">
            <div class="cabecera">
                CONTROL DE RIESGO - BÚSQUEDA AVANZADA
            </div>
            <div class="cabecera2">
                <input type="tel" name="telf" maxlength="9" minlength="9" class="form-control" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="telf" (input)="findNumber($event.target.value)" placeholder="INTRODUCE UN MÓVIL" class="movilInput">
            </div>
        </form>
    </div>
    <div class="formMovil" *ngIf="data">
        <form [formGroup]="registroBloqueo">
            <div id="datatableAdvancedRiesgo" class="form1" *ngIf="data" style="display: flex;justify-content: space-evenly; zoom: 90%;">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" id="dt">
                    <thead style="font-family: MontserratBold;">
                        <tr>
                            <th>NOMBRE</th>
                            <th>TELÉFONO</th>
                            <th>LIMITE</th>
                            <th>BLOQUEO</th>
                            <th>BLOQUEO/DESBLOQUEO</th>
                            <th>GUARDAR</th>
                        </tr>
                    </thead>
                    <tbody style="font-family: MontserratRegular; text-align: center;" *ngIf="data">
                        <tr>
                            <td>{{data.Tercero}}</td>
                            <td>{{data.Telefono}}</td>
                            <td id="limiteDeRiesgo"> <input type="text" oninput="this.value = this.value.replace(/[^0-99.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\.\d{6}).+/g, '$1');" name="limite" id="limite" value="{{data.Limite}}" formControlName="limiteForm"></td>
                            <td><input type="checkbox" name="bloqueo" id="bloqueo" [checked]="(data.Bloquear=='0')?false:true" formControlName="bloqueoForm"></td>
                            <td *ngIf="data.Boton=='D'"><button class="btnDesbloqueo btnCustom desbloquear" (click)="desbloquearLinea(data,'desbloquear')"> DESBLOQUEAR</button></td>
                            <td *ngIf="data.Boton!='D'"><button class="btnDesbloqueo btnCustom bloquear" (click)="desbloquearLinea(data, 'bloqueoGeneral')"> BLOQUEO GENERAL</button><button class="btnDesbloqueo btnCustom bloquear" (click)="desbloquearLinea(data,'bloqueoLlamadas')">BLOQUEO LLAMADAS</button></td>
                            <td><button class="guardarBtn btnCustom" (click)="guardarLimiteDeRiesgo()">GUARDAR</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </div>
</div>