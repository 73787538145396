import { NormaliceAddres } from "./NormalizedAddress";


export interface createSubcription {
    contratoOficial: string;
    tipoDocumento: string;
    documento: string;
    nombre: string;
    apellido1: string;
    apellido2: string;
    provincia: string;
    poblacion: string;
    tipoVia: string;
    nombreVia: string;
    numeroVia: string;
    codigoPostal: string;
    planta: string;
    puerta: string;
    escalera: string;
    edificio: string;
    numeroTelefono: string;
    email: string;
    codigoTarifa: string;
    normalizedAddress: NormaliceAddres;
}

export class CreateSubcription implements createSubcription{
    contratoOficial: string;
    tipoDocumento: string;
    documento: string;
    nombre: string;
    apellido1: string;
    apellido2: string;
    provincia: string;
    poblacion: string;
    tipoVia: string;
    nombreVia: string;
    numeroVia: string;
    codigoPostal: string;
    planta: string;
    puerta: string;
    escalera: string;
    edificio: string;
    numeroTelefono: string;
    email: string;
    codigoTarifa: string;
    normalizedAddress: NormaliceAddres;

}