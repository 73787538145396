import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publi1',
  templateUrl: './publi1.component.html',
  styleUrls: ['./publi1.component.css']
})
export class Publi1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
