import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class Guardservice2Guard implements CanActivate {
  constructor(private auth:AuthService){}

  //_____________________________________________________________AUTENTICACIÓN PARA NO ACCEDER A LOGIN SI ESTA LOGUEADO
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.isAuthenticated2();
  }
  
}
