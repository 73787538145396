import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publi3',
  templateUrl: './publi3.component.html',
  styleUrls: ['./publi3.component.css']
})
export class Publi3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
