import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Component } from '@angular/core';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
})
export class AppComponent {
    title = 'sesionptv';
    public options = { types: [], componentRestrictions: { country: 'FR' } }
    getAddress(place: Object) { console.log("Address", place); }
}