//______________________________________________________________________FORMAT DATEPICKER________________________________________________________________________________________
export class AppDateAdapter extends NativeDateAdapter {
    getFirstDayOfWeek(): number {
        return 1;
    }
    format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'input') {

            let day: string = date.getDate().toString();
            day = +day < 10 ? '0' + day : day;
            let month: string = (date.getMonth() + 1).toString();
            month = +month < 10 ? '0' + month : month;
            let year = date.getFullYear();
            return `${day}-${month}-${year}`;
        } else {
            let monthsYear = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO",
                "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
            let year = date.getFullYear();
            let mes = monthsYear[date.getMonth().toString()]
            return `${mes} ${year}`;
        }
    }
}

export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};
import { DatePipe } from '@angular/common';
//_____________________________________________________________________END FORMAT DATEPICKER____________________________________________________________________________
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, DoCheck, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { IpServiceService } from 'src/app/ip-service.service';
import { user } from 'src/app/model/user';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as jspdf from 'jspdf';
import { empty, Observable, Subscriber } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { RepositoryDocumentationService } from 'src/app/services/repository-Documentation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BuscarLineaService } from './buscar-linea.service';
import { log } from 'console';
import { invalid } from '@angular/compiler/src/render3/view/util';
import { promise } from 'protractor';
import { MatDatepicker } from '@angular/material/datepicker';
@Component({
    selector: 'app-initmovil',
    templateUrl: './initmovil.component.html',
    styleUrls: ['./initmovil.component.css'],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class InitmovilComponent implements OnInit {
    @ViewChild('dp3') datePicker: MatDatepicker<any>;
    mostrarDiv: boolean = false;
    previousNifValue: string;
    con: any;
    lineaValidaParaCreacion = false;
    poblacion;
    provincia;
    codigopostal;
    pais: any;
    direccion: string;
    title = 'rou';
    uDetails: user;
    iccresponse: any
    public country: Boolean = false;
    public load: Boolean = false;
    public prepago: Boolean = false;
    public coste: Boolean = false;
    public portar: Boolean = false;
    public espacio: Boolean = true;
    public sim: Boolean = true;
    public send: Boolean = false;
    public iccres: Boolean = false;
    public iccresdos: Boolean = false;
    registroForm: FormGroup;
    data;
    data2;
    data7: any;
    registrolineaform: FormGroup;
    aux: any;
    nif;
    tiponif;
    idcliente;
    nombre;
    apellido1;
    apellido2;
    fechanac;
    tipocliente: any;
    contratoOficial;
    cuarto;
    portabilidad;
    numero;
    tarifa;
    bono: any;
    doc1;
    doc2;
    doc3: any;
    tipo1;
    tipo2;
    tipo3: any;
    nombre1;
    nombre2;
    nombre3: any;
    paisGuardado: any;
    encontrado;
    errorCIF;
    errorBuscarLinea;
    erroSim: boolean = false;
    msglinea: boolean;
    listArchivosServer: any
    terceroaux;
    contratoaux;
    cuartoaux: any;
    isDisableSelectPais: boolean;
    disablebtnBuscarNif: boolean;
    esimEstado: boolean;
    isDisabledBuscarLineas: boolean;
    crearOEDITAROBUSCAR: boolean;
    cuartoCliente: string
    numeroCalle: string = "1";
    ext1: any;
    ext2: any;
    ext3: any;
    paisString
    ipAddress: string;
    public loadModal: boolean
    listaLineasClienteBuscado: any = null;
    longitudDocumento: number;

    //_________-BONUS + TARIFAS
    allTarifas;
    tarifaSelected = "";
    listBonusVoz = null;
    listBonusDatos = null;
    bonoDatosSelected = null;
    bonoVozSelected = null;
    telefonoAsignado = null;
    @Input() crearLinea = false
    mensajeErrorCIF: string;
    mensajeErrorBuscarLinea: string;
    esPortable: boolean = false

    options = {
        componentRestrictions: {
            country: ["ES"]
        },
        types: ['address']
    }

    Tarifa = {
        tercero: '',
        contratoP: ''
    }

    contrato = {
        username: '',
        password: '',
    };

    setlinea = {
        "tercero": '',
        "contratoProvisional": '',
        "tarifa": '',
        "portabilidad": '',
        "iccNuevo": '',
        "cuarto": '',
        "iccPortabilidad": '',
        "telefono": '',
        "bonoMinutos": '',
        "bonoMegas": '',
        "mail": '',
        "is_eSim": ''
    }

    constructor(
        private sanitizer: DomSanitizer,
        public repositoryService: RepositoryDocumentationService,
        private formBuilder: FormBuilder,
        private http: HttpClient,
        private auth: AuthService,
        private router: Router,
        private datePipe: DatePipe,
        private cookie: CookieService,
        private ip: IpServiceService,
        public buscarLineaService: BuscarLineaService,
        private activatedRoute: ActivatedRoute
    ) {
        this.encontrado = false;
        this.repositoryService.listArchFromServe = null;
    }

    ngOnInit(): void {
        this.crearOEDITAROBUSCAR = false;
        this.terceroaux = this.cookie.get('tercero');
        this.longitudDocumento = 30;

        if (this.router.url == "/initmovil") { this.crearLinea = true }

        this.getIP(); //Registramos la ip del cliente
        this.uDetails = this.auth.uLoged;
        this.validacionDeFormularios()
        //__________________________________________Obteniendo contratos
        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
            'pass': this.cookie.get("pass"),
        });

        this.contrato = {
            "username": this.cookie.get("user"),
            "password": this.cookie.get("pass")
        }

        const url2 = environment.endpoint + environment.getContratosMovil;
        var currentIp = (this.ipAddress) ? currentIp = this.ipAddress : currentIp =  "255.255.255.0";

        try {
            this.http.post(url2, { id: currentIp }, { headers }).subscribe((res: any) => {
                if (Array.isArray(res)) {
                    this.aux = res;
                    for (var i = 0; i < this.aux.length; i++) {
                        var con = this.aux[i];
                    }
                } else {
                    this.aux = res;
                    for (var i = 0; i < this.aux.length; i++) {
                        var con = this.aux[i];
                    }
                }
            }, error => {
                console.log(error);
            });
        } catch {
            this.hideloading();
        }
    }

    compruebaClick() {
        const validacionNIF = document.getElementById('tdTiponif');
        validacionNIF.classList.remove('errorParpadeo');
    }

    /**
     * La función `onNifKeyUp` restablece los campos del formulario y habilita los controles del
     * formulario si el valor de entrada nif ha cambiado.
     * @param  - El parámetro `$event` en la función `onNifKeyUp` representa un
     * objeto de evento que se pasa cuando ocurre un evento keyup. Este objeto de evento contiene
     * información sobre el evento que tuvo lugar
     */
    onNifKeyUp($event) {
        const currentValue = $event;
        if (currentValue !== this.previousNifValue) {
            this.nombre = "";
            this.direccion = "";
            this.apellido1 = "";
            this.poblacion = "";
            this.apellido2 = "";
            this.provincia = "";
            this.codigopostal = "";
            this.registroForm.get('fechanac').reset();
            this.registroForm.enable();
            this.registroForm.markAsUntouched();
            this.encontrado = false;
            this.errorCIF = false;
            this.errorBuscarLinea = false;
        }
    }

    /**
     * La función "validaCamposUsuario" comprueba si un formulario es válido y marca todos los controles como
     * tocados si no lo es para mostrar el error.
     */
    //TODO:Valida el crear usuario
    validaCamposUsuario() {

        if (this.registroForm.invalid) {
            const validacion1 = document.getElementById('tdTiponif');
            const validacion2 = document.getElementById('tdTipocliente');
            if (this.registroForm.get('tiponif').value == null || this.registroForm.get('tiponif').value == undefined) {
                validacion1.classList.remove('errorParpadeo');
                void validacion1.offsetWidth;
                validacion1.classList.add('errorParpadeo');
            } else {
                validacion1.classList.remove('errorParpadeo');
            }
            if (this.registroForm.get('tipocliente').value == null || this.registroForm.get('tipocliente').value == undefined) {
                validacion2.classList.remove('errorParpadeo');
                void validacion2.offsetWidth;
                validacion2.classList.add('errorParpadeo');
            } else {
                validacion2.classList.remove('errorParpadeo');
            }

            let documento = this.registroForm.get('nif').value;
            if (documento != null || documento != undefined) {
                let valor = false;
                this.errorCIF = false;
                setTimeout(() => {
                    switch (this.registroForm.get('tiponif').value) {
                        case 'N':
                            if (this.isNif(documento)) { valor = true; this.errorCIF = false; }
                            if (this.isNie(documento)) { valor = true; this.errorCIF = false; }
                            if (valor == false) {
                                this.mensajeErrorCIF = "El documento NIF/NIE es incorrecto";
                                this.errorCIF = true;
                                const idclienteControl = this.registroForm.get('nif');
                                if (this.errorCIF == true) {
                                    idclienteControl.setErrors({ 'invalid': true });
                                }
                            }
                            break;
                        case 'C':
                            if (this.validaCif(documento)) { valor = true; this.errorCIF = false; }
                            if (valor == false) { this.mensajeErrorCIF = "El documento CIF es incorrecto"; this.errorCIF = true; }
                            break;
                        default:

                            break;
                    }
                });
            }

            Object.values(this.registroForm.controls).forEach(control => {
                if (control.invalid) {
                    this.remarcarErrores(control);
                }
            });

            this.mostrarDiv = true;
            return false;

        }
        this.mostrarDiv = false;
        return true;
    }

    validaCamposlineas() {
        Object.values(this.registrolineaform.controls).forEach(control => {
            if (control.invalid) {
                this.remarcarErrores(control);
            }
            return false;
        });
        return true;
    }


    /**
     * Creacion y modificacion de linea
     */
    setLineas() {
        this.showloading();
        const url = environment.endpoint + environment.setLineasMovil;
        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
            'pass': this.cookie.get("pass"),
        });

        if (this.bonoVozSelected == null) {
            this.bonoVozSelected = "0";
        }
        if (this.bonoDatosSelected == null) {
            this.bonoDatosSelected = "0";
        }

        let antiguasim = "0";
        this.registrolineaform.controls['iccPortabilidad'].disable();
        if (this.prepago == true) {
            antiguasim = this.registrolineaform.get('iccPortabilidad').value;
            this.registrolineaform.controls['iccPortabilidad'].enable();
        }

        switch (this.registrolineaform.get('portabilidad').value) {
            case "S": //____________________________________________________SIN PORTABILIDAD (NUMERO NUEVO)
                this.setlinea = {
                    "tercero": this.terceroaux,
                    "contratoProvisional": this.contratoaux,
                    "tarifa": this.registrolineaform.get('tarifa').value,
                    "portabilidad": this.registrolineaform.get('portabilidad').value,
                    "iccNuevo": this.registrolineaform.get('iccNuevo').value,
                    "cuarto": this.cuartoaux,
                    "iccPortabilidad": '0',
                    "telefono": "0",
                    "bonoMinutos": this.bonoVozSelected,
                    "bonoMegas": this.bonoDatosSelected,
                    "mail": this.registrolineaform.get('email').value,
                    "is_eSim": this.registrolineaform.get('checkESIM').value
                }

                if (this.cuartoaux != undefined &&
                    this.registrolineaform.get('tarifa').value != 0 &&
                    this.registrolineaform.get('tarifa').value != null &&
                    (this.setlinea.iccNuevo != "" || this.setlinea.mail != "")
                ) { //HAY CLIENTE, HAY TARIFA // Cliente nuevo numero 
                    const url = environment.endpoint + environment.setLineasMovil;
                    this.lineaValidaParaCreacion = true;
                } else {
                    this.hideloading()
                    if (this.registroForm.invalid) {
                        this.mensajeErrorCIF = "Debe buscar un cliente antes de crear una linea";
                        this.errorCIF = true;
                        Object.values(this.registroForm.controls).forEach(control => {
                            if (control.invalid) {
                                this.remarcarErrores(control);
                            }
                        });
                    }
                }

            //______________________________________________________________________END SIN PORTABILIDAD
            case "C": //__________________________________________CON PORTABILIDAD CON NUMERO ADJUNTADO
                // this.hideloading()
                this.setlinea = {
                    "tercero": this.terceroaux,
                    "contratoProvisional": this.contratoaux,
                    "tarifa": this.registrolineaform.get('tarifa').value,
                    "portabilidad": this.registrolineaform.get('portabilidad').value,
                    "iccNuevo": this.registrolineaform.get('iccNuevo').value,
                    "cuarto": this.cuartoaux,
                    "iccPortabilidad": antiguasim,
                    "telefono": this.numero,
                    "bonoMinutos": '',
                    "bonoMegas": '',
                    "mail": this.registrolineaform.get('email').value,
                    "is_eSim": this.registrolineaform.get('checkESIM').value
                }
                if (this.coste) {
                    this.setlinea['bonoMinutos'] = this.registrolineaform.get('bonominutos').value;
                    this.setlinea['bonoMegas'] = this.registrolineaform.get('bonomegas').value;
                } else {
                    this.setlinea['bonoMinutos'] = '0';
                    this.setlinea['bonoMegas'] = '0';
                }

                if (
                    this.cuartoaux != undefined &&
                    this.registrolineaform.get('tarifa').value != 0 &&
                    this.registrolineaform.get('tarifa').value != null &&
                    (this.setlinea.iccNuevo != "" || this.setlinea.mail != "")
                ) { //HAY CLIENTE, HAY TARIFA
                    this.lineaValidaParaCreacion = true;

                    //________________________________prueba de crear una linea sin adjuntar el contrato

                } else {
                    this.hideloading()
                    if (this.registroForm.invalid) {
                        this.errorCIF = false;
                        setTimeout(() => {
                            this.mensajeErrorCIF = "Debe buscar un cliente antes de crear una linea";
                            this.errorCIF = true;
                        });
                        Object.values(this.registroForm.controls).forEach(control => {
                            if (control.invalid) {
                                this.remarcarErrores(control);
                            }
                        });
                    }
                }
                // }
                //END EL NUMERO ES PORTABLE
                // else { //NO ES PORTABLE
                // this.createErrorAlert('El número de teléfono introcucido no es portable.');

                // } //NO ES PORTABLE
                break; //___________________________________________________________-END CON PORTABILIDAD CON NUMERO ADJUNTADO
        }

        if (this.registrolineaform.get('checkESIM').value) {
            delete this.setlinea.iccNuevo;
        } else {
            delete this.setlinea.mail;
        }

        this.validaCamposlineas();

        //========================SI TODO ES CORRECTO PODREMOS CREAR LA LINEA LA LINEA
        if (this.lineaValidaParaCreacion) {
            try {
                this.http.post(url, this.setlinea, { headers }).subscribe(
                    (res: any) => { // Success
                        // resolve();
                        this.subirDocumentacionSiExiste(res.telf); //Sube la documentacion si existe adjuntada sino creara el path del cliente el el ftp
                        if (this.registrolineaform.get('portabilidad').value == "S") { //Grabado con portabilidad
                            this.telefonoAsignado = res.telf;
                            this.hideloading();
                            Swal.fire({
                                icon: 'success',
                                title: 'COMPLETADO',
                                text: 'Se ha creado una línea con número: (' + res.telf + ') y número de incidencia: (' + res.inci + '). ¿Desea exportar el contrato en formato PDF?',
                                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                                background: '#F5F5F5',
                                denyButtonText: "No, lo haré mas adelante",
                                showDenyButton: true,
                                showConfirmButton: true,
                                confirmButtonText: "Si, deseo exportarlo"
                            }).then(response => {
                                if (response.isConfirmed) {
                                    //se descargará el contrato en pdf
                                    this.exportarContratoLineaCreada('ptv_conMobile_' + this.apellido1 + '_' + this.apellido2 + '_' + this.nombre, this.telefonoAsignado)
                                    this.portabilidadClick("S");
                                }
                                this.registrolineaform.reset({
                                    portabilidad: 'S',
                                    checkESIM: false
                                });
                                //this.registrolineaform.reload();
                                this.registroForm.reset({
                                    idcliente: '0'
                                });
                            })
                        } else { //Con portabilidad
                            this.hideloading();
                            this.telefonoAsignado = res.telf; // para la generacion del contrato
                            Swal.fire({
                                icon: 'success',
                                title: 'COMPLETADO',
                                text: 'Correcto. Se ha creado la línea con portabilidad en el teléfono: ' + this.numero + ' ¿Desea exportar el contrato en formato PDF?',
                                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                                background: '#F5F5F5',
                                denyButtonText: "No, lo haré mas adelante",
                                showDenyButton: true,
                                showConfirmButton: true,
                                confirmButtonText: "Si, deseo exportarlo"
                            }).then(response => {
                                if (response.isConfirmed) {
                                    //se descargará el contrato en pdf
                                    this.exportarContratoMovilEnPdf('ptv_conMobile_' + this.apellido1 + '_' + this.apellido2 + '_' + this.nombre)
                                    //Para exportacion mediante portabilidad tras la creacion de linea
                                    this.portabilidadClick("S");
                                }
                                this.registrolineaform.reset({
                                    portabilidad: 'S',
                                    checkESIM: false
                                });
                                this.registroForm.reset();
                            })
                        }
                        this.sim = true;
                        this.send = false;
                        this.registrolineaform.get('iccNuevo').setValidators([
                            Validators.required,
                            Validators.minLength(19),
                            Validators.maxLength(19),
                            Validators.pattern(/^[0||1||2||3||4||5||6||7||8||9]\d{18}$/gm)
                        ]);
                        this.registrolineaform.get('iccNuevo').updateValueAndValidity();
                    },
                    (error) => { // Error
                        // reject(msg);
                        if (Array.isArray(error.error)) {
                            this.createErrorAlert('Error', error.error.toString());
                            this.hideloading();
                        } else {
                            this.hideloading();
                            this.createErrorAlert(error.error.des, 'ERROR ' + error.error.id);
                        }

                    }
                );
            } catch {
                this.hideloading();
            }
        }

        // }).catch((res) => {
        //     //Resultado si la linea no es portable

        // });
        //End Linea valida para creacion
    }

    //__________________________________________CACHEAR_DOCUMENTO
    setDoc(event, t: string) {
        const file = event.target.files[0];

        let fileSizeMb = (file.size / 1024) / 1024;
        if (fileSizeMb > 5) { //SI SE EXCEDE LOS 5MB
            Swal.fire({
                title: "ARCHIVO DEMASIADO GRANDE",
                icon: "warning",
                text: "El archivo supera el límite de 5Mb."
            })
            this.loadModal = false;
            return
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        this.cuartoCliente = this.cookie.get("cuarto");
        reader.onload = () => {
            switch (t) {
                case "DocIdentidad":
                    this.doc1 = reader.result;
                    this.nombre1 = file.name.split(".")[0];
                    this.ext1 = file.name.split(".").pop();

                case "Copia":
                    this.doc2 = reader.result;
                    this.nombre2 = file.name.split(".")[0];
                    this.ext2 = file.name.split(".").pop();
                    break;
                case "grabacion":
                    this.doc3 = reader.result;
                    this.nombre3 = file.name.split(".")[0];
                    this.ext3 = file.name.split(".").pop();
                    break;
            }
        };
    }

    /**
     * Sube documentacion o crea el path si el cliente ha dado de alta a una linea movil
     */
    subirDocumentacionSiExiste(telefono: String) {
        let terceroOperador = this.cookie.get("tercero");

        if (this.doc1) {
            this.repositoryService.uplodaDocuments(this.doc1, this.ext1, terceroOperador, telefono, this.nombre1);
        }
        if (this.doc2) {
            this.repositoryService.uplodaDocuments(this.doc2, this.ext2, terceroOperador, telefono, this.nombre2);
        }

        if (this.doc3) {
            this.repositoryService.uplodaDocuments(this.doc3, this.ext3, terceroOperador, telefono, this.nombre3);
        }

    }

    /**
     * Método para la exportacion del contrato para el boton
     */
    exportarContratoMovilEnPdf(nameFile) {
        // this.telefonoAsignado="744637839";//para pruebas

        if (this.comprobarDatosParaExportacion() != "Todo correcto") {
            let message = this.comprobarDatosParaExportacion();
            Swal.fire({
                icon: 'error',
                title: 'ERROR',
                text: this.comprobarDatosParaExportacion(),
                footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                background: '#F5F5F5',
                html: `<div>*` + message.toUpperCase() + `</div>
                <div>Si de lo contrario desea exportar el contrato de una línea ya existente debe introducir el número para poder exportarlo.</div>
                <input oninput="this.value=this.value.replace(/(?![0-9])./gmi,'')" name="telefonoMovil" id="telefonoMovil" class=" swal2-input numerModal" placeholder="Introduzca el teléfono móvil a buscar"  maxlength="9">`,
            }).then(response => {
                if (response.isConfirmed) {
                    let numero = $("#telefonoMovil").val();
                    if (String(numero).length == 9) { //si nos manda el numero haremos la busqueda
                        this.exportarContratoLineaCreada('ptv_conMobile_' + numero, numero); //Exportacion por numero
                    }
                }
            })
            return;
        } else {
            this.exportacionContratoSinAlta('ptv_conMobile_' + this.numero); //Exportacion de contrato mediante Portabilidad
        }
    }

    /**
     * Evento encargado de retornar el contrato para ser firmado por el cliente *de lineas ya creadas
     */
    exportarContratoLineaCreada(nameFile, numero) {
        //generacion de contrato en pdf
        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
            'pass': this.cookie.get("pass"),
        });

        const body = {
            "tercero": this.cookie.get("tercero"),
            "telefono": String(numero),
            "contratoProvisional": this.con
        }
        this.showLoadModal();
        const url = environment.exportarEnPDfContratoMovil;

        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.hiddeLoadModal();
                this.downloadData(res.fileBase64, nameFile + ".pdf");

            }, error => {
                this.hiddeLoadModal();
                this.createErrorAlert('La línea introducida no contiene datos para la exportación.');
            })
        } catch {
            this.hiddeLoadModal();
        }
    }

    /**
     * Validacion de parametros para la exportación del contrato
     */
    comprobarDatosParaExportacion() {
        for (const element in this.registroForm.value) {
            let error = false;
            if (!this.registroForm.get(element).value && typeof (this.registroForm.get(element).value) == 'object') {
                error = true;
            } else if (!this.registroForm.get(element).value || this.registroForm.get(element).value.trim().length == 0) {
                error = true;
            }
            if (error) return "Falta por rellenar el campo '" + element + "'";
        }
        return "Todo correcto"
    }

    /**
     * Método para exportar el contrato sin dar de alta a la línea *solo para portabilidades
     */
    exportacionContratoSinAlta(nameFile: string) {
        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
            'pass': this.cookie.get("pass"),
        });

        let datos = {
            //_______________________DATOS DEL USUARIO_____________________________
            "nombreCompleto": this.registroForm.get("nombre").value + ' ' + this.apellido1 + ' ' + this.apellido2,
            "documento": this.registroForm.get("nif").value,
            "fechaContrato": this.dateNow(),
            "fechaNacimiento": this.registroForm.get("fechanac").value,
            "calle": this.registroForm.get("direccion").value,
            "codigoPostal": this.registroForm.get("codigopostal").value,
            "poblacion": this.registroForm.get("poblacion").value,
            "provincia": this.registroForm.get("provincia").value,
            //_______________________DATOS DE LA LÍNEA_____________________________
            "telefono": this.registrolineaform.get('numero').value,
            "multiSim": "N",
            "esim": this.registrolineaform.get('checkESIM').value,
            "simNueva": this.registrolineaform.get('iccNuevo').value,
            "tarifa": this.registrolineaform.get('tarifa').value,
            "portabilidad": this.registrolineaform.get('portabilidad').value,
            "antiguaSim": this.registrolineaform.get("iccPortabilidad").value
        }
        this.showLoadModal();
        const url = environment.pdfWholesale;

        try {
            this.http.post(url, datos, { headers }).subscribe((res: any) => {
                this.hiddeLoadModal();
                this.downloadData(res.fileBase64, nameFile + ".pdf");
            }, error => {
                this.hiddeLoadModal();
                this.createErrorAlert('La línea introducida no contiene datos para la exportación.');
            })
        } catch {
            this.hiddeLoadModal();
        }
    }

    /**
     * Descarga del base del contrato para retornar el PDF
     */
    downloadData(base64, nombre) {
        const linkSource = "data:application/pdf;base64," + base64;

        let pdfBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(linkSource);
        var a = document.createElement("a"); //Create <a>
        a.href = linkSource; //Image Base64 Goes here
        a.download = nombre; //File name Here
        a.click(); //Downloaded file

    }

    /**
     * Util para devolver la fecha de nacimiento en formato DD/MM/YYYY
     * @param DateToFormat Fecha en formato String a formatear
     * @returns 
     */
    formatingFecha(DateToFormat) {
        let date = new Date(DateToFormat);
        let mes = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        return date.getDate() + "/" + mes + "/" + date.getFullYear();
    }

    /**
     * Método para devolver la fecha actual en Formato 
     * @returns DD/MM/YYYY actual del sistema
     */
    dateNow() {
        let date = new Date();
        let mes = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        return date.getDate() + "/" + mes + "/" + date.getFullYear();
    }

    //_________________________COMPROBAR_OPERADOR_ENDPOINT
    loadXML() {
        return this.http.get(environment.cOperador + this.registrolineaform.get('telefono').value, { responseType: 'text' })
    }

    //_________________________CONVERSOR_DE_DATOS
    parseData(data: any) {
        let parser: DOMParser = new DOMParser();
        let xmlDoc = parser.parseFromString(data, "text/xml");

        let elements: HTMLCollection;
        elements = xmlDoc.getElementsByTagName("Registro");
        if (elements[0]) {
            let nombre: any = elements[0].getAttribute("Operador");
            if (nombre) {
                Swal.fire('Búsqueda completada del Operador', nombre, 'info');
            }
        }
        return true;
    }

    //_________________________COMPROBAR_OPERADOR
    async cOperador() {
        if (this.registrolineaform.get('telefono').value != null) {
            this.loadXML()
                .subscribe(res => {
                    if (this.parseData(res)) {
                        this.parseData(res);
                    }
                })

            setTimeout(() => { }, 2000);
        } else {
            this.createErrorAlert('Debe introducir el número previamente.');
        }
    }

    //_________________________________VALIDADOR_DE_NIF
    isNif(documento) {
        let lettersEnd = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];
        let isNif = false;
        if (documento.length == 9) {
            let letter = documento.substring(8, 9);
            let number = parseInt(documento.substring(0, 8));
            if (!isNaN(number) && isNaN(parseInt(letter))) {
                if (lettersEnd[number % 23] === letter) {
                    isNif = true;
                }
            }
        }
        return isNif;
    }

    //_________________________VALIDADOR_DE_NIE
    isNie(documento) {
        let isNie = false;
        let nieRegex = /^[XYZ][0-9][0-9][0-9][0-9][0-9][0-9][0-9][A-Z0-9]/;
        let num = [];

        if (nieRegex.test(documento)) {
            for (let i = 0; i < 9; i++) {
                var aux = i;
                num[i] = documento.substr(i, aux++);
            }
            let letters = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];
            let dniTransform = documento.replaceAll('X', '0').replaceAll('Y', '1').replaceAll('Z', '2');
            if (num[8] === letters[parseInt(dniTransform.substring(0, 8)) % 23]) {
                isNie = true;
            }
        }
        return isNie;
    }

    //_________________________VALIDADOR_DE_CIF    
    validaCif(cif) {
        if (!cif || cif.length !== 9) {
            return false;
        }
        var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
        var digits = cif.substr(1, cif.length - 2);
        var letter = cif.substr(0, 1);
        var control = cif.substr(cif.length - 1);
        var sum = 0;
        var i;
        var digit;
        if (!letter.match(/[A-Z]/)) {
            return false;
        }

        for (i = 0; i < digits.length; ++i) {
            digit = parseInt(digits[i]);

            if (isNaN(digit)) {
                return false;
            }

            if (i % 2 === 0) {
                digit *= 2;
                if (digit > 9) {
                    digit = parseInt(String(digit / 10)) + (digit % 10);
                }

                sum += digit;
            } else {
                sum += digit;
            }
        }

        sum %= 10;
        if (sum !== 0) {
            digit = 10 - sum;
        } else {
            digit = sum;
        }

        if (letter.match(/[ABEH]/)) {
            return String(digit) === control;
        }
        if (letter.match(/[NPQRSW]/)) {
            return letters[digit] === control;
        }

        return String(digit) === control || letters[digit] === control;
    }


    //_______________________________________________Loading
    showloading() {
        this.loadModal = true;
    }
    hideloading() {
        this.loadModal = false;
    }

    //_________________________________CREAR_USUARIO_CON_VALIDACION_NIF/CIF/NIE_Y_VALIDACIÓN_DE_FECHA
    formatDate() {
        let d = new Date(),
            month = (d.getMonth() + 1).toString(),
            day = d.getDate().toString(),
            year = (d.getFullYear() - 18).toString();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        let fecha = year + month + day;

        return fecha;
    }

    //_________________________EXTRACTOR_DEL_TIPO_DE_CLIENTE
    guardaTipoCliente(letra: string) {
        const validacionCliente = document.getElementById('tdTipocliente');
        validacionCliente.classList.remove('errorParpadeo');
        this.tipocliente = letra;
    }

    //_________________________CREAR/EDITAR_USUARIOS
    crearUsuario() {
        if (this.validaCamposUsuario()) {
            this.showloading();
            let data = {};
            data = JSON.parse(JSON.stringify(this.registroForm.value));
            data['ip'] = this.ipAddress;
            data['tercero'] = this.terceroaux;
            delete data['idcliente'];
            delete data['tiponif'];
            delete data['fechanac'];
            data['tipoNif'] = this.registroForm.get('tiponif').value;
            let fechaNacimiento = this.registroForm.get('fechanac').value;
            data['fechaNacimiento'] = fechaNacimiento.getFullYear() + "/" + (fechaNacimiento.getMonth() + 1) + "/" + fechaNacimiento.getDate();
            data['contratoProvisional'] = this.con;

            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });

            const url = environment.endpoint + environment.setCliente;
            this.http.post(url, data, { headers }).subscribe((res: any) => {
                if (!res.Pedido) {
                    this.createErrorAlert("NIF o Nombre no identificado por AEAT");
                } else {
                    if (res.error && res.error.includes("Ya existe codigo para este cliente:")) {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Usuario existente.',
                            text: 'El usuario que intenta registrar ya existe.',
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                            background: '#F5F5F5',
                        });
                    } else {
                        Swal.fire({
                            icon: 'success',
                            title: 'COMPLETADO',
                            text: 'El usuario ' + data['nombre'] + ' ' + data['apellido1'] + ' ' + data['apellido2'] + ' ha sido registrado correctamente.',
                            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                            background: '#F5F5F5',
                        });
                        this.registroForm.reset();
                        this.registroForm.disable();
                        this.registroForm.controls['idcliente'].enable();
                    }
                }
                this.hideloading();
            });
        };
    }

    editarUsuario() {
        let modifiedFields = ['nif', 'direccion', 'codigopostal', 'poblacion', 'provincia']
        const dataModified = {};
        for (let field of modifiedFields) {
            if (field == 'nif') {
                dataModified['documento'] = this.registroForm.controls[field].value;
            } else if (field == 'codigopostal') {
                dataModified['codPostal'] = this.registroForm.controls[field].value;
            }
            dataModified['tercero'] = this.cookie.get('tercero');
            dataModified['contratoProvisional'] = this.con;
            dataModified[field] = this.registroForm.controls[field].value;

        }

        this.showloading();
        const headers = new HttpHeaders({
            'pass': this.cookie.get("pass"),
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
        });
        const url = environment.endpoint + environment.modifyCliente;
        this.http.post(url, dataModified, { headers }).subscribe((res: any) => {
            if (res.error) {
                this.createErrorAlert("No se ha podido modificar el usuario");
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'COMPLETADO',
                    text: 'El usuario ' + this.registroForm.controls['nombre'].value + ' ' + this.registroForm.controls['apellido1'].value + ' ' + this.registroForm.controls['apellido2'].value + ' ha sido modificado correctamente.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                });
                this.registroForm.reset();
                this.con = "";
                this.registroForm.disable();
                this.registroForm.controls['idcliente'].enable();
                this.registroForm.get('idcliente').setValue('0');
                this.encontrado = false;
            }
            this.hideloading();
        });

    }

    /**
     * Obtenemos el codigo postal, la población, provincia y pais de la direccion
     * @param address recibe una direccion
     */
    public AddressChange(address: any) {
        if (address.address_components) {
            var paisSeleccionado: String = ""

            for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
                const componentType = component.types[0];
                switch (componentType) {
                    case "route":
                        this.direccion = `${component.long_name}`;
                        break;
                    case "street_number":
                        this.numeroCalle = (`${component.long_name}` == undefined) ? "1" : `${component.long_name}`;
                        break;

                    case "postal_code":
                        this.codigopostal = `${component.long_name}`;
                        break;

                    case "locality":
                        this.poblacion = `${component.long_name}`;
                        break;

                    case "administrative_area_level_2":
                        this.provincia = `${component.long_name}`;
                        break;

                    case "country":
                        paisSeleccionado = `${component.long_name}`;
                        this.paisString = paisSeleccionado;
                        break;
                }
            }
            this.direccion += ", " + this.numeroCalle;
        }
        var paisSelect = (document.getElementById("pais")) as HTMLSelectElement;
        const select = paisSelect;
        const value = select.value;
        const desc = select.selectedOptions[0].text = paisSeleccionado.toUpperCase();

        this.data7.forEach(element => {
            if (element.desc == paisSeleccionado.toUpperCase()) {
                this.pais = element.codi;
            }
        });
    }

    //_________________________________OBTENCION_IP_DE_LA_COOKIE
    getIP() {
        this.ipAddress = this.cookie.get("ip");
    }

    //_________________________________CARGANDO DATOS DE TARIFA
    selectOptionTarifa($event) {
        if ($event == 0) {
            this.crearOEDITAROBUSCAR = false;
            this.registroForm.reset();
            this.registrolineaform.reset();
            this.idcliente = 0;
            this.tarifa = 0;
            this.errorCIF = false;
            this.errorBuscarLinea = false;
            this.registroForm.get('pais').disable();
            this.registroForm.disable();
            this.registrolineaform.disable();
            this.registroForm.get('idcliente').enable();
            this.datePicker.disabled = true;
            this.encontrado = false;

            const validacionNIF = document.getElementById('tdTiponif');
            validacionNIF.classList.remove('errorParpadeo');
            const validacionCliente = document.getElementById('tdTipocliente');
            validacionCliente.classList.remove('errorParpadeo');
        } else {
            const splittedValues = $event.split('|');
            this.con = splittedValues[1];
            this.tarifaSelected = null;
            this.listBonusVoz = null
            this.listBonusDatos = null
            this.tarifaSelected = null;
            this.registroForm.enable();
            this.registroForm.get('pais').disable();
            this.isDisableSelectPais = false;
            this.disablebtnBuscarNif = false;
            this.isDisabledBuscarLineas = true;
            this.crearOEDITAROBUSCAR = true;
            var splitted = $event.split("|", 2);
            this.registrolineaform.enable();
            this.contratoaux = splitted[1];
            this.getTarifas(this.cookie.get('tercero'), splitted[1]);
            this.esimEstado = false;
            this.portabilidad = 'S';
            this.portar = false;
            this.send = false;

            this.datePicker.disabled = false;
            this.nombre = "";
            this.direccion = "";
            this.apellido1 = "";
            this.poblacion = "";
            this.apellido2 = "";
            this.provincia = "";
            this.codigopostal = "";
            this.registroForm.get('tiponif').setValue(null);
            this.registroForm.get('tipocliente').setValue(null);
            this.registroForm.get('fechanac').reset();

        }
    }

    /**
     * DEVUELVE UN LISTADO DE TARIFAS PERTENECIENTES A UN CONTRATO PROVISIONAL Y UN TERCERO
     */
    getTarifas(tercero, contratop) {
        this.showloading();
        let promise = new Promise<void>((resolve, reject) => {
            this.Tarifa = {
                tercero: tercero,
                contratoP: contratop
            }
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });

            const url = environment.endpoint + environment.getTarifaMovil;
            this.http.post(url, this.Tarifa, { headers }).toPromise().then(
                res => { // Success
                    this.allTarifas = res;
                    if (res['id']) {
                        this.hideloading();
                        this.createErrorAlert('Se ha producido un error al cargar las tarifas.');
                    } else {
                        for (var i = 0; i < this.allTarifas.length; i++) {
                            var con = this.allTarifas[i];
                        }
                        resolve();
                        this.hideloading();
                    }
                },
                msg => { // Error
                    reject(msg);
                    this.hideloading();
                    this.createErrorAlert('Error al cargar las tarifas.');
                }
            );
        });
        return promise;
    }

    devuelveFecha(fechaNoFormateada) {
        const anio = Number(fechaNoFormateada.substring(0, 4));
        const mes = Number(fechaNoFormateada.substring(4, 6)) - 1;
        const dia = Number(fechaNoFormateada.substring(6, 8));


        const fecha = new Date(anio, mes, dia);
        return fecha;
    }


    mostrarFechaNacimiento: boolean = false;

    // Luego, en algún lugar de tu lógica, podrías cambiar esta propiedad cuando se seleccione una fecha:
    seleccionarFecha() {
        this.mostrarFechaNacimiento = true;
    }

    /**
     * La función "remarcarErrores" marca un valor como bo tocado y luego, después de un breve retraso,
     * lo marca como tocado en TypeScript.
     * @param valor - El parámetro `valor` en la función `remarcarErrores` es un campo de
     * entrada o elemento de control de formulario. La función se utiliza para restablecer el estado de
     * validación de este campo de entrada. Primero marca el campo como intacto y luego, después de un
     * breve retraso usando `setTimeout`, lo marca
     */
    remarcarErrores(valor) {
        valor.markAsUntouched();
        setTimeout(() => {
            valor.markAsTouched();
        });
    }
    remarcarErroresTexto(valor) {
        valor.markAsUntouched();
        setTimeout(() => {
            valor.markAsTouched();
        });
    }

    //_________________________________BUSCAR_USUARIO
    /**
     * La función `getUsuario` recupera información del usuario basándose en un NIF proporcionado,
     * manejando casos de error y actualizando los campos del formulario en consecuencia.
     */
    getUsuario() {
        if (this.nif == null || this.nif == '') {
            const NIF = this.registroForm.get('nif');
            this.remarcarErrores(NIF);
        } else {
            this.showloading();
            this.nif = this.nif.toUpperCase()
            const body = {
                "nif": this.nif,
                "tercero": this.cookie.get('tercero'),
                "contratoProvisional": this.con

            }
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });
            const url = environment.endpoint + environment.getUsuario;
            this.http.post(url, body, { headers }).subscribe((res: any) => { // Success
                if (res.id) {
                    this.hideloading();
                    this.createErrorAlert('Usuario no encontrado.');
                    return false;
                } else {
                    this.errorCIF = false;
                    this.cookie.set('cuarto', res.cod);
                    let documento, tcliente;

                    if (this.isNif(res.doc) || this.isNie(res.doc)) {
                        documento = "N"
                    } else if (this.validaCif(res.doc)) {
                        documento = "C"
                    } else if (!this.validaCif(res.doc) && !this.validaCif(res.doc) && !this.isNif(res.doc)) {
                        documento = "P"
                    }

                    if (res.tcli == "R") {
                        tcliente = "R"
                    } else if (res.tcli == "S") {
                        tcliente = "S"
                    } else if (res.tcli == "A") {
                        tcliente = "A"
                    }

                    this.registroForm.patchValue({
                        apellido1: res.tcli == 'C' ? '' : res.ape1,
                        apellido2: res.tcli == 'C' ? '' : res.ape2,
                        nombre: res.tcli == 'C' ? res.des : res.nom,
                        codigopostal: res.cp,
                        direccion: res.dir,
                        fechanac: this.devuelveFecha(res.fecha2),
                        tiponif: documento,
                        poblacion: res.poblacion,
                        provincia: res.localidad,
                        tipocliente: tcliente,
                        pais: 34
                    })
                    let disabledFields = ['nombre', 'apellido1', 'apellido2', 'fechanac', 'tipocliente', 'tiponif'];
                    for (let field of disabledFields) {
                        this.registroForm.controls[field].disable();
                    }
                    this.cookie.set("cuarto", res.cod);
                    this.cuartoaux = res.cod

                    this.encontrado = true;
                    this.hideloading();
                    this.previousNifValue = this.nif;
                    this.mostrarDiv = false;
                    this.errorBuscarLinea = false;
                    this.registroForm.get('pais').disable();
                    const validacionNIF = document.getElementById('tdTiponif');
                    validacionNIF.classList.remove('errorParpadeo');
                    const validacionCliente = document.getElementById('tdTipocliente');
                    validacionCliente.classList.remove('errorParpadeo');
                    return true;
                }
            });
        }
    }

    //________________________________________HABILITA/DESHABILITA_EL_NUMERO_DE_PORTABILIDAD_SI_SE_SELECCIONA_"Con_portabilidad"
    checkboxClick(value) {
        if (value == true) {
            this.registrolineaform.controls['iccPortabilidad'].enable();
            this.prepago = true;
        } else if (value == false) {
            this.registrolineaform.controls['iccPortabilidad'].disable();
            this.registrolineaform.controls['iccPortabilidad'].setValue("");
            this.prepago = false;
        }
    }

    //_________________________________________COMPRUEBA_SI_LA_TARIFA_ES_COSTE_PARA_HABILITAR_CAMPOS
    selectOptioncoste(value) {
        let isBase: boolean = false;
        this.tarifaSelected = null, this.listBonusVoz = null, this.listBonusDatos = null;
        if (value.includes("MOVISUR") && value.includes("Base")) {
            this.tarifaSelected = "MOVISUR";
            isBase = true;
        } else if (value.includes("DEION") && value.includes("base") || value.includes("BASE")) {
            this.tarifaSelected = "DEION";
            isBase = true;
        } else if (value.includes("COSTE") || value.includes("coste")) {
            this.tarifaSelected = "COSTE";
            isBase = true;
        } else if (value.includes("avatel") || value.includes("AVATEL") && value.includes("base") || value.includes("BASE")) {
            this.tarifaSelected = "AVATEL";
            isBase = true;
        } else {
            this.tarifaSelected = value;
        }
        // this.tarifaSelected= null;
        if (isBase) {
            this.getBonosTarifas(this.tarifaSelected, "voz");
            this.getBonosTarifas(this.tarifaSelected, "datos");
        }

        value == "COSTE" ? this.coste = true : this.coste = false;
    }


    /**
     * Metodo que cambia y modifica los if del html para mostrar o no mostrar algunos input
     * @param event 
     */
    checkESim(event: any) {
        if (event.target.checked) {
            this.send = true;
            this.sim = false;
            this.registrolineaform.get('iccNuevo').clearValidators();
            this.registrolineaform.get('iccNuevo').updateValueAndValidity();
            this.registrolineaform.get('iccNuevo').reset();
        }
        else {
            this.sim = true;
            this.send = false;
            this.registrolineaform.get('iccNuevo').setValidators([
                Validators.required,
                Validators.minLength(19),
                Validators.maxLength(19),
                Validators.pattern(/^[0||1||2||3||4||5||6||7||8||9]\d{18}$/gm)
            ]);
            this.registrolineaform.get('iccNuevo').updateValueAndValidity();
            this.registrolineaform.get('email').reset();
        }
    }

    //_________________________________________COMPRUEBA_PORTABILIDAD_PARA_ACTIVAR/DESACTIVAR_CAMPOS
    portabilidadClick(value) {
        this.registrolineaform.controls['iccPortabilidad'].disable();
        value == "C" ? this.portar = true : this.portar = false;
    }

    //_________________________________COMPRUEBA_QUE_EL_ICC_NO_ESTE_SELECCIONADO
    onICChange(value) {
        $("#alerticc").show()
        var response: any;
        this.iccres = false;
        this.iccresdos = false;
        var valorICC = value;
        var num = /^([0-9]){0,19}$/;

        if (value.length == 19 && num.test(valorICC)) {
            this.showloading();

            let promise = new Promise<void>((resolve, reject) => {
                const headers = new HttpHeaders({
                    'user': this.cookie.get("user"),
                    'token': this.cookie.get("token"),
                    'pass': this.cookie.get("pass"),
                });
                let data = {
                    tercero: this.terceroaux,
                    iccId: this.registrolineaform.get('iccNuevo').value
                }

                const url = environment.endpoint + environment.checksim;
                this.http.post(url, data, { headers }).toPromise().then(
                    res => { // Success

                        response = res;
                        if (response.iccid == 'TRUE') {
                            this.msglinea = true;
                            this.iccresponse = 'La nueva SIM está disponible.';
                            this.iccresdos = true;
                            this.erroSim = false;
                        } else {
                            this.msglinea = false;
                            this.iccresponse = 'La nueva SIM no es correcta o ya está registrada.'
                            this.iccres = true;
                            this.erroSim = true;
                        }

                        this.alertICC();
                        this.hideloading();
                        resolve();
                    },
                    msg => { // Error

                        reject(msg);
                        this.hideloading();
                        this.createErrorAlert('Se ha producido un al comprobar ICC. Este error también puede ocurrir si no ha seleccionado una tarifa.');
                    }
                );
            });
            return promise;
        }
    }
    //_________________________________MUESTRA_ALERTA_AL_ENCONTRAR_UN_ICC
    alertICC() {
        if (this.msglinea == true) {
            setTimeout(function () {
                $("#alerticcdos").fadeOut(300, function () {
                    $("#alerticcdos").hide();
                });
            }, 6000);
        } else {
            setTimeout(function () {
                $("#alerticc").fadeOut(300, function () {
                    $("#alerticc").hide();
                });
            }, 6000);
        }
    }

    /**
     * bUSQUEDA DE LINEAS MÓVILES PERTENECIENTES A UN CLIENTE
     */
    buscarLineasMoviles() {
        Swal.fire({
            title: 'Comprobar Lineas',
            confirmButtonText: "Comprobar",
            showCancelButton: true,
            confirmButtonColor: '#00b1aa',
            cancelButtonColor: 'red',
            showConfirmButton: true,
            html: `<style>
            .swal2-actions{
                display: flex;
                justify-content: space-around;
            }
            .swal2-confirm{
                padding: 15px;
                font-family: MontserratBold;
                color: #fff;
                /* background: #00b1aa; */
                text-transform: uppercase;
                letter-spacing: 0.15em;
                border: none;
                border-radius: 50px!important;
                cursor: pointer;
                background: #00b1aa;
                color: white;
            }
            .swal2-cancel{
                padding: 15px;
                font-family: MontserratBold;
                color: #fff;
                /* background: #00b1aa; */
                text-transform: uppercase;
                letter-spacing: 0.15em;
                border: none;
                border-radius: 50px!important;
                cursor: pointer;
                background: red;
                color: white;
            }

            #dniInput{
                text-transform: uppercase;
            }
            </style>
            <lable for="dniInput"  class="swal2-label">Introduzca el Documento de identidad</lable>
            <input name="dniInput" id="dniInput" class=" swal2-input dniModal" placeholder="INTRODUZCA EL DNI A BUSCAR"  maxlength="9">`
        }).then(ok => {
            if (ok.isConfirmed) {
                var inputDni = (document.getElementById("dniInput")) as HTMLInputElement //Obtengo el Dni del Modal
                this.buscarLineasMovilesLaunch(inputDni.value)
            }
        })
    }


    /**
     * La función `buscarLineasMovilesV2` comprueba la validez de los controles del formulario y lanza
     * una búsqueda de líneas móviles si el formulario es válido.
     */

    //TODO:BUSCAR LINEAS
    buscarLineasMovilesV2() {
        const idclienteControl = this.registroForm.get('idcliente');
        if (idclienteControl.status == 'INVALID') {
            idclienteControl.setErrors({ 'invalid': true });
            idclienteControl.markAsTouched();
        } else {
            const NIF = this.registroForm.get('nif');
            if (NIF.status == 'INVALID') {
                NIF.setErrors({ 'invalid': true });
                NIF.markAsTouched();
            } else {
                if (!this.registroForm.valid) {
                    this.errorBuscarLinea = false;
                    setTimeout(() => {
                        this.mensajeErrorBuscarLinea = "Debe de buscar al usuario antes de buscar una linea";
                        this.errorBuscarLinea = true;
                    });
                } else {
                    const dni = document.getElementById("docIdentity") as HTMLInputElement
                    this.buscarLineasMovilesLaunch(dni.value)
                }
            }
        }
    }

    /**
     * Método encargado de buscar las lineas Móbiles que posee un cliente mediante su documento de identidad
     * @param dni 
     */
    buscarLineasMovilesLaunch(dni: String) {
        this.showLoadModal()
        if (!this.con) {
            Swal.fire({
                icon: 'warning',
                title: 'Debe seleccionar un contrato',
                text: 'Por favor, seleccione un contrato antes de continuar.',
                confirmButtonText: 'Entendido'
            });
            this.hiddeLoadModal();
            const idclienteControl = this.registroForm.get('idcliente');
            idclienteControl.setErrors({ 'invalid': true });
            idclienteControl.markAsTouched();
        } else {
            let codigoTercero = this.cookie.get("tercero");
            const body = {
                "dniCuarto": dni,
                "tercero": codigoTercero,
                "contratoProvisional": this.con
            }
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });
            const url = environment.checkMobileLines;
            // this.listLineasMobiles = []
            this.buscarLineaService.listaLineasClienteBuscado = null;

            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.hiddeLoadModal()
                if (res.length > 0) {
                    this.buscarLineaService.listaLineasClienteBuscado = [];
                    this.buscarLineaService.con = this.con;
                    res.forEach(element => {
                        this.buscarLineaService.listaLineasClienteBuscado.push({
                            des: element.des,
                            telf: element.telf,
                            estaLi: element.estaLi,
                            tarifa: element.tarifa,

                            pet: element.pet,
                            cuarto: element.cuarto,
                            mac: element.mac,

                            tipo: element.tipo,
                            ventana: element.ventana,
                            hora: element.hora,
                            estado: element.estadom,

                            boton: element.boton,
                            tercero: element.tercero,
                            cttprv: element.cttprv,
                            cttof: element.cttof,
                            con: this.con
                        })
                    });

                } else { //No Existen Valores
                    Swal.fire({
                        title: 'ERROR',
                        confirmButtonText: "Ok",
                        showCancelButton: false,
                        icon: 'warning',
                        text: "El DNI introducido no contiene ninguna línea."
                    })
                }
            }, error => {
                this.hiddeLoadModal();
            })
        }
    }

    showLoadModal() {
        this.loadModal = true;
    }

    hiddeLoadModal() {
        this.loadModal = false;
    }

    /**
     * Inicializacion de componentes del Formulario 
     */
    initForm() {
        this.tarifa = "0";
        this.idcliente = "0";
        this.registroForm.disable();
        this.registroForm.controls['idcliente'].enable();
        this.disablebtnBuscarNif = true;
        this.isDisableSelectPais = true;
        this.registrolineaform.controls['iccPortabilidad'].disable();
        this.isDisabledBuscarLineas = false;
        this.crearOEDITAROBUSCAR = false;
        this.registrolineaform.disable();
        this.portabilidad = 'S';
        this.portar = false;
        this.esimEstado = true;
    }

    /**
     * comprueba si un telefono es portable o no
     * @param telefono movil a portar
     */
    comprobarNumeroAMigrar(telefono: String) {
        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'pass': this.cookie.get("pass"),
            'token': this.cookie.get("token")
        })
        const body = {
            "telefono": telefono
        }

        let url = environment.checkFreeNumberMobile;
        return this.http.post(url, body, { headers })
    }

    //____________________________-CUMSTOM VALIDATE FORMS______________________________________________
    validacionDeFormularios() {

        const customValidator = (control: AbstractControl): ValidationErrors | null => {
            if (control.value === '0') {
                return { 'invalidOption': true };
            }
            return null;
        };

        this.registrolineaform = this.formBuilder.group({
            'tarifa': ['', [Validators.required, customValidator]],
            'portabilidad': ['', [Validators.required]],
            'iccNuevo': ['', [
                Validators.required,
                Validators.minLength(19),
                Validators.maxLength(19),
                Validators.pattern(/^[0||1||2||3||4||5||6||7||8||9]\d{18}$/gm)
            ]],
            'cuarto': ['',],
            'iccPortabilidad': ['', [
                Validators.required,
                Validators.minLength(19),
                Validators.maxLength(19),
                Validators.pattern(/^[0||1||2||3||4||5||6||7||8||9]\d{18}$/gm)
            ]],
            'numero': ['', [
                Validators.required,
                Validators.minLength(9),
                Validators.maxLength(9),
                Validators.pattern(/^[6||7]\d{8}$/gm)

            ]],
            'email': ['', [Validators.required]],
            'checkESIM': [false, ''],
            'bonoMinutos': ['',],
            'bonoMegas': ['',],
            'fileDocIdentidad': ['',], //OPCIONALES
            'fileCopiaContrato': ['',], //OPCIONALES
            'fileGrabacion': ['',], //OPCIONALES
        });


        this.registroForm = this.formBuilder.group({
            'nif': ['', [Validators.required, Validators.maxLength(this.longitudDocumento)]], // nif: this.registroForm.get('nif').value
            'tiponif': ['', [Validators.required]], // tipoNif: this.registroForm.get('tiponif').value
            'idcliente': ['', [Validators.required, customValidator]], // tercero: this.registroForm.get('idcliente').value
            'tipocliente': ['', [Validators.required]], // tipocliente: this.registroForm.get('tipocliente').value
            'nombre': ['', [Validators.required]], // nombre: this.registroForm.get('nombre').value
            'apellido1': ['', [Validators.required]], // apellido1: this.registroForm.get('apellido1').value
            'apellido2': ['', Validators.required], // apellido2: this.registroForm.get('apellido2').value
            'fechanac': ['', [Validators.required]], // fechaNacimiento: this.datePipe.transform(this.registroForm.get('fechanac').value, 'yyyyMMdd')
            'direccion': ['', [Validators.required]], // direccion: this.registroForm.get('direccion').value
            'codigopostal': ['', [Validators.required, Validators.maxLength(5)]], // codigopostal: this.registroForm.get('codigopostal').value
            'poblacion': ['', [Validators.required]], // poblacion: this.registroForm.get('poblacion').value
            'provincia': ['', [Validators.required]], // provincia: this.registroForm.get('provincia').value
            'pais': [''], // provincia: this.registroForm.get('provincia').value

        });
        this.initForm();
    }


    //__________________________________________________________COMPROBAR PORTABILIDAD DE NUMERO 
    /**
     * Comprueba si un telefono es portable
     * @param value telefono
     */
    changeNumberTelf(value) {
        this.iccres = false;
        this.iccresdos = false;
        return new Promise((resolve, reject) => {
            if (value && value.length == 9) {
                this.comprobarNumeroAMigrar(value).subscribe(
                    (res: any) => {
                        if (res.id) {
                            if (res.id = "E001") { //NO SE PUEDE PORTAR
                                this.msglinea = false;
                                this.iccresponse = 'El número móvil no es portable';
                                this.iccres = true;
                                this.esPortable = false;
                                reject(false);
                            }
                        } else if (res.tel) {
                            this.msglinea = true;
                            this.iccresponse = 'El número móvil es totalmente portable';
                            this.iccresdos = true;
                            resolve(true);
                            this.esPortable = true;
                        } //END PORTABILIDAD VALIDA
                        this.alertICC();
                    }
                )
            }
            else {
                resolve(true);
            }
        });
    }

    validarNumeroPortable = (control: FormGroup): ValidationErrors | null => {
        return this.esPortable ? { novale: false } : null;
    }

    /**
     * Cierra el Modal de busqueda de lineas pertenecientes a un cliebte
     */
    cerrarBusqueda() {
        this.listaLineasClienteBuscado = null;
    }

    /**
     * Verificacion de campos Linea
     */
    verificacionDatosLinea() {
        if (this.tarifa == undefined) {
            this.createErrorAlert('Debe seleccionar una tarifa.');
            return false;
        } else if (this.registrolineaform.value.iccNuevo == "" || this.registrolineaform.value.iccNuevo == empty || this.registrolineaform.value.iccNuevo == null) {
            this.createErrorAlert('Debe introducir la nueva SIM.');
            return false;
        } else if (this.portabilidad == 'C') {
            if (this.numero == undefined) {
                this.createErrorAlert('Debe introducir un número para portar.');
                return false;
            } else if (this.registrolineaform.value.iccPortabilidad == "" || this.registrolineaform.value.iccPortabilidad == empty || this.registrolineaform.value.iccPortabilidad == null) {
                this.createErrorAlert('Debe introducir un número para portar.');
                return false;
            }
        }
        return true;
    }

    /**
     * Retorna el listado de bonos aplicables a una tarifa concreta
     */
    async getBonosTarifas(tarifaSelected, typeBono) {
        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'pass': this.cookie.get("pass"),
            'token': this.cookie.get("token")
        });
        const body = {
            "tarifa": tarifaSelected,
            "typeBonus": typeBono
        };
        this.listBonusVoz = null;
        this.listBonusDatos = null;
        this.bonoDatosSelected = null;
        this.bonoVozSelected = null;
        const url = environment.getBonosList;
        this.showLoadModal();
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (typeBono == "voz") {
                    this.listBonusVoz = [];
                    res.forEach(element => {
                        this.listBonusVoz.push(element);
                    });
                } else {
                    this.listBonusDatos = [];
                    res.forEach(element => {
                        this.listBonusDatos.push(element);
                    });
                }
                this.hiddeLoadModal();
            }, error => {
                this.hiddeLoadModal();
            })
        } catch {
            this.hiddeLoadModal();
        }
    }

    //______________________-seleccion bono de boz
    selectVozBono($value) {
        this.bonoVozSelected = $value;
    }

    //______________________-seleccion bono de datos
    selectDatosBono($value) {
        this.bonoDatosSelected = $value;
    }

    obtenerFechaMayorEdad() {
        const today = new Date();
        let mayoriaEdad = 18;
        let minFechaNac = new Date();
        minFechaNac.setMonth(today.getMonth() - (mayoriaEdad * 12));
        minFechaNac.setDate(minFechaNac.getDate() - 1);
        return minFechaNac;
    }

    /**
     * Crea un Swal para errores
     */
    createErrorAlert(text, title = 'ERROR', html?) {
        Swal.fire({
            icon: 'error',
            title: title,
            background: '#F5F5F5',
            text: text,
            footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
            html: html
        });
    }
}