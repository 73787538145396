<div class="options" *ngIf="verOpciones">
    <button (click)="viewDocumentacion()">ver Documentacion Adjuntada</button>
    <button (click)="uploadDocumentacion()">Subir Documentacion</button>
</div>

<div>
    <div class="contentListDocs" *ngIf="verDocumentacion">
        <div class="form3" *ngIf="listArchFromServe">
            <div class="wrapper_containerListArch">
                <div *ngFor="let archiv of listArchFromServe" class="rowArch">
                    <div class="wrapper_infoNameArch">
                        <div id="{{archiv.nameFile}}" class="infoNameArch"
                            (click)="downLoadDocumentFromServer(archiv.nameFile)">{{archiv.nameFile}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="subirDocumentacion ">
        <div class="archivoT">
            <label>DOCUMENTACIÓN ADJUNTAR
            </label>
        </div>

        <div class="tipoArchivo">
            <label>TIPO DE ARCHIVOS
                ACEPTADOS: PDF, PNG, JPG</label>
        </div>

        <div style="width: 100%;">
            <form [formGroup]="formFiles" style="width: 100%">
                <mat-grid-list cols="14" rowHeight="2.5em" style="width: 60em;">
                    <mat-grid-tile [colspan]=4 [rowspan]=1 class="alinearTI">
                        <label for="file1" class="tituloMB">DOCUMENTO DE IDENTIDAD:</label>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]=10 [rowspan]=1>
                        <input class="archivo" type="file" name="file1" id="demo1" formControlName="fileDocIdentidad"
                            (change)="setDoc($event, 'fileDocIdentidad')">
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]=4 [rowspan]=1 class="alinearTI">
                        <label for="file2" class="tituloMB">COPIA DEL CONTRATO:</label>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]=10 [rowspan]=1>
                        <input class="archivo" type="file" name="file2" id="demo2" formControlName="fileCopia"
                            (change)="setDoc($event, 'fileCopia')">
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]=4 [rowspan]=1 class="alinearTI">
                        <label for="file1" class="tituloMB">GRABACIÓN (OPCIONAL):</label>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]=10 [rowspan]=1>
                        <input class="archivo" type="file" name="file3" id="demo3" formControlName="fileGrabacion"
                            (change)="setDoc($event, 'fileGrabacion')">
                    </mat-grid-tile>
                </mat-grid-list>
            </form>
        </div>

    </div>


</div>