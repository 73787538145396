import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-wrong-passport-modal',
  templateUrl: './wrong-passport-modal.component.html',
  styleUrls: ['./wrong-passport-modal.component.css']
})
export class WrongPassportModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<WrongPassportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ) { }

  ngOnInit(): void {
  }
  confirmar(){
    this.dialogRef.close({
      change:true,
    });
  }
  manual(){
    this.dialogRef.close({
      change:false,
    });
  }

}
