import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-export-page',
    templateUrl: './export-page.component.html',
    styleUrls: ['./export-page.component.css']
})
export class ExportPageComponent implements OnInit, OnDestroy, AfterViewInit {
    load: boolean = false;
    data2;
    aux;
    tercero;
    enbusqueda = false;
    soloUnContrato = false;
    //====================== DATATABLE =======================================//
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        dom: 'Bfrtip',
        language: {
            url: '//cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },

        retrieve: true
    };

    dtTrigger: Subject < any > = new Subject();
    //====================END DATATABLE =====================================//
    listExportaciones: any = null;
    constructor(private cookies: CookieService,
        private http: HttpClient
    ) {
        var dataTable = $("#dataTableExportaciones").dataTable();
        dataTable.DataTable().destroy;
    }

    ngAfterViewInit(): void {
        var dataTable = $("#dataTableExportaciones").dataTable();
        dataTable.DataTable().destroy; //DESTRUIMOS EL DATATABLE
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngOnInit(): void {
        this.getContratos();
        var dataTable = $("#dataTableExportaciones").dataTable();
        dataTable.DataTable().destroy;
    }

    /**
     * Retorna un listado de contratos
     */
    getContratos() {
        try {
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });

            let body = {
                ip: "255.255.255.0"
            }
            this.data2 = null;
            this.enbusqueda = true;
            const url = environment.endpoint + environment.getContratosMovil;
            this.showloading();
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (Array.isArray(res)) { //VARIOS CONTRATOS
                    this.enbusqueda = false;
                    this.data2 = res;
                    this.aux = this.data2;
                } else { //SOLO UN CONTRATO
                    this.data2 = [];
                    this.data2.push(res);
                }
                if (res.length == 1) {
                    this.soloUnContrato = true;
                    this.cargarContrato(res[0].con);
                }
                this.hideloading();
            }, error => {
                this.enbusqueda = false;
                this.hideloading();
            });
        } catch {
            this.hideloading();
        }
    }

    //__________________________________LOADER ________________________
    hideloading() {
        this.load = false;
    }
    showloading() {
        this.load = true;
    }

    //__________________________END LOADER ________________________
    changeContrato($event) {
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });

        const body = {
            'dni': this.cookies.get("user"),
            'tercero': this.cookies.get("tercero"),
            'cprovisional': $event,
            // 'dni': "B54495981",
            // 'tercero': "312867",
            // 'cprovisional': "294815",
        };
        const url = environment.exportacionesMoviles;

        this.listExportaciones = null;
        try {
            this.showloading();
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (res) {
                    if (res.des == "") {
                        Swal.fire({
                            title: "INFO EXPORTACIONES",
                            icon: "warning",
                            text: "El contrato seleccionado no tiene exportaciones."
                        });
                    } else {
                        this.listExportaciones = res;
                        this.updateDataTable();
                    }
                }
                this.hideloading();
            }, error => {
                this.listExportaciones = null;
                Swal.fire({
                    title: "INFO EXPORTACIONES",
                    icon: "warning",
                    text: "El contrato seleccionado no tiene exportaciones."
                })
                this.updateDataTable();
                this.hideloading();
            })
        } catch {
            this.hideloading();
        }
    }
    /**
     * Actualizacion del dataTable
     */
    updateDataTable() {
        var dataTable = $("#dataTableExportaciones").dataTable();
        dataTable.DataTable().destroy;
        $('#dataTableExportaciones').DataTable({
            retrieve: true,
            search: false,
            paging: false
        })
        this.dtTrigger.next();
    }
    cargarContrato(cprovisional: string) {
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });

        const body = {
            'dni': this.cookies.get("user"),
            'tercero': this.cookies.get("tercero"),
            'cprovisional': cprovisional,
            // 'dni': "B54495981",
            // 'tercero': "312867",
            // 'cprovisional': "294815",
        };
        const url = environment.exportacionesMoviles;

        this.listExportaciones = null;
        try {
            this.showloading();
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (res) {
                    if (res.des == "") {
                        Swal.fire({
                            title: "INFO EXPORTACIONES",
                            icon: "warning",
                            text: "El contrato seleccionado no tiene exportaciones."
                        });
                    } else {
                        this.listExportaciones = res;
                        this.updateDataTable();
                    }
                }
                this.hideloading();
            }, error => {
                this.listExportaciones = null
                Swal.fire({
                    title: "INFO EXPORTACIONES",
                    icon: "warning",
                    text: "El contrato seleccionado no tiene exportaciones."
                });
                this.updateDataTable();
                this.hideloading();
            })
        } catch {
            this.hideloading();
        }
    }

    /**
     * Retorna la hora en formato HH:MM:SS
     * @param resHours 
     * @returns 
     */
    timeTransform(resHours: String) {
        if (resHours.length > 5 && resHours.length < 7) {
            return resHours.substring(0, 2) + ":" + resHours.substring(2, 4) + ":" + resHours.substring(4, 5);
        } else {
            if (resHours.length > 4 && resHours.length < 6) {
                return "0" + resHours.substring(0, 1) + ":" + resHours.substring(1, 3) + ":" + resHours.substring(3, 5);
            } else {
                return resHours;
            }
        }
    }

    /**
     * Retorna la fecha en formato DD-MM-AAAA
     * @param dateString 
     * @returns 
     */
    dateTransform(dateString: string) {
        const DATE_TIME_FORMAT = 'DD-MM-YYYY';
        let date = String(parseInt(dateString) + 19000000);

        let year = date.substring(0, 4);
        let month = date.substring(4, 6);
        let day = date.substring(6, 8);
        return day + "-" + month + "-" + year;
    }
}