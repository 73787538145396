import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NormaliceAddres } from 'src/app/model/NormalizedAddress';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    listCoincidencias
    lislocales
    localSeleccionado
    load

    calleSeleccionada = null
    _prepareCoverage = {
        street: null,
        settlementType: null,
        Tipo: null
    }

    public _normalizedAddress: NormaliceAddres
    constructor(private cookie: CookieService, private http: HttpClient, private router: Router) {}
    localSelected(lSelect) {
        this.localSeleccionado = lSelect
        this._normalizedAddress = lSelect
    }

    cerrarAccion() {
        this.lislocales = null
    }

    /**
     * Funcion encargada de lanzar la peticion hacia el enpoint \coverage 
     * con ello comprobaremos si poseemos cobertura de internet en dicha zona
     * @param position posicion del array en el que se encuentra el domicilio
     */
    async getcoverageLaunch() {
        var textInfo;
        const headers = new HttpHeaders({
            'username': this.cookie.get("user"),
            'token': this.cookie.get("token"),
        });

        const url = environment.coverage

        await this.showLoading()
        try {
            this.http.post(url, this._normalizedAddress, { headers }).subscribe((response: any) => {

                if (response.status) {
                    textInfo = "Hay cobertura para este domicilio"
                    Swal.fire({
                        title: 'ESTADO DE LA COBERTURA',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#00b1aa',
                        confirmButtonText: 'Confirmar',
                        text: textInfo
                    }).then((res: any) => {
                        //--La peticion de Coverage ha ido bien
                        this.lislocales = null
                        this._normalizedAddress.street = this._prepareCoverage.street;
                        this.router.navigateByUrl('/create-subcription', { 
                            state: this._normalizedAddress,
                        });
                    })
                } else {
                    textInfo = "No hay cobertura para este domicilio."
                    Swal.fire({
                        title: 'ESTADO DE LA COBERTURA',
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#00b1aa',
                        confirmButtonText: 'Confirmar',
                        text: textInfo
                    })
                }
                this.hiddeloading()
            }, (error: any) => {
                this.hiddeloading();
                Swal.fire({
                    title: "OPERACIÓN FALLIDA",
                    text: "Debe seleccionar una dirección para continuar con el formulario.",
                    icon: "error"
                })
            })
        } catch {
            this.hiddeloading();
        }
    }
        /**
         * Mostrar el progressbar 
         */
    showLoading() {
            this.load = true
        }
        /**
         * Ocultar el progressbar
         */
    hiddeloading() {
        this.load = false
    }


    /**
     * Comparacion de la calle seleccionada con el resto
     * 
     * @param coincidencia 
     */
    coincidenciaSeleccionada(coincidencia) {


        this.calleSeleccionada = coincidencia;
        this._prepareCoverage = {
            street: coincidencia.PTV,
            settlementType: coincidencia.TipoOrg,
            Tipo: coincidencia.Tipo
        }
    }
}