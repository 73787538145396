import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoadingServiceService } from '../loading-service.service';

@Component({
    selector: 'app-cambio-cliente-component',
    templateUrl: './cambio-cliente-component.component.html',
    styleUrls: ['./cambio-cliente-component.component.css']
})
export class CambioClienteComponentComponent implements OnInit {

    @Input() con: any;

    hiddeTitularidadContent = false;
    dataClientExist = null;
    documento: any;
    crearnuevo = false;
    buscarCliente = false;
    opciones = true;

    doc1: any;
    doc2: any;
    doc3: any;

    nombre1: string;
    nombre2: string;
    nombre3: string;

    ext1: string;
    ext2: string;
    ext3: string;

    formFiles: FormGroup;
    @Input() resetFormSubject: Subject<boolean> = new Subject<boolean>();
    constructor(
        private sanitizer: DomSanitizer,
        private formBuilder: FormBuilder,
        private http: HttpClient,
        private cookies: CookieService,
        public loadService: LoadingServiceService
    ) { }

    ngOnInit(): void {
        this.resetValues();
        this.initForm();
    }

    buscarClienteExistente() {
        this.buscarClienteExistenteLaunch();
        this.initForm();
    }

    /**Método encargado de retornar a un cliente existente en el sistema se busca por su nif
     */
    buscarClienteExistenteLaunch() {
        this.opciones = false;
        this.buscarCliente = true;
        const body = {
            "nif": this.documento.toUpperCase(),
            "tercero": this.cookies.get('tercero'),
            "contratoProvisional": this.con
        }

        const headers = new HttpHeaders({
            'user': this.cookies.get('user'),
            'token': this.cookies.get('token'),
            'pass': this.cookies.get('pass'),
        });
        
         this.loadService.showloading();
         const url = environment.endpoint + environment.getUsuario;
         this.dataClientExist = null;
 
         try {
             this.http.post(url, body, { headers }).subscribe(
                 (res: any) => { // Success
                     this.loadService.hideloading();
                     if (res.id) {
                         Swal.fire({
                             icon: "warning",
                             text: "No existe el cliente con documento: " + this.documento
                         });
                     } else {
                         this.opciones = false;
                         this.dataClientExist = res;
                         this.hiddeTitularidadContent = true;
                         this.buscarCliente = false;
                     }
                     this.loadService.hideloading();
                 }, error => {
                     this.loadService.hideloading();
                     Swal.fire({
                         icon: "warning",
                         text: "No existe el cliente con documento: " + this.documento
                     });
                 })
         } catch {
             this.loadService.hideloading();
         }
    }

    /**
     * Cambia la opcion de menú y nos retorna la vista del formulario para un nuevo cliente
     */
    crearNuevoCliente() {
        this.opciones = false;
        this.buscarCliente = false;
        this.crearnuevo = true;
        this.dataClientExist = null;
        this.hiddeTitularidadContent = true;
    }

    /**
     * Método encargado de realizar el cambio de Titularidad
     */
    cambioTitular() {
        if (!this.doc2) { //Si no subio el contrato de subrrogacion no nos permitira realizar el cambio
            Swal.fire({
                title: "ERROR",
                icon: "warning",
                text: "Debe subir antes el contrato de subrrogación al menos para realizar el cambio de titularidad"
            });
            return;
        }
        this.loadService.showloading();
        const headers = new HttpHeaders({
            'user': this.cookies.get('user'),
            'token': this.cookies.get('token'),
            'pass': this.cookies.get('pass'),
        });

        const body = {
            "tercero": this.cookies.get('tercero'),
            "cuarto": this.dataClientExist.cod,
            "telefono": this.cookies.get("numero"),
            "contratoProvisional": this.con
        }

        const url = environment.cambioTitular;
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.loadService.hideloading();
                if (res.est == "TRUE") {
                    this.refreshNewTitular();
                    Swal.fire({
                        icon: "success",
                        text: "TITULARIDAD MODIFICADA CON EXITO"
                    });
                    this.dataClientExist = null;
                    this.crearnuevo = false;
                    this.buscarCliente = false;
                    this.opciones = true;
                } else {
                    Swal.fire({
                        icon: "warning",
                        text: res.des
                    });
                }
            }, error => {
                this.loadService.hideloading();
            })
        } catch {
            this.loadService.hideloading();
        }
    }

    /**
     * Actualizacion de titular en caso de cambio de titularidad
     */
    refreshNewTitular() {
        this.loadService.titular = this.dataClientExist.des;
        this.loadService.dni = this.dataClientExist.doc;
    }

    /**
     * Reinicializacion de valores del formulario 
     */
    resetValues() {
        this.resetFormSubject.subscribe(response => {
            if (response) {
                this.hiddeTitularidadContent = false;
                this.dataClientExist = null;

                this.crearnuevo = false;
                this.buscarCliente = false;
                this.opciones = true;

            }
        })
    }

    /**
     * Inicialización del formulario y validación
     */
    initForm() {
        this.formFiles = this.formBuilder.group({
            'fileDocIdentidad': ['', [Validators.required]],
            'fileCopia': ['', [Validators.required]],
            'fileGrabacion': ['', [Validators.required]]
        });
    }

    /**
     * Método encargado de subir al servicor documentacion del cliente bien sea en formato imagen o documento pdf
     * @param docFile base64 del fichero
     * @param extFile extension del fichero sin el punto -> jpg, png, pdf
     * @param nameFile nombre del fichwero sin la extension
     * @param datosPersonales nif del cliente
     */
    uplodaDocuments(docFile, extFile, tercero, telefono, nombreDoc) {
        let url = environment.uploadDocumentation;

        this.loadService.showloading();
        const headers = new HttpHeaders({
            "user": this.cookies.get("user"),
            "token": this.cookies.get("token")
        });

        this.loadService.showloading();

        const body = {
            "tercero": tercero,
            "base64Doc": docFile,
            "extensionDocumento": extFile,
            "nombreDoc": nombreDoc,
            "telefono": telefono,
        }

        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.loadService.hideloading();
                if (res == "subido Correctamente") {
                    this.loadService.messageDocumentacion = null;
                    this.formFiles.reset();
                    Swal.fire({
                        title: "RESULTADO",
                        icon: "success",
                        text: "La operación se ha realizado correctamente."
                    });
                } else {
                    Swal.fire({
                        title: "ARCHIVO DEMASIADO GRANDE",
                        icon: "warning",
                        text: "El archivo supera el límite de 5Mb."
                    });
                }
            }, (error: any) => { //VALIDACION DE LA SUBIDA DE DOCUMENTOS POSIBLES ERRORES
                this.loadService.hideloading();
                if (error.error.message == "Falta el parametro DocumentBase64") {
                    Swal.fire({
                        title: "OPERACIÓN FALLIDA",
                        icon: "warning",
                        text: (error.error.message == "Falta el parametro DocumentBase64") ? "Es necesario añadir el archivo." : error.error.message,
                    });
                } else if (error.error.message == "Falta el parametro codigoCliente") {
                    Swal.fire({
                        title: "OPERACIÓN FALLIDA",
                        icon: "warning",
                        text: (error.error.message == "Falta el parametro codigoCliente") ? "Es necesario seleccionar un cliente." : error.error.message,
                    });
                } else {
                    Swal.fire({
                        title: "OPERACIÓN FALLIDA",
                        icon: "warning",
                        text: error.error.message,
                    });
                }
            })
        } catch {
            this.loadService.hideloading();
        }
    }

    //__________________________________________CACHEAR_DOCUMENTO
    setDoc(event, t: string) {
        const file = event.target.files[0];

        let fileSizeMb = (file.size / 1024) / 1024;
        if (fileSizeMb > 5) {
            Swal.fire({
                title: "ARCHIVO DEMASIADO GRANDE",
                icon: "warning",
                text: "El archivo supera el límite de 5Mb."
            });
            return
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);

        let terceroOperador = this.cookies.get("tercero");
        let telefono = this.cookies.get("numero");
        reader.onload = () => {
            switch (t) {
                case "fileDocIdentidad":
                    this.doc1 = reader.result;
                    this.nombre1 = file.name.split(".")[0];
                    this.ext1 = file.name.split(".").pop();
                    this.uplodaDocuments(this.doc1, this.ext1, terceroOperador, telefono, this.nombre1);
                    break;

                case "fileCopia":
                    this.doc2 = reader.result;
                    this.nombre2 = file.name.split(".")[0];
                    this.ext2 = file.name.split(".").pop();
                    this.uplodaDocuments(this.doc2, this.ext2, terceroOperador, telefono, this.nombre2);
                    break;

                case "fileGrabacion":
                    this.doc3 = reader.result;
                    this.nombre3 = file.name.split(".")[0];
                    this.ext3 = file.name.split(".").pop();
                    this.uplodaDocuments(this.doc3, this.ext3, terceroOperador, telefono, this.nombre3);
                    break;
            }
        };
    }

    /**
     * Evento encargado de retornar el contrato para ser firmado por el cliente
     * @param nameFile 
     * @returns 
     */
    exportarContratoMovilEnPdf(nameFile) {
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });

        const url = environment.getContratoSubrrogacion;
        try {
            this.http.get(url, { headers }).subscribe((res: any) => {
                this.downloadData(res.fileBase64, nameFile + ".pdf");
            }, error => {
                this.loadService.hideloading();
            })
        } catch {
            this.loadService.hideloading();
        }
    }

    /**
     * Descarga del contrato
     * @param base64 
     * @param nombre 
     */
    downloadData(base64, nombre) {
        const linkSource = "data:application/pdf;base64," + base64;
        let pdfBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(linkSource);
        var a = document.createElement("a"); //Create <a>
        a.href = linkSource; //Image Base64 Goes here
        a.download = nombre; //File name Here
        a.click(); //Downloaded file
    }
}