<div class="formbox">

    <div class="contentLoad" *ngIf="load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>
    <div class="contentPDF" *ngIf="facturaDetalle">
        <div class="close" (click)="closeInvoice()" *ngIf="facturaDetalle">X</div>
        <embed [src]="facturaDetalle" zoom="100%" frameborder="0" width="100%" height="100%" class="embedClass"
            *ngIf="facturaDetalle">
    </div>
    <div class="cabecera">
        FACTURACIÓN
    </div>
    <div class="divTabla"  *ngIf="listFacturas">
        <div id="dataTableListBajas" *ngIf="listFacturas" style="zoom: 90%;">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable
                [dtOptions]="dtOptions" id="dt" *ngIf="listFacturas">
                <thead style="font-family: MontserratBold;">
                    <tr>
                        <th>NÚMERO DE FACTURA</th>
                        <th>CONTRATO OFICIAL</th>
                        <th>FECHA DE EMISION</th>
                        <th>TIPO DE FACTURA</th>
                        <th>IMPORTE</th>
                        <th>VER FACTURA</th>
                    </tr>
                </thead>
                <tbody style="font-family: MontserratRegular;word-break: break-word;text-align: center;">
                    <tr *ngFor="let f of listFacturas let i=index">
                        <td>{{f.numfact}}</td>
                        <td>{{f.contratoOficial}}</td>
                        <td>{{f.fechafact}}</td>
                        <td class="typeFact"> {{typeFacturaString(f.tipoFactura)}}</td>
                        <td>{{f.importe}}</td>
                        <td>
                            <button type="button" class="detalleB" (click)="verFactura(f)"></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

</div>

<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>
