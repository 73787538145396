
<div class="wrapper_loading" *ngIf="this.loadService.load;else sim">
    <img class="loading" src="assets/img/loading2.gif">
</div>

<ng-template #sim>
    <table>
        <tr>
            <th>SIM</th>
            <th>PIN</th>
            <th>PUK</th>
            <th>PIN2</th>
            <th>PUK2</th>
        </tr>
        <tr>
            <td>{{this.simService.SIM.getICC()}}</td>
            <td>{{this.simService.SIM.getPIN()[0]}}</td>
            <td>{{this.simService.SIM.getPUNK()[0]}}</td>
            <td>{{this.simService.SIM.getPIN()[1]}}</td>
            <td>{{this.simService.SIM.getPUNK()[1]}}</td>
        </tr>
    </table>
</ng-template>