<div id="dataTableHistoric" *ngIf="listHistoric" style="zoom: 90%;">

    <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" id="dt">
        <thead style="font-family: MontserratBold;">
            <tr>
                <th>PETICION</th>
                <th>TARIFA</th>
                <th>FECHA DEL CAMBIO</th>
            </tr>
        </thead>
        <tbody style="font-family: MontserratRegular;text-align: center;" *ngIf="listHistoric">
            <tr *ngFor="let tarifa of listHistoric">
                <td>{{tarifa.PETICION}}</td>
                <td>{{tarifa.TARIFA}}</td>
                <td>{{tarifa.FECHA_CAMBIO}}</td>
            </tr>
        </tbody>
    </table>

</div>