import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { CreateSubcription } from 'src/app/model/createSubcription';
import { NormaliceAddres } from 'src/app/model/NormalizedAddress';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-create-subcription',
    templateUrl: './create-subcription.component.html',
    styleUrls: ['./create-subcription.component.css']
})
export class CreateSubcriptionComponent implements OnInit {
    normaliceAddres: any
    data: any
    public load = false;
    public listContratos: any = []
    public listRates: any = []
    public typeViaString: String = ""
    public messageLoading: String = ""
    public errorTypeDocumento: String = null
    public errorDocumento: String = null
    public errorNombre: String = null
    public errorApellido1: String = null
    public errorApellido2: String = null
    public errorTelefono: String = null
    public errorEmail: String = null
    createSubcriptionModel: CreateSubcription
    public identificadoDocument: Boolean = false
    formsCreateSub: FormsModule //Necesario para poder tener la comunicacion bidireccional entre nuestro modelo y los input
        constructor(
        private http: HttpClient,
        private auth: AuthService,
        private cookie: CookieService,
        private route: Router,
    ) {
        this.normaliceAddres = this.route.getCurrentNavigation().extras.state; //OBTENGO TODOS LOS DATOS DE LA PANTALLA ANTERIOR
    }

    ngOnInit(): void {
        this.createSubcriptionModel = new CreateSubcription
        this.getSubcription() //Obtengo todas las subcripcciones
        this.getStreetByType() //Obtengo el tipo de calle segun el codigo
        this.createSubcriptionModel.normalizedAddress = this.normaliceAddres
        this.loadingValues();
    }

    //-------------------------------CHANGE SELECT--------------------------------//
    changeSelectContrato($event) {
        if ($event === 0) { //si puslas sobre las cabeceras
            return
        }
        this.createSubcriptionModel.contratoOficial = $event //Almaceno el contrato en el modelo
        this.getRatesLaunch($event)
    }

    /**
     * Evento encargado de gestionar las tarifas
     * @param $event
     * @returns
     */
    changeRateSelect($event) {
        if ($event === 0) { //si puslas sobre las cabeceras
            return
        }
        let values = $event.split("|")
        let codigo = values[0]
        let tipoObjeto = values[1]
        this.createSubcriptionModel.codigoTarifa = codigo //ALmaceno el codigo de la tarifa
    }

    /**
     * Evento encargado de retornar el tipo de documento seleccionado
     * @param $event
     * @returns
     */
    changeSelectDocument($event) {
        if ($event == 0) {
            return
        }
        this.createSubcriptionModel.tipoDocumento = $event
    }
    //-----------------------------END CHANGE SELECT--------------------------------//

    /**
     * Retorna el listado de subcripciones de FTTH de un contrato
     */
    getSubcription() {
        this.showloading()
        const headers = new HttpHeaders({
            'username': this.cookie.get("user"),
            'token': this.cookie.get("token"),
        });
        const body = {
            'username': this.cookie.get("user"),
            'password': this.cookie.get("pass"),
        }

        const url = environment.getsubcriptions;
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.hideloading()
                if (res.subscriptions == "No hay subscripciones disponibles") {
                    Swal.fire({
                        title: "ERROR",
                        icon: "warning",
                        text: "El operador no dispone de contratos en el FTTH."
                    })
                    this.route.navigate(['create-account']);
                    return;
                }
                res["subscriptions"].forEach(element => {
                    this.listContratos.push(element);
                });
            });
        } catch {
            this.hideloading()
        }
    }

    /**
     * Retorna el listado de tarifas FTTH
     * @param contrato
     */
    getRatesLaunch(contrato: string) {
        this.showloading()
        const headers = new HttpHeaders({
            'username': this.cookie.get("user"),
            'token': this.cookie.get("token"),
        });
        const body = {
            "contract": contrato
        }
        let url = environment.getRates
        this.listRates = []
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                res.forEach(element => {
                    this.listRates.push(element)
                })
                this.hideloading()
            })
        } catch {
            this.hideloading()
        }
    }

    /**
     * Retorna el tipo de via en formato string
     */
    getStreetByType() {
        // await this.showloading()
        const url = environment.gettlementTypes;
        try {
            this.http.get(url).subscribe((response: any) => {
                for (const property in response) {
                    if (this.normaliceAddres.additionalInfo.streetType == response[property]) {
                        this.typeViaString = property
                    }
                }
            })
        } catch {
            this.hideloading()
        }
    }
    /**
     * Metodo encargado de lanzar la peticion hacia el endpoint \createSubcription
     * la cual genera una nueva subcripcion
     * @returns
     */
    createSubcriptionLaunch() {
        if (this.validateData()) { //Si los campos no estan en blanco
            this.loadingValues(); //Actualizo los valores del modelo
            this.createSubcriptionModel.numeroTelefono.trim();
            this.createSubcriptionModel.documento = this.createSubcriptionModel.documento.toUpperCase();
            this.showloading();
            this.messageLoading = "Validando los datos proporcionados... porfavor Espere.";
            //=========================================== VALIDACION DOCUMENTO LOCAL ==========================================================================
            switch (this.createSubcriptionModel.tipoDocumento) {
            case "NIF":
            case "NIE":
            case "Tarjeta de Residencia":
                if (!this.isNif(this.createSubcriptionModel.documento) && !this.isNie(this.createSubcriptionModel.documento)) {
                    this.errorDocumento = "El Documento introducido no es válido o no se corresponde con los datos introducidos. Porfavor revisalos";
                    this.messageLoading = "";
                    this.hideloading();
                    return
                }
                break;
            case "Pasaporte":
                break;
            case "CIF":
                if (!this.validaCif(this.createSubcriptionModel.documento)) {
                    this.errorDocumento = "El Documento introducido no es válido o no se corresponde con los datos introducidos. Porfavor revisalos"
                    this.messageLoading = "";
                    this.hideloading();
                    Swal.fire({
                      icon: 'error',
                      title: 'ERROR',
                      text: "El Documento introducido no es válido o no se corresponde con los datos introducidos. Porfavor revisalos",
                      background: '#F5F5F5',
                  })
                    return
                }
                break;
            }
            //=========================================== END VALIDACION DOCUMENTO LOCAL ==========================================================================
            //======================CHECK HACIENDA DOCUMENT ========================================================================================================
            const uri = environment.checkDocumentValidate;
            let nombreCompleto = this.createSubcriptionModel.nombre.trim() +
                ' ' + this.createSubcriptionModel.apellido1.trim() +
                ' ' + this.createSubcriptionModel.apellido2.trim();
            const bod = {
                "dni": this.createSubcriptionModel.documento,
                "nombreCompleto": nombreCompleto,
            }
            this.messageLoading = "Validando los datos proporcionados... porfavor Espere.";
            try {
                this.http.post(uri, bod).subscribe((response: any) => {
                    if (response.Documento == "IDENTIFICADO" || this.createSubcriptionModel.tipoDocumento == "Pasaporte") { //Identificado por hacienda OK 
                        const headers = new HttpHeaders({
                            'username': this.cookie.get("user"),
                            'token': this.cookie.get("token"),
                        });
                        const body = this.createSubcriptionModel;
                        const url = environment.createNewSubcription;
                        try {
                            this.http.post(url, body, { headers }).subscribe((response: any) => {
                                this.hideloading();
                                this.hideloading();
                                Swal.fire({
                                    title: "ESTADO DE LA SUSCRIPCIÓN",
                                    icon: 'success',
                                    showCancelButton: false,
                                    confirmButtonColor: '#00b1aa',
                                    confirmButtonText: 'Ok',
                                    text: response.detalle
                                }).then(aceptar => {
                                    if (aceptar.isConfirmed) {
                                        //TODO HA IDO CORRECTAMENTE
                                    }
                                })
                            }, (error) => {
                                this.hideloading()
                                if (error.error.message) {
                                    Swal.fire({
                                        title: 'ERROR',
                                        icon: 'warning',
                                        showCancelButton: false,
                                        confirmButtonColor: '#00b1aa',
                                        confirmButtonText: 'Ok',
                                        text: error.error.message
                                    })
                                } else if (error.error.documento) {
                                    Swal.fire({
                                        title: 'ERROR',
                                        icon: 'warning',
                                        showCancelButton: false,
                                        confirmButtonColor: '#00b1aa',
                                        confirmButtonText: 'Ok',
                                        text: error.error.documento
                                    })
                                    this.errorDocumento = "El Documento introducido no es válido o no se corresponde con los datos introducidos. Porfavor revisalos"
                                    this.messageLoading = ""
                                    return
                                }
                            })
                        } catch {
                            this.hideloading()
                        }
                    } else { //datos invalidos
                        Swal.fire({
                            title: 'ERROR',
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonColor: '#00b1aa',
                            confirmButtonText: 'Ok',
                            text: "Usuario no identificado en AEAT"
                        })
                        this.errorDocumento = "El Documento introducido no es válido o no se corresponde con los datos introducidos. Porfavor revisalos";
                        this.messageLoading = ""
                        this.hideloading()
                        return
                    }
                    },
                    error => { //Error en el webservice de hacienda
                    })
            } catch {
                this.hideloading()
            }
            //======================END CHECK HACIENDA DOCUMENT ========================================================================================================
        }
    }

    /**
     * Método encargado de lanzar la peticion createSubcription mediante el evento click del boton del formulario
     */
    createSubcription() {
        this.createSubcriptionLaunch();
    }

    //-------------------LOADING ------------------//
    hideloading() {
        this.load = false
    }
    showloading() {
        this.load = true
    }

    /**
     * Metodo encargado de cargar los valores correspondientes al modelo principal [createSubcriptionModel]
     */
    loadingValues() {
        let edificio = this.normaliceAddres.additionalInfo.buildingName;
        let puerta = this.normaliceAddres.additionalInfo.door;
        let escalera = this.normaliceAddres.additionalInfo.staircaseNumber;
        let planta = this.normaliceAddres.additionalInfo.floor;
        let numeroApartamento = this.normaliceAddres.additionalInfo.apartmentNumber;

        this.createSubcriptionModel.nombreVia = this.normaliceAddres.street;
        this.createSubcriptionModel.numeroVia = this.normaliceAddres.number;
        this.createSubcriptionModel.tipoVia = this.typeViaString + "";
        this.createSubcriptionModel.poblacion = this.normaliceAddres.city;
        this.createSubcriptionModel.provincia = this.normaliceAddres.state;
        this.createSubcriptionModel.codigoPostal = this.normaliceAddres.postalCode;

        // EDIFICIO
        (!edificio || edificio == " ") ? this.createSubcriptionModel.edificio = "":  this.createSubcriptionModel.edificio = edificio;

        //PUERTA
        (!puerta || puerta == " ") ? this.createSubcriptionModel.puerta = "" : this.createSubcriptionModel.puerta = puerta;

        // ESCALERA
        (!escalera || escalera == " ") ? this.createSubcriptionModel.escalera = "": this.createSubcriptionModel.escalera = escalera;

        // PLANTA
        (!planta || planta == " ") ? this.createSubcriptionModel.planta = "": this.createSubcriptionModel.planta = planta;

        // NUMERO DE APARTAMENTO
        if (!numeroApartamento || numeroApartamento == " ") {
            this.createSubcriptionModel.normalizedAddress.apartmentNumber = "";
        } else {
            this.createSubcriptionModel.normalizedAddress.apartmentNumber = numeroApartamento;
        }
    }
    //_________________________________VALIDADORES
    isNif(documento) {
        var nif = documento.toUpperCase();
        var lettersEnd = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];

        if (nif.length == 9) {
            var letter = nif.substring(8, 9);
            var number = parseInt(nif.substring(0, 8));
            if (!isNaN(number) && isNaN(parseInt(letter))) {
                if (lettersEnd[number % 23] === letter) {
                    return true;
                }
            }
        }
        return false;
    }
    isNie(documento) {
        var nie = documento.toUpperCase(); //Z4138590K

        var i;
        var num = [];
        if (nie.match('^[XYZ][0-9][0-9][0-9][0-9][0-9][0-9][0-9][A-Z0-9]')) {
            for (i = 0; i < 9; i++) {
                var aux = i;
                num[i] = nie.substr(i, aux++);
            }
            var letters = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];
            var dniTransform = nie.replaceAll('X', '0').replaceAll('Y', '1').replaceAll('Z', '2');
            if (num[8] === letters[parseInt(dniTransform.substring(0, 8)) % 23]) {
                return true;
            }
        }
        return false;
    }
    /**
     * Validador de cif
     * @param documento
     * @returns
     */
    validaCif(documento) {
        if (documento == "") {return false; }
        var texto = documento;
        var pares = 0;
        var impares = 0;
        var suma;
        var ultima;
        var unumero;
        var uletra = new Array("J", "A", "B", "C", "D", "E", "F", "G", "H", "I");
        var xxx;

        texto = texto.toUpperCase();

        var regular = new RegExp(/^[ABCDEFGHKLMNPQS]\d\d\d\d\d\d\d[0-9,A-J]$/g);
        if (!regular.exec(texto)) {}

        ultima = texto.substr(8, 1);

        for (var cont = 1; cont < 7; cont++) {
            xxx = (2 * parseInt(texto.substr(cont++, 1))).toString() + "0";
            impares += parseInt(xxx.substr(0, 1)) + parseInt(xxx.substr(1, 1));
            pares += parseInt(texto.substr(cont, 1));
        }
        xxx = (2 * parseInt(texto.substr(cont, 1))).toString() + "0";
        impares += parseInt(xxx.substr(0, 1)) + parseInt(xxx.substr(1, 1));

        suma = (pares + impares).toString();
        unumero = parseInt(suma.substr(suma.length - 1, 1));
        unumero = (10 - unumero).toString();
        if (unumero == 10) unumero = 0;

        if (ultima == unumero || ultima == uletra[unumero]) {
            return true
        } else {
            return false;
        }
    }

    /**
     * Método encargado de comprobar que los datos introducidos no están en blanco
     */
    validateData(): Boolean {
        this.errorTypeDocumento = null
        this.errorDocumento = null
        this.errorNombre = null
        this.errorApellido1 = null
        this.errorApellido2 = null
        this.errorTelefono = null
        this.errorEmail = null
        if (!this.createSubcriptionModel.tipoDocumento) {
            this.errorTypeDocumento = "Seleccione el tipo de Documento"
        }
        if (!this.createSubcriptionModel.documento) {

            this.errorDocumento = "El Documento de identidad no es válido"
        }
        if (!this.createSubcriptionModel.nombre) {
            this.errorNombre = "El campo nombre no puede quedar en blanco"
        }
        if (!this.createSubcriptionModel.apellido1) {
            if(this.createSubcriptionModel.tipoDocumento == "CIF"){
              this.createSubcriptionModel.apellido1 = '.';
            }
            if(this.createSubcriptionModel.tipoDocumento != "CIF"){
              this.errorApellido1 = "El campo apellido1 no puede quedar en blanco"
            }
        }
        if (!this.createSubcriptionModel.apellido2) {
            if(this.createSubcriptionModel.tipoDocumento == "CIF"){
              this.createSubcriptionModel.apellido2 = '.';
            }
            if(this.createSubcriptionModel.tipoDocumento != "CIF"){
              this.errorApellido2 = "El campo apellido2 no puede quedar en blanco"
            }
        }
        if (!this.createSubcriptionModel.numeroTelefono) {
            this.errorTelefono = "El campo Teléfono no puede quedar en blanco"
        }
        if (!this.createSubcriptionModel.email) {
            this.errorEmail = "El campo email no puede quedar en blanco"

        }
        if (!this.errorDocumento && !this.errorNombre && !this.errorApellido1 && !this.errorApellido2 && !this.errorTelefono && !this.errorEmail) {
            return true
        } else {

            Swal.fire({
              icon: 'error',
              title: 'ERROR',
              text: 'Introduce los datos seleccionados',
              background: '#F5F5F5',
            })
            return false
        }
    }
    volverAtras() {
        this.route.navigate(['initFtth']);
    }
}
