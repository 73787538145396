<div class="contentLoad" *ngIf="load">
  <img class="loading" src="assets/img/loading2.gif">
</div>
<div class="previewDocumentation" *ngIf="loadService.archive">
    <div class="contedorBoton"><button type="button" (click)="cerrarDocumentacion()">
            <img src="assets/img/PTV/cerrar.png">
        </button></div>
    <div class="contenedorImg">
        <app-ver-documento [archiv]="loadService.archive"></app-ver-documento>
    </div>
</div>

<mat-grid-list cols="20" rowHeight="2.5:1"
    style="height: 50px;overflow-y: scroll; background-color: #ebeff2 !important;">
    <mat-grid-tile [colspan]=4 [rowspan]=2 class="Linea">
        DATOS DE LA LÍNEA
    </mat-grid-tile>

    <mat-grid-tile [colspan]=16 [rowspan]=2>

        <!-------------------------------------------NAV---------------------------------------------------- -->
        <nav>
            <button class="uno" (click)="documentacion()">DOCUMENTACIÓN</button>
            <button class="uno" (click)="accionesSim()">SIM</button>
            <button class="uno" (click)="getConsumo()">CONSUMO</button>
            <button class="uno" (click)="bonusForLine()">BONOS</button>
            <button class="uno" (click)="cambioTarifaPanel()">TARIFA</button>
            <button class="uno" (click)="cambioTitularidad()">TITULARIDAD</button>
            <button class="uno" (click)="controlRiesgoLaunch()">CTRL & RIESGO</button>
        </nav>
        <!---------------------------------------END -NAV---------------------------------------------------- -->
    </mat-grid-tile>

    <!-- _____________________________PETICIÓN_____________________________ -->
    <mat-grid-tile [colspan]=4 [rowspan]=1 class="titulo">
        PETICIÓN
    </mat-grid-tile>

    <!-- _____________________________OPCIÓN_DEL_MENÚ_____________________________ -->
    <mat-grid-tile [colspan]=16 [rowspan]=50 style="background-color: #ebeff2;" class="container_main">

        <div class="wrapper_loading" *ngIf="loadService.load">
            <img class="loading" src="assets/img/loading2.gif">
        </div>

        <div *ngIf="bonoswrapper" [ngClass]="esContfratoDistribuidor? 'boxWrapper': 'noDistribuidor'">
            <div class="boxWrapper_content" *ngIf="!loadService.historicBonos && !loadService.gestionBonos ">
                <div class="optionsTarifas">
                    <button (click)="gestionDeBonos()">
                        GESTION DE BONOS
                    </button>
                    <button (click)="historicoDeTarifas()">
                        VER HISTORICO DE BONOS
                    </button>
                </div>
            </div>

            <div class="boxWrapper_content" *ngIf="loadService.historicBonos ||loadService.gestionBonos ">
                <div *ngIf="loadService.gestionBonos">
                    <app-gestion-bono-component></app-gestion-bono-component>
                </div>
                <div *ngIf="loadService.historicBonos">
                    <app-historic-bonos></app-historic-bonos>
                </div>
            </div>
        </div>


        <div *ngIf="infowrapper" [ngClass]="esContfratoDistribuidor? 'boxWrapper': 'noDistribuidor'">
            <div class="boxWrapper_content">
                <app-infor-sim-component></app-infor-sim-component>
            </div>
        </div>

        <div *ngIf="listarMultisimwrapper" [ngClass]="esContfratoDistribuidor? 'boxWrapper': 'noDistribuidor'">
            <div class="boxWrapper_content">
                <app-listar-multisim-component></app-listar-multisim-component>
            </div>
        </div>

        <div *ngIf="consumowrapper" [ngClass]="esContfratoDistribuidor? 'boxWrapper': 'noDistribuidor'">
            <app-consumo-component [resetFormSubject]="resetConsumo.asObservable()"></app-consumo-component>
        </div>

        <div *ngIf="cambiowrapper" [ngClass]="esContfratoDistribuidor? 'boxWrapper': 'noDistribuidor'">
            <div class="boxWrapper_content" *ngIf="!loadService.cambiarTarifa && !loadService.historicTarifa">
                <div class="optionsTarifas">
                    <button (click)="cambioTarifa()">
                        REALIZAR CAMBIO TARIFA
                    </button>
                    <button (click)="verHistorico()">
                        VER HISTORICO CAMBIOS DE TARIFAS
                    </button>
                </div>
            </div>

            <div class="boxWrapper_content" *ngIf="loadService.cambiarTarifa || loadService.historicTarifa">
                <!-- _____________________CAMBIO DE TARIFA  _______________________________ -->
                <div *ngIf="loadService.cambiarTarifa">
                    <app-cambio-tarifa-component></app-cambio-tarifa-component>
                </div>
                <div *ngIf="loadService.historicTarifa">
                    <app-historic-tarifas></app-historic-tarifas>
                </div>
            </div>
        </div>

        <div *ngIf="titularidadwrapper" [ngClass]="esContfratoDistribuidor? 'boxWrapper': 'noDistribuidor'">
            <app-cambio-cliente-component [resetFormSubject]="resetCambioCliente.asObservable()" [con]="con">
            </app-cambio-cliente-component>
        </div>

        <div *ngIf="controlRiesgoWrapper" [ngClass]="esContfratoDistribuidor? 'boxWrapper': 'noDistribuidor'">
            <app-control-riesgo-component></app-control-riesgo-component>
        </div>

        <div *ngIf="cambioSimWrapper" [ngClass]="esContfratoDistribuidor? 'boxWrapper': 'noDistribuidor'">
            <div class="boxWrapper_content">
                <app-cambio-sim-component></app-cambio-sim-component>
            </div>
        </div>

        <div *ngIf="actionSim" [ngClass]="esContfratoDistribuidor? 'boxWrapper': 'noDistribuidor'">
            <div class="boxWrapper_content">
                <div class="optionSim">
                    <button (click)="infosim()">
                        INFO SIM
                    </button>
                    <button *ngIf="cookies.get('multisim')=='S'" (click)="listarmultisim()" >
                        MULTISIM
                    </button>
                    <button (click)="cambioSim()">
                        CAMBIO SIM
                    </button>
                    <button (click)="bloqueoDesbloqueo(this.peticion)">
                     <span *ngIf="this.cookies.get('robo')=='S'">Desbloqueo (Robo)</span>
                     <span *ngIf="this.cookies.get('robo')=='N'">Bloquear por Robo</span>
                  </button>
                </div>
            </div>
        </div>

        <div *ngIf="documentacionWrapper" [ngClass]="esContfratoDistribuidor? 'boxWrapper': 'noDistribuidor'">
            <div class="boxWrapper_content">
                <app-documentacion [resetFormSubject]="resetDocumentacion.asObservable()"></app-documentacion>
            </div>
        </div>

        <div *ngIf="defaultwrapper" [ngClass]="esContfratoDistribuidor? 'boxWrapper': 'noDistribuidor'">

        </div>

        <!-- _____________________________NOTIFY PANEL (DOCUMENTACION , DOBLE GIGAS , NEW TARIFA)_____________________________ -->
        <div class="contentAux">
            <app-notify-component [esContfratoDistribuidor]="esContfratoDistribuidor" [newTarifa]="newTarifa"
                [dobleGb]="dobleGb" [numero]="numero"
                [documentacionAdjuntada]="this.loadService.documentacionAdjuntada"></app-notify-component>
        </div>
    </mat-grid-tile>

    <!-- _____________________________PETICIÓN_____________________________ -->
    <mat-grid-tile [colspan]=4 [rowspan]=1>
        <div class="datos">
            {{this.peticion}}
        </div>
    </mat-grid-tile>

    <!-- _____________________________ACTIVACIÓN_____________________________ -->
    <mat-grid-tile [colspan]=4 [rowspan]=1 class="titulo">
        ACTIVACIÓN
    </mat-grid-tile>
    <mat-grid-tile [colspan]=4 [rowspan]=1>
        <div class="datos">
            {{this.activacion}}
        </div>
    </mat-grid-tile>

    <!-- _____________________________TARIFA_____________________________ -->
    <mat-grid-tile [colspan]=4 [rowspan]=1 class="titulo">
        TARIFA
    </mat-grid-tile>
    <mat-grid-tile [colspan]=4 [rowspan]=1>
        <div class="datos">
            {{this.tarifa}}
        </div>
    </mat-grid-tile>
<!-- _____________________________TarifaOrigen_____________________________ -->
 <!-- En el caso de que tenga promo='S' -->



 <div *ngIf="promo">
  <mat-grid-tile  [colspan]=4 [rowspan]=1 class="titulo">
    Tarifa origen
  </mat-grid-tile>
  <mat-grid-tile [colspan]=4 [rowspan]=1>
    <div class="datos">
        {{this.tarifaOrigen}}
    </div>
  </mat-grid-tile>

 </div>
    <!-- _____________________________TITULAR_____________________________ -->
    <mat-grid-tile [colspan]=4 [rowspan]=1 class="titulo">
        TITULAR
    </mat-grid-tile>
    <mat-grid-tile [colspan]=4 [rowspan]=1>
        <div class="datos" id="titular" *ngIf="loadService.titular">
            {{this.loadService.titular}}
        </div>
        <div class="datos" id="titular" *ngIf="!loadService.titular">
            {{this.titular}}
        </div>
    </mat-grid-tile>

    <!-- _____________________________DNI_____________________________ -->
    <mat-grid-tile [colspan]=4 [rowspan]=1 class="titulo">
        DNI
    </mat-grid-tile>
    <mat-grid-tile [colspan]=4 [rowspan]=1>
        <div class="datos" *ngIf="loadService.dni">
            {{this.loadService.dni}}
        </div>
        <div class="datos" *ngIf="!loadService.dni">
            {{this.dni}}
        </div>
    </mat-grid-tile>

    <!-- _____________________________NÚMERO_____________________________ -->
    <mat-grid-tile [colspan]=4 [rowspan]=1 class="titulo">
        NÚMERO
    </mat-grid-tile>
    <mat-grid-tile [colspan]=4 [rowspan]=1>
        <div class="datos">
            {{this.numero}}
        </div>
    </mat-grid-tile>

    <!-- _____________________________MULTISIM_____________________________ -->
    <mat-grid-tile [colspan]=4 [rowspan]=1 class="titulo">
        MULTISIM
    </mat-grid-tile>
    <mat-grid-tile [colspan]=4 [rowspan]=1>
        <div class="datos">
            {{this.multisim}}
        </div>
    </mat-grid-tile>

    <!-- _____________________________CAMBIO_____________________________ -->
    <mat-grid-tile [colspan]=4 [rowspan]=1 class="titulo">
        CAMBIO
    </mat-grid-tile>
    <mat-grid-tile [colspan]=4 [rowspan]=1>
        <div class="datos">
            {{this.cambio}}
        </div>
    </mat-grid-tile>

    <!-- _____________________________HORA_____________________________ -->
    <mat-grid-tile [colspan]=4 [rowspan]=1 class="titulo">
        HORA
    </mat-grid-tile>
    <mat-grid-tile [colspan]=4 [rowspan]=1>
        <div class="datos">
            {{this.hora}}
        </div>
    </mat-grid-tile>

    <!-- if(this.cookies.get("robo")=="N") -->

    <!-- _____________________________BLOQUEO POR ROBO_____________________________ -->
    <mat-grid-tile [colspan]=4 [rowspan]=1 class="titulo">
      BLOQUEO POR ROBO
  </mat-grid-tile>

  <mat-grid-tile [colspan]=4 [rowspan]=1 *ngIf="this.cookies.get('robo')=='S'">
      <div class="datos">
          Activado
      </div>
  </mat-grid-tile>
  <mat-grid-tile [colspan]=4 [rowspan]=1 *ngIf="this.cookies.get('robo')=='N'">
    <div class="datos">
        Desactivado
    </div>
</mat-grid-tile>
  <mat-grid-tile [colspan]=4 [rowspan]=1 class="titulo">
  </mat-grid-tile>

</mat-grid-list>
<mat-grid-tile [colspan]=4 [rowspan]=1 class="titulo">
</mat-grid-tile>
<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>
