<div #body class="body">
    <div *ngIf="this.carga || modalServiceWhiteBrand.load" id="loading">
    </div>
    <div *ngIf="this.carga || modalServiceWhiteBrand.load" id="loadingImagenDiv">
        <mat-grid-list cols=3 rowHeight=40em>
            <mat-grid-tile>
            </mat-grid-tile>
            <mat-grid-tile>
                <img id="loading-image" src="assets/img/loading2.gif" alt="Loading..." />
            </mat-grid-tile>
            <mat-grid-tile>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
    
    <div *ngIf="modalServiceWhiteBrand.listCoincidencias" class="localList">
        <app-modal-coincidencias [modalService]="modalServiceWhiteBrand"></app-modal-coincidencias>
    </div>
    <div *ngIf="modalServiceWhiteBrand.lislocales" class="localList">
        <app-modal-domicilios [modalService]="modalServiceWhiteBrand"></app-modal-domicilios>
    </div>
    
    <form method="post" [formGroup]="whiteBrandForm" class="whiteBrandForm">
        <div class="titulo">
            <label>Crear suscriptor (FIBRA MARCA BLANCA)</label>
        </div>
    
        <h2 class="subTitulo">
            Comprobar Cobertura
        </h2>
        <div class="whiteBrandFormContent">
            <div class="provinciaDiv divsContent">
                <label>PROVINCIA:</label><br>
                <select id="provincia" (change)="seleccionado(2000, $event.target.value)" formControlName="provincia">
                    <option [value]=""></option>
                    <option [value]="item.idProvincia+'|'+item.comunidadAutonoma+'|'+item.provincia" *ngFor="let item of listProvincias">{{item.provincia}}</option>
                </select>
            </div>
            <div class="municipioDiv divsContent" *ngIf="muestraMunicipio">
                <label>MUNICIPIO:</label><br>
                <select id="municipio" (change)="seleccionado(2001, $event.target.value)" formControlName="municipio">
                    <option [value]=""></option>
                    <option [value]="item.codMunicipio+'|'+item.municipio" *ngFor="let item of listMunicipio">{{item.municipio}}</option>
                </select>
            </div>
            <div style="margin: auto;width: fit-content; height: fit-content; margin-top: 1em;" *ngIf="cargaMunicipio">
                <img style="width: 30px;" src="assets/img/loading2.gif" alt="Loading..." />
            </div>
            <div class="buscadorDiv divsContent" *ngIf="muestraBuscador">
                <label for="buscadorInput">BUSCADOR:</label><br>
                <input type="text" id="buscadorInput" placeholder="Introduzca el nombre de la vía" formControlName="buscadorInput"><br>
                <button (click)="getCoincidencias()">BUSCAR COINCIDENCIAS</button>
            </div>
            <div class="direccionDiv divsContent"  *ngIf="this.modalServiceWhiteBrand._prepareCoverage.street!=null" >
                <div class="calleDiv">
                    <label for="calle">CALLE:</label><br>
                    <input readonly="readonly" type="text" id="calle" placeholder="Calle" formControlName="calle" value="{{ this.modalServiceWhiteBrand._prepareCoverage.street }}">
                </div>
                <div class="numeroDiv">
                    <label for="numero">NÚMERO*:</label><br>
                    <input type="number" id="numero" placeholder="Número" formControlName="numero" min="0">
                </div>
                <div class="plantaDiv">
                    <label for="planta">PLANTA:</label><br>
                    <input type="text" id="planta" placeholder="planta" formControlName="planta">
                </div>
                <div class="puertaDiv">
                    <label for="puerta">PUERTA:</label><br>
                    <input type="text" id="puerta" placeholder="puerta" formControlName="puerta">
                </div>
                <div class="portalDiv">
                    <label for="portal">PORTAL:</label><br>
                    <input type="text" id="portal" placeholder="portal" formControlName="portal">
                </div>
                <div class="escaleraDiv">
                    <label for="escalera">ESCALERA:</label><br>
                    <input type="text" id="escalera" placeholder="escalera" formControlName="escalera">
                </div>
                <div class="localDiv">
                    <label for="local">LOCAL:</label><br>
                    <input type="text" id="local" placeholder="local" formControlName="local">
                </div>
                <div class="clearFloat"></div>
                <button type="submit" (click)="siguienteClick()" class="siguienteBtn" *ngIf="this.modalServiceWhiteBrand._prepareCoverage.street!=null&&this.modalServiceWhiteBrand._prepareCoverage.street!=''">SIGUIENTE</button>
            </div>
        </div>
    
    </form>
</div>