export interface PrepareCoverage {
    country: string;
    county: string;
    state: string;
    city: string;
    street: string;
    postalCode: string;
    number: string;
    settlementType: string;
}
export class prepareCoverageModel implements PrepareCoverage{
    country: string;
    county: string;
    state: string;
    city: string;
    street: string;
    postalCode:string;
    number: string;
    settlementType: string;
  
    
}

