const mode = {
    //urlbase : "http://localhost:8000"
    urlbase: "https://apimarcablanca.ptvtelecom.com"
};
export const environment = {
    production: false,
    // endpoint: 'http://127.0.0.1:8000/api',

    login: '/login',
    endpointdatos: 'https://apifijo.ptvtelecom.com',
    // endpointdatos: 'http://localhost:8081',
    getContratoPdfBase64: '/contratos/getContratoPdfBase64',
    // endpointdatos: 'http://212.225.223.78:8081',
    // endpointdatos: 'https://apifijo.ptvtelecom.com/swagger-ui',
    getContratos: '/contratos/getContratos',
    getLineas: '/lineas/getLineas',
    getDetalleLineaFija: '/lineas/getDetalleLineaFija',
    getConsumofijo: '/lineas/getConsumoFijo',
    bloqueofija: '/lineas/bloqueoFija',
    setCliente: '/cliente/setCliente',
    modifyCliente: '/cliente/modifyClient',
    setUsuarioFijo: '/contratos/setUsuario',
    setLineas: '/lineas/setLineaFija',
    setLineasMovil: '/lineas/setLinea',
    getUsuario: '/cliente/getcliente',
    cOperador: 'http://212.225.255.130:8010/ws/wsopedona/',
    setUser: '/contratos/setUsuario',
    getContratosMovil: '/contratos/getContratosMovil',
    getContratos_: '/contratos/devuelveContratos',
    getTarifaMovil: '/lineas/GetTarifasmovil',
    getPais: '/cliente/getPais',
    getConsumoMovil: '/lineas/getConsumo',
    getCallsForLine: '/lineas/getCallsForLine',
    checksim: "/lineas/checkSim",
    getBonosMovil: '/lineas/getBonos',
    InfoSIMMovil: '/lineas/infoSim',
    verlistMutisim: '/lineas/listarMultisim',
    getSimCodes: '/lineas/getSimCodes',
    getServicioFijo: '/lineas/getServiciosFijos',
    modificarServicioFijo: '/lineas/modificaServicioFijo',
    getServicioMovil: '/lineas/getServiciosMovil',
    modificarServicioMovil: '/lineas/modificaServicioMovil',
    //_______________________________________FTTH

    getsubcriptions: 'https://apiftth.ptvtelecom.com/getSuscriptions',
    getSuscriptionsByContract: 'https://apiftth.ptvtelecom.com/getSuscriptionsByContract',
    orderStatus: 'https://apiftth.ptvtelecom.com/orderStatus',
    ontDiagnosis: 'https://apiftth.ptvtelecom.com/ontDiagnosis',
    diagnosisCodes: 'https://apiftth.ptvtelecom.com/diagnosisCodes',
    getRates: 'https://apiftth.ptvtelecom.com/getRates',
    migrateSpeed: 'https://apiftth.ptvtelecom.com/migrateSpeed',
    blockUser: 'https://apiftth.ptvtelecom.com/blockUser',
    deleteSuscriber: 'https://apiftth.ptvtelecom.com/deleteSuscriber',
    gettlementTypes: 'https://apiftth.ptvtelecom.com/gettlementTypes',
    normalizedAddress: 'https://apiftth.ptvtelecom.com/normalizedAddress',
    coverage: 'https://apiftth.ptvtelecom.com/coverage',
    getProvincias: 'https://apiftth.ptvtelecom.com/getProvincias',
    getPoblacion: 'https://apiftth.ptvtelecom.com/getPoblacion',
    getStreets: 'https://apiftth.ptvtelecom.com/getStreets',
    createNewSubcription: 'https://apiftth.ptvtelecom.com/createSubscription',
    /*________________________________________BITSTREAM_______________________________________________________ */

    loginBitstream: 'https://bitstream.ptvtelecom.com/user/login',
    refreshBitstream: 'https://bitstream.ptvtelecom.com/user/refresh',
    getProvinciasBitstream: 'https://bitstream.ptvtelecom.com/cobertura/getProvincias',
    getMunicipiosBitstream: 'https://bitstream.ptvtelecom.com/cobertura/getMunicipios/',
    getCalleBitstream: 'https://bitstream.ptvtelecom.com/cobertura/getCalles/',
    getCoberturaBitstream: 'https://bitstream.ptvtelecom.com/cobertura/getCobertura/',
    getPedidosBitstream: 'https://bitstream.ptvtelecom.com/pedidos/recuperarplataforma',
    contratoNuevoBitstream: 'https://bitstream.ptvtelecom.com/contrato/nuevo',
    subirPedidosBitstream: 'https://bitstream.ptvtelecom.com/pedidos/subir',
    

    /*______________________________________________________PRODUCCION________________________________________ */

    //___API FIBRA PROD
    checkDocumentValidate: 'https://apiftth.ptvtelecom.com/checkDocument',
    getSubscriptionStateOrange: 'https://apiftth.ptvtelecom.com/getSubscriptionState',
    // __BACK OPERADORES PROD
    endpoint: 'https://apimovil.ptvtelecom.com/api',
    checkMobileLines: 'https://apimovil.ptvtelecom.com/api/lineas/getLineasCuarto',
    getTarifaMobile: 'https://apimovil.ptvtelecom.com/api/lineas/GetTarifasmovil',
    updateTarifa: 'https://apimovil.ptvtelecom.com/api/lineas/updateTarifa',
    getCliente: 'https://apimovil.ptvtelecom.com/api/cliente/getcliente',
    uploadDocumentation: 'https://apimovil.ptvtelecom.com/api/contratos/uploadDocumentation',
    getDocumentationNames: 'https://apimovil.ptvtelecom.com/api/contratos/getDocumentationNames',
    downloadDocumentation: 'https://apimovil.ptvtelecom.com/api/contratos/downloadDocumentation',
    downloadLogoOperator: 'https://apimovil.ptvtelecom.com/api/operador/getLogo',
    uploadLogoOperator: 'https://apimovil.ptvtelecom.com/api/operador/uploadLogo',
    getMostrarRiesgo: 'https://apimovil.ptvtelecom.com/api/lineas/getMostrarRiesgo',
    getGuardarLimiteRiesgo: 'https://apimovil.ptvtelecom.com/api/lineas/getGuardarLimiteRiesgo',
    getListCdr: 'https://apimovil.ptvtelecom.com/api/operador/getListCdr',
    getCdr: 'https://apimovil.ptvtelecom.com/api/operador/getCdr',
    cambioSim: 'https://apimovil.ptvtelecom.com/api/lineas/cambioSim',
    bonusForLine: 'https://apimovil.ptvtelecom.com/api/lineas/bonusForLine',
    activateBonus: 'https://apimovil.ptvtelecom.com/api/lineas/activateBonus',
    cambioTitular: 'https://apimovil.ptvtelecom.com/api/lineas/cambioTitular',
    checkFreeNumberMobile: 'https://apimovil.ptvtelecom.com/api/lineas/checkFreeNumberMobile',
    advancedFind: 'https://apimovil.ptvtelecom.com/api/lineas/advancedFind',
    launchDobleGigas: 'https://apimovil.ptvtelecom.com/api/lineas/lauchDoubleGb',
    getTarifaFamilar: 'https://apimovil.ptvtelecom.com/api/lineas/getTarifaFamiliar',
    startGroupLines: 'https://apimovil.ptvtelecom.com/api/lineas/startGroupLines',
    addLineGroup: 'https://apimovil.ptvtelecom.com/api/lineas/addLineGroup',
    exportacionesMoviles: 'https://apimovil.ptvtelecom.com/api/lineas/exportacionesMoviles',
    getListSharedGroup: 'https://apimovil.ptvtelecom.com/api/lineas/getListSharedGroup',
    bajaLineGroup: 'https://apimovil.ptvtelecom.com/api/lineas/getBajaCompartida',
    getForzarCambioTarifa: 'https://apimovil.ptvtelecom.com/api/lineas/getForzarCambioTarifa',
    controlBajas: 'https://apimovil.ptvtelecom.com/api/lineas/getInfoBajas',
    gestionPortabilidad: 'https://apimovil.ptvtelecom.com/api/lineas/gestionPortabilidades',
    getInvoices: 'https://apimovil.ptvtelecom.com/api/lineas/getInvoices',
    obtenerFacturasPorOperador: 'https://apimovil.ptvtelecom.com/api/lineas/obtenerFacturasPorOperador',
    verpdfFactura: 'https://apimovil.ptvtelecom.com/api/lineas/verpdfFactura',
    getListControlRiesgo: 'https://apimovil.ptvtelecom.com/api/lineas/getListControlRiesgo',
    bloqueo: 'https://apimovil.ptvtelecom.com/api/lineas/bloquear',
    getListBloqueados: 'https://apimovil.ptvtelecom.com/api/lineas/getListBloqueados',
    modificaServicioMovil: 'https://apimovil.ptvtelecom.com/api/lineas/modificaServicioMovil',
    historicTarifa: 'https://apimovil.ptvtelecom.com/api/lineas/historicTarifas',
    historicBonos: 'https://apimovil.ptvtelecom.com/api/lineas/historicBonos',
    getBonosList: 'https://apimovil.ptvtelecom.com/api/lineas/getBonosList',
    resetPassword: 'https://apimovil.ptvtelecom.com/api/resetPass',
    exportarEnPDfContratoMovil: 'https://apimovil.ptvtelecom.com/api/lineas/generateContractPdf',
    bloqueoDesbloqueo:"https://apimovil.ptvtelecom.com/api/lineas/bloqueoDesbloqueo",
    getTarifaOrigen: 'https://apimovil.ptvtelecom.com/api/lineas/getOrigen',
    downloadCsvPromoVerano: 'https://apimovil.ptvtelecom.com/api/lineas/downloadCsv',
    puedeDobleGigas: 'https://apimovil.ptvtelecom.com/api/lineas/puedeDobleGigas',


    //____________________________________________FIJO

    buscarLineaFija: 'https://apifijo.ptvtelecom.com​/lineas/buscarLineaFija',
    cambioTitularFijo: 'https://apifijo.ptvtelecom.com​/lineas/cambioTitular',
    getGestionRechazos: 'https://apifijo.ptvtelecom.com/documentacion/gestionRechazos',
    gestionPortabilidadesFijos: 'https://apiFijo.ptvtelecom.com​/documentacion/gestPortabilidad',
    getexportFijas: 'https://apiFijo.ptvtelecom.com​/lineas/getExportaciones',
    uplodadDocsFijo: 'https://apifijo.ptvtelecom.com/documentacion/postDocumento',
    getDocsFijo: 'https://apifijo.ptvtelecom.com/documentacion/getDocumentos',
    downloadDocsFijo: 'https://apifijo.ptvtelecom.com/documentacion/downloadDocs',

    //_______________________________TOKEN GENERICO ACCESO
    GENERICTOKEN: 'c107add9c013e11f373918d8ff7510ca',
    pdfWholesale: 'https://apimovil.ptvtelecom.com/api/lineas/pdfWholesale',
    getContratoSubrrogacion: 'https://apimovil.ptvtelecom.com/api/contratos/getContratoSubrrogacion',

    //-------------------------------MARCA BLANCA------------------------------------- 
    listarAlbaranes: mode.urlbase + '/api/operador/obtenerListaAlbaranes',
    descargarAlbaran: mode.urlbase + '/api/operador/descargarAlbaran',
    loginMarcaBlanca: mode.urlbase + '/api/login',
    listarContratos: mode.urlbase + '/api/contrato/listarContratos',
    crearClienteMarcaBlanca: mode.urlbase + '/api/crearCliente',
    crearContratoMarcaBlanca: mode.urlbase + '/api/contrato/distribucion/nuevo',
    obtenerServiciosMarcaBlanca: mode.urlbase + '/api/servicios/getServiciosContratables',
    obtenerPacksMarcaBlanca: mode.urlbase + '/api/servicios/getPacks',
    obtenerServiciosAdicionalesMarcaBlanca: mode.urlbase + '/api/servicios/getServiciosAdicionales',
    setServiciosMarcaBlanca: mode.urlbase + '/api/servicios/setServicios',
    obtenerTecnico: mode.urlbase + "/api/tecnicos/obtenerTecnico",
    obtenerTramos: mode.urlbase + "/api/tecnicos/horasLibres",
    asignarCita: mode.urlbase + "/api/agenda/setCita",
    visualizarIncidenciasMarcaBlanca: mode.urlbase + '/api/incidencias/visualizar/',
    grabarComentarioIncidenciasMarcaBlanca: mode.urlbase + '/api/incidencias/grabarComentario',
    recuperarServiciosActivosMarcaBlanca: mode.urlbase + '/api/contrato/recuperarServiciosActivos',
    bloquearDesbloquearServicioMarcaBlanca: mode.urlbase + '/api/servicios/bloqueoDesbloqueo',
    confirmarContratoMarcaBlanca: mode.urlbase + '/api/contrato/distribucion/confirmar',
    diagnosticoONTMarcaBlanca: mode.urlbase + "/api/ont/diagnostico",
    nivelesOnt: mode.urlbase + "/api/ont/niveles",
    listarDocumentacionContratos: mode.urlbase + "/api/documentacion/listar",
    obtenerDocumentoMarcaBlanca: mode.urlbase + '/api/documentacion/obtener',
    subirDocumentoMarcaBlanca: mode.urlbase + '/api/documentacion/subir',
    logoutMarcaBlanca:  mode.urlbase + '/api/logout',
    cambioDomicilio:mode.urlbase+"/api/contrato/cambioDomicilio",
    rechazaPedido:mode.urlbase+"/api/servicios/rechazaPedido",
    bajaContrato:mode.urlbase+"/api/contrato/baja",
    bajaPedido:mode.urlbase+"/api/servicios/bajaPedido",
    crearIncidencia :mode.urlbase+'/api/incidencias/crearIncidencia',
    getBuzonIncidencias:mode.urlbase+'/api/incidencias/obtenerBuzonesIncidencias',



};
