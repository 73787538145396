<div class="entradaOpciones" *ngIf="opciones">
    <button type="button" title="BUSCAR CLIENTE EXISTENTE EN EL SISTEMA" (click)="buscarClienteExistente()">
        BUSCAR CLIENTE EXISTENTE EN EL SISTEMA
    </button>
    <button type="button" title="AÑADIR NUEVO CLIENTE AL SISTEMA" (click)="crearNuevoCliente()">
        AÑADIR NUEVO CLIENTE AL SISTEMA
    </button>
</div>

<div *ngIf="crearnuevo">
    <app-create-client></app-create-client>
</div>

<div class="entradaOpciones" *ngIf="buscarCliente">
    <form novalidate>
        <label for="documentoIdentidad">Introduzca el documento de identidad del cliente a buscar:</label>
        <input id="documentoIdentidad" type="text" name="documentoIdentidad" maxlength="9" placeholder="DNI/NIE/CIF"
            [(ngModel)]="documento">
        <button type="button" name="cambiar" title="BUSCAR" (click)="buscarClienteExistenteLaunch()">BUSCAR CLIENTE
        </button>
    </form>
</div>

<div class="containerTitularidad" *ngIf="dataClientExist">
    <!-- __________________________________________DATOS_CLIENTE__________________________________________ -->
    <mat-grid-list cols="16" rowHeight="2.5em" style="width: 100%;">
        <mat-grid-tile [colspan]=8 [rowspan]=1 class="gridTitulos">
            <label class="tituloText">DATOS PERSONALES</label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]=8 [rowspan]=1 class="gridTitulos">
            <label class="tituloText">DIRECCIÓN</label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]=2 [rowspan]=1 class="alinearTI">
            <label class="gridText">NOMBRE</label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]=6 [rowspan]=1>
            <input type="text" name="nombre" value="{{dataClientExist.nom}}" [disabled]="true" class="datosCliente">
        </mat-grid-tile>
        <mat-grid-tile [colspan]=2 [rowspan]=1 class="alinearTI">
            <label class="gridText">POBLACION</label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]=6 [rowspan]=1>
            <input type="text" name="poblacion" value="{{dataClientExist.poblacion}}" [disabled]="true"
                class="datosCliente">
        </mat-grid-tile>
        <mat-grid-tile [colspan]=2 [rowspan]=1 class="alinearTI">
            <label class="gridText">1º APELLIDO</label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]=6 [rowspan]=1>
            <input type="text" name="apellido1" value="{{dataClientExist.ape1}}" [disabled]="true" class="datosCliente">
        </mat-grid-tile>
        <mat-grid-tile [colspan]=2 [rowspan]=1 class="alinearTI">
            <label class="gridText">LOCALIDAD</label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]=6 [rowspan]=1>
            <input type="text" name="localidad" value="{{dataClientExist.localidad}}" [disabled]="true"
                class="datosCliente">
        </mat-grid-tile>
        <mat-grid-tile [colspan]=2 [rowspan]=1 class="alinearTI">
            <label class="gridText">2º APELLIDO</label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]=6 [rowspan]=1>
            <input type="text" name="apellido2" value="{{dataClientExist.ape2}}" [disabled]="true" class="datosCliente">
        </mat-grid-tile>
        <mat-grid-tile [colspan]=2 [rowspan]=1 class="alinearTI">
            <label class="gridText">CÓDIGO POSTAL</label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]=6 [rowspan]=1>
            <input type="text" name="cp" value="{{dataClientExist.cp}}" [disabled]="true" class="datosCliente">
        </mat-grid-tile>
        <mat-grid-tile [colspan]=2 [rowspan]=1 class="alinearTI">
            <label class="gridText">F. NACIMIENTO</label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]=6 [rowspan]=1>
            <input type="text" name="fechnan" value="{{dataClientExist.fecha}}" [disabled]="true" class="datosCliente">
        </mat-grid-tile>
        <mat-grid-tile [colspan]=2 [rowspan]=1 class="alinearTI">
            <label class="gridText">DIRECCIÓN</label>
        </mat-grid-tile>
        <mat-grid-tile [colspan]=6 [rowspan]=1>
            <input type="text" name="dir" value="{{dataClientExist.dir}}" [disabled]="true" class="datosCliente">
        </mat-grid-tile>
        <mat-grid-tile [colspan]=16 [rowspan]=1>
            <button type="button"
                (click)="exportarContratoMovilEnPdf('ptv_conMobile_'+apellido1+'_'+apellido2+'_'+nombre)"
                class="botonLM" title="DESCARGAR CONTRATO DE SUBRROGACIÓN">DESCARGAR CONTRATO DE SUBRROGACIÓN
            </button>
        </mat-grid-tile>
        <mat-grid-tile [colspan]=16 [rowspan]=1>
        </mat-grid-tile>
        <!-- __________________________________________DOCUMENTACIÓN__________________________________________ -->
        <mat-grid-tile [colspan]=16 [rowspan]=1 class="gridTitulos">
            <label class="tituloText">DOCUMENTACIÓN A ADJUNTAR (PDF, PNG, JPG)</label>
        </mat-grid-tile>
        <form [formGroup]="formFiles" style="width: 100%">
            <mat-grid-tile [colspan]=4 [rowspan]=1 class="alinearTI">
                <label for="file1" class="gridText">DOCUMENTO DE IDENTIDAD:</label>
            </mat-grid-tile>
            <mat-grid-tile [colspan]=12 [rowspan]=1>
                <input class="archivo" type="file" name="file1" id="demo1" formControlName="fileDocIdentidad"
                    (change)="setDoc($event, 'fileDocIdentidad')">
            </mat-grid-tile>
            <mat-grid-tile [colspan]=4 [rowspan]=1 class="alinearTI">
                <label for="file2" class="gridText">C. DE SUBRROGACIÓN (FIRMADO):</label>
            </mat-grid-tile>
            <mat-grid-tile [colspan]=12 [rowspan]=1>
                <input class="archivo" type="file" name="file2" id="demo2" formControlName="fileCopia"
                    (change)="setDoc($event, 'fileCopia')">
            </mat-grid-tile>
            <mat-grid-tile [colspan]=4 [rowspan]=1 class="alinearTI">
                <label for="file1" class="gridText">GRABACIÓN (OPCIONAL):</label>
            </mat-grid-tile>
            <mat-grid-tile [colspan]=12 [rowspan]=1>
                <input class="archivo" type="file" name="file3" id="demo3" formControlName="fileGrabacion"
                    (change)="setDoc($event, 'fileGrabacion')">
            </mat-grid-tile>
        </form>
        <mat-grid-tile [colspan]=16 [rowspan]=1>
            <button type="button" title="CONFIRMAR" (click)="cambioTitular()" class="botonLM">CONFIRMAR</button>
        </mat-grid-tile>
    </mat-grid-list>
</div>