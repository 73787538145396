import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import jsPDF from 'jspdf';

import { CookieService } from 'ngx-cookie-service';

import { Observable, Subscriber } from 'rxjs';

import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class RepositoryDocumentationService {
    MESES = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];


    mesSelectedFile: string;
    yearSelectedFile: string;
    public listArchFromServe: any
    public listDataOperator: any
    logoOperatorBase64: any
    load: boolean
    currentFileUpload: string
    constructor(private cookie: CookieService, private sanitizer: DomSanitizer, private formBuilder: FormBuilder, private http: HttpClient) {}


    /**
     * Método encargado de subir al servicor documentacion del cliente bien sea en formato imagen o documento pdf
     * @param docFile base64 del fichero
     * @param extFile extension del fichero sin el punto -> jpg, png, pdf
     * @param nameFile nombre del fichwero sin la extension
     * @param datosPersonales nif del cliente
     */
    async uplodaDocuments(docFile, extFile, tercero, telefono, nameDocs) {
            let url = environment.uploadDocumentation

            const headers = new HttpHeaders({
                "user": this.cookie.get("user"),
                "token": this.cookie.get("token")
            });

            const body = {
                "tercero": tercero,
                "base64Doc": docFile,
                "extensionDocumento": extFile,
                "nombreDoc": nameDocs,
                "telefono": telefono,
            }
            this.load = true;
            try {
                this.http.post(url, body, { headers }).subscribe((res: any) => {
                    this.load = false;

                    if (res == "subido Correctamente") {
                        Swal.fire({
                            title: "RESULTADO CORRECTO",
                            icon: "success",
                            text: "La operación se ha realizado con éxito."
                        })
                            // this.loadDocumentationDateNow(tercero, cuarto);
                    }

                    // else {
                    //     Swal.fire({
                    //         title: "ARCHIVO DEMASIADO GRANDE",
                    //         icon: "warning",
                    //         text: "El archivo supera el límite de 5Mb."
                    //     })
                    // }

                }, (error: any) => { //VALIDACION DE LA SUBIDA DE DOCUMENTOS POSIBLES ERRORES
                    this.load = false;
                    if (error.error.message == "Falta el parametro DocumentBase64") {
                        Swal.fire({
                            title: "OPERACIÓN FALLIDA",
                            icon: "warning",
                            text: (error.error.message == "Falta el parametro DocumentBase64") ? "Debe subir un archivo." : error.error.message,
                        })

                    } else if (error.error.message == "Falta el parametro codigoCliente") {
                        Swal.fire({
                            title: "OPERACIÓN FALLIDA",
                            icon: "warning",
                            text: (error.error.message == "Falta el parametro codigoCliente") ? "Debe seleccionar un cliente." : error.error.message,
                        })

                    } else {
                        Swal.fire({
                            title: "OPERACIÓN FALLIDA",
                            icon: "warning",
                            text: error.error.message,
                        })
                    }
                })
            } catch {
                this.load = false;
            }

        }
        /**
         *  Método encargado de actualizar la lista conforme se sube un archivo del cliente al servidor
         * @param tercero operador
         * @param cuarto cliente
         */

    async uplodaDocumentsFijo(docFile, extFile, tercero, telefono, nombreDoc) {
        let url = environment.uplodadDocsFijo

        const headers = new HttpHeaders({
            "user": this.cookie.get("user"),
            "token": this.cookie.get("token"),
            "pass": this.cookie.get("pass")
        });

        const body = {
            "tipoMime": extFile,
            "tel": telefono,
            "nombre": nombreDoc,
            "contenido": docFile,
            "codTercero": tercero
        }

        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (res.Status == "OK") {
                    Swal.fire({
                            title: "Resultado de la Subida",
                            icon: "success",
                            text: " Correctamente Subido"
                        })
                        // this.loadDocumentationDateNow(tercero, cuarto);
                } else {
                    Swal.fire({
                        title: "ARCHIVO DEMASIADO GRANDE",
                        icon: "warning",
                        text: "El archivo supera el límite de 5Mb."
                    })
                }
            }, (error: any) => { //VALIDACION DE LA SUBIDA DE DOCUMENTOS POSIBLES ERRORES
                if (error.error.message == "Falta el parametro DocumentBase64") {
                    Swal.fire({
                        title: "OPERACIÓN FALLIDA",
                        icon: "warning",
                        text: (error.error.message == "Falta el parametro DocumentBase64") ? "Debe subir un archivo." : error.error.message,
                    })

                } else if (error.error.message == "Falta el parametro codigoCliente") {
                    Swal.fire({
                        title: "OPERACIÓN FALLIDA",
                        icon: "warning",
                        text: (error.error.message == "Falta el parametro codigoCliente") ? "Debe seleccionar un cliente." : error.error.message,
                    })

                } else {
                    Swal.fire({
                        title: "OPERACIÓN FALLIDA",
                        icon: "warning",
                        text: error.error.message,
                    })
                }
            })
        } catch {
            // this.hideloading();
        }
    }

    /**
     * Método encargado de subir y actualizar el logo del operador
     */
    async uploadLogoOperator(docFile, extFile: string) {
        let urlSubida = environment.uploadLogoOperator
        const headers = new HttpHeaders({
            "user": this.cookie.get("user"),
            "token": this.cookie.get("token")
        });
        const bodySubida = {
            "base64Logo": docFile,
            "extLogo": extFile,
        }

        try {
            this.http.post(urlSubida, bodySubida, { headers }).subscribe((res: any) => {
                if (res) {
                    Swal.fire({
                        icon: "success",
                        text: "Logo modificado correctamente"
                    })
                    this.logoOperatorBase64 = docFile;
                }
            })
        } catch {
            this.load = false
        }
    }


    /**
     * Método encargado de retornar el logo propio de cada operador si este dispone de otro diferente al de PTV
     * @param nombreArchivoConExtension 
     */
    downloadLogoOperator(nombreArchivoConExtension: string) {
        const body = {
            "nombreArchivoConExtension": nombreArchivoConExtension
        }
        const headers = new HttpHeaders({
            "user": this.cookie.get("user"),
            "pass": this.cookie.get("pass"),
            "token": this.cookie.get("token")
        });
        let urlDescarga = environment.downloadLogoOperator
        try {
            this.http.post(urlDescarga, body, { headers }).subscribe((res: any) => {
                // if (res != "EL OPERADOR NO POSEE LOGO CUSTOMIZADO") {
                this.logoOperatorBase64 = res.fileBase64; //this.sanitizer.bypassSecurityTrustResourceUrl(res.fileBase64)
                // }
                // this.logoOperatorBase64 = res.fileBase64
            }, (error: any) => {
                console.log(error);
            })
        } catch {
            this.load = false;
        }
    }



    async exportarContratoFijoEnPDF(datos, nombrePDF, numSinPortabilidad?){
        //ESTE FRAGMENTO DE CODIGO DEBE IR COMENTADO EN CUANTO EL ENDPOINT DE LA API DE FIJO QUE LO GENERA ESTE EN PRODUCCION
        //=====================================GENERAR EL PDF DEL CONTRATO EN EL FRONT
        let operador = this.cookie.get("user");
        this.downloadLogoOperator("logo_" + operador + ".png")
        var paisSelect = (document.getElementById("paisSelect")) as HTMLSelectElement
        var sel = paisSelect.selectedIndex;
        var opt = paisSelect.options[sel];
        var paisString = (opt).text;
        var tarifaMovil = (document.getElementById("tarifaSelect")) as HTMLSelectElement
        var seltar = tarifaMovil.selectedIndex;
        var optTarifa = tarifaMovil.options[seltar];
        var tarifaSelectedString = (optTarifa).text;
        var bonoSelect = (document.getElementById("bonoSelect")) as HTMLSelectElement
        var selBono = bonoSelect.selectedIndex;
        var optBono = bonoSelect.options[selBono];
        var bonoSelectedString = (optBono).text;
        let numero = (datos.numero == "") ? numSinPortabilidad: datos.numero;
        ///=============================== TEMPLATE PDF
        var doc = new jsPDF('p', 'mm', 'a4', true);
        var logo = 'assets/img/logoptv.png';
        var imgLogo = new Image();
        imgLogo.src = logo
        if (this.logoOperatorBase64) { //si el operador actual dispone de logo entoces se mostrará en el pdf su propio logo no el de ptv
            doc.addImage(this.logoOperatorBase64, 'PNG', 18, 3, 35, 15)

        } else {
            doc.addImage(imgLogo, 'PNG', 18, 3, 35, 15)
        }

        doc.setFontSize(22);
        doc.setTextColor('white');
        doc.rect(18, 20, 180, 12, 'F'); // filled square
        doc.text('Contrato de lineas Fijas', 20, 30);
        doc.setFontSize(14);
        doc.setTextColor('white');
        doc.setFillColor('black');
        doc.rect(18, 35, 180, 12, 'F'); // filled square
        doc.text('Datos del cliente', 20, 45);
        doc.setFontSize(14);
        doc.setTextColor('black');
        doc.rect(18, 50, 180, 45);
        doc.text('Nombre y apellidos / Empresa: ' + datos.nombre + ' ' + datos.apellido1 + ' ' + datos.apellido2, 20, 60);
        doc.text('D.N.I. / N.I.F. / C.I.F. : ' + datos.nif, 20, 70);
        doc.text('Nacionalidad: ' + paisString, 100, 70);
        doc.text('Nombre de la via: ' + datos.direccion, 20, 80);
        doc.text('Codigo Postal: ' + datos.cp, 20, 90);
        doc.text('Poblacion: ' + datos.poblacion, 70, 90);
        doc.text('Provincia: ' + datos.provincia, 140, 90);
        doc.setFillColor('black');
        doc.rect(18, 100, 180, 14, 'F'); // filled square
        doc.setTextColor('white');
        doc.text('CONTRATACIÓN LÍNEA FIJA', 20, 110);
        doc.setTextColor('black');
        doc.rect(18, 120, 180, 55);
        doc.text('Tecnología: ' + datos.tecnologia, 20, 130)
        doc.text('Línea Nueva teléfono analógica: ' + numero, 20, 140);
        doc.text('Portabilidad: ' + datos.portabilidad, 20, 150);
        doc.text('Bono Móvil: ' + (bonoSelectedString == 'SELECCIONE UN BONO MÓVIL') ? '' : bonoSelectedString, 20, 160);
        doc.text('Tarifa: ' + (tarifaSelectedString == "SELECCIONE UNA TARIFA PLANA") ? '' : tarifaSelectedString, 20, 170);
        doc.setFontSize(6);
        doc.text('(1)AI: ACCESO INDIVIDUAL. Deberá escoger esta opción en caso de tratarse de una linea individual analógica o un número asociado a servicios de VOIP', 20, 178);
        doc.rect(18, 180, 180, 14, 'F'); // filled square
        doc.setFontSize(14);
        doc.setTextColor('white');
        doc.text('FIRMA DEL CONTRATO', 20, 190);
        doc.setTextColor('black')
        doc.setFontSize(6);
        doc.text('Por medio de la presente solicitud, el abajo firmante:', 20, 197);
        doc.text('-Desea causar baja en el operador que hasta el momento le presta el servicio, conservando su numeración asignada.', 20, 200);
        doc.text('-Acepta la posible interrupción o limitación en la prestación del servicio durante el tiempo mínimo indispensable para realizar las actuaciones en los sistemas de los', 20, 203);
        doc.text('operadores por cambio de operador, que será de un máximo de cuatro horas.', 20, 206);
        doc.text('-Consiente el tratamiento de los datos personales necesarios para la conservación de los números, tanto los incluidos en esta solicitud como los que conocen los', 20, 209);
        doc.text('operadores donante y receptor, y autoriza su tratamiento y su comunicación y cesión con el fin exclusivo de la conservación de su número en el proceso de portabilidad.', 20, 212);
        doc.text('Podrá ejercer sus derechos de acceso, rectificación, cancelación y oposición sobre los datos tratados por Procono S.A', 20, 215);
        doc.setFontSize(14);
        doc.rect(18, 220, 180, 60);
        doc.text('Fecha contrato: ' +
            this.dateNow(), 20, 225);
        doc.text('Firma del cliente: ____________________', 20, 275);
        doc.save(nombrePDF);
        //================================================END GENERATE PDF (FRONT)


        ///=====================================DESCOMENTAR CUANDO EL ENDPOINT DE LA API ESTE EN FUNCIONAMIENTO
        ///===============================================================FROM API FIJO
        // var datosLinea = datosLinea;
        // var datosPersonales = datos;
        // var paisSelect = (document.getElementById("paisSelect")) as HTMLSelectElement
        // var sel = paisSelect.selectedIndex;
        // var opt = paisSelect.options[sel];
        // var paisString = (opt).text;
        // var tarifaMovil = (document.getElementById("tarifaSelect")) as HTMLSelectElement
        // var seltar = tarifaMovil.selectedIndex;
        // var optTarifa = tarifaMovil.options[seltar];
        // var tarifaSelectedString = (optTarifa).text;
        // var bonoSelect = (document.getElementById("bonoSelect")) as HTMLSelectElement
        // var selBono = bonoSelect.selectedIndex;
        // var optBono = bonoSelect.options[selBono];
        // var bonoSelectedString = (optBono).text;
        // let numero = (datosLinea.get("numero").value == "") ? "0" : datosLinea.get("numero").value;
        // const uri = environment.endpointdatos + environment.getContratoPdfBase64;
        // const headers = new HttpHeaders({
        //     "user": this.cookie.get("user"),
        //     "pass": this.cookie.get("pass"),
        //     "token": this.cookie.get("token")
        // });

        // const body = {
        //     "nombreCompleto": datosPersonales.get("nombre").value + ' ' + datosPersonales.get("apellido1").value + ' ' + datosPersonales.get("apellido2").value,
        //     "documento": datosPersonales.get("nif").value,
        //     "nacionalidad": paisString,
        //     "calle": datosPersonales.get("direccion").value,
        //     "codigoPostal": datosPersonales.get("cp").value,
        //     "poblacion": datosPersonales.get("poblacion").value,
        //     "provincia": datosPersonales.get("localidad").value,
        //     "tecnologia": datosLinea.get("tecnologia").value,
        //     "numero": numero,
        //     "portabilidad": datosLinea.get("portabilidad").value,
        //     "bono": (bonoSelectedString == 'SELECCIONE UN BONO MÓVIL') ? '' : bonoSelectedString,
        //     "tarifa": tarifaSelectedString == "SELECCIONE UNA TARIFA PLANA") ? '' : tarifaSelectedString
        // }
        // this.load = true;
        // try {
        //     this.http.post(uri, body, { headers }).subscribe((res: any) => {
        //         this.load = false;
        //         this.downloadData(res.FileBase64, "ContractFixed_" + numero + "_" + datosPersonales.get("nif").value + ".pdf");
        //     }, error => {
        //         this.load = false;
        //     });
        // } catch {
        //     this.load = false;
        // }


    }



    async downloadData(base64, nombre) {
        const linkSource = "data:application/pdf;base64," + base64;

        let pdfBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(linkSource);
        var a = document.createElement("a"); //Create <a>
        a.href = linkSource; //Image Base64 Goes here
        a.download = nombre; //File name Here
        a.click(); //Downloaded file

    }

    exportarContratoMovilEnPDF(datos, datosLineaActual, nameFile) {

        //_______DESCARGO SI POSEE EL OPERADOR LOGO CUSTOMIZADO
        let operador = this.cookie.get("user");

        this.downloadLogoOperator("logo_" + operador + ".jpg")
            //________________________________________________________________________________

        var datosPersonales = datos;
        var datosLinea = datosLineaActual
            // var paisSelect = (document.getElementById("paisSelect")) as HTMLSelectElement
            // var sel = paisSelect.selectedIndex; //obtengo la opcion seleccionada
            // var opt = paisSelect.options[sel]; //obtengo el option seleccionado
            // var paisString = opt.text; //obtengo el valor del option seleccionado

        var datosPf = {
            "nombre": datosPersonales.get("nombre").value + ' ' + datosPersonales.get("apellido1").value + ' ' + datosPersonales.get("apellido2").value,
            "nif": datosPersonales.get("nif").value,
            "nacionalidad": "España",
            "calle": datosPersonales.get("direccion").value,
            "cp": datosPersonales.get("codigopostal").value,
            "poblacion": datosPersonales.get("poblacion").value,
            "provincia": datosPersonales.get("provincia").value,
            "tecnologia": "",
            // "nuevaLinea": datosLinea.telefono,
            // "portabilidad": datosLinea.portabilidad,
            // "bono": datosLinea.tarifa,
            // "tarifa": datosLinea.tarifa

        }

        //===================================TEMPLATE PDF
        var doc = new jsPDF('p', 'mm', 'a4', true);
        let logo = 'assets/img/logoptv.png';
        var imgLogo = new Image()
        imgLogo.src = logo
        if (this.logoOperatorBase64) { //si el operador actual dispone de logo entoces se mostrará en el pdf su propio logo no el de ptv
            doc.addImage(this.logoOperatorBase64, 'PNG', 18, 3, 15, 15)
        } else {
            doc.addImage(imgLogo, 'PNG', 18, 3, 15, 15)
        }


        doc.setFontSize(22);
        doc.setTextColor('white');
        doc.rect(18, 30, 180, 12, 'F'); // filled square
        doc.text('Contrato de lineas Móviles', 20, 40);

        doc.setFontSize(14);
        // doc.setDrawColor(255, 0, 0);
        doc.setTextColor('black');
        doc.rect(18, 45, 180, 45); // empty red square
        doc.text('Nombre y apellidos / Empresa: ' + datosPf.nombre, 20, 55);
        doc.text('D.N.I. / N.I.F. / C.I.F. : ' + datosPf.nif, 20, 65);
        doc.text('Nacionalidad: España', 20, 75);
        doc.text('Fecha nacimiento: ' + this.formatingFecha(datosPersonales.get("fechanac").value), 20, 85);


        doc.rect(18, 95, 180, 45); // empty red square
        doc.text('Nombre de la via: ' + datosPersonales.get("direccion").value, 20, 105);
        doc.text('Codigo Postal: ' + datosPersonales.get("codigopostal").value, 20, 115);
        doc.text('Poblacion: ' + datosPersonales.get("poblacion").value, 20, 125);
        doc.text('Provincia: ' + datosPersonales.get("provincia").value, 20, 135);

        doc.rect(18, 150, 180, 45); // empty red square
        if (datosLinea != null) {
            if (datosLinea.get("numero").value) { //Si existe el numero de telefono; en caso de no ser portabilidad será un nuevo telefono
                doc.text('Nº teléfono:' + datosLinea.get("numero").value, 20, 160);
            }
            if (datosLinea.get("portabilidad").value) { //Si existe la portabilidad
                doc.text('Portabilidad: ' + datosLinea.get("portabilidad").value, 20, 170);
            }
            doc.text('Tarifa: ' + this.cookie.get("tarifa"), 20, 180);
            if (datosLinea.get("iccNuevo").value) { //sie existe el iccNuevo
                doc.text('Nº tarjeta SIM nueva: ' + datosLinea.get("iccNuevo").value, 20, 190);
            }
        }
        doc.rect(18, 200, 180, 60); // empty red square
        doc.text('Fecha contrato: ' + this.dateNow(), 20, 210);
        doc.text('Firma del cliente: ____________________', 20, 255);

        doc.save(nameFile + '.pdf');
        //===================================END TEMPLATE PDF
    }

    /**
     * Util para devolver la fecha de nacimiento en formato DD/MM/YYYY
     * @param DateToFormat Fecha en formato String a formatear
     * @returns 
     */
    formatingFecha(DateToFormat) {
        let date = new Date(DateToFormat);

        let mes = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        return date.getDate() + "/" + mes + "/" + date.getFullYear();

    }


    /**
     * Método para devolver la fecha actual en Formato 
     * @returns DD/MM/YYYY actual del sistema
     */
    dateNow() {
        let date = new Date();
        let mes = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        return date.getDate() + "/" + mes + "/" + date.getFullYear();
    }









}