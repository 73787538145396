<div class="formbox">

    <div class="contentLoad" *ngIf="load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>

    <div class="cabecera">
        GRUPO COMPARTIDO - LISTA
    </div>
    <div class="cabecera2">
        <select class="form-control" name="contratos" class="contratoSelect"
            (change)="changeContratos($event.target.value)">
            <option value="0">Seleccione un Contrato:</option>
            <option value={{d.cof}}|{{d.con}} *ngFor="let d of data2">{{d.des}} - {{d.cof}} {{d.alias ? '('+d.alias+')' : '' }}</option>
        </select>
    </div>

    <div class="divTabla" *ngIf="list">
        <!-- SELECT TARIFA FAMILIAR -->

        <div class="customRow container" style="width: 95%; margin-top: 2em; background-color: white;" *ngIf="list">
            <div id="dataTableListBajas" *ngIf="list" style="zoom: 90%;">

                <table class="table table-striped table-bordered table-sm row-border hover" datatable
                    [dtOptions]="dtOptions" id="dt" *ngIf="list">
                    <thead style="font-family: MontserratBold;">
                        <tr>
                            <th>GRUPO</th>
                            <th>CABECERA</th>
                            <th>CONTRATO</th>
                            <th>TARIFA</th>
                            <th>TITULAR</th>
                            <th>NUMERO</th>
                        </tr>
                    </thead>
                    <tbody style="font-family: MontserratRegular;text-align: center;" *ngIf="list">
                        <tr *ngFor="let linea of list let i=index">
                            <!-- ______________GRUPO______________ -->
                            <td [ngClass]="!linea.PET_HIJA? 'grupo': 'columna'" *ngIf="!linea.PET_HIJA">
                                {{linea.PETICION}}
                            </td>

                            <td [ngClass]="!linea.PET_HIJA? 'grupo': 'columna'" *ngIf="linea.PET_HIJA"><span
                                    style="visibility: hidden;">{{linea.PETICION}}</span></td>
                            
                            <!-- ______________CABECERA______________ -->
                            <td [ngClass]="!linea.PET_HIJA? 'grupo': 'columna'" *ngIf="!linea.PET_HIJA">
                                {{linea.CABECERA}}
                            </td>

                            <td [ngClass]="!linea.PET_HIJA? 'grupo': 'columna'" *ngIf="linea.PET_HIJA"><span
                                    style="visibility: hidden;">{{linea.CABECERA}}</span></td>
                                    

                            <!-- ______________CONTRATO_OFICIAL______________ -->
                            <td [ngClass]="!linea.PET_HIJA? 'grupo': 'columna'">
                                <label *ngIf="linea.PET_HIJA">
                                    {{(linea.TARIFA)?contratoOficial:''}}
                                </label>
                                <label *ngIf="!linea.PET_HIJA" class="textoOculto">
                                    {{(linea.TARIFA)?contratoOficial:''}}
                                </label>
                            </td>
                            <!-- ______________TARIFA______________ -->
                            <td [ngClass]="!linea.PET_HIJA? 'grupo': 'columna'">
                                <label *ngIf="linea.PET_HIJA">
                                    {{linea.TARIFA}}
                                </label>
                                <label *ngIf="!linea.PET_HIJA" class="textoOculto">
                                    {{linea.TARIFA}}
                                </label>
                            </td>

                            <!-- ______________TITULAR______________ -->
                            <td [ngClass]="!linea.PET_HIJA? 'grupo': 'columna'">
                                <label *ngIf="linea.PET_HIJA">
                                    {{linea.TITULAR}}
                                </label>
                                <label *ngIf="!linea.PET_HIJA" class="textoOculto">
                                    {{linea.TITULAR}}
                                </label>
                            </td>

                            <!-- ______________NUMERO______________ -->
                            <td [ngClass]="!linea.PET_HIJA? 'grupo': 'columna'">
                                <label *ngIf="linea.PET_HIJA">
                                    {{linea.NUMERO}}
                                </label>
                                <label *ngIf="!linea.PET_HIJA" class="textoOculto">
                                    {{linea.NUMERO}}
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
        <label>.</label>
    </div>
</div>
