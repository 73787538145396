import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { LoadingServiceService } from '../loading-service.service';
import { SIMService } from 'src/app/services/SIM/sim.service';
@Component({
  selector: 'app-listar-multisim-component',
  templateUrl: './listar-multisim-component.component.html',
  styleUrls: ['./listar-multisim-component.component.css']
})
export class ListarMultisimComponentComponent implements OnInit {
    constructor(public simService: SIMService, public loadService: LoadingServiceService) {}

    ngOnInit(): void {
        this.simService.listarMultisim();
    }
}