import { ContratosService } from 'src/app/services/contratos.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { event } from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { AsyncSubject, Subject } from 'rxjs';


import { DoneteComponent } from 'src/app/donete/donete.component';
import { AuthService } from 'src/app/services/auth.service';
import { ChangePageService } from 'src/app/services/change-page.service';
import { environment } from 'src/environments/environment';
import { LoadingServiceService } from '../accioneslineamovil/loading-service.service';

@Component({
    selector: 'app-lineamovil',
    templateUrl: './lineamovil.component.html',
    styleUrls: ['./lineamovil.component.css']
})
export class LineamovilComponent implements OnInit, OnDestroy, AfterViewInit {
    data: any;
    data2; data3: any;
    tercero: any;
    enbusqueda = false
    public contactoOficial: string
    public load: Boolean = true;
    aux: any;
    promo: boolean
    consumo = {
        telefono: ''
    }
    linea = {
        tercero: '',
        contratoProvisional: ''
    };
    contrato = {
        tercero: ''
    };
    selectContrato
    carga: boolean = false
    //====================== DATATABLE =======================================//
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;

    dtOptions = {
        dom: 'Bfrtip',
        search: true,

        language: {
            url: '//cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        buttons: false,
        retrieve: true,
        paging: true,

    };

    // dt-button buttons-print


    dtTrigger: Subject<any> = new Subject();
    //====================END DATATABLE =====================================//
    constructor(private http: HttpClient,
        private auth: AuthService,
        private router: Router,
        private cookies: CookieService,
        private dialog: MatDialog,
        private loadService: LoadingServiceService,
        private changePageService: ChangePageService,
        private contratosSvc: ContratosService
    ) {
        var dataTable = $("#datatableMovil").dataTable();
        dataTable.DataTable().destroy
    }
    ngAfterViewInit(): void {
        var dataTable = $("#datatableMovil").dataTable();
        dataTable.DataTable().destroy //DESTRUIMOS EL DATATABLE
        this.dtTrigger.next();
    }

    ngOnInit(): void {
        // let ultimoBoton=document.getElementsByClassName("dt-buttons").createElement("<button class='dt-button buttons-copy buttons-html5' tabindex='0' aria-controls='dt' type='button'><span>Copiar</span></button>");




        this.selectContrato = "0";
        this.getContratos();

        var dataTable = $("#datatableMovil").dataTable();
        dataTable.DataTable().destroy
        //Si accedio a acciones al volver se seleccionará automagicamente el contrato
        //Si accedio a acciones al volver se seleccionará automagicamente el contrato
        //Si accedio a acciones al volver se seleccionará automagicamente el contrato
        //Si accedio a acciones al volver se seleccionará automagicamente el contrato
        //Si accedio a acciones al volver se seleccionará automagicamente el contrato
        if (this.changePageService.contratoMovilSeleccionado) {

            this.getlineas(this.changePageService.contratoMovilSeleccionado);
            this.selectContrato = this.changePageService.contratoMovilSeleccionado
            this.changePageService.contratoMovilSeleccionado = null; //reset


        }


    }
    // rerender(): void {
    //     this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {

    //         var dataTable = $("#dt").dataTable();

    //         dataTable.DataTable().destroy
    //         $('#dt').DataTable({

    //             pagingType: 'full_numbers',
    //             pageLength: 10,
    //             processing: true,
    //             dom: 'Bfrtip',

    //             language: {
    //                 url: '//cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
    //             },
    //             retrieve: true
    //                 // paging: false
    //         })
    //         // Call the dtTrigger to rerender again
    //         this.dtTrigger.next();
    //     });
    // }
    //_____________________________________________________CARGAR LINEAS POR CONTRATO
    getlineas($event) {

        /* this.ter = t;
        this.con = c; */
        this.enbusqueda = true;
        this.contactoOficial = $event.split("|")[0];
        this.cookies.set("coficial", $event.split("|")[0]);
        this.cookies.set("cprovisional", $event.split("|")[1]);
        this.cookies.set("promo", $event.split("|")[2]);
        // if(this.cookies.get("promo")=="undefined"){

        // }

        if (this.cookies.get("promo") == "S") {
            this.promo = true
        } else {
            this.promo = false
        }

        this.showloading()
        try {
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });
            this.linea = {
                "tercero": this.cookies.get("tercero"),
                "contratoProvisional": $event.split("|")[1]
            }
            this.data = null;

            const url = environment.endpoint + environment.getLineas;
            this.http.post(url, this.linea, { headers }).subscribe((res: any) => {
                this.data = [];
                var auxlist = [];
                this.data = res
                // res.forEach(a => {

                //     res.forEach(b => {
                //         if (new Date(this.strToDate(b.FecActivacion)) > new Date(this.strToDate(a.FecActivacion))) {


                //             this.data.push({
                //                 Cambio: b.Cambio,
                //                 DesTitular: b.DesTitular,
                //                 Dni: b.Dni,
                //                 DobleGB: b.DobleGB,
                //                 FecActivacion: b.FecActivacion,
                //                 Hora: b.Hora,
                //                 MultiSIM: b.MultiSIM,
                //                 NextTarifa: b.NextTarifa,
                //                 Numero: b.Numero,
                //                 RegPeticion: b.RegPeticion,
                //                 Tarifa: b.Tarifa,
                //             })
                //         }
                //     });

                // });
                //___________________________________DATATABLE ________________________________//
                this.updateDataTable();
                //________________________________END DATATABLE_________________________________-//
                this.hideloading()
                this.enbusqueda = false;
            }, error => {
                this.data = null;
                this.updateDataTable();
                this.hideloading()
                this.enbusqueda = false;
            });
        } catch {
            this.hideloading()
        }
    }
    strToDate(str: string): string {
        return str.split("-")[2] + "/" + str.split("-")[1] + "/" + str.split("-")[0];
    }

    getContratos() {
        try {
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });

            let body = {
                ip: "255.255.255.0"
            }
            this.data2 = null;
            this.showloading()
            const url = environment.endpoint + environment.getContratosMovil;
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (Array.isArray(res)) {
                    this.data2 = res;
                    this.aux = this.data2;
                    for (var i = 0; i < this.aux.length; i++) {
                        var con = this.aux[i];
                        this.tercero = con.id;
                    }
                } else {
                    this.data2 = [];
                    this.data2.push(res)
                    this.aux = this.data2;
                    for (var i = 0; i < this.aux.length; i++) {
                        var con = this.aux[i];
                        this.tercero = con.id;
                    }
                }
                this.hideloading();
            }, error => {
                this.load = false;
            });
        } catch {
            this.load = false;
        }
    }

    showloading() {
        this.load = true
    }
    hideloading() {
        this.load = false
    }

    getConsumo(t: any) {
        this.showloading()
        try {
            const headers = new HttpHeaders({
                'user': this.cookies.get("user"),
                'token': this.cookies.get("token"),
                'pass': this.cookies.get("pass"),
            });
            this.consumo = {
                "telefono": t
            }
            const url = environment.endpoint + environment.getConsumoMovil;
            this.http.post(url, this.consumo, { headers }).subscribe((res: any) => {
                this.data3 = res;
                this.opendialog(res);
                this.hideloading()
            });
        } catch {
            this.hideloading()
        }
    }
    opendialog(data: any) {
        let dialogref = this.dialog.open(DoneteComponent, { data: { Consumo: data.Consumo, Balance: data.Balance, Bono: data.Bono } });
    }

    /**
     * Evento encargado de setear cookies para pasar de página hacia acciones Móviles
     * @param peticion
     * @param activacion
     * @param tarifa
     * @param titular
     * @param dni
     * @param numero
     * @param multisim
     * @param cambio
     * @param hora
     * @param dobleGb
     * @param NextTarifa
     */

    setLinea(peticion, activacion, tarifa, titular, dni, numero, multisim, cambio, hora, dobleGb, NextTarifa, robo) {
        this.cookies.set("peticion", peticion);
        this.cookies.set("activacion", activacion);
        this.cookies.set("tarifa", tarifa);
        this.cookies.set("titular", titular);
        this.cookies.set("dni", dni);
        this.cookies.set("numero", numero);
        this.cookies.set("multisim", multisim);
        this.cookies.set("cambio", cambio);
        this.cookies.set("hora", this.timeTransform(hora));
        this.cookies.set("contratoF", this.contactoOficial);
        this.cookies.set("dobleGb", dobleGb);
        this.cookies.set("nextTarifa", NextTarifa);

        this.cookies.set("robo", robo);

        (this.cookies.get("nextTarifa") == "") ? this.loadService.cambioTarifaEnCurso = false : this.loadService.cambioTarifaEnCurso = true

        //Para info SIM
        this.cookies.set("tercero", this.tercero);

        this.router.navigate(['accioneslineamovil'], { queryParams: { con: this.linea.contratoProvisional }});
    }
    /**
     * Encargado de setear cookies y pasar de pagina hacia servicios móviles
     * @param peticion
     * @param numero
     */
    servicios(peticion, numero) {
        this.cookies.set("peticion", peticion);
        this.cookies.set("numero", numero);
        this.router.navigate(['serviciosmovil']);
    }

    ngOnDestroy(): void {

        this.dtTrigger.unsubscribe();

    }

    /**
     * Actualización del dataTable
     */
    updateDataTable() {

        var dataTable = $("#datatableMovil").dataTable();
        dataTable.DataTable().destroy
        $('#datatableMovil').DataTable({

            retrieve: true,
            search: false,
            paging: false,

        })

        this.dtTrigger.next();
    }
    /**
     * FUNCION ENCARGADA DE RETORNA LA HORA EN FORMATO LEGIBLE POR EL USUSARIO HH:MM:SS
     * @param resHours
     * @returns
     */
    timeTransform(resHours: string) {
        // return "0" + resHours.substr(0, 1) + ":" + resHours.substr(1, 2) + ":" + resHours.substr(3, 2);
        if (resHours.length > 5 && resHours.length < 7) {
            return resHours.substr(0, 2) + ":" + resHours.substr(2, 2) + ":" + resHours.substr(4, 2);
        } else {
            if (resHours.length > 4 && resHours.length < 6) {
                return "0" + resHours.substr(0, 1) + ":" + resHours.substr(1, 2) + ":" + resHours.substr(3, 2);
            } else {
                return resHours;
            }


        }


    }


    descargaCsv() {
        this.showloading()
        let contrato = this.cookies.get("coficial");
        this.contratosSvc.downloadCsvPromoVerano({ "conf": contrato }).subscribe((data: any) => {
            this.load = true
            let dataType = data.type;
            let binaryData = []
            binaryData.push(data)

            let filePath = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }))
            let downLoadLink = document.createElement("a")
            downLoadLink.href = filePath;
            downLoadLink.setAttribute("download", "Lineas_promocion_verano_" + contrato)
            document.body.appendChild(downLoadLink)
            downLoadLink.click()
            this.hideloading()
        })

    }


}
