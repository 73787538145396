import { Component, OnInit } from '@angular/core';
import { MobileComponent } from 'src/app/menus/mobile/mobile.component';
@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
    public load: Boolean = false;
    // load = true;
    //_____________________________________________________________MOSTRAMOS EL LOADING DURANTE DOS SEGUNDOS
    ngOnInit(): void {
        setTimeout(() => {
            this.load = true;
        }, 2000);
        
    }
    
    constructor(mc:MobileComponent) {
        mc.ngOnInit()
    }

    openPdf(pdfFileName: string): void {
        this.load =true;
        const pdfUrl = `assets/pdf/${pdfFileName}`;
        window.open(pdfUrl, '_blank'); // Abre el PDF en una nueva pestaña
      }
}