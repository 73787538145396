<div class="formbox">

    <div class="contentLoad" *ngIf="load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>
    <div class="cabecera">
        <label>CONTROL DE BAJAS</label>
    </div>
    <div class="divTabla" *ngIf="listBajas">
        <div id="dataTableBajas" *ngIf="listBajas" style="zoom: 90%;">

            <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" id="dt" *ngIf="listBajas">
                <thead style="font-family: MontserratBold;">

                    <tr>
                        <th>PETICION</th>
                        <th>NUMERO</th>
                        <th>ACTIVACION</th>
                        <th>BAJA</th>
                        <th>TITULAR</th>
                        <th>DNI</th>
                        <th>TARIFA</th>
                        <th>SIM</th>

                    </tr>
                </thead>
                <tbody style="font-family: MontserratRegular;text-align: center;">

                    <tr *ngFor="let linea of listBajas let i=index">


                        <td>{{linea.PETICION}}</td>
                        <td>{{linea.NUMERO}}</td>
                        <td> {{dateTransform(linea.ACTIVACION)}}</td>
                        <td> {{linea.BAJA}}</td>
                        <td>{{linea.TITULAR}}</td>
                        <td>{{linea.DNI}}</td>
                        <td>{{linea.TARIFA}}</td>
                        <td>{{linea.SIM}}</td>

                    </tr>
                </tbody>
            </table>
        </div>


    </div>
    <div style="color: red; text-align: center;">Muestra las bajas de los dos últimos meses desde la fecha actual</div>

</div>