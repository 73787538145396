import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoadingServiceService } from '../accioneslineamovil/loading-service.service';

@Component({
    selector: 'app-view-documentacion-page',
    templateUrl: './view-documentacion-page.component.html',
    styleUrls: ['./view-documentacion-page.component.css']
})
export class ViewDocumentacionPageComponent implements OnInit {
    advancedForm: FormGroup;
    loadModal: Boolean;
    data: any;
    listArchFromServe: any;
    numTelefono:number=0;
    constructor(
        private cookie: CookieService,
        private http: HttpClient,
        public loadingService: LoadingServiceService,
        private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void { }

    /**
     * Buscar una linea movil
     * @param value telefono movil
     * @returns 
     */
    findNumber(value) {
        if (value.length == 9) {
            if (value.substring(0, 1) != "7" && value.substring(0, 1) != "6") {
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'El número introducido no es válido.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                });
                return
            }
            this.showLoadModal();
            const body = {
                "telefono": value,
                "tercero": this.cookie.get("tercero")
            }
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass")
            });
            const url = environment.getDocumentationNames;
            this.showLoadModal();
            this.listArchFromServe = null;
            try {
                this.http.post(url, body, { headers }).subscribe((res: any) => {
                    this.listArchFromServe = null;
                    this.hiddeModal();
                    if (res.length) { //si hay archivos
                        this.listArchFromServe = [];
                        this.numTelefono=value;
                        res.forEach(element => {
                            this.listArchFromServe.push({ nameFile: element, base64File: null, typeFile: null, group: 'doc' })
                        });
                    } else {
                        this.listArchFromServe = null;
                        Swal.fire({
                            icon: "warning",
                            text: "El número buscado no dispone de documentacion."
                        });
                    }
                }, error => {
                    this.hiddeModal();
                    Swal.fire({
                        icon: "warning",
                        text: "El número buscado no dispone de documentacion."
                    });
                })
            } catch {
                this.hiddeModal();
            }
        }
    }

    //______________________________MODAL ________________________________
    showLoadModal() {
        this.loadModal = true;
    }
    hiddeModal() {
        this.loadModal = false;
    }

    /**
     * Método para descargar del servidor los documentos subidos
     * @param nombreArchivoConExtension 
     */
    downLoadDocumentFromServer(nombreArchivoConExtension: string) {
        let url = environment.downloadDocumentation
        const headers = new HttpHeaders({
            "user": this.cookie.get("user"),
            "token": this.cookie.get("token")
        });

        let tercero = this.cookie.get("tercero");
        const body = {
            "tercero": tercero,
            "telefono": this.numTelefono,
            "nombreDocumentoConExtension": nombreArchivoConExtension
        }

        let ext = nombreArchivoConExtension.split(".")[1];
        this.loadingService.archive = null;
        this.loadingService.showloading();
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.loadingService.hideloading();
                    if (res) {
                        switch (ext) {
                        case "jpeg":
                        case "jpg":
                            this.loadingService.archive = {
                                base64: 'data:image/jpeg;base64,' + res.fileBase64,
                                name: nombreArchivoConExtension,
                                tipo: "image"
                            }
                            break;
                        case "png":
                            // let pngBase64 = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + res.fileBase64);
                            let pngBase64 = 'data:image/jpg;base64,' + res.fileBase64;
                            this.loadingService.archive = {
                                base64: pngBase64,
                                name: nombreArchivoConExtension,
                                tipo: "image"
                            }
                            break;
                        case "pdf":
                            let pdfBase64 = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + res.fileBase64);
                            this.loadingService.archive = {
                                base64: pdfBase64,
                                name: nombreArchivoConExtension,
                                tipo: "pdf"
                            }
                            break;
                        }
                    } else {
                        Swal.fire({
                            icon: "warning",
                            text: res.Error
                        });
                    }
                },
                error => {
                    this.loadingService.hideloading();
                    Swal.fire({
                        icon: "warning",
                        text: error.error.messge
                    });
                })
        } catch {
            this.loadingService.hideloading();
        }
    }
    /**
     * Método para la actualizacion del array de objetos obtenidos por el servidor
     * muestra el nombre del archivo el base64 del mismo como el tipo de archivo (imagen,texto o pdf) 
     * @param fileName nombre del archivo incluido su extensión [string]
     * @param base64F archivo en base 64 formateado para su visualización [string]
     * @param typeF tipo de archivo (imagen, texto, pdf) [string]
     */
    updateListArch(fileName, base64F, typeF) {
        this.listArchFromServe.forEach(element => {
            if (element.nameFile == fileName) {
                element.base64File = base64F;
                element.typeFile = typeF;
            }
        });
    }

    /**
     * Cerrar Modal del listado de documentación
     */
    cerrarDocumentacion() {
        this.loadingService.archive = null;
    }
}