<div class="formbox">
    <div class="contentLoad" *ngIf="load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>
    <div class="cabecera">
        GESTIÓN DE RECHAZOS
    </div>
    <div class="divTabla" *ngIf="data">
        <div class="form1" style="width: 95%;">
            <div id="dataTableRechazos" *ngIf="data" style="display: flex;justify-content: space-evenly; text-align: center; zoom: 90%;">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" id="dt">
                    <thead style="font-family: MontserratBold; ">
                        <tr>
                            <th>INCIDENCIA</th>
                            <th>FECHA RECHAZO</th>
                            <th>CONTRATO</th>
                            <th>PETICION</th>
                            <th>NUMERO</th>
                            <th>CLIENTE</th>
                            <th>DNI</th>
                            <th>MOTIVO</th>
                        </tr>
                    </thead>
                    <tbody style="font-family: MontserratRegular;text-align: center;" *ngIf="data">
                        <tr *ngFor="let linea of data; let i= index">
                            <td>{{linea.incidencia}}</td>
                            <td>{{linea.fecharechazo}}</td>
                            <td>{{linea.contrato}}</td>
                            <td>{{linea.peticion}}</td>
                            <td>{{linea.numero}}</td>
                            <td>{{linea.cliente}}</td>
                            <td>{{linea.dni}}</td>
                            <td>{{linea.motivo}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
        <label>.</label>
    </div>