import { Component, OnInit } from '@angular/core';
import { SIMService } from 'src/app/services/SIM/sim.service';
import { LoadingServiceService } from '../loading-service.service';

@Component({
    selector: 'app-infor-sim-component',
    templateUrl: './infor-sim-component.component.html',
    styleUrls: ['./infor-sim-component.component.css']
})
export class InforSImComponentComponent implements OnInit {
    constructor(public simService: SIMService, public loadService: LoadingServiceService) {}
    ngOnInit(): void {
        this.simService.infoSIM();
    }
}