import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publi2',
  templateUrl: './publi2.component.html',
  styleUrls: ['./publi2.component.css']
})
export class Publi2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
