<div class="formbox">
    <div class="contentLoad" *ngIf="loadModal || repositoryService.load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>

    <!-- __________________________________________ALERTAS__________________________________________ -->
    <div class="validar" id="alerticc" *ngIf="iccres">
        <div class="textoV">
            {{iccresponse}}
        </div>
    </div>

    <div class="validar" id="alerticcdos" *ngIf="iccresdos">
        <div class="textoVII">
            {{iccresponse}}
        </div>
    </div>

    <form [formGroup]="registroForm">
        <div class="formularioCabecera">
            <table style="margin-left: 4em; margin-right: 4em; width: 100%;">
                <tbody>
                    <tr>
                        <td style="font-size: 2em; font-family: BigShouldersDisplayBold;">1 - DATOS DEL CLIENTE</td>
                        <td style="justify-content: flex-end; display: flex; align-items: center; height: 4em;">
                            <button type="button" [ngClass]="!crearOEDITAROBUSCAR ? 'botonSecundarioDisable' : 'botonSecundario'" (click)="crearUsuario()" *ngIf="!encontrado"  style="margin:1em;" title="Crear Usuario">CREAR
                                USUARIO
                            </button> 

                            <!-- __________________________EDITAR_USUARIO__________________________ -->
                            <button type="button" (click)="editarUsuario()" *ngIf="encontrado" class="botonSecundario"
                                style="margin:1em;" title="Editar Usuario"> EDITAR USUARIO
                            </button>

                            <button type="button" [ngClass]="!crearOEDITAROBUSCAR ? 'botonSecundarioDisable' : 'botonSecundario'"
                                (click)="buscarLineasMovilesV2()" title="BUSCAR LINEAS">
                                BUSCAR LINEAS
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- __________________________________________CARD_DATOS_DEL_CLIENTE__________________________________________ -->
        <div class="formularioCliente">
            <div style="width: 35%;">
                <table id="tablaContrato">
                    <tr class="maximoTamanio">
                        <td id="tdContrato">
                            <div id="divTdContrato">
                                <label class="estiloTitulosLabel">CONTRATO</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="alinearTI">
                            <div>
                                <select name="contratos" class="contratosF" [(ngModel)]="idcliente"
                                    formControlName="idcliente" (change)="selectOptionTarifa($event.target.value)"
                                    style="padding: 5px;">
                                    <option value="0">SELECCIONE UN CONTRATO</option>
                                    <option *ngFor="let d of aux" [value]="d.id + '|' + d.con + '|' + d.des">{{d.des}} -
                                        {{d.cof}} {{d.alias ? '('+d.alias+')' : '' }}</option>
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <table class="maximoTamanio">
                            <tr>
                                <th class="labelTitulosTabla">DOCUMENTO</th>
                                <th class="labelTitulosTabla">CLIENTE</th>
                            </tr>
                            <tr class="maximoTamanio">
                                <td id="tdTiponif" class="estilosTablaRadioButton">
                                    <p class="paddingLeft25">
                                        <label class="documentoCliente">
                                            <input [(ngModel)]="tiponif" type="radio" name="tiponif" value="N"
                                                formControlName="tiponif" (click)="compruebaClick()">
                                            NIF/NIE
                                        </label>
                                        <br>
                                        <br>
                                        <label class="documentoCliente">
                                            <input [(ngModel)]="tiponif" type="radio" name="tiponif" value="C"
                                                formControlName="tiponif" (click)="compruebaClick()">
                                            CIF
                                        </label>
                                        <br>
                                        <br>
                                        <label class="documentoCliente">
                                            <input [(ngModel)]="tiponif" type="radio" name="tiponif" value="P"
                                                formControlName="tiponif" (click)="compruebaClick()">
                                            OTRO
                                        </label>
                                    </p>
                                </td>
                                <td id="tdTipocliente" class="estilosTablaRadioButton">
                                    <p class="paddingLeft25">
                                        <label class="documentoCliente">
                                            <input [(ngModel)]="tipocliente" type="radio" name="tipocliente" value="R"
                                                formControlName="tipocliente" (click)="guardaTipoCliente('R')">
                                            RESIDENCIAL
                                        </label><br><br>
                                        <label class="documentoCliente">
                                            <input [(ngModel)]="tipocliente" type="radio" name="tipocliente" value="S"
                                                formControlName="tipocliente" (click)="guardaTipoCliente('S')">
                                            SOCIEDAD
                                        </label><br><br>
                                        <label class="documentoCliente">
                                            <input [(ngModel)]="tipocliente" type="radio" name="tipocliente" value="A"
                                                formControlName="tipocliente" (click)="guardaTipoCliente('A')">
                                            AUTÓNOMO
                                        </label>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </tr>
                </table>
            </div>

            <div style="width: 75%;">
                <table id="divSegundaTabla">
                    <tr>
                        <td colspan="2" class="alinearTextoCentro">
                            <div id="divTdNif">
                                <label class="estiloTitulosLabel">NIE/NIF/CIF</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style="display: flex;flex-direction: column;">
                                <input (keyup)="onNifKeyUp($event.target.value)" class="inputGeneral" type="text"
                                    name="nif" placeholder="Introduzca NIF/CIF/NIE" [maxLength]="longitudDocumento"
                                    [(ngModel)]="nif" formControlName="nif" id="docIdentity" />
                               <span *ngIf="errorCIF" [ngClass]="{'errorParpadeoTexto': errorCIF}" style="margin-top: 10px; font-size: 14px;">{{mensajeErrorCIF}}</span>
                            </div>
                        </td>
                        <td id="tdBuscarUsuario">
                            <button [ngClass]="crearOEDITAROBUSCAR ? 'botonSecundario' : 'botonSecundarioDisable'" #botonBuscarUsuario type="button"
                                 (click)="getUsuario()" [disabled]="disablebtnBuscarNif">
                                <img src="assets/img/zoom.svg" id="imagenBuscarUsuario">
                                BUSCAR USUARIO
                            </button>
                            <span *ngIf="errorBuscarLinea" [ngClass]="{'errorParpadeoTexto': errorBuscarLinea}" style="margin-top: 10px; font-size: 14px;">{{mensajeErrorBuscarLinea}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input class="inputGeneral" type="text" name="nombre" maxlength="30"
                                placeholder="NOMBRE/EMPRESA" [(ngModel)]="nombre" formControlName="nombre" />
                        </td>
                        <td>
                            <input ngx-google-places-autocomplete [options]='options' class="inputGeneral"
                                formControlName="direccion" [placeholder]="'UBICACIÓN'"
                                (onAddressChange)="AddressChange($event)" [(ngModel)]="direccion" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input class="inputGeneral" type="text" name="apellido1" maxlength="30"
                                placeholder="1º APELLIDO" [(ngModel)]="apellido1" formControlName="apellido1" />
                        </td>
                        <td>
                            <input class="inputGeneral" type="text" maxlength="30" formControlName="poblacion"
                                name="poblacion" placeholder="POBLACIÓN" value="{{this.poblacion}}"
                                [(ngModel)]="poblacion" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input class="inputGeneral" type="text" name="apellido2" placeholder="2º APELLIDO"
                                maxlength="30" [(ngModel)]="apellido2" formControlName="apellido2" />
                        </td>
                        <td>
                            <input class="inputGeneral" type="text" maxlength="30" name="provincia"
                                formControlName="provincia" placeholder="PROVINCIA" value="{{this.provincia}}"
                                [(ngModel)]="provincia" />
                        </td>
                    </tr>
                    <tr id="trFecha">
                        <td class="fondoOscuro">
                            <div id="divTdFecha">
                                <input placeholder="FECHA DE NACIMIENTO" matInput readonly [matDatepicker]="dp3"
                                    [max]="obtenerFechaMayorEdad()" [(ngModel)]="fechanac" formControlName="fechanac"
                                    name="fechanac" class="Fecha" id="dt">
                                <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                                <mat-datepicker #dp3 disabled="true"></mat-datepicker>
                            </div>
                        </td>

                        <td>
                            <input class="inputGeneral" type="text" maxlength="5" name="codigopostal"
                                value="{{this.codigopostal}}" [(ngModel)]="codigopostal" placeholder="CÓDIGO POSTAL"
                                formControlName="codigopostal" />
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </form>

    <!-- __________________________________________CABECERA-2ºFORM__________________________________________ -->
    <form [formGroup]="registrolineaform">
        <div class="formularioCabecera" style="margin-bottom: 30px;">
            <table style="margin-left: 4em; margin-right: 4em; width: 100%;">
                <tbody>
                    <tr>
                        <td style="font-size: 2em; font-family: BigShouldersDisplayBold;">2 - DATOS DE LÍNEA</td>
                        <td style="justify-content: flex-end; display: flex; align-items: center; height: 4em;">
                            <!-- __________________________CREAR_LÍNEA__________________________ -->
                            <button type="button" (click)="setLineas()" [ngClass]="!crearOEDITAROBUSCAR ? 'botonSecundarioDisable' : 'botonSecundario'" title="Crear Linea">
                                CREAR LÍNEA
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- __________________________________________CARD_DATOS_DE_LINEA__________________________________________ -->
        <div class="formularioLinea" *ngIf="crearLinea">
            <div id="divCabeceraDatosDeLinea">
                <table id="tableCabeceraDatosDeLinea">
                    <tr class="ancho100AlineadoCentro">
                        <td colspan="3">
                            <div id="tdDivCabeceraDatosDeLinea">
                                <label class="estiloTitulosLabel">LÍNEA</label>
                            </div>
                        </td>
                    <tr>
                    <tr>
                        <td>
                            <label class="tituloMB">
                                <input [(ngModel)]="portabilidad" type="radio" name="portabilidad" value="C"
                                    formControlName="portabilidad" (click)="portabilidadClick($event.target.value);">
                                CON PORTABILIDAD
                            </label>
                        </td>
                        <td>
                            <label class="tituloMB">
                                <input [(ngModel)]="portabilidad" type="radio" name="portabilidad" value="S"
                                    formControlName="portabilidad" (click)="portabilidadClick($event.target.value);"
                                    [checked]="true">
                                SIN PORTABILIDAD
                            </label>
                        </td>
                        <td>
                            <label class="tituloMB">
                                <input type="checkbox" name="checkESIM" value="E" formControlName="checkESIM"
                                    (click)="checkESim($event);" [disabled]="esimEstado">
                                ESIM
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="alinearTI" colspan="3">
                            <select name="tarifas" class="selectGeneral" [(ngModel)]="tarifa" formControlName="tarifa"
                                (change)="selectOptioncoste($event.target.value)" style="padding: 5px;">
                                <option value="0">SELECCIONE UNA TARIFA</option>
                                <option value={{t.des}} *ngFor="let t of allTarifas">
                                    Tarifa {{t.des}} | {{t.nom}}
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td class="alinearTI" colspan="3">
                            
                                <input class="inputGeneral" formControlName="iccNuevo" maxlength="19" type="text"
                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                    (input)="onICChange($event.target.value)" name="nuevasim" placeholder="INTRODUZCA SIM"
                                    *ngIf="!send" />
                                <input class="inputGeneral" name="email" formControlName="email" placeholder="INTRODUZCA MAIL" type="email" *ngIf="send">
                            
                            <div *ngIf="erroSim"style="margin-top: 10px;">
                                <span style="color: #ff0400;font-size: 14px;">La nueva SIM no es correcta o ya está registrada</span>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="portar">
                        <td class="alinearTI" *ngIf="portar" colspan="3">
                            <input class="inputGeneral" [(ngModel)]="numero" maxlength="9" name="numero"
                                formControlName="numero" placeholder="TELÉFONO MÓVIL A PORTAR" type="tel"
                                *ngIf="portar">
                        </td>
                    </tr>
                    <tr *ngIf="portar">
                        <td class="alinearTI" style="display: flex;" colspan="1">
                            <label style="margin-right: 3em;" *ngIf="portar" class="tituloMB">PREPAGO</label>
                            <input type="checkbox" id="" name="" value=""
                                (click)="checkboxClick($event.target.checked);" *ngIf="portar">
                        </td>
                        <td class="alinearTI" colspan="2">
                            <input class="inputGeneral" style="width: 100%;" type="text" *ngIf="portar" name="prepago"
                                formControlName="iccPortabilidad" placeholder="ANTIGUA SIM" maxlength="30" />
                        </td>
                    </tr>
                    <tr *ngIf="listBonusDatos">
                        <td colspan="3" class="alinearTI">
                            <select name="datos" class="selectGeneral" (change)="selectDatosBono($event.target.value)"
                                style="padding: 5px;">
                                <option value="0">SELECCIONE UN BONO DE DATOS</option>
                                <option value="{{b.lanzamiento}}" *ngFor="let b of listBonusDatos">{{b.NombreCoste}}
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr *ngIf="listBonusVoz">
                        <td colspan="3" class="alinearTI" (change)="selectVozBono($event.target.value)">
                            <select name="voz" class="selectGeneral" style="padding: 5px;">
                                <option value="0">SELECCIONE UN BONO DE VOZ</option>
                                <option value="{{b.lanzamiento}}" *ngFor="let b of listBonusVoz">{{b.NombreCoste}}
                                </option>
                            </select>
                        </td>
                    </tr>
                </table>
            </div>

            <div style="width: 70%;">
                <table id="estilosSegundaTabla">
                    <tr>
                        <td class="maximoTamanio">
                            <div id="tdDivSegundaTabla">
                                <label class="estiloTitulosLabel">DOCUMENTACIÓN ADJUNTAR</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="maximoTamanio">
                            <label class="tipoArchivo">TIPO DE ARCHIVOS ACEPTADOS: PDF, PNG, JPG</label>
                        </td>
                    </tr>
                    <tr>
                        <td class="ancho100DisplayFlex">
                            <label class="tituloMB">DOCUMENTO DE IDENTIDAD:</label>
                            <input class="archivo" type="file" name="file1" id="demo1"
                                formControlName="fileDocIdentidad" (change)="setDoc($event, 'DocIdentidad')"
                                [disabled]="!registroForm.valid">
                        </td>
                    </tr>
                    <tr>
                        <td class="ancho100DisplayFlex">
                            <label class="tituloMB">COPIA DEL CONTRATO:</label>
                            <input class="archivo" type="file" name="file2" id="demo2"
                                formControlName="fileCopiaContrato" (change)="setDoc($event, 'Copia')"
                                [disabled]="!registroForm.valid">
                        </td>
                    </tr>
                    <tr>
                        <td class="ancho100DisplayFlex">
                            <label class="tituloMB">GRABACIÓN (OPCIONAL):</label>
                            <input class="archivo" type="file" name="file3" id="demo3" formControlName="fileGrabacion"
                                (change)="setDoc($event,'grabacion')" [disabled]="!registroForm.valid">
                        </td>
                    </tr>
                    <tr>
                        <td id="tdExportarPDF">
                            <button type="button" *ngIf="portabilidad == 'C'" class="botonPDF"
                                title="Exportar Contrato En PDF" (click)="exportarContratoMovilEnPdf(null)">
                                EXPORTAR CONTRATO PDF
                            </button>
                            <span *ngIf="portabilidad == 'S'" class="spanSinPortabilidad">
                                En el caso de líneas sin portabilidad, el contrato se exportará después de haber creado
                                la línea.
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </form>

    <!-- <div style="color: rgba(255, 255, 255, 0); ">____________________</div> -->

    <div class="lineaBusqueda" *ngIf="buscarLineaService.listaLineasClienteBuscado">
        <app-buscar-lineas></app-buscar-lineas>
    </div>

</div>
<!-- <div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div> -->