import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { RepositoryDocumentationService } from 'src/app/services/repository-Documentation.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoadingServiceService } from '../loading-service.service';
@Component({
    selector: 'app-documentacion',
    templateUrl: './documentacion.component.html',
    styleUrls: ['./documentacion.component.css']
})
export class DocumentacionComponent implements OnInit, OnDestroy {
    doc1: any
    doc2: any
    doc3: any


        nombre1: string
    nombre2: string
    nombre3: string

        ext1: string
    ext2: string
    ext3: string

        subirDocumentacion = false;
    verDocumentacion = false;
    public verOpciones = true;



    public listArchFromServe: any

        formFiles: FormGroup;
    @Input() resetFormSubject: Subject < boolean > = new Subject < boolean > ();
    constructor(private formBuilder: FormBuilder, private repositoryService: RepositoryDocumentationService,
        private cookie: CookieService, public loadingService: LoadingServiceService, private http: HttpClient,
        private sanitizer: DomSanitizer
    ) {}
    ngOnDestroy(): void {

    }

        ngOnInit(): void {
        this.initForm();
        this.resetValues()

    }

    //__________________________________________CACHEAR_DOCUMENTO
        setDoc(event, t: string) {
        const file = event.target.files[0];

        let fileSizeMb = (file.size / 1024) / 1024;
        if (fileSizeMb > 5) { //SI SE EXCEDE LOS 5MB

            Swal.fire({
                    title: "ARCHIVO DEMASIADO GRANDE",
                    icon: "warning",
                    text: "El archivo supera el límite de 5Mb."
                })
                // this.loadModal = false;
            return
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);





        // this.cuartoCliente = this.cookie.get("cuarto");



        let terceroOperador = this.cookie.get("tercero");
        let telefono = this.cookie.get("numero");
        reader.onload = () => {



            switch (t) {
            case "fileDocIdentidad":

                this.doc1 = reader.result;
                this.nombre1 = file.name.split(".")[0];
                this.ext1 = file.name.split(".").pop();


                console.log("File1");
                console.log(this.doc1);
                this.uplodaDocuments(this.doc1, this.ext1, terceroOperador, telefono, this.nombre1);
                break;
            case "fileCopia":
                this.doc2 = reader.result;
                this.nombre2 = file.name.split(".")[0];
                this.ext2 = file.name.split(".").pop();
                console.log("File2");
                console.log(this.doc2);
                this.uplodaDocuments(this.doc2, this.ext2, terceroOperador, telefono, this.nombre2);
                break;
            case "fileGrabacion":
                this.doc3 = reader.result;
                this.nombre3 = file.name.split(".")[0];
                this.ext3 = file.name.split(".").pop();
                console.log("File3");
                console.log(this.doc3);
                this.uplodaDocuments(this.doc3, this.ext3, terceroOperador, telefono, this.nombre3);
                break;


            }
        };






    }





    /**
     * Método encargado de subir al servicor documentacion del cliente bien sea en formato imagen o documento pdf
     * @param docFile base64 del fichero
     * @param extFile extension del fichero sin el punto -> jpg, png, pdf
     * @param nameFile nombre del fichwero sin la extension
     * @param datosPersonales nif del cliente
     */
        async uplodaDocuments(docFile, extFile, tercero, telefono, nombreDoc) {
        let url = environment.uploadDocumentation

        this.loadingService.showloading();
        const headers = new HttpHeaders({
            "user": this.cookie.get("user"),
            "token": this.cookie.get("token")
        });


        this.loadingService.showloading();
        console.log(this.cookie.get("user"));



        const body = {
            "tercero": tercero,
            "base64Doc": docFile,
            "extensionDocumento": extFile,
            "nombreDoc": nombreDoc,
            "telefono": telefono,
        }
        console.log(body);

        // console.log(docFile);



        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.loadingService.hideloading();
                console.log(res);
                if (res == "subido Correctamente") {
                    this.loadingService.messageDocumentacion = null;
                    this.formFiles.reset();
                    Swal.fire({
                            title: "RESULTADO",
                            icon: "success",
                            text: "La operación se ha realizado correctamente."
                        })
                        // this.loadDocumentationDateNow(tercero, cuarto);
                }
            }, (error: any) => { //VALIDACION DE LA SUBIDA DE DOCUMENTOS POSIBLES ERRORES
                // console.log(error);
                this.loadingService.hideloading();
                console.log(error);
                if (error.error.message == "Falta el parametro DocumentBase64") {
                    Swal.fire({
                        title: "OPERACIÓN FALLIDA",
                        icon: "warning",
                        text: (error.error.message == "Falta el parametro DocumentBase64") ? "Es necesario añadir el archivo." : error.error.message,
                    })

                } else if (error.error.message == "Falta el parametro codigoCliente") {
                    Swal.fire({
                        title: "OPERACIÓN FALLIDA",
                        icon: "warning",
                        text: (error.error.message == "Falta el parametro codigoCliente") ? "Es necesario seleccionar un cliente." : error.error.message,
                    })

                } else {
                    Swal.fire({
                        title: "OPERACIÓN FALLIDA",
                        icon: "warning",
                        text: error.error.message,
                    })
                }
                // console.log(error);


            })
        } catch {
            this.loadingService.hideloading();
        }

    }



    /**
     * Inicialización del formulario y validación
     */
        initForm() {
        this.formFiles = this.formBuilder.group({
            'fileDocIdentidad': ['', [Validators.required]],
            'fileCopia': ['', [Validators.required]],
            'portabilidad': ['', [Validators.required]],
            'fileGrabacion': ['', [Validators.required]]
        });

    }



    /**
     * Método para devolver la fecha actual en Formato 
     * @returns DD_MM_YYYY_hh_mm_ss actual del sistema
     */
        dateNowToString() {
        let date = new Date();
        let mes = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        return date.getDate() + "_" + mes + "_" + date.getFullYear() + "_" + date.getHours() + "_" + date.getMinutes() + "_" + date.getSeconds();
    }


    /**
     * Subir documentación
     */
        uploadDocumentacion() {
            this.verDocumentacion = false;
            this.subirDocumentacion = true;
            this.verOpciones = false;
        }
        /**
         * Ver la documentación
         */
    viewDocumentacion() {
        this.verDocumentacion = true;
        this.subirDocumentacion = false;
        this.verOpciones = false;
        this.launchListDocu()

    }

    /**
     * retorna el listadom de documentos pertenecientes a un cliente alojados en el FTP
     * @returns 
     */
    launchListDocu() {
        let telefono = this.cookie.get("numero");
        let tercero = this.cookie.get("tercero");

        if (!telefono) {
            Swal.fire({

                icon: 'warning',
                text: "Debe seleccionar un teléfono."
            })

            //Si no se posee un cliente no se realizará la peticion
            return
        }




        const headers = new HttpHeaders({
            "user": this.cookie.get("user"),
            "token": this.cookie.get("token")
        });

        const body = {

            "tercero": tercero,
            "telefono": telefono
        }

        this.loadingService.showloading();
        let url = environment.getDocumentationNames


        console.log(body);

        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.loadingService.hideloading();

                this.listArchFromServe = null;
                console.log(res);
                console.log(res.length);

                if (res.length) { //si hay archivos


                    this.listArchFromServe = [];
                    res.forEach(element => {


                        switch (element.split("_")[0]) {
                        case "doc":
                            this.listArchFromServe.push({ nameFile: element, base64File: null, typeFile: null, group: 'doc' })
                            break;
                        case "contract":
                            this.listArchFromServe.push({ nameFile: element, base64File: null, typeFile: null, group: 'contract' })




                            break;
                        case "rec":
                            this.listArchFromServe.push({ nameFile: element, base64File: null, typeFile: null, group: 'rec' })

                            break;
                        default:
                            this.listArchFromServe.push({ nameFile: element, base64File: null, typeFile: null, group: 'doc' }) //esto es para la estructura que tenia el FTP

                            break;

                        }


                        // if (element.split("_")[0] == "doc" ) {

                        // }
                        // else if (element.split("_")[0] == "contract") {
                        //     this.listArchFromServe.push({ nameFile: element, base64File: null, typeFile: null, group: 'contract' })
                        // }
                        // else if (element.split("_")[0] == "contract" ) {
                        // }

                    });


                } else {
                    this.verOpciones = true
                    this.listArchFromServe = null
                    Swal.fire({

                        icon: "warning",
                        text: "No existen datos relacionados o bien no se ha seleccionado al cliente aun.. "
                    })
                }

            }, error => {
                this.loadingService.hideloading();
                this.verOpciones = true
                this.listArchFromServe = null
                Swal.fire({
                    icon: "warning",
                    text: "No se encuentran archivos en la fecha seleccionada"
                })

            })
        } catch {
            this.loadingService.hideloading();
            this.verOpciones = true
            this.listArchFromServe = null
        }
    }



    /**
     * Método para descargar del servidor los documentos subidos
     * @param nombreArchivoConExtension 
     */
    async downLoadDocumentFromServer(nombreArchivoConExtension: string) {

        let url = environment.downloadDocumentation
        const headers = new HttpHeaders({
            "user": this.cookie.get("user"),
            "token": this.cookie.get("token")
        });


        let tercero = this.cookie.get("tercero");
        let telefono = this.cookie.get("numero");

        const body = {
            "tercero": tercero,
            "telefono": telefono,
            "nombreDocumentoConExtension": nombreArchivoConExtension
        }


        // console.log(body);   
        let ext = nombreArchivoConExtension.split(".")[1];
        this.loadingService.archive = null
        this.loadingService.showloading();
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                    this.loadingService.hideloading();
                    console.log(res);
                    if (res) {

                        console.log(res.fileBase64);


                        switch (ext) {
                        case "jpeg":


                            this.loadingService.archive = {
                                base64: 'data:image/jpeg;base64,' + res.fileBase64,
                                name: nombreArchivoConExtension,
                                tipo: "image"
                            }

                        case "jpg":



                            this.loadingService.archive = {
                                base64: 'data:image/jpeg;base64,' + res.fileBase64,
                                name: nombreArchivoConExtension,
                                tipo: "image"
                            }

                            break;
                        case "png":
                            let pngBase64 = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + res.fileBase64);

                            this.loadingService.archive = {
                                base64: pngBase64,
                                name: nombreArchivoConExtension,
                                tipo: "image"
                            }

                            break;

                        case "pdf":



                            let pdfBase64 = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + res.fileBase64);

                            // Swal.fire({
                            //         html: '<iframe id="pdfiframe" src=' + "data:application/pdf;base64," + pdfBase64 + ' zoom="50%" frameborder="0" width="100%" height="100%" type="application/pdf"></iframe>'
                            //     })



                            this.loadingService.archive = {
                                base64: pdfBase64,
                                name: nombreArchivoConExtension,
                                tipo: "pdf"
                            }


                            break;


                        }


                    } else {
                        Swal.fire({
                            icon: "warning",
                            text: res.Error
                        })
                    }





                },
                error => {
                    this.loadingService.hideloading();
                    Swal.fire({
                        icon: "warning",
                        text: error.error.messge
                    })
                })
        } catch {
            this.loadingService.hideloading();
        }
    }



    /**
     * Reinicializa el componente si el padre lo ordena
     */
    resetValues() {
        this.resetFormSubject.subscribe(response => {
            if (response) {
                this.subirDocumentacion = false;
                this.verDocumentacion = false;
                this.verOpciones = true;

            }
        })
    }


}