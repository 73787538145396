<div class="formbox">

    <div class="contentLoad" *ngIf="load ">
        <img class="loading" src="assets/img/loading2.gif">
    </div>
    <div class="formularioCliente" style="width: 95%;">
        <mat-grid-list cols="21" rowHeight="2.5em" style="width: 95%;">
            <form [formGroup]="registroForm">
                <mat-grid-tile [colspan]=10 [rowspan]=1 class="clienteF">
                    <label style="font-size: 1.8em;">DATOS DEL CLIENTE</label>
                    <img *ngIf="load" style="height: 20px;width:20px;padding-top: 5px;margin-left: 20px;display: flex;"
                        src="assets/img/loading2.gif">
                </mat-grid-tile>

                <mat-grid-tile [colspan]=5 [rowspan]=1>
                </mat-grid-tile>

                <mat-grid-tile [colspan]=3 [rowspan]=1>
                    <button type="button" (click)="realizarCambio()" class="botonSecundario"
                        title="REALIZAR CAMBIO">EDITAR USUARIO
                    </button>
                </mat-grid-tile>

                <mat-grid-tile [colspan]=3 [rowspan]=1>
                    <button type="button" (click)="crearUsuario()" class="botonSecundario"
                        title="CREAR NUEVO TITULAR">CREAR USUARIO
                    </button>
                </mat-grid-tile>

                <mat-grid-tile [colspan]=4 [rowspan]=1
                    style="font-family: BigShouldersDisplayBold; background-color: #000; color: white;">
                    <label style="font-size: 1.5em;">NIE/NIF/CIF</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=17 [rowspan]=1></mat-grid-tile>

                <!-- __________________________________________NIF/CIF/NIE__________________________________________ -->
                <mat-grid-tile [colspan]=6 [rowspan]=1 class="alinearTI">
                    <input class="inputGeneral" type="text" name="nif" placeholder="Introduzca NIF/CIF/NIE"
                        maxlength="30" [(ngModel)]="nif" formControlName="nif" (ngModelChange)="nif = nif.toUpperCase()"
                        id="docIdentity" />
                </mat-grid-tile>
                <mat-grid-tile [colspan]=1 [rowspan]=5>
                </mat-grid-tile>

                <!-- __________________________________________BUSCAR_USUARIO__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1
                    style="justify-content: center; align-items: center; display: flex;">
                    <button type="button" class="botonSecundario" (click)="getUsuario()" [disabled]="true">
                        <img src="assets/img/zoom.svg" style="width: 0.8em; height: 0.8em; padding-right: 3px;">
                        BUSCAR USUARIO
                    </button>
                </mat-grid-tile>

                <!-- __________________________________________NACIONALIDAD__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <select name="tarifas" class="selectGeneral" id="paisSelect" [(ngModel)]="paisModel"
                        [ngModelOptions]="{standalone: true}" [disabled]="paisSelectedDisable">
                        <option value="0">SELECCIONE UN PAÍS</option>
                        <option value={{d.codi}} *ngFor="let d of data7">
                            {{d.desc}}
                        </option>
                    </select>
                </mat-grid-tile>

                <mat-grid-tile [colspan]=3 [rowspan]=1 style="background-color: #000; color: white;">
                    <label class="tituloMB">DOCUMENTO</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=3 [rowspan]=1 style="background-color: #000; color: white;">
                    <label class="tituloMB">CLIENTE</label>
                </mat-grid-tile>

                <!-- __________________________________________NOMBRE__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <input class="inputGeneral" type="text" name="nombre" maxlength="30" placeholder="NOMBRE/EMPRESA"
                        [(ngModel)]="nombre" formControlName="nombre" />
                </mat-grid-tile>

                <!-- __________________________________________DIRECCION__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <input ngx-google-places-autocomplete [options]='options' class="inputGeneral"
                        (onAddressChange)="addressChange($event)" [(ngModel)]="direccion" formControlName="direccion"
                        [placeholder]="'UBICACIÓN'" />
                </mat-grid-tile>

                <!-- __________________________________________TIPO_NIF__________________________________________ -->
                <mat-grid-tile [colspan]=3 [rowspan]=3 style="background-color: #808080; color: white;">
                    <p>
                        <input [(ngModel)]="tiponif" type="radio" name="tiponif" value="N" formControlName="tiponif">
                        <label class="documentoCliente">NIF/NIE</label>
                        <br>
                        <br>
                        <input [(ngModel)]="tiponif" type="radio" name="tiponif" value="C" formControlName="tiponif">
                        <label class="documentoCliente">CIF</label>
                        <br>
                        <br>
                        <input [(ngModel)]="tiponif" type="radio" name="tiponif" value="P" formControlName="tiponif">
                        <label class="documentoCliente">OTRO</label>
                    </p>
                </mat-grid-tile>

                <!-- __________________________________________TIPO_de_CLIENTE__________________________________________ -->
                <mat-grid-tile [colspan]=3 [rowspan]=3 style="background-color: #808080; color: white;">
                    <p>
                        <input [(ngModel)]="tipocliente" type="radio" name="tipocliente" value="R"
                            formControlName="tipocliente">
                        <label class="documentoCliente">RESIDENCIAL</label>
                        <br>
                        <br>
                        <input [(ngModel)]="tipocliente" type="radio" name="tipocliente" value="S"
                            formControlName="tipocliente">
                        <label class="documentoCliente">SOCIEDAD</label>
                        <br>
                        <br>
                        <input [(ngModel)]="tipocliente" type="radio" name="tipocliente" value="A"
                            formControlName="tipocliente">
                        <label class="documentoCliente">AUTÓNOMO</label>
                    </p>
                </mat-grid-tile>

                <!-- __________________________________________APELLIDO1__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <input class="inputGeneral" type="text" name="apellido1" maxlength="30" placeholder="1ºAPELLIDO"
                        [(ngModel)]="apellido1" formControlName="apellido1" />
                </mat-grid-tile>

                <!-- __________________________________________POBLACION__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <input class="inputGeneral" formControlName="poblacion" type="text" maxlength="30" name="poblacion"
                        placeholder="POBLACIÓN" value="{{this.poblacion}}" />
                </mat-grid-tile>

                <!-- __________________________________________APELLIDO2__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <input class="inputGeneral" type="text" name="apellido2" placeholder="2ºAPELLIDO" maxlength="30"
                        [(ngModel)]="apellido2" formControlName="apellido2" />
                </mat-grid-tile>

                <!-- __________________________________________PROVINCIA__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <input class="inputGeneral" type="text" formControlName="localidad" maxlength="30" name="provincia"
                        placeholder="PROVINCIA" value="{{this.provincia}}" />
                </mat-grid-tile>

                <!-- __________________________________________FECHA__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI" style="margin-top: 0.3em;">
                    <mat-form-field style="background-color: rgba(255, 255, 255, 0); width: 96%;">
                        <mat-label style="padding-left: 1.5em; font-size: 0.8em; font-family: MontserratMedium;">FECHA
                            DE NACIMIENTO
                        </mat-label>

                        <input matInput [matDatepicker]="dp3" disabled [(ngModel)]="fechanac" formControlName="fechanac"
                            name="fechanac" class="Fecha" id="dt">
                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                        <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                    </mat-form-field>
                </mat-grid-tile>

                <!-- __________________________________________CP__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <input class="inputGeneral" type="text" maxlength="30" name="codigopostal" value="{{this.postcode}}"
                        placeholder="CÓDIGO POSTAL" [(ngModel)]="cp" formControlName="cp" />
                </mat-grid-tile>


                <mat-grid-tile [colspan]=21 [rowspan]=1 class="alinearTI">
                </mat-grid-tile>

                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <label class="tituloMB">DOCUMENTACIÓN(PDF/PNG/JPG):</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=9 [rowspan]=1>
                    <input class="archivo" type="file" name="file1" id="demo1"
                        (change)="setDoc($event, 'Documentacion')" formControlName="documents">
                </mat-grid-tile>
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                </mat-grid-tile>

                <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                    <label class="tituloMB">CONTRATO DE SUBRROGACIÓN(PDF/PNG/JPG):</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=9 [rowspan]=1>
                    <input class="archivo" type="file" name="file2" id="demo2"
                        (change)="setDoc($event, 'ContratoDeSubrrogacion')" formControlName="conSub">
                </mat-grid-tile>
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <button type="button" (click)="descargarContratoSub()" class="botonPDF"
                        title="REALIZAR CAMBIO">IMPRIMIR CONTRATO DE SUBROGACION
                    </button>
                </mat-grid-tile>

                <mat-grid-tile [colspan]=21 [rowspan]=1 class="alinearTI">
                </mat-grid-tile>
            </form>
        </mat-grid-list>
    </div>
</div>