import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { BuscarLineaService } from '../buscar-linea.service';

@Component({
    selector: 'app-buscar-lineas',
    templateUrl: './buscar-lineas.component.html',
    styleUrls: ['./buscar-lineas.component.css']
})
export class BuscarLineasComponent implements OnInit {
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {

        search: false,
        paging: true,
        language: {
            url: '//cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,
        order: false
    };
    dtTrigger: Subject < any > = new Subject();
    constructor(public buscarLineaService: BuscarLineaService) {}

    ngOnInit(): void {}
}