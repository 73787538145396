import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { ContratosService } from 'src/app/services/contratos.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-export-fijo-page',
    templateUrl: './export-fijo-page.component.html',
    styleUrls: ['./export-fijo-page.component.css']
})
export class ExportFijoPageComponent implements OnInit {
    load: boolean = false;
    contratos: any
    data2
    aux
    tercero
    enbusqueda = false
    soloUnContrato = false;
    listExportaciones: any
    //====================== DATATABLE =======================================//
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        dom: 'Bfrtip',
        language: {
            url: '//cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true
    };

    dtTrigger: Subject<any> = new Subject();
    //====================END DATATABLE =====================================//

    constructor(private cookies: CookieService,
        private http: HttpClient,
        private contratosSvc: ContratosService
    ) {
        var dataTable = $("#datatablefijoexport").dataTable();
        dataTable.DataTable().destroy

    }
    ngAfterViewInit(): void {
        var dataTable = $("#datatablefijoexport").dataTable();
        dataTable.DataTable().destroy //DESTRUIMOS EL DATATABLE
        this.dtTrigger.next();
    }
    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngOnInit(): void {
        // this.getExportaciones()
        var dataTable = $("#datatablefijoexport").dataTable();
        dataTable.DataTable().destroy
        this.cargaSelectContratos()
    }




    //__________________________________LOADER ________________________
    hideloading() {
        this.load = false;
    }
    showloading() {
        this.load = true;
    }

    //__________________________END LOADER ________________________

    /**
     * Actualizacion del dataTable
     */
    updateDataTable() {
        var dataTable = $("#datatablefijoexport").dataTable();
        dataTable.DataTable().destroy
        $('#datatablefijoexport').DataTable({
            retrieve: true,
            search: false,
            paging: false
        })
        this.dtTrigger.next();
    }

    /**
     * Evento encargado de retornar las exportacion bajo un contrato seleccionado
     * @param cprovisional 
     */
    cargarContrato(cprovisional: string) {
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });

        const body = {
            'dni': this.cookies.get("user"),
            'tercero': this.cookies.get("tercero"),
            'cprovisional': cprovisional,
            // 'dni': "B54495981",
            // 'tercero': "312867",
            // 'cprovisional': "294815",
        };
        const url = environment.exportacionesMoviles;
        this.listExportaciones = null
        try {
            this.showloading();
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (res) {
                    if (res.des == "") {
                        Swal.fire({
                            title: "INFO EXPORTACIONES",
                            icon: "warning",
                            text: "El contrato seleccionado no tiene exportaciones."
                        })
                    } else {
                        this.listExportaciones = res;
                        this.updateDataTable();
                    }
                }
                this.hideloading();
            }, error => {
                this.listExportaciones = null
                Swal.fire({
                    title: "INFO EXPORTACIONES",
                    icon: "warning",
                    text: "El contrato seleccionado no tiene exportaciones."
                })
                this.updateDataTable();
                this.hideloading();
            })
        } catch {
            //___________________________________DATATABLE ________________________________//
            // this.updateDataTable();
            //________________________________END DATATABLE_________________________________-//
            this.hideloading();
        }
    }

    /**
     * Retorna la hora en formato HH:MM:SS
     * @param resHours 
     * @returns 
     */
    timeTransform(resHours: String) {
        if (resHours.length > 5 && resHours.length < 7) {
            return resHours.substr(0, 2) + ":" + resHours.substr(2, 2) + ":" + resHours.substr(4, 2)
        } else {
            if (resHours.length > 4 && resHours.length < 6) {
                return "0" + resHours.substr(0, 1) + ":" + resHours.substr(1, 2) + ":" + resHours.substr(3, 2)
            } else {
                return resHours;
            }
        }
    }
    /**
     * Retorna la fecha en formato DD-MM-AAAA
     * @param dateString 
     * @returns 
     */
    dateTransform(dateString: string) {
        const DATE_TIME_FORMAT = 'DD-MM-YYYY';
        let date = String(parseInt(dateString) + 19000000);
        let year = date.substr(0, 4);
        let month = date.substr(4, 2)
        let day = date.substr(6, 2)
        return day + "-" + month + "-" + year;
    }

    cargaSelectContratos() {
        let data = {
            "username": this.cookies.get("user"),
            "password": this.cookies.get("pass")
        }


        try {
            this.contratosSvc.getContratosFijo(data).subscribe((data: any) => {
                const array = [];
                if (Array.isArray(data)) {
                    array.push(...data);
                } else {
                    array.push(data);
                }
                this.contratos = array;
            })
        } catch {
            Swal.fire({
                title: "Error",
                icon: "error",
                text: "Los contratos no han podido ser cargados"
            })
        }
    }

    //retorna el listado a partir del contrato seleccionado
    changeContrato(contrato: any) {
        let payload = {
            "tercero": this.cookies.get("tercero"),
            "documento": this.cookies.get("doc"),
            "contrato_provisional": contrato
        }
        const headers = new HttpHeaders({
            'user': this.cookies.get("user"),
            'token': this.cookies.get("token"),
            'pass': this.cookies.get("pass"),
        });

        const url = environment.getexportFijas
        this.listExportaciones = null
        try {
            this.showloading();
            this.http.post(url, payload, { headers }).subscribe((res: any) => {
                if (res) {
                    if (res.error) {
                        Swal.fire({
                            title: "ERROR",
                            icon: "error",
                            text: "No tiene exportaciones."
                        })
                    } else {
                        this.listExportaciones = res;
                        this.updateDataTable();
                    }
                }
                this.hideloading();
            }, error => {
                this.listExportaciones = null
                Swal.fire({
                    title: "INFO EXPORTACIONES",
                    icon: "error",
                    text: "NO DISPONE DE EXPORTACIONES"
                })
                this.updateDataTable();
                this.hideloading();
            })
        } catch {
            this.hideloading();
        }

    }
}