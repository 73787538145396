<div class="formbox">

    <div class="contentLoad" *ngIf="cdrService.loading">
        <img class="loading" src="assets/img/loading2.gif">
    </div>
    <div class="cabecera">
        CDR
    </div>
    <!-- __________________________________________CARD_DATOS_DEL_CLIENTE__________________________________________ -->
    <div class="divTabla" *ngIf="cdrService.listCDR">

        <div class="contentCdr" style="width: 80%;">

            <div id="datatableCdrs" *ngIf="cdrService.listCDR" style="zoom: 90%;">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" id="dt">
                    <thead style="font-family: MontserratBold; text-align: center;">
                        <tr>
                            <th>DOCUMENTO</th>
                            <th>FECHA CREACIÓN</th>
                            <th>FECHA MODIFICACIÒN</th>
                            <th>DEFINITIVO</th>
                        </tr>
                    </thead>
                    <tbody style="font-family: MontserratRegular; text-align: center;" *ngIf="cdrService.listCDR">
                        <tr *ngFor=" let doc of cdrService.listCDR" class="itemCdr">
                            <td>
                                <div class="nameCdr" (click)="cdrService.getCdr(doc.name)">{{doc.name}}</div>
                            </td>
                            <td>
                                <div class="dateCreate">{{doc.dateCreate}}</div>
                            </td>
                            <td>
                                <div class="dateMod">{{doc.dateMod}}</div>
                            </td>
                            <td>
                                <div class="dateMod"> {{doc.definitivo=="SI"?doc.definitivo:""}}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>

</div>
<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>