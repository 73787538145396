<div class="formbox">

    <div class="contentLoad" *ngIf="load || repositoryService.load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>

    <form [formGroup]="registroForm">

        <div class="formularioCabecera">
            <table style="margin-left: 4em; margin-right: 4em; width: 100%;">
                <tbody>
                    <tr>
                        <td style="font-size: 2em; font-family: BigShouldersDisplayBold;">1 - DATOS DEL CLIENTE</td>
                        <td style="justify-content: flex-end; display: flex; align-items: center; height: 4em;">
                            <button type="button" *ngIf="!encontrado" (click)="crearUsuario()" class="botonSecundario" title="Crear Usuario">CREAR
                                USUARIO
                            </button>

                            <!-- __________________________________________EDITAR_USUARIO__________________________________________ -->
                            <button type="button" *ngIf="encontrado" (click)="editarUsuario()" class="botonSecundario" title="Editar Usuario"> EDITAR USUARIO
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>



        <div class="formularioCliente">
            <mat-grid-list cols="21" rowHeight="2.5em" style="width: 95%;">
                <mat-grid-tile [colspan]=4 [rowspan]=1 style="font-family: BigShouldersDisplayBold; background-color: #000; color: white;">
                    <label style="font-size: 1.5em;">CONTRATO</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=3 [rowspan]=1></mat-grid-tile>
                <mat-grid-tile [colspan]=4 [rowspan]=1 style="font-family: BigShouldersDisplayBold; background-color: #000; color: white;">
                    <label style="font-size: 1.5em;">NIE/NIF/CIF</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=10 [rowspan]=1></mat-grid-tile>

                <!-- __________________________________________CONTRATOS__________________________________________ -->
                <mat-grid-tile [colspan]=6 [rowspan]=1 class="alinearTI">
                    <select name="contratos" class="contratosF" [(ngModel)]="contratoModel" formControlName="contratoForm" (change)="getContratoSelected($event.target.value)">
                        <option value="0">SELECCIONE UN CONTRATO:</option>
                        <option value={{d.ContratoOficial}}|{{d.Contrato}} *ngFor="let d of aux">CONTRATO {{d.ContratoOficial}} {{d.Alias ? '('+d.Alias+')' : '' }}</option>
                    </select>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=1 [rowspan]=1>
                </mat-grid-tile>

                <!-- __________________________________________NIF/CIF/NIE__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <input class="inputGeneral" type="text" name="nif" placeholder="Introduzca NIF/CIF/NIE" [maxLength]="longitudDocumento" [(ngModel)]="nif" (ngModelChange)="nif = nif.toUpperCase()" formControlName="nif" id="docIdentity" />
                </mat-grid-tile>


                <!-- __________________________________________NACIONALIDAD__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <select name="tarifas" class="selectGeneral" formControlName="pais" id="paisSelect" [(ngModel)]="paisModel">
                        <option value="0">SELECCIONE UN PAÍS</option>
                        <option value={{d.codi}} *ngFor="let d of data7">
                            {{d.desc}}
                        </option>
                    </select>
                </mat-grid-tile>

                <!-- __________________________________________DOCUMENTO/CLIENTE__________________________________________ -->
                <mat-grid-tile [colspan]=3 [rowspan]=1 style="background-color: #000; color: white;">
                    <label class="tituloMB">DOCUMENTO</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=3 [rowspan]=1 style="background-color: #000; color: white;">
                    <label class="tituloMB">CLIENTE</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=1 [rowspan]=4>
                </mat-grid-tile>

                <!-- __________________________________________BUSCAR_USUARIO__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 style="justify-content: center; align-items: center; display: flex;">
                    <button type="button" class="botonSecundario" (click)="getUsuario(true)">
                        <img src="assets/img/zoom.svg"
                            style="width: 1em; height: 1em; padding-right: 3px; color: white;">
                        BUSCAR USUARIO
                    </button>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                </mat-grid-tile>

                <!-- __________________________________________TIPO_NIF__________________________________________ -->
                <mat-grid-tile [colspan]=3 [rowspan]=3 style="background-color: #808080; color: white;">
                    <p>
                        <label class="documentoCliente">
                            <input [(ngModel)]="tiponif" type="radio" name="tiponif" value="N" (click)="needPais('N')" formControlName="tiponif" (change)="checkNifType()">
                            NIF/NIE
                        </label>
                        <br>
                        <br>
                        <label class="documentoCliente">
                            <input [(ngModel)]="tiponif" type="radio" name="tiponif" value="C" (click)="needPais('C')" formControlName="tiponif" (change)="checkNifType()">
                            CIF
                        </label>
                        <br>
                        <br>
                        <label class="documentoCliente">
                            <input [(ngModel)]="tiponif" type="radio" name="tiponif" value="P" (click)="needPais('P')" formControlName="tiponif" (change)="checkNifType()">
                            OTRO
                        </label>
                    </p>
                </mat-grid-tile>

                <!-- __________________________________________TIPO_de_CLIENTE__________________________________________ -->
                <mat-grid-tile [colspan]=3 [rowspan]=3 style="background-color: #808080; color: white;">
                    <p>
                        <label class="documentoCliente">
                            <input type="radio" name="tipocliente" value="R" formControlName="tipocliente">
                            RESIDENCIAL
                        </label><br><br>
                        <label class="documentoCliente">
                            <input type="radio" name="tipocliente" value="S" formControlName="tipocliente">
                            SOCIEDAD
                        </label><br><br>
                        <label class="documentoCliente">
                            <input type="radio" name="tipocliente" value="A" formControlName="tipocliente">
                            AUTÓNOMO
                        </label>
                    </p>
                </mat-grid-tile>

                <!-- __________________________________________NOMBRE__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <input class="inputGeneral" type="text" name="nombre" maxlength="30" placeholder="NOMBRE/EMPRESA" [(ngModel)]="nombre" formControlName="nombre" />
                </mat-grid-tile>

                <!-- __________________________________________DIRECCION__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <input ngx-google-places-autocomplete [options]='options' class="inputGeneral" (onAddressChange)="AddressChange($event)" [(ngModel)]="direccion" formControlName="direccion" [placeholder]="'UBICACIÓN'" />
                </mat-grid-tile>

                <!-- __________________________________________APELLIDO1__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <input class="inputGeneral" type="text" name="apellido1" maxlength="30" placeholder="1º APELLIDO" [(ngModel)]="apellido1" formControlName="apellido1" />
                </mat-grid-tile>

                <!-- __________________________________________POBLACION__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <input class="inputGeneral" formControlName="poblacion" type="text" maxlength="30" name="poblacion" placeholder="POBLACIÓN" value="{{this.poblacion}}" [(ngModel)]="poblacion"/>
                </mat-grid-tile>

                <!-- __________________________________________APELLIDO2__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <input class="inputGeneral" type="text" name="apellido2" placeholder="2º APELLIDO" maxlength="30" [(ngModel)]="apellido2" formControlName="apellido2" />
                </mat-grid-tile>

                <!-- __________________________________________PROVINCIA__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <input class="inputGeneral" type="text" formControlName="provincia" maxlength="30" name="provincia" placeholder="PROVINCIA" value="{{this.provincia}}" [(ngModel)]="provincia"/>
                </mat-grid-tile>

                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                </mat-grid-tile>

                <!-- __________________________________________FECHA__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI" style="margin-top: 0.3em;">
                    <mat-form-field style="background-color: rgba(255, 255, 255, 0); width: 96%;">
                        <mat-label style="padding-left: 1.5em; font-size: 0.8em; font-family: MontserratMedium;">FECHA DE NACIMIENTO
                        </mat-label>

                        <input matInput readonly [matDatepicker]="dp3" disabled [(ngModel)]="fechanac" [max]="obtenerFechaMayorEdad()" formControlName="fechanac" name="fechanac" class="Fecha" id="dt">
                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                        <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                    </mat-form-field>
                </mat-grid-tile>

                <!-- __________________________________________CP__________________________________________ -->
                <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                    <input class="inputGeneral" type="text"v  maxlength="5" maxlength="30" name="codigopostal" value="{{this.codigopostal}}" placeholder="CÓDIGO POSTAL" formControlName="codigopostal" [(ngModel)]="codigopostal"/>
                </mat-grid-tile>

            </mat-grid-list>
        </div>
    </form>

    <form [formGroup]="registrolineaform">
        <div class="formularioCabecera">
            <table style="margin-left: 4em; margin-right: 4em; width: 100%;">
                <tbody>
                    <tr>
                        <td style="font-size: 2em; font-family: BigShouldersDisplayBold;">2 - DATOS DE LÍNEA</td>
                        <td style="justify-content: flex-end; display: flex; align-items: center; height: 4em;">
                            <button type="button" (click)="setLineas()" class="botonSecundario" title="Crear Linea" [disabled]="!registroForm.valid"> CREAR LÍNEA
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="formularioLinea">
            <mat-grid-list cols="21" rowHeight="2.5em" style="width: 95%;">

                <mat-grid-tile [colspan]=4 [rowspan]=1 style="font-family: BigShouldersDisplayBold; background-color: #000; color: white;">
                    <label style="font-size: 1.5em;">LÍNEA</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=3 [rowspan]=1></mat-grid-tile>
                <mat-grid-tile [colspan]=4 [rowspan]=1 style="font-family: BigShouldersDisplayBold; background-color: #000; color: white;">
                    <label style="font-size: 1.5em;">DOCUMENTACIÓN ADJUNTAR</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=10 [rowspan]=1></mat-grid-tile>

                <!-- __________________________________________PORTABILIDAD__________________________________________ -->
                <mat-grid-tile [colspan]=3 [rowspan]=1>
                    <input [(ngModel)]="portabilidad" type="radio" name="portabilidad" value="S" formControlName="portabilidad" [disabled]="!registroForm.valid">
                    <label class="tituloMB">CON PORTABILIDAD</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=3 [rowspan]=1>
                    <input [(ngModel)]="portabilidad" type="radio" name="portabilidad" value="N" formControlName="portabilidad" [disabled]="!registroForm.valid">
                    <label class="tituloMB">SIN PORTABILIDAD</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=1 [rowspan]=1 style="font-weight: bold;">
                </mat-grid-tile>

                <mat-grid-tile [colspan]=14 [rowspan]=1 class="alinearTI">
                    <label class="tipoArchivo">TIPO DE ARCHIVOS
                        ACEPTADOS: PDF, PNG, JPG</label>
                </mat-grid-tile>

                <!-- __________________________________________TECNOLOGIA__________________________________________ -->
                <mat-grid-tile [colspan]=3 [rowspan]=1>
                    <input type="radio" name="tecnologia" [value]="'SIP'" formControlName="tecnologia" [disabled]="!registroForm.valid">
                    <label class="tituloMB">TECNOLOGÍA SIP</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=3 [rowspan]=1>
                    <input type="radio" name="tecnologia" [value]="'TRUNK'" formControlName="tecnologia" checked="checked" [disabled]="!registroForm.valid">
                    <label class="tituloMB">TECNOLOGÍA TRUNK</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=1 [rowspan]=1 style="font-weight: bold;">
                </mat-grid-tile>

                <!-- __________________________________________D.deIDENTIDAD__________________________________________ -->
                <mat-grid-tile [colspan]=3 [rowspan]=1 class="alinearTI">
                    <label class="tituloMB">DOCUMENTO DE IDENTIDAD:</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=11 [rowspan]=1>
                    <input class="archivo" type="file" name="file1" id="demo1" (change)="setDoc($event, 'DocIdentidad')" formControlName="fileDocIdentidad" [disabled]="!registroForm.valid">
                </mat-grid-tile>

                <!-- __________________________________________TELEFONO__________________________________________ -->
                <mat-grid-tile [colspan]=6 [rowspan]=1 class="alinearTI">
                    <input class="inputGeneral" *ngIf="portabilidad == 'S'" [(ngModel)]="numero" name="numero" maxlength="9" minlength="9" oninput="this.value=this.value.replace(/[^0-9]/g,'');" placeholder="TELÉFONO FIJO A PORTAR" formControlName="numero" type="number" [disabled]="!registroForm.valid">
                </mat-grid-tile>
                <mat-grid-tile [colspan]=1 [rowspan]=1 class="alinearTI">
                </mat-grid-tile>

                <!-- __________________________________________C.delCONTRATO__________________________________________ -->
                <mat-grid-tile [colspan]=3 [rowspan]=1 class="alinearTI">
                    <label class="tituloMB">COPIA DEL CONTRATO:</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=11 [rowspan]=1>
                    <input class="archivo" type="file" name="file2" id="demo2" (change)="setDoc($event, 'Copia')" formControlName="fileCopia" [disabled]="!registroForm.valid">
                </mat-grid-tile>

                <!-- __________________________________________TARIFA__________________________________________ -->
                <mat-grid-tile [colspan]=6 [rowspan]=1 class="alinearTI">
                    <select name="tarifas" class="selectGeneral" [(ngModel)]="tarifa" formControlName="tarifa" id="tarifaSelect" [disabled]="!registroForm.valid">
                        <option value="0">SELECCIONE UNA TARIFA PLANA</option>
                        <option value="4001">TARIFA 2000 MIN NACIONAL OPE</option>
                        <option value="4002">TARIFA 1000 MIN NACIONAL OPE</option>
                        <option value="4003">TARIFA 1000 MIN FIJO MAS MÓVIL NACIONAL OPE</option>
                        <option value="4005">TARIFA 2000 MIN FIJO MAS MÓVIL NACIONAL OPE</option>
                    </select>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=1 [rowspan]=1 class="alinearTI">
                </mat-grid-tile>

                <!-- __________________________________________GRABACIÓN__________________________________________ -->
                <mat-grid-tile [colspan]=3 [rowspan]=1 class="alinearTI">
                    <label class="tituloMB">GRABACIÓN (OPCIONAL):</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=11 [rowspan]=1>
                    <input class="archivo" type="file" name="file3" id="demo3" (change)="setDoc($event,'grabacion')" formControlName="fileGrabacion" [disabled]="!registroForm.valid">
                </mat-grid-tile>

                <!-- __________________________________________BONO__________________________________________ -->
                <mat-grid-tile [colspan]=6 [rowspan]=1 class="alinearTI">
                    <select name="tarifas" class="selectGeneral" [(ngModel)]="bono" formControlName="bono" id="bonoSelect" [disabled]="!registroForm.valid">
                        <option value="0">SELECCIONE UN BONO MÓVIL</option>
                        <option value="4020">BONO MÓVIL 100 MINUTOS</option>
                    </select>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=15 [rowspan]=1>
                    <button type="button" *ngIf="portabilidad == 'S'" class="botonPDF" title="Exportar Contrato En PDF" (click)="exportarContratoFijoEnPdf('ptv_Fixed_'+apellido1+'_'+apellido2+'_'+nombre)">EXPORTAR
                        CONTRATO PDF
                    </button>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </form>
</div>

<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>
