<div class="formbox">
    <div class="contentLoad" *ngIf="load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>
    <div class="cabecera">
        CONTROL DE RIESGO - LISTADO BLOQUEADO
    </div>
    <div class="divTabla" *ngIf="data">
        <div style="width: 95%;">
            <div id="datatableBloqueado" *ngIf="data" style="display: flex;justify-content: space-evenly; zoom: 90%;">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" id="dt" *ngIf="data">
                    <thead style="font-family: MontserratBold;">
                        <tr>
                            <th>NOMBRE</th>
                            <th>TELEFONO</th>
                            <th>LIMITE</th>
                            <th>BLOQUEO SI EXCEDE EL LIMITE</th>
                            <th>BLOQUEAR/DESBLOQUEAR</th>
                            <th>BLOQUEAR</th>
                        </tr>
                    </thead>
                    <tbody style="font-family: MontserratRegular;text-align: center;">
                        <tr *ngFor="let linea of data; let i= index">
                            <td>{{linea.Tercero}}</td>
                            <td>{{linea.Telefono}}</td>
                            <td id="limiteDeRiesgo"> <input type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\.\d{2}).+/g, '$1');" name="limite" id="limite" value="{{linea.Limite}}"></td>
                            <td><input type="checkbox" name="bloqueo" id="bloqueo" (change)="bloquearLimite($event ,linea)" [checked]="(linea.Bloquear=='0')?false:true"></td>
                            <td *ngIf="linea.Boton=='D'"><button class="btnDesbloqueo btnCustom desbloquear" (click)="desbloquearLinea(linea,'desbloquear')"> DESBLOQUEAR</button></td>
                            <td *ngIf="linea.Boton!='D'"><button class="btnDesbloqueo btnCustom bloquear" (click)="desbloquearLinea(linea, 'bloqueoGeneral')"> BLOQUEO GENERAL</button><button class="btnDesbloqueo btnCustom bloquear" (click)="desbloquearLinea(linea,'bloqueoLlamadas')">BLOQUEO LLAMADAS</button></td>
                            <td><button class="guardarBtn btnCustom" (click)="guardarLimiteDeRiesgo(linea)">GUARDAR</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>