import { Component, OnInit } from '@angular/core';
import { LoadingServiceService } from '../loading-service.service';
import { SIMService } from 'src/app/services/SIM/sim.service';

@Component({
    selector: 'app-cambio-sim-component',
    templateUrl: './cambio-sim-component.component.html',
    styleUrls: ['./cambio-sim-component.component.css']
})
export class CambioSimComponentComponent implements OnInit {
    public simEsValida:boolean=false;
    constructor(
        public SIMService: SIMService, 
        public loadService: LoadingServiceService
    ) {}

    ngOnInit(): void {}

    cambioSIM(){
        this.SIMService.cambioSIM((document.getElementById('simNumber') as HTMLInputElement).value);
    }

    /**
     * Retorna el estado en el que se encuentra una SIM
     * @param value 
     */
    onICChange(value) {
        var valorICC = value;
        var num = /^([0-9]){0,19}$/;
        if (value.length == 19 && num.test(valorICC)) {
            this.SIMService.comprobarICCSIM(valorICC).then(
                (res)=>this.simEsValida=res
            );
        }
    }

}