<div class="formbox">
    <div class="cabecera">
        <label>DETALLES</label>
    </div>
    <div class="divTabla">
        <div class="container" style="width: 95%; zoom:90%;">
            <img *ngIf="load" style="height: 30px;width: 30px; padding-top: 3px;margin: auto;display: flex;" src="assets/img/loading2.gif">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="dt">
                <thead style="font-family: MontserratBold; text-transform: uppercase;">
                    <tr *ngIf="data!=null">
                        <th>Teléfono</th>
                        <th>Tipon</th>
                        <th>Tipo</th>
                        <th>Fecha</th>
                        <th>Hora</th>
                        <th>Dest</th>
                        <th>Dura</th>
                        <th>Acum</th>
                        <th>Imp</th>
                        <th>bono</th>
                        <th>cadenap</th>
                    </tr>
                </thead>
                <tbody style="font-family: MontserratRegular;text-align: center;">
                    <tr *ngFor="let c of data">
                        <td>{{c.telf}}</td>
                        <td>{{c.tipon}}</td>
                        <td>{{c.tipo}}</td>
                        <td>{{c.fecha}}</td>
                        <td>{{c.hora}}</td>
                        <td>{{c.dest}}</td>
                        <td>{{c.dura}}</td>
                        <td>{{c.acum}}</td>
                        <td>{{c.imp}}</td>
                        <td>{{c.bono}}</td>
                        <td>{{c.cadenap}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>