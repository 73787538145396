import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-gestion-rechazos-page',
    templateUrl: './gestion-rechazos-page.component.html',
    styleUrls: ['./gestion-rechazos-page.component.css']
})
export class GestionRechazosPageComponent implements OnInit, OnDestroy, AfterViewInit {
    load
    data

    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {
        dom: 'Bfrtip',
        language: {
            url: '//cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,
    };

    dtTrigger: Subject < any > = new Subject();
    constructor(private http: HttpClient, private cookie: CookieService) {}
    ngOnInit(): void {
        this.getGestionRechazos();
        var dataTable = $("#dataTableRechazos").dataTable();
        dataTable.DataTable().destroy

    }
    ngAfterViewInit(): void {
        var dataTable = $("#dataTableRechazos").dataTable();
        dataTable.DataTable().destroy //DESTRUIMOS EL DATATABLE
        this.dtTrigger.next();

    }
    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    /**
     * Evento encargado de retornar el listado de rechazos que posee un tercero
     */
    getGestionRechazos() {
        this.showloading()
        try {
            const headers = new HttpHeaders({
                'user': this.cookie.get("user"),
                'token': this.cookie.get("token"),
                'pass': this.cookie.get("pass"),
            });
            const body = {
                "codTercero": this.cookie.get("tercero"),
                // "codTercero": "208447",
                // "cprovisional": $event
            }
            this.data = null;
            const url = environment.getGestionRechazos;
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.data = [];
                this.hideloading()
                this.data = res
                //___________________________________DATATABLE ________________________________//
                this.updateDataTable();
                //________________________________END DATATABLE_________________________________-//
                // this.enbusqueda = false;
            }, error => {
                this.hideloading()
                Swal.fire({
                    icon: 'error',
                    title: 'ERROR',
                    text: 'El operador actual no dispone de rechazos.',
                    footer: '<a href="https://ptvtelecom.com/">¿Necesita ayuda?</a>',
                    background: '#F5F5F5',
                })
                this.data = null;
                // this.updateDataTable();
                // this.hideloading()
                // this.enbusqueda = false;
            });
        } catch {
            this.hideloading()
        }
    }

    showloading() {
        this.load = true
    }
    hideloading() {
        this.load = false
    }

    /**
     * Actualizacion del dataTable
     */
    updateDataTable() {
        var dataTable = $("#dataTableRechazos").dataTable();
        dataTable.DataTable().destroy
        $('#dataTableRechazos').DataTable({

            retrieve: true,
            search: false,
            paging: false
        })
        this.dtTrigger.next();
    }

    /**
     * Retorno de la fecha en formato string dd-mm-aaaa
     * @param str 
     * @returns 
     */
    dateFormat(str: String) {
        return str.substring(6, 2) + '-' + str.substring(4, 2) + '-' + str.substring(0, 4);
    }
}