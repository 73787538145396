<div class="formbox">
    <div class="contentLoad" *ngIf="loadModal ">
        <img class="loading" src="assets/img/loading2.gif">
    </div>
    <div class="container" style="width:100%;">
        <form [formGroup]="advancedForm">
            <div class="cabecera">
                FIJO - BÚSQUEDA AVANZADA
            </div>
            <div class="cabecera2">
                <input type="tel" name="telf" maxlength="9" minlength="9" class="form-control" oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="telf" (input)="findNumber($event.target.value)" placeholder="INTRODUCE UN FIJO" class="fijoInput">
            </div>
        </form>
    </div>

    <div class="formFijo" *ngIf="data">
        <div id="datatableAdvancedFijo" class="form1" style="display: flex;justify-content: space-evenly; zoom: 90%;">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" id="dt" *ngIf="data">
                <thead style="font-family: MontserratBold; text-transform: uppercase;">
                    <tr>
                        <th>Petición</th>
                        <th>Aparato</th>
                        <th>Doc</th>
                        <th>Titular</th>
                        <th>Activación</th>
                        <th>Tipo</th>
                        <th>Estado</th>
                        <th>Número</th>
                        <th>Ventana</th>
                        <th>Hora</th>
                        <th>Bloqueo</th>
                        <th>Consumo</th>
                        <th>Detalles</th>
                        <th>Servicios</th>
                    </tr>
                </thead>
                <tbody style="font-family: MontserratRegular;text-align: center;">
                    <tr>
                        <td>{{data.pet}}</td>
                        <td>{{data.apar}}</td>
                        <td>{{data.doc}}</td>
                        <td>{{data.des}}</td>
                        <td>{{dateTransform(data.activacion)}}</td>
                        <td>{{data.tipo}}</td>
                        <td>{{data.estaLi}}</td>
                        <td>{{data.telf}}</td>
                        <td>{{data.ventana}}</td>
                        <td>{{timeTransform(data.hora)}}</td>
                        <td>
                            <button *ngIf="data.boton!='B'" type="button" (click)="getBloqueo(data.boton, data.telf)" class="desbloquear">
                            </button>
                            <button *ngIf="data.boton!='D'" type="button" (click)="getBloqueo(data.boton, data.telf)" class="bloquear">
                            </button>
                        </td>
                        <td>
                            <button type="button" (click)="getConsumo(data.telf)" class="consumoB">
                            </button>
                        </td>
                        <td>
                            <button type="button" (click)="getDetalle(data.telf)" class="detalleB">
                            </button>
                        </td>
                        <td>
                            <button type="button" (click)="getServicios(data.telf)" class="ServiceB">
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>