<div class="formbox">

    <div class="contentLoad" *ngIf="load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>

    <div class="cabecera">
        MÓVIL - EXPORTACIONES
    </div>
    <div class="cabecera2">
        <select class="form-control contratoSelect" name="contratos" [disabled]="enbusqueda"
            (change)="changeContrato($event.target.value)">
            <option value="0">SELECCIONE UN CONTRATO:</option>
            <option value={{d.con}} *ngFor="let d of data2">{{d.des}} - {{d.cof}} {{d.alias ? '('+d.alias+')' : '' }}</option>
        </select>
    </div>
    <div class="divTabla" *ngIf="listExportaciones">

        <!-- DATATABLE -->
        <div *ngIf="listExportaciones" id="dataTableExportaciones">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable
                [dtOptions]="dtOptions" id="dt" *ngIf="listExportaciones">
                <thead style="font-family: MontserratBold; text-align: center; text-transform: uppercase;">
                    <tr>
                        <th>Dni</th>
                        <th>Cliente</th>
                        <th>Linea</th>
                        <th>Contrato</th>
                        <th>Operador</th>
                        <th>Mes-Dia</th>
                        <th>Hora</th>
                        <th>Estado</th>

                    </tr>
                </thead>
                <tbody style="font-family: MontserratRegular; text-align: center;">
                    <tr *ngFor="let linea of listExportaciones ">
                        <td>{{linea.dni}}</td>
                        <td>{{linea.cli}}</td>
                        <td>{{linea.num}}</td>
                        <td>{{linea.cttof}}</td>
                        <td>{{(linea.ope)?linea.ope:'NONE'}}</td>
                        <td>{{dateTransform(linea.vent)}}</td>
                        <td>{{timeTransform(linea.hora)}}</td>
                        <td *ngIf="linea.est=='E'" class="ejecutada">EJECUTADA</td>
                        <td *ngIf="linea.est=='P'" class="planificada">PLANIFICADA</td>
                        <td *ngIf="linea.est!='P' &&  linea.est!='E' " class="noejecutada">NO EJECUTADA</td>

                    </tr>
                </tbody>
            </table>


        </div>
        <!-- END DATATABLE -->
    </div>














</div>