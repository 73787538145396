<div class="menu">
    <nav>
        <button class="uno" routerLink="/publi1">TARIFAS EXCLUSIVAS</button>
        <button class="dos" >ZAPI</button>
        <button class="tres" routerLink="/publi2">CABLES</button>
        <button class="cuatro" routerLink="/publi3">MARCA BLANCA ORANGE</button>
    </nav>
</div>

<div class="contenido">

</div>

<div class="containerRedesSociales">
    <a target="_blank" href="https://twitter.com/PTVtelecom_com"><img src="assets/img/RedesSociales/ico1W_TT.svg"
            class="logoRS"></a>
    <a target="_blank" href="https://www.facebook.com/PTVTelecom/"><img src="assets/img/RedesSociales/ico2W_FA.svg"
            class="logoRS"></a>
    <a target="_blank" href="https://www.instagram.com/ptvtelecom/?hl=es"><img
            src="assets/img/RedesSociales/ico3W_IN.svg" class="logoRS"></a>
    <a target="_blank" href="https://www.youtube.com/channel/UCJyzDx6ExVS3nGPAuL8U5UA"><img
            src="assets/img/RedesSociales/ico4W_YO.svg" class="logoRS"></a>
</div>
