import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class GuardserviceGuard implements CanActivate {
    constructor(private auth: AuthService, private route: Router) {}

    //_____________________________________________________________AUTENTICACIÓN PARA NO ACCEDER A PAGINAS
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable < boolean | UrlTree > | Promise < boolean | UrlTree > | boolean | UrlTree {
        //________________login  correcto_________________
        if (this.auth.isAuthenticated()) {
            return true;
        }
        //---------No puede acceder a la ruta lo redirigimos al login 
        this.route.navigate(['']);
        return false;
    }


}