import { Component, OnInit,ViewChild } from '@angular/core';
import { SuscriptoresService } from 'src/app/services/suscriptores.service';
import { CookieService } from 'ngx-cookie-service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { PeticionServiceService } from 'src/app/services/peticion-service.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-lista-albaranes',
  templateUrl: './lista-albaranes.component.html',
  styleUrls: ['./lista-albaranes.component.css']
})
export class ListaAlbaranesComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  
  listado:any= [];
  albaran:any=null;
  load:boolean=false

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(public suscriptorSvc:SuscriptoresService,
    private cookie: CookieService, 
    private funcionesSvc:PeticionServiceService,
    ) {}

  ngOnInit(): void {

    this.getLista();

    this.dtOptions = {
      dom: 'Bfrtip',
      language: {
          url: '//cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
      },

    }; 
    
  }

  async getLista(){
    this.loadSpiner();

    let url=environment.listarAlbaranes
    let  param={
      "id_operador":this.cookie.get("tercero")
    }

   await this.funcionesSvc.peticionMarca(url,"post",{},param).then((data:any)=> {
  
    this.listado = data.albaranes 

   }).catch((error) => {
    Swal.fire({
      title:'Error',
      icon:'error',
      text:'error al cargar albaranes',
      showCancelButton: false,
      confirmButtonColor: '#00b1aa',
      confirmButtonText: 'Confirmar',
    })
   });

    this.dtTrigger.next();
    this.loadSpiner()
  }

  loadSpiner(){
    this.load=!this.load
  }
 
 async descargarAlbaran($id){

    this.loadSpiner();

    let url=environment.descargarAlbaran
    let  param={
      "id_operador":this.cookie.get("tercero"),
      "nombre_fichero": $id
    }

   await this.funcionesSvc.peticionMarca(url,"post",{},param).then((data:any)=> {
  
    this.albaran = data.file;
    const base64 = this.albaran;
    const fileName = $id;
    const fileBlob = this.dataURItoBlob(base64);
    const imageFile = new File([fileBlob], fileName, { type: 'zip' });
    let filePath=window.URL.createObjectURL(imageFile);
    let downLoadLink=document.createElement("a");
    downLoadLink.href=filePath;
    downLoadLink.setAttribute("download",$id);
    document.body.appendChild(downLoadLink);
    downLoadLink.click();

   }).catch((error) => {
    Swal.fire({
      title:'Error',
      icon:'error',
      text:'error al descargar albaran',
      showCancelButton: false,
      confirmButtonColor: '#00b1aa',
      confirmButtonText: 'Confirmar',
    })
   });

    this.loadSpiner()
  }


  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });    
    return blob;
 }

}