<div class="formbox">
    <div class="contentLoad" *ngIf="load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>

    <div class="cabecera">
        CONTROL DE RIESGO - LISTADO
    </div>
    <div class="cabecera2">
        <select class="form-control contratoSelect" name="contratos" (change)="getlineas($event.target.value)">
            <option value="0">SELECCIONE UN CONTRATO:</option>
            <option value={{d.con}} *ngFor="let d of data2">{{d.des}} - {{d.cof}} {{d.alias ? '('+d.alias+')' : '' }}</option>
        </select>
    </div>

    <div class="divTabla" *ngIf="data">
        <div style="width: 95%;"></div>
        <div id="datatableRiesgo" *ngIf="data" style="display: flex;justify-content: space-evenly;zoom: 90%;">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" id="dt" *ngIf="data">
                <thead style="font-family: MontserratBold;">
                    <tr>
                        <th>NOMBRE</th>
                        <th>TELEFONO</th>
                        <th>LIMITE</th>
                        <th>BLOQUEO SI EXCEDE EL LIMITE</th>
                        <th>BLOQUEAR</th>
                        <th>GUARDAR</th>
                    </tr>
                </thead>
                <tbody style="font-family: MontserratRegular;text-align: center;">
                    <tr *ngFor="let c of data; let i= index">
                        <td>{{c.Tercero}}</td>
                        <td>{{c.Telefono}}</td>
                        <td id="limiteDeRiesgo"> <input type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\.\d{2}).+/g, '$1');" name="limite" id="limite" value="{{c.Limite}}"></td>
                        <td><input type="checkbox" name="bloqueo" id="bloqueo" (change)="bloquearLimite($event ,c)" [checked]="(c.Bloquear=='0')?false:true"></td>
                        <td *ngIf="c.Boton=='D'"><button class="btnDesbloqueo btnCustom desbloquear" (click)="desbloquearLinea(c,'desbloquear')"> DESBLOQUEAR</button></td>
                        <td *ngIf="c.Boton!='D'"><button class="btnDesbloqueo btnCustom bloquear" (click)="desbloquearLinea(c, 'bloqueoGeneral')"> BLOQUEO GENERAL</button><button class="btnDesbloqueo btnCustom bloquear" (click)="desbloquearLinea(c,'bloqueoLlamadas')">BLOQUEO LLAMADAS</button></td>
                        <td><button class="guardarBtn btnCustom" (click)="guardarLimiteDeRiesgo(c)">GUARDAR</button>
                        </td>
                        <!-- <td><button class="bloqueobtn">{{(data.Bloquear==1)?'DESBLOQUEAR':'BLOQUEAR'}}</button></td> -->

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>
