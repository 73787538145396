import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ModalService } from '../modal.service';

@Component({
    selector: 'app-modal-coincidencias',
    templateUrl: './modal-coincidencias.component.html',
    styleUrls: ['./modal-coincidencias.component.css']
})
export class ModalCoincidenciasComponent implements OnInit {

    @Input()
    public modalService: ModalService;

    constructor() {}
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions = {

        search: false,
        paging: true,
        language: {
            url: '//cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
        },
        retrieve: true,
        order: false
    };

    dtTrigger: Subject < any > = new Subject();
    ngOnInit(): void {}

    /**
     * Cierre del modal
     */
    cerrarModal() {
        this.modalService.listCoincidencias = null;
    }
}