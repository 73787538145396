<div class="formbox">
    <div class="cabecera">
        FIJO - CONSUMO
    </div>
    <div class="divTabla">
        <div class="container" style="width: 95%; zoom:90%;">
            <img *ngIf="load" style="height: 30px;width: 30px; padding-top: 3px;margin: auto;display: flex;" src="assets/img/loading2.gif">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="dt">
                <thead style="font-family: MontserratBold;">
                    <tr *ngIf="data!=null">
                        <th>TIPO DE LLAMADA</th>
                        <th>CONSUMO</th>
                        <th>PLANES</th>
                    </tr>
                </thead>
                <tbody style="font-family: MontserratRegular; text-align: center;">
                    <tr *ngFor="let c of data">
                        <td>{{c.TIPO_LLAMADA}}</td>
                        <td>{{c.CONSUMO}}</td>
                        <td>{{c.PLANES}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>

<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>