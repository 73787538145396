
<div id="header">
    <div id="header-left">
        <h1>Diagnóstico de ONT</h1>
    </div>
    <div id="header-right">
        <button  *ngIf="!loadModal" mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>
</div>
<div id="content">
    <div class="contentLoad" *ngIf="loadModal">
        <img class="loading" src="assets/img/loading2.gif">
    </div>
    <div *ngIf="!loadModal">
        <button id="restart" class="header-button" (click)="accion('ont_restart')">Reiniciar ONT</button>
        <button id="full_restart" class="header-button" (click)="accion('ont_full_restart')">Reiniciar de fábrica ONT</button>
        <button id="status" class="header-button" (click)="accion('ont_status')">Comprobar Estado ONT</button>
        <button id="niveles" class="header-button" (click)="comprobarNiveles()">Comprobar Niveles ONT</button>
    </div>
</div>

