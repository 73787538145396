import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';
import { AsignaTecnicosService } from 'src/app/services/asigna-tecnicos.service';
import { PeticionServiceService } from 'src/app/services/peticion-service.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-modal-asigna-horas',
  templateUrl: './modal-asigna-horas.component.html',
  styleUrls: ['./modal-asigna-horas.component.css']
})
export class ModalAsignaHorasComponent implements OnInit {
  listDias:any=[]
  diaSeleccionado:any
  listadoHoras:any
  incidencia:any
  horaSeleccionada:any
  muestraAgendada:any=false
  horaAgendada:any
  fechaAgendada:any
  constructor(
    public dialogRef:MatDialogRef<ModalAsignaHorasComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    public peticionMBSvc:PeticionServiceService,
    public asignaTec:AsignaTecnicosService

  ) { 
    this.muestraAgendada=this.data.asignaHoras
  }
  
  ngOnInit(): void {//ESTE MODAL MOSTRARA O  LA FECHA Y HORA DE CUANDO ESTA AGENDADA LA INCIDENCIA O EL FORMULARIO PARA AGENDAR
    this.listaSemana()
    
     this.horaAgendada=this.data.hora.substring(0,2)+":"+this.data.hora.substring(2,4)+":"+this.data.hora.substring(4,6);
     this.fechaAgendada=this.data.fecha.substring(6,9)+"/"+this.data.fecha.substring(4,6)+"/"+this.data.fecha.substring(0,4);

  }




  async obteneTramo(){
    await this.asignaTec.obtenerTramo(this.diaSeleccionado).then((data:any)=>{
      
      this.listadoHoras=data.Tramo
      this.listadoHoras=this.listadoHoras.filter(hora=>hora.Disponible!="NO")
    }).catch((err:any)=>{
      console.log(err);
      
    })
  }

  listaSemana(){
    let hoy=new Date()
    for (let i = 0; i < 7; i++) {
      this.listDias.push(moment(hoy).add(i,"d"))
    }
    
  }


  getFechaSeleccionada(valor){
    this.diaSeleccionado=valor;
    this.obteneTramo()
    this.horaSeleccionada=""
  }


  getHoraSeleccionada(valor){
    this.horaSeleccionada=valor;
  }


  async agendar(){    
    let hora=this.horaSeleccionada.split(":")[0]+this.horaSeleccionada.split(":")[1]+"00"
    let fecha=this.diaSeleccionado.split("-")[0]+this.diaSeleccionado.split("-")[1]+this.diaSeleccionado.split("-")[2]
    await this.asignaTec.asignaHora(this.data.incidencia,fecha,hora).then((data:any)=>{
      Swal.fire({
        icon: 'success',
        title: 'Cita agendada',
        text: data.mensaje,
      })
    }).catch((err:any)=>{
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: err.error.mensaje
      })
       
    })    
  }

}
