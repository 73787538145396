import { Injectable } from '@angular/core';
import { PeticionServiceService } from './peticion-service.service';
import { environment } from 'src/environments/environment';
import { resolve } from 'dns';
import { promise } from 'protractor';


@Injectable({
  providedIn: 'root'
})
export class AsignaTecnicosService {

  constructor(private funcionesSvc:PeticionServiceService) { }

  empleado:any=null
  delegacion:any=null
  zona:any=null
  incidencia:any=null

  async obtenerTecnico(data){
    let headers={}
    let promise=new Promise((resolve,reject)=>{
      this.funcionesSvc.peticionMarca(environment.obtenerTecnico,"post",headers,data).then((data:any)=>{
        this.empleado=data.Tecnico.Empleado;
        this.delegacion=data.Tecnico.Delegacion;
        this.zona=data.Tecnico.Zona;
        resolve(data)
      }).catch((err:any)=>{
        console.log(err);
        reject(err)
      })
    })

    return promise;
  }


  async obtenerTramo(fecha) {
    let header={}
    let datos={
      "tecnico":this.empleado,
      "fecha":fecha
    }
      let promise=new Promise((resolve,reject)=>{
        this.funcionesSvc.peticionMarca(environment.obtenerTramos,"post",header,datos).then((data:any)=>{
          resolve(data)  
        }).catch((err:any)=>{
          reject(err)
        })
      })
      return promise;
  }



  async asignaHora(incidencia,fecha,hora){
    let header={}
    let datos={
      "incidencia": incidencia,
      "fecha": fecha ,
      "hora": hora
    }

    let promise=new Promise((resolve,reject)=>{
      this.funcionesSvc.peticionMarca(environment.asignarCita,"post",header,datos).then((data:any)=>{
        resolve(data)
      }).catch((err:any)=>{
        reject(err)
      })
    })

    return promise;
  }
}
