import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { PeticionServiceService } from 'src/app/services/peticion-service.service';
import { MatDialog } from '@angular/material/dialog';
import { WrongPassportModalComponent } from '../wrong-passport-modal/wrong-passport-modal.component';

export const MY_FORMATS = {
  parse: {
      dateInput: 'LL'
  },
  display: {
      dateInput: 'DD-MM-YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY'
  }
};
@Component({
  selector: 'app-create-subcription-white-brand',
  templateUrl: './create-subcription-white-brand.component.html',
  styleUrls: ['./create-subcription-white-brand.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class CreateSubcriptionWhiteBrandComponent implements OnInit, AfterViewInit {
  public static GETTARIFA=3000;
  public static TARIFAS=4000;
  public static TIPO_DOCUMENTO=4001;
  public static TIPO_TERCERO=4002;
  public static PAIS_ORIGEN=4003;
  private accessToken: string;
  private refreshToken: string;
  private codigos;
  private tipo_obj;
  private cod_obj;
  private idContrato;
  private ultimoEscrito={};
  private cambioTipoDoc: boolean=false;
  startDate = new Date(1990, 0, 1);
  datosDireccion; 
  esExtrangero: boolean;
  esEmpresarial: boolean;
  paisValido: boolean=true;
  actividadEscrita: boolean;
  textSelect: string;
  listPedidos; 
  listTipoCliente;
  tipoVia;
  whiteBrandForm;
  paises=[];
  carga: boolean=false;
  textoCarga:string;
  isCIF:boolean=false;
  bis:boolean=false;
  private wrongPassportModalRef:any;
  
  @ViewChild('divPrincipal') divPrincipalElemento: ElementRef;
  @ViewChild('documentoInput') documentoInputElemento: ElementRef;
  @ViewChild('tipoDocumento') tipoDocumentoElemento: ElementRef;
  @ViewChild('email') emailInputElemento: ElementRef;
  @ViewChild('telefono') telefonoInputElemento: ElementRef;
  @ViewChild('nombre') nombreInputElemento: ElementRef;
  @ViewChild('primerApellido') primerApellidoInputElemento: ElementRef;
  @ViewChild('segundoApellido') segundoApellidoInputElemento: ElementRef;
  @ViewChild('seleccionTarifa') seleccionTarifaElemento: ElementRef;
  @ViewChild('seleccionTipoCliente') seleccionTipoClienteElemento: ElementRef;
  @ViewChild('fechaNacimiento') fechaNacimientoInputElemento: ElementRef;

  constructor(
    private route: Router,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private cookie: CookieService,
    private miDatePipe: DatePipe,
    private marcaBlanca : PeticionServiceService,
    private dialog: MatDialog,
  ) { 
    this.listTipoCliente=[
      {
        "valor":"R", 
        "texto":"Residencial"
      },
      {
        "valor":"S", 
        "texto":"Sociedad"
      },
      {
        "valor":"A", 
        "texto":"Autónomo"
      }
    ];
    if(this.route.getCurrentNavigation().extras.state!=undefined){
      this.datosDireccion = this.route.getCurrentNavigation().extras.state[0];      
      this.accessToken = this.route.getCurrentNavigation().extras.state[1];
      this.codigos = this.route.getCurrentNavigation().extras.state[2];
    }else this.route.navigateByUrl('/create-account-white-brand');
  }

  ngAfterViewInit(): void {
    this.comprobarDoc(this.documentoInputElemento.nativeElement);
    this.comprobarEmail(this.emailInputElemento.nativeElement);
    this.comprobarTelefono(this.telefonoInputElemento.nativeElement);
    this.compruebaInputEscrito(this.nombreInputElemento.nativeElement);
    this.compruebaInputEscrito(this.primerApellidoInputElemento.nativeElement);
    this.compruebaInputEscrito(this.segundoApellidoInputElemento.nativeElement);
    this.compruebaInputEscrito(this.seleccionTipoClienteElemento.nativeElement);
    this.divPrincipalElemento.nativeElement.offsetParent.scrollTop=0;
    //Le asignamos color rojo a la linea del datepicker
    this.fechaNacimientoInputElemento.nativeElement.offsetParent.offsetParent.childNodes[1].style.background='red';
  }

  ngOnInit(): void {
    this.esExtrangero=false;
    this.textSelect='Seleccione la tarifa deseada.';

    this.getPais();
    this.whiteBrandForm = this.formBuilder.group({
      'tarifa': [''],
      'tipoDocumento': ['', [Validators.required]],
      'documentoInput': ['', [Validators.required]],
      'nombre': ['', [Validators.required]],
      'telefono': ['', [Validators.required]],
      'primerApellido': [''],
      'segundoApellido': [''],
      'email': ['', [Validators.required, Validators.email]],
      'fechaNacimiento': [''],
      'pais': [''],
      'tipoCliente': ['', [Validators.required]],
      'aclarador': [''],
      'codActividad': [''],
      'tlf2':[''],
      'nombreApoderado':[''],
      'nifApoderado':[''],
      'notaria':[''],
      'protocolo':[''],
      'fPoder':[''],
      'tvivienda':[''],
      'bloque':[''],
      'codPostal':[''],
      'internet':[''],
      'television':[''],
      'telefonoServicio':[''],
      'zapi':[''],
      "apellido1Portabilidad":[''],
      "apellido2Portabilidad":[''],
      "documentoPortabilidad":[''],
      "numeroPortabilidad":[''],
      "nPortabilidad":[''],
    });
  }


  /**
   * Se encarga de iniciar los valores para poder trabajar con la API bitstream.
   * También llama al getTarifas para extraer valores iniciales necesarios.
   */
  initEnpoint(codigo: number){
    this.http.post(environment.loginBitstream, {
      "email": "ptvtelecom@ptvtelecom.com",
      "password": "tZ516$"
    }).subscribe((res: any)=>{
      this.accessToken = res.access_token;
      this.refreshToken = res.refresh_token;

    },(error)=>{
      console.error(error);
    });
  }

  /**
   * Hace una llamada a un enpoint que devuelve los paises
   */
  getPais(){
    const headers = {
      'user': this.cookie.get("user"),
      'token': this.cookie.get("token"),
      'pass': this.cookie.get("pass"),
    };

    this.http.post(environment.endpoint + environment.getPais, {}, {headers}).subscribe((res: any)=>{
      this.paises=res;
    },(error)=>{
      console.error(error.error);
    });
  }
  
  seleccionado(codigo, valor){  
    switch(codigo){
      case CreateSubcriptionWhiteBrandComponent.TARIFAS:
        var valores=valor.split('|');
        this.tipo_obj=valores[0];
        this.cod_obj=valores[1];
        break;
      case CreateSubcriptionWhiteBrandComponent.TIPO_DOCUMENTO:
        this.cambioTipoDoc=true;
        this.comprobarDoc(this.documentoInputElemento.nativeElement);
        this.whiteBrandForm.controls['pais'].setValue('34|ESPAÑA');
        this.whiteBrandForm.controls['tipoCliente'].setValue('');
        this.compruebaInputEscrito(this.seleccionTipoClienteElemento.nativeElement);
        this.whiteBrandForm.controls['codActividad'].setValue('');
        this.esExtrangero=valor=='NIE';     
        this.paisValido=valor!='NIE';
        if(!this.paisValido)this.paisValido=this.whiteBrandForm.controls['pais'].value=='';
        this.isCIF=valor==='C';
        this.esEmpresarial=false;  
        this.actividadEscrita=true;
        break;
      case CreateSubcriptionWhiteBrandComponent.TIPO_TERCERO:
        this.whiteBrandForm.controls['codActividad'].setValue('');
        this.esEmpresarial=valor=='S';  
        this.actividadEscrita=valor!='S';
        break;
      case CreateSubcriptionWhiteBrandComponent.PAIS_ORIGEN:
        var valores=valor.split('|');
        this.paisValido=Number.parseInt(valores[0])>=0;
        break;
    }
  }

  /**
   * Finaliza la suscripción con los datos adquiridos.
   */
  finalizarSuscripcion(){
    if(this.comprobarDoc(this.documentoInputElemento.nativeElement)&&this.comprobarEmail(this.emailInputElemento.nativeElement)){
      this.textoCarga='Creando contrato...';
      this.mostrarCarga();
      const headers={
        "accept": "application/json",
        "Authorization": "Bearer "+this.accessToken,
        'Content-Type': 'application/json' 
      };
      this.http.post(environment.contratoNuevoBitstream, {
        "gescal37": "",
        "bitstream": false,
        "tipoDoc": this.whiteBrandForm.controls['tipoDocumento'].value=='NIE'?'N':this.whiteBrandForm.controls['tipoDocumento'].value,
        "documento": this.whiteBrandForm.controls['documentoInput'].value.toUpperCase().trim(),
        "nombre": this.whiteBrandForm.controls['nombre'].value.trim(),
        "apellido1": this.whiteBrandForm.controls['primerApellido'].value.trim(),
        "apellido2": this.whiteBrandForm.controls['segundoApellido'].value.trim(),
        "telefono": this.whiteBrandForm.controls['telefono'].value.trim(),
        "email": this.whiteBrandForm.controls['email'].value.trim(),
        "fechaNacimiento": this.miDatePipe.transform(this.whiteBrandForm.controls['fechaNacimiento'].value, 'yyyyMMdd'),
        "tipoTercero": this.whiteBrandForm.controls['tipoCliente'].value,
        "codPais": this.whiteBrandForm.controls['pais'].value.split('|')[0]>=0?this.whiteBrandForm.controls['pais'].value.split('|')[0]:0,
        "codActividad": this.whiteBrandForm.controls['codActividad'].value==''?0:this.whiteBrandForm.controls['codActividad'].value,
        "provincia": this.codigos.codProvincia,
        "municipio": this.codigos.codMunicipio,
        "calle": this.codigos.codCalle,
        "finca": this.datosDireccion.numero,
        "portal": this.datosDireccion.portal,
        "escalera": this.datosDireccion.escalera==''?0:this.datosDireccion.escalera,
        "local": this.datosDireccion.local==''?0:this.datosDireccion.local,
        "planta": this.datosDireccion.planta==''?0:this.datosDireccion.planta,
        "puerta": this.datosDireccion.puerta,
        "aclarador": this.whiteBrandForm.controls['aclarador'].value.trim(),
        "tercero":this.cookie.get('tercero')
      }, { headers }).subscribe((res:any)=>{
        this.ocultarCarga();
        if(res.status){
          this.textoCarga=res.message;
          this.idContrato=res.id_contrato;
          this.subirPedidos();
        }else 
          Swal.fire({
            title: 'ERRORES ENCONTRADOS',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#00b1aa',
            confirmButtonText: 'Confirmar',
            html: res.message
          });
      },(error)=>{
        this.ocultarCarga();
        if(error.error.message=='Errores de validacion') this.mensajeErrores(error);
        else Swal.fire({
          title: 'Error',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#00b1aa',
          confirmButtonText: 'Confirmar',
          html: error.error.message
        });
        console.error(error);
      });
    }else{
      var errorView='<ul class="errorView">';
      if(!this.comprobarDoc(this.documentoInputElemento.nativeElement)) errorView +='<li>El documento no es valido</li>';
      if(!this.comprobarEmail(this.emailInputElemento.nativeElement)) errorView +='<li>El email no es valido</li>';
      errorView += '</ul>';
      Swal.fire({
        title: 'Error de validacion',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Confirmar',
        html: errorView
      });
    }
  }

  /**
   * Sube la tarifa seleccionada
   */
  subirPedidos(){
    this.textoCarga='Subiendo pedidos...'
    const headers={
      "accept": "application/json",
      "Authorization": "Bearer "+this.accessToken,
      'Content-Type': 'application/json' 
    };
    this.http.post(environment.subirPedidosBitstream,{
        "tipo_obj": this.tipo_obj,
        "cod_obj": this.cod_obj,
        "id_contrato": this.idContrato
    }, { headers }).subscribe((res:any)=>{
      this.ocultarCarga();
      if(res.status)
        Swal.fire({
          title: res.message,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#00b1aa',
          confirmButtonText: 'Confirmar'
        }).then(()=>{
          this.route.navigateByUrl('/welcome');
        });
      else 
        Swal.fire({
          title: 'ERRORES ENCONTRADOS',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#00b1aa',
          confirmButtonText: 'Confirmar',
          html: res.message
        }).then(()=>{
          this.route.navigateByUrl('/create-account-white-brand');
        });
    },(error)=>{
      this.ocultarCarga();
      if(error.error.message=='Errores de validacion') this.mensajeErrores(error);
      else Swal.fire({
        title: 'Error',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Confirmar',
        html: error.error.message
      });
      console.error(error);
    });
  }
  /**
   * Comprueba si el documento escrito esta acorde con el tipo de documento seleccionado
   * @param dom es el elemento input que recoge el documento
   */
  comprobarDoc(dom){
    var valido: boolean;
    this.tipoDocumentoElemento.nativeElement.style.border='';
    switch (this.whiteBrandForm.controls['tipoDocumento'].value) {
      case "N":
        if (!this.isNif(this.whiteBrandForm.controls['documentoInput'].value)) dom.style.border='2px solid red';
        else dom.style.border='2px solid green';
        valido=this.isNif(this.whiteBrandForm.controls['documentoInput'].value);
        if(this.cambioTipoDoc){
          this.listTipoCliente=[
            {
              "valor":"R", 
              "texto":"Residencial"
            },
            {
              "valor":"A", 
              "texto":"Autónomo"
            }
          ];
          this.cambioTipoDoc=false;
        }
        break;
      case "NIE":
        if (!this.isNie(this.whiteBrandForm.controls['documentoInput'].value)) dom.style.border='2px solid red';
        else dom.style.border='2px solid green';
        valido=this.isNie(this.whiteBrandForm.controls['documentoInput'].value);
        if(this.cambioTipoDoc){
          this.listTipoCliente=[
            {
              "valor":"R", 
              "texto":"Residencial"
            },
            {
              "valor":"A", 
              "texto":"Autónomo"
            }
          ];
          this.cambioTipoDoc=false;
        }
        break;
      case "P":
        let pasaporte=this.whiteBrandForm.controls['documentoInput'].value;
        dom.style.border='2px solid red'
          valido=!this.validaCif(pasaporte) && !this.isNie(pasaporte) && !this.isNif(pasaporte);
          if(this.cambioTipoDoc){
            this.listTipoCliente=[
              {
                "valor":"R", 
                "texto":"Residencial"
              },
            ];
            this.cambioTipoDoc=false;
          }
          break;
      case "C":
          if (!this.validaCif(this.whiteBrandForm.controls['documentoInput'].value))dom.style.border='2px solid red';
          else dom.style.border='2px solid green';
          valido=this.validaCif(this.whiteBrandForm.controls['documentoInput'].value);
          if(this.cambioTipoDoc){
            this.listTipoCliente=[
              {
                "valor":"S", 
                "texto":"Sociedad"
              },
            ];
            this.cambioTipoDoc=false;
          }
          break;
      default:
        this.tipoDocumentoElemento.nativeElement.style.border='2px solid red';
        this.listTipoCliente=[
          {
            "valor":"R", 
            "texto":"Residencial"
          },
          {
            "valor":"A", 
            "texto":"Autónomo"
          },
          {
            "valor":"S", 
            "texto":"Sociedad"
          },
        ];
        break;
    }
    return valido;
  }

  /**
   * Comprueba si el email es valido, en caso de no ser valido pinta el borde del input en rojo
   * si es valido lo pinta en verde
   */
  comprobarEmail(dom){
    if(/^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.whiteBrandForm.controls['email'].value)){ 
      dom.style.border='2px solid green';
      return true;
    }else{
      dom.style.border='2px solid red';
      return false;
    }
  }

  /**
   * Comprueba si el teléfono introducido es valido
   */
  comprobarTelefono(dom){
    if(/[0-9]{9}/.test(this.whiteBrandForm.controls['telefono'].value)){ 
      dom.style.border='2px solid green';
      return true;
    }else{
      dom.style.border='2px solid red';
      return false;
    }
  }

  /**
   * Comprueba si el elemento tiene valor
   */
  compruebaInputEscrito(dom){
    if(dom.id=='fechaNacimiento' || dom.id=="fPoder"){
      //Icono del datepicker
      dom.parentNode.nextElementSibling.firstElementChild.style.color='black';
      //Placeholder del daterpicker
      dom.labels[0].firstElementChild.style.color='gray';
      //Underline del datepicker
      dom.offsetParent.offsetParent.childNodes[1].style.background='black';
    }else{
      if(dom.value.length){ 
        dom.style.border=dom.nodeName=='SELECT'||dom.nodeName=='TEXTAREA'?'1px solid gray':'2px solid green';
        if(dom.nodeName=='TEXTAREA')dom.style.color='black';
        return true;
      }else{
        dom.style.border=dom.nodeName=='TEXTAREA'?'':'2px solid red';
        if(dom.nodeName=='TEXTAREA')dom.style.color='';
        return false;
      }
    }
  }

  /**
   * No permite escribir más de los caracteres indicados por parametros.
   * @param nombreFormControl es el nombre asignado del input en el formBuilder.
   * @param maxCaracteres es el maximo de caracteres que vamos a permitir.
   */
  maxCaracteres(nombreFormControl: string, maxCaracteres: number){
    if(this.whiteBrandForm.controls[nombreFormControl].value.length>=maxCaracteres+1)
      this.whiteBrandForm.controls[nombreFormControl].setValue(this.ultimoEscrito[nombreFormControl+'maxCarac']);
    this.ultimoEscrito[nombreFormControl+'maxCarac']=this.whiteBrandForm.controls[nombreFormControl].value;
  }
  
  /**
   * Evita que se puedan escribir letras
   * @param nombreFormControl es el nombre asignado del input en el formBuilder.
   */
  noLetras(nombreFormControl: string){
    if(/\D/.test(this.whiteBrandForm.controls[nombreFormControl].value))
      this.whiteBrandForm.controls[nombreFormControl].setValue(this.ultimoEscrito[nombreFormControl+'noLet']);
    this.ultimoEscrito[nombreFormControl+'noLet']=this.whiteBrandForm.controls[nombreFormControl].value;
  }

  /**
   * Limpia los espacios en blanco del input pasado por párametro.
   * @param nombreFormControl es el nombre asignado del input en el formBuilder.
   */
  limpiarEspaciosBlancos(nombreFormControl: string){
    //Evitamos los espacios
    this.whiteBrandForm.controls[nombreFormControl].setValue(this.whiteBrandForm.controls[nombreFormControl].value.replace(' ', '').trim());
  }
  /**
   * Muestra los errores con un swal
   */
    mensajeErrores(error){
    var errorView='<ul class="errorView">';
    for (let key in error.error.errors) {
      if(typeof error.error.errors[key] === 'string' || error.error.errors[key] instanceof String){
        errorView += '<li>'+error.error.errors[key]+'</li>';
      }else{
        error.error.errors[key].forEach(mensaje=>{
          errorView += '<li>'+mensaje+'</li>';
        });
      }
    }
    errorView += '</ul>'
    Swal.fire({
      title: 'Error',
      icon: 'error',
      showCancelButton: false,
      confirmButtonColor: '#00b1aa',
      confirmButtonText: 'Confirmar',
      html: errorView
    });
  }
  /**
   * Muestra la carga
   */
  mostrarCarga(){
    this.carga=true;
  } 
  /**
   * Oculta la carga
   */
  ocultarCarga(){
    this.carga=false;
  }

  isNif(documento) {
    var nif = documento.toUpperCase();
    var lettersEnd = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];

    if (nif.length == 9) {
      var letter = nif.substring(8, 9);
      var number = parseInt(nif.substring(0, 8));
      if (!isNaN(number) && isNaN(parseInt(letter))) {
          if (lettersEnd[number % 23] === letter) {
              return true;
          }
      }
    }
    return false;
  }
  isNie(documento) {
      var nie = documento.toUpperCase(); //Z4138590K

      var i;
      var num = [];
      if (nie.match('^[XYZ][0-9][0-9][0-9][0-9][0-9][0-9][0-9][A-Z0-9]')) {
          for (i = 0; i < 9; i++) {
              var aux = i;
              num[i] = nie.substr(i, aux++);
          }
          var letters = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];
          var dniTransform = nie.replaceAll('X', '0').replaceAll('Y', '1').replaceAll('Z', '2');
          if (num[8] === letters[parseInt(dniTransform.substring(0, 8)) % 23]) {
              return true;
          }
      }
      return false;
  }
  /**
   * Validador de cif
   * @param documento
   * @returns
   */
  validaCif(documento) {
      if (documento == "" || documento.length!=9) {return false; }
      documento=documento.toUpperCase();
      var letras = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
	    var numeros = documento.substr(1, documento.length - 2);
	    var letra = documento.substr(0, 1);
      if(letra.match(/[^ABCDEFGHJNPQRSUVW]/)) return false;
	    var control = documento.substr(documento.length - 1);
	    var sum = 0;
	    var numero:any;

	    if (!letra.match(/[A-Z]/)) {
	    	return false;
	    }

	    for (let i = 0; i < numeros.length; ++i) {
	    	numero = parseInt(numeros[i]);
      
	    	if (isNaN(numero)) {
	    		return false;
	    	}
      
	    	if (i % 2 === 0) {
	    		numero *= 2;
	    		if (numero > 9) {
	    			numero = parseInt(""+numero / 10) + (numero % 10);
	    		}
        
	    		sum += numero;
	    	} else {
	    		sum += numero;
	    	}
	    }
    
	    sum %= 10;
	    if (sum !== 0) {
	    	numero = 10 - sum;
	    } else {
	    	numero = sum;
	    }
    
	    if (letra.match(/[ABEH]/)) {
	    	return String(numero) === control;
	    }
	    if (letra.match(/[NPQRSW]/)) {
	    	return letras[numero] === control;
	    }
    
	    return String(numero) === control || letras[numero] === control;
  }
  toogleBis(){
    this.bis=!this.bis;
  }
  async crearCliente(){
    if(this.validarDoc()){
        this.textoCarga='Creando Cliente...';
        this.mostrarCarga();
        const payload={
        'documento':this.whiteBrandForm.controls['documentoInput'].value,
        'tipo_documento':this.whiteBrandForm.controls['tipoDocumento'].value=='NIE'?'N':this.whiteBrandForm.controls['tipoDocumento'].value,
        'nombre':this.whiteBrandForm.controls['nombre'].value,
        'apellido':this.whiteBrandForm.controls['primerApellido'].value,
        'apellido2':this.whiteBrandForm.controls['segundoApellido'].value ?? null,
        'fecha_nacimiento':this.miDatePipe.transform(this.whiteBrandForm.controls['fechaNacimiento'].value, 'yyyy-MM-dd'),
        'tipo_tercero':this.whiteBrandForm.controls['tipoCliente'].value,
        'cod_pais':this.whiteBrandForm.controls['pais'].value.split('|')[0]>=0?this.whiteBrandForm.controls['pais'].value.split('|')[0]:0,
        'cod_actividad':this.whiteBrandForm.controls['codActividad'].value==''?'0':this.whiteBrandForm.controls['codActividad'].value,
        'direccion_cod_provincia':this.codigos.codProvincia,
        'direccion_cod_municipio':this.codigos.codMunicipio,
        'direccion_cod_calle':this.codigos.codCalle,
        'direccion_num_finca':this.datosDireccion.numero,
        'direccion_bis':this.bis?'B':null,
        'direccion_portal':this.datosDireccion.portal==''?null:this.datosDireccion.portal,
        'direccion_escalera':this.datosDireccion.escalera==''?null:this.datosDireccion.escalera,
        'direccion_local':this.datosDireccion.local==''?null:this.datosDireccion.local,
        'direccion_planta':this.datosDireccion.planta==''?null:this.datosDireccion.planta,
        'direccion_aclarador':this.whiteBrandForm.controls['aclarador'].value.trim(),
        'direccion_puerta':this.datosDireccion.puerta==''?null:this.datosDireccion.puerta,
        'sociedad_apoderado_documento':this.whiteBrandForm.controls['nifApoderado'].value,
        'sociedad_apoderado_nombre':this.whiteBrandForm.controls['nombreApoderado'].value,
        'sociedad_apoderado_fecha_poder':this.miDatePipe.transform(this.whiteBrandForm.controls['fPoder'].value, 'yyyy-MM-dd'),
        'sociedad_apoderado_notario':this.whiteBrandForm.controls['notaria'].value,
        'sociedad_apoderado_protocolo':this.whiteBrandForm.controls['protocolo'].value,
      
      };
      const headers={
        "accept": "application/json",
        "Authorization": "Bearer "+this.accessToken,
        'Content-Type': 'application/json' 
      }
      await this.marcaBlanca.peticionMarca(environment.crearClienteMarcaBlanca,'post',headers,payload).then((res:any)=>{        
        this.crearContrato(res.codTercero);
      },(err:any)=>{
        console.error(err);
        this.ocultarCarga();
          if(err.error.errors!=undefined) this.mensajeErrores(err);
          else Swal.fire({
          title: 'Error',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#00b1aa',
          confirmButtonText: 'Confirmar',
          html: err.error.message ?? err.error.detalle
        });
      });
    }
  }
  async crearContrato(codTercero){
    this.textoCarga='Creando Contrato...';
    const payload= {
      'documento':this.whiteBrandForm.controls['documentoInput'].value,
      'codigo_tercero':codTercero,
      'telefono':this.whiteBrandForm.controls['telefono'].value,
      'email':this.whiteBrandForm.controls['email'].value,
      'tipo_tercero':this.whiteBrandForm.controls['tipoCliente'].value
    };
    const headers={
      "accept": "application/json",
      "Authorization": "Bearer "+this.accessToken,
      'Content-Type': 'application/json' 
    };
    await this.marcaBlanca.peticionMarca(environment.crearContratoMarcaBlanca,'post',headers,payload).then((res:any)=>{
      this.ocultarCarga();      
      Swal.fire({
        title: res.message ?? res.detalle,
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#00b1aa',
        confirmButtonText: 'Confirmar',
      }).then(()=>{
        this.route.navigateByUrl('/crear-pedidos-marca-blanca',{
          state:[
            this.whiteBrandForm.controls['tipoCliente'].value,
            res.cttOfic,
            codTercero
          ]
        });
      });
    },(err:any)=>{
      console.error(err);
      this.ocultarCarga();
        if(err.error.errors!=undefined) this.mensajeErrores(err);
        else Swal.fire({
          title: 'Error',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#00b1aa',
          confirmButtonText: 'Confirmar',
          html: err.error.message ?? err.error.detalle
        });
    });
  }
  validarDoc(){
    var valido: boolean=false;
    this.tipoDocumentoElemento.nativeElement.style.border='';
    switch (this.whiteBrandForm.controls['tipoDocumento'].value) {
      case "N":
        valido=this.isNif(this.whiteBrandForm.controls['documentoInput'].value);
      break;
      case "NIE":
        valido=this.isNie(this.whiteBrandForm.controls['documentoInput'].value);
      break;
      case "P":
        let pasaporte=this.whiteBrandForm.controls['documentoInput'].value;
        let isCif=this.validaCif(pasaporte);
        let isNie=this.isNie(pasaporte);
        let isNif=this.isNif(pasaporte);
          if(isCif){
            this.wrongPassportModalRef=this.dialog.open(WrongPassportModalComponent,{
              height: '250px',
              width: '600px',
              data:{
                tipo:'CIF',
                completar:'Cambiar Ahora y Crear Cliente'
              }
            });
            this.wrongPassportModalRef.afterClosed().subscribe({
              next:(change:any)=>{
                if(change.change){
                  this.whiteBrandForm.controls['tipoDocumento'].setValue('C');
                  this.whiteBrandForm.controls['segundoApellido'].setValue('');
                  this.whiteBrandForm.controls['primerApellido'].setValue('');
                  this.isCIF=true;
                }
              }
            });
          }
          if(isNie){
            this.wrongPassportModalRef=this.dialog.open(WrongPassportModalComponent,{
              height: '250px',
              width: '600px',
              data:{
                tipo:'NIE',
                completar:'Cambiar Ahora y Crear Cliente'
              }
            });
            this.wrongPassportModalRef.afterClosed().subscribe({
              next:(change:any)=>{
                if(change.change){
                  this.whiteBrandForm.controls['tipoDocumento'].setValue('NIE');
                  this.crearCliente();
                }
              }
            });
          }
          if(isNif){
            this.wrongPassportModalRef=this.dialog.open(WrongPassportModalComponent,{
              height: '250px',
              width: '600px',
              data:{
                tipo:'NIF',
                completar:'Cambiar Ahora y Crear Cliente'
              }
            });
            this.wrongPassportModalRef.afterClosed().subscribe({
              next:(change:any)=>{
                if(change.change){
                  this.whiteBrandForm.controls['tipoDocumento'].setValue('N');
                  this.crearCliente();
                }
              }
            });
          }
          valido=!isCif && !isNie && !isNif;
        break;
      case "C":
          valido=this.validaCif(this.whiteBrandForm.controls['documentoInput'].value);
        break;
    }
    return valido;
  }
}


