export class SIM{
    private ICC:number;
    private PIN:number[];
    private PUNK:number[];
    private peticionPrincipal:number;
    private peticionMultiSIM:number;
    constructor(ICC?:number, peticionPrincipal?:number, peticionMultiSIM?:number){
        this.ICC=ICC;
        this.peticionPrincipal=peticionPrincipal;
        this.peticionMultiSIM=peticionMultiSIM;
    }
    public getICC(){return this.ICC;}
    public setICC(ICC:number){this.ICC=ICC;}
    public getPIN(){return this.PIN;}
    public setPIN(PIN:number[]){this.PIN=PIN;}
    public addPIN(PIN:number){this.PIN.push(PIN);}
    public getPUNK(){return this.PUNK;}
    public setPUNK(PUNK:number[]){this.PUNK=PUNK;}
    public addPUNK(PUNK:number){this.PUNK.push(PUNK);}
    public getPeticionPrincipal(){return this.peticionPrincipal;}
    public setPeticionPrincipal(peticionPrincipal:number){this.peticionPrincipal=peticionPrincipal;}
    public getPeticionMultiSIM(){return this.peticionMultiSIM;}
    public setPeticionMultiSIM(peticionMultiSIM:number){this.peticionMultiSIM=peticionMultiSIM;}
}