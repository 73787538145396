<div class="previewDocumentation" *ngIf="loadingService.archive">
    <div class="contedorBoton"><button type="button" class="botonSalir" (click)="cerrarDocumentacion()">
            <img src="assets/img/PTV/cerrar.png" class="iconBS">
        </button></div>
    <div class="contenedorImg">
        <app-ver-documento [archiv]="loadingService.archive"></app-ver-documento>
    </div>
</div>

<div class="formbox">
    <div class="cabecera">
        FIJO - DOCUMENTACIÓN
    </div>
    <div class="cabecera2">
        <form [formGroup]="advancedForm" class="formFijo">
            <input type="tel" name="telf" maxlength="9" minlength="9" class="form-control fijoInput"
                oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="telf"
                placeholder="INTRODUCE UN FIJO" (input)="findNumber($event.target.value)">
        </form>
    </div>

    <div class="contentLoad" *ngIf="loadModal || loadingService.load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>

    <div>
        <form [formGroup]="formFiles" style="width: 100%" class="formInput contentListDocs" *ngIf="clienteEncontrado">
            <mat-grid-list cols="14" rowHeight="2.5em" style="width: 50%;">
                <mat-grid-tile [colspan]=4 [rowspan]=1 class="alinearTI">
                    <label class="tituloMB">DOCUMENTO DE IDENTIDAD:</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=10 [rowspan]=1>
                    <input class="archivo" type="file" name="file1" id="demo1" formControlName="fileDocIdentidad"
                        (change)="setDoc($event, 'fileDocIdentidad')">
                </mat-grid-tile>

                <mat-grid-tile [colspan]=4 [rowspan]=1 class="alinearTI">
                    <label class="tituloMB">COPIA DEL CONTRATO:</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=10 [rowspan]=1>
                    <input class="archivo" type="file" name="file2" id="demo2" formControlName="fileCopia"
                        (change)="setDoc($event, 'fileCopia')">
                </mat-grid-tile>

                <mat-grid-tile [colspan]=4 [rowspan]=1 class="alinearTI">
                    <label class="tituloMB">GRABACIÓN (OPCIONAL):</label>
                </mat-grid-tile>
                <mat-grid-tile [colspan]=10 [rowspan]=1>
                    <input class="archivo" type="file" name="file3" id="demo3" formControlName="fileGrabacion"
                        (change)="setDoc($event, 'fileGrabacion')">
                </mat-grid-tile>
            </mat-grid-list>
        </form>
    </div>

    <div *ngIf="listaDocsServer">
        <div class="wrapper_containerListArch" *ngIf="listaDocsServer">
            <div *ngFor="let archiv of listaDocsServer" class="rowArch">
                <div class="wrapper_infoNameArch">
                    <div id="{{archiv.nameFile}}" class="infoNameArch"
                        (click)="downLoadDocumentFromServer(archiv.nameFile)">{{archiv.nameFile}}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>