<div class="formbox">

    <div class="contentLoad" *ngIf="load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>
    <div class="form1">
        <label class="titlePage">GRUPO COMPARTIDO (MÓVIL) - AñADIR NÚMEROS</label>
        <!-- SELECT CONTRATO -->
        <div class="customRow" *ngIf="data2">
            <label for="contratos">CONTRATO:</label>
            <select class="form-control" name="contratos" class="contratoF" (change)="getlineas($event.target.value)">
                <option value="0">Seleccione un Contrato:</option>
                <option value={{d.cof}}|{{d.con}} *ngFor="let d of data2">{{d.des}} - {{d.cof}} {{d.alias ? '('+d.alias+')' : ''
                    }}</option>
            </select>
        </div>

        <div class="customRow" *ngIf="list">
            <label for="contratos">PETICIÓN CABECERA</label>
            <select class="form-control" name="contratos" class="contratoF"
                (change)="getPeticionCabecera($event.target.value)">
                <option value="0">Seleccione una peticion de cabecera:</option>
                <option value={{item.peticionCabecera}}|{{item.tarifa}} *ngFor="let item of listPetCab">
                    CAB[{{item.peticionCabecera}}] [{{item.tarifa}}]
                </option>
            </select>
        </div>

        <div class="customRow" *ngIf="listLineasInclude">
            <label for="documento">BÚSQUEDA POR DNI:</label>
            <div class="busquedaDni">
                <input type="text" class="documento" #inputDni name="documento" maxlength="30" minlength="9">
                <button>
                    <mat-icon aria-hidden="false" class="material-icons-outlined searchIcon" (click)="buscarLineas()">
                        search</mat-icon>
                </button>
            </div>
        </div>


        <div class="customRow2" *ngIf="lineasCliente">
            <mat-grid-list cols="2" rowHeight="2.5em" style="width: 100%;">
                <mat-grid-tile [colspan]=1 [rowspan]=1 style="background-color: #808080; color: white;">LISTA LINEAS
                </mat-grid-tile>
                <mat-grid-tile [colspan]=1 [rowspan]=1 style="background-color: #808080; color: white;">LINEAS A AÑADIR
                </mat-grid-tile>
                <mat-grid-tile [colspan]=1 [rowspan]=3 style="padding: 0.5em;">
                    <div class="listLineas">
                        <table class="lineasCliente">
                            <tr *ngFor="let linea of lineasCliente" class="itemLineaCliente"
                                (click)="appendGroup(linea)" title="CLICK PARA AÑADIR LA LINEA {{linea.telf}}">
                                [{{linea.telf}}]--[{{linea.des}}]
                            </tr>
                        </table>
                    </div>
                </mat-grid-tile>

                <mat-grid-tile [colspan]=1 [rowspan]=3 style="padding: 0.5em;">
                    <div class="listLineas">
                        <table class="lineasCliente">
                            <tr *ngFor="let linea of lineasAppendGroup" class="itemLineaCliente"
                                (click)="removeLinesGroup(linea)" title="CLICK PARA QUITAR LA LINEA: {{linea.telf}}">
                                [{{linea.telf}}]--[{{linea.des}}]
                            </tr>
                        </table>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </div>

        <div class="customRow" *ngIf="listLineasInclude">
            <div class="listLineas" style="width: 100%;">
                <table class="listLineasInclude" style="width: 100%;">
                    <tr style="text-align: center;">
                        <th>LÍNEAS INCLUIDAS</th>
                    </tr>
                    <tr *ngFor="let linea of listLineasInclude" style="text-align: center;">
                        [{{linea.telf}}]--[{{linea.des}}]
                    </tr>
                </table>
            </div>
        </div>


        <div class="customRow" *ngIf="lineasAppendGroup">
            <button class="contratoF btnCreateGroup" (click)="launchAddLinesGroup()" *ngIf="activarBotonAdd">Añadir
                líneas</button>
        </div>
    </div>

</div>

<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>