import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoadingServiceService {
    load: boolean = false;
    titular;
    dni;
    cambioTarifaEnCurso = false;
    archive: any = null;
    public newTarifa: string = null;

    gestionBonos: boolean = false;
    historicBonos: boolean = false;
    cambiarTarifa: boolean = false;
    historicTarifa: boolean = false;

    public messageDocumentacion: string = null;
    public documentacionAdjuntada: boolean;

    constructor(private http: HttpClient, private cookie: CookieService) {}
    
    showloading() {
        this.load = true;
    }

    hideloading() {
        this.load = false;
    }
}