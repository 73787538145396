import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoadingServiceService } from '../accioneslineamovil/loading-service.service';
import { forEach } from 'jszip';

@Injectable({
    providedIn: 'root'
})
export class BuscarLineaService {
    con:any;
    listaLineasClienteBuscado: any;
    linea: any;
    constructor(private cookie: CookieService, private router: Router, private http: HttpClient, private loadingService: LoadingServiceService) {}


    cerrarModal() {
        console.log("Llega");
        
        this.listaLineasClienteBuscado = null
    }

    verDetallesDeLinea(lineaSelected: any) {
        const body = {

            "telefono": lineaSelected.telf,
            "tercero": lineaSelected.tercero
        }
        const headers = new HttpHeaders({
            'user': this.cookie.get("user"),
            'token': this.cookie.get("token"),
            'pass': this.cookie.get("pass")
        });
        const url = environment.advancedFind


        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                if (res.id) { //_________INVALIDO

                } //_______________END INVALIDO
                else {


                    this.cookie.set("peticion", res.pet);
                    this.cookie.set("tarifa", res.tarifa);
                    this.cookie.set("titular", res.des);
                    this.cookie.set("dni", res.doc);
                    this.cookie.set("numero", res.telf);
                    this.cookie.set("multisim", res.multisim);
                    this.cookie.set("cambio", res.cambio);
                    this.cookie.set("hora", this.timeTransform(parseInt(res.hora)));
                    this.cookie.set("contratoF", res.cttof);
                    this.cookie.set("cambio", "0");
                    this.cookie.set("dobleGb", res.dobleGB);
                    this.cookie.set("nextTarifa", res.nextTar);
                    this.cookie.set("activacion", this.dateTransform(res.fechAct));
                    this.cookie.set("multisim", res.multiSim);
                    this.cookie.set("con", this.con);

                    (this.cookie.get("nextTarifa") == "") ? this.loadingService.cambioTarifaEnCurso = false: this.loadingService.cambioTarifaEnCurso = true
                    this.router.navigate(['accioneslineamovil']);

                }

            }, error => {
                console.log(error);

            })
        } catch {

        }





    }

    /**
     * FUNCION ENCARGADA DE RETORNA LA HORA EN FORMATO LEGIBLE POR EL USUSARIO HH:MM:SS
     * @param resHours 
     * @returns 
     */
    timeTransform(resHours: number) {
        let strHours: string = resHours.toString();
        if (strHours.length > 5 && strHours.length < 7) {
            return strHours.substr(0, 2) + ":" + strHours.substr(2, 2) + ":" + strHours.substr(4, 2);
        } else {
            if (strHours.length > 4 && strHours.length < 6) {
                return "0" + strHours.substr(0, 1) + ":" + strHours.substr(1, 2) + ":" + strHours.substr(3, 2);
            } else {
                return resHours + "";
            }


        }


    }

    /**
     * FUNCION ENCARGADA DE RETORNA LA FECHA EN FORMATO LEGIBLE POR EL USUSARIO DD-MM-AAAA
     * @param fecha 
     * @returns 
     */
    dateTransform(fecha: string) {
        var fs: String = String(parseInt(fecha) + 19000000)
        return fs.substr(6, 2) + "-" + fs.substr(4, 2) + "-" + fs.substr(0, 4);
    }



}