<!-- __________________________________________CARD_DATOS_DEL_CLIENTE__________________________________________ -->
<div class="formularioCliente">
    <div class="contentLoad" *ngIf="loadModal || repositoryService.load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>

    <mat-grid-list cols="21" rowHeight="2.5em" style="width: 95%;">
        <form [formGroup]="registroForm">

            <img *ngIf="load" style="height: 20px;width:20px;padding-top: 5px;margin-left: 20px;display: flex;"
                src="assets/img/loading2.gif">

            <mat-grid-tile [colspan]=5 [rowspan]=1
                style="font-family: BigShouldersDisplayBold; background-color: #000; color: white;">
                <label style="font-size: 1.5em;">CONTRATO</label>
            </mat-grid-tile>
            <mat-grid-tile [colspan]=2 [rowspan]=1></mat-grid-tile>
            <mat-grid-tile [colspan]=5 [rowspan]=1
                style="font-family: BigShouldersDisplayBold; background-color: #000; color: white;">
                <label style="font-size: 1.5em;">DATOS DEL CLIENTE</label>
            </mat-grid-tile>
            <mat-grid-tile [colspan]=2 [rowspan]=1></mat-grid-tile>
            <mat-grid-tile [colspan]=5 [rowspan]=1
                style="font-family: BigShouldersDisplayBold; background-color: #000; color: white;">
                <label style="font-size: 1.5em;">DIRECCIÓN</label>
            </mat-grid-tile>
            <mat-grid-tile [colspan]=2 [rowspan]=1></mat-grid-tile>


            <!-- __________________________________________CONTRATOS__________________________________________ -->
            <mat-grid-tile [colspan]=6 [rowspan]=1 class="alinearTI">
                <select name="contratos" class="contratosF" [(ngModel)]="idcliente" formControlName="idcliente"
                    (change)="selectOptionTarifa($event.target.value)">
                    <option value="0">SELECCIONE UN CONTRATO:</option>
                    <option value={{d.id}}|{{d.con}} *ngFor="let d of aux">
                        {{d.des}} - {{d.cof}} ({{d.alias}})
                    </option>
                </select>
            </mat-grid-tile>
            <mat-grid-tile [colspan]=1 [rowspan]=1>
            </mat-grid-tile>

            <!-- __________________________________________NIF/CIF/NIE__________________________________________ -->
            <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                <input class="inputGeneral" type="text" name="nif" placeholder="Introduzca NIF/CIF/NIE" maxlength="30"
                    [(ngModel)]="nif" formControlName="nif" id="docIdentity" maxlength="9"
                    (input)="nif = nif.toUpperCase()" />
            </mat-grid-tile>

            <!-- __________________________________________NACIONALIDAD__________________________________________ -->
            <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                <select class="selectGeneral" name="pais" id="paisSelect" [(ngModel)]="paisModel"
                    [ngModelOptions]="{standalone: true}">
                    <option value="0">SELECCIONE UN PAÍS</option>
                    <option value={{d.codi}} *ngFor="let d of data7">
                        {{d.desc}}
                    </option>
                </select>
            </mat-grid-tile>

            <mat-grid-tile [colspan]=3 [rowspan]=1 style="background-color: #000; color: white;">
                <label class="tituloMB">DOCUMENTO</label>
            </mat-grid-tile>

            <mat-grid-tile [colspan]=3 [rowspan]=1 style="background-color: #000; color: white;">
                <label class="tituloMB">CLIENTE</label>
            </mat-grid-tile>

            <mat-grid-tile [colspan]=1 [rowspan]=4>
            </mat-grid-tile>

            <!-- __________________________________________NOMBRE__________________________________________ -->
            <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                <input class="inputGeneral" type="text" name="nombre" maxlength="30" placeholder="NOMBRE/EMPRESA"
                    [(ngModel)]="nombre" formControlName="nombre" (input)="nombre = nombre.toUpperCase()" />
            </mat-grid-tile>

            <!-- __________________________________________DIRECCION__________________________________________ -->
            <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                <input ngx-google-places-autocomplete [options]='options' class="inputGeneral"
                    formControlName="direccion" (onAddressChange)="AddressChange($event)" [(ngModel)]="direccion"
                    (input)="direccion = direccion.toUpperCase()" [placeholder]="'UBICACIÓN'" />
            </mat-grid-tile>

            <!-- __________________________________________TIPO_NIF__________________________________________ -->
            <mat-grid-tile [colspan]=3 [rowspan]=3 style="background-color: #808080; color: white;">
                <p>
                    <input [(ngModel)]="tiponif" type="radio" name="tiponif" value="N" (click)="needPais('N')"
                        formControlName="tiponif">
                    <label class="documentoCliente">NIF/NIE</label>
                    <br>
                    <br>
                    <input [(ngModel)]="tiponif" type="radio" name="tiponif" value="C" (click)="needPais('C')"
                        formControlName="tiponif">
                    <label class="documentoCliente">CIF</label>
                    <br>
                    <br>
                    <input [(ngModel)]="tiponif" type="radio" name="tiponif" value="P" (click)="needPais('P')"
                        formControlName="tiponif">
                    <label class="documentoCliente">OTRO</label>
                </p>
            </mat-grid-tile>

            <!-- __________________________________________TIPO_de_CLIENTE__________________________________________ -->
            <mat-grid-tile [colspan]=3 [rowspan]=3 style="background-color: #808080; color: white;">
                <p>
                    <input [(ngModel)]="tipocliente" type="radio" name="tipocliente" value="R"
                        formControlName="tipocliente" (click)="guardaTipoCliente('R')">
                    <label class="documentoCliente">RESIDENCIAL</label>
                    <br>
                    <br>
                    <input [(ngModel)]="tipocliente" type="radio" name="tipocliente" value="S"
                        formControlName="tipocliente" (click)="guardaTipoCliente('S')">
                    <label class="documentoCliente">SOCIEDAD</label>
                    <br>
                    <br>
                    <input [(ngModel)]="tipocliente" type="radio" name="tipocliente" value="A"
                        formControlName="tipocliente" (click)="guardaTipoCliente('A')">
                    <label class="documentoCliente">AUTÓNOMO</label>
                </p>
            </mat-grid-tile>

            <!-- __________________________________________APELLIDO1__________________________________________ -->
            <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                <input class="inputGeneral" type="text" name="apellido1" maxlength="30" placeholder="1ºAPELLIDO"
                    [(ngModel)]="apellido1" formControlName="apellido1" (input)="apellido1 = apellido1.toUpperCase()" />
            </mat-grid-tile>

            <!-- __________________________________________POBLACION__________________________________________ -->
            <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                <input class="inputGeneral" type="text" maxlength="30" name="poblacion" formControlName="poblacion"
                    placeholder="POBLACIÓN" value="{{this.poblacion}}" [(ngModel)]="poblacion"
                    (input)="poblacion = poblacion.toUpperCase()" />
            </mat-grid-tile>

            <!-- __________________________________________APELLIDO2__________________________________________ -->
            <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                <input class="inputGeneral" type="text" name="apellido2" placeholder="2ºAPELLIDO" maxlength="30"
                    [(ngModel)]="apellido2" formControlName="apellido2" (input)="apellido2 = apellido2.toUpperCase()" />
            </mat-grid-tile>

            <!-- __________________________________________PROVINCIA__________________________________________ -->
            <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                <input class="inputGeneral" type="text" maxlength="30" name="provincia" formControlName="provincia"
                    placeholder="PROVINCIA" value="{{this.provincia}}" [(ngModel)]="provincia"
                    (input)="provincia = provincia.toUpperCase()" />
            </mat-grid-tile>

            <!-- __________________________________________FECHA__________________________________________ -->
            <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI" style="margin-top: 0.3em;">
                <mat-form-field style="background-color: rgba(255, 255, 255, 0); width: 96%;">
                    <mat-label style="padding-left: 1.5em; font-size: 0.8em; font-family: MontserratMedium;">FECHA
                        DE NACIMIENTO
                    </mat-label>

                    <input matInput [matDatepicker]="dp3" disabled [(ngModel)]="fechanac" formControlName="fechanac"
                        name="fechanac" class="Fecha" id="dt">
                    <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                    <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                </mat-form-field>
            </mat-grid-tile>

            <!-- __________________________________________CP__________________________________________ -->
            <mat-grid-tile [colspan]=7 [rowspan]=1 class="alinearTI">
                <input class="inputGeneral" type="number" maxlength="30" name="codigopostal"
                    value="{{this.codigopostal}}" placeholder="CÓDIGO POSTAL" [(ngModel)]="codigopostal"
                    formControlName="cp" />
            </mat-grid-tile>
        </form>

        <mat-grid-tile [colspan]=7 [rowspan]=1>
        </mat-grid-tile>

        <mat-grid-tile [colspan]=14 [rowspan]=1>
            <button type="button" *ngIf="!clientecreado" (click)="crearUsuario(1)" class="botonSecundario"
                title="CREAR CLIENTE">CREAR USUARIO
            </button>
        </mat-grid-tile>

        <form [formGroup]="documentosGroup">
            <!-- __________________________________________D.deIDENTIDAD__________________________________________ -->
            <mat-grid-tile [colspan]=2 [rowspan]=1 class="alinearTI">
            </mat-grid-tile>
            <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                <label class="tituloMB">DOCUMENTO DE IDENTIDAD:</label>
            </mat-grid-tile>
            <mat-grid-tile [colspan]=12 [rowspan]=1>
                <input class="archivo" type="file" name="file1" id="demo1" formControlName="fileDocIdentidad"
                    (change)="setDoc($event, 'DocIdentidad')">
            </mat-grid-tile>
            <mat-grid-tile [colspan]=2 [rowspan]=1 class="alinearTI">
            </mat-grid-tile>

            <!-- __________________________________________C.delCONTRATO__________________________________________ -->
            <mat-grid-tile [colspan]=2 [rowspan]=1 class="alinearTI">
            </mat-grid-tile>
            <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                <label class="tituloMB">COPIA DEL CONTRATO:</label>
            </mat-grid-tile>
            <mat-grid-tile [colspan]=12 [rowspan]=1>
                <input class="archivo" type="file" name="file2" id="demo2" formControlName="fileCopiaContrato"
                    (change)="setDoc($event, 'Copia')">
            </mat-grid-tile>
            <mat-grid-tile [colspan]=2 [rowspan]=1 class="alinearTI">
            </mat-grid-tile>

            <!-- __________________________________________GRABACIÓN__________________________________________ -->
            <mat-grid-tile [colspan]=2 [rowspan]=1 class="alinearTI">
            </mat-grid-tile>
            <mat-grid-tile [colspan]=5 [rowspan]=1 class="alinearTI">
                <label class="tituloMB">GRABACIÓN (OPCIONAL):</label>
            </mat-grid-tile>
            <mat-grid-tile [colspan]=12 [rowspan]=1>
                <input class="archivo" type="file" name="file3" id="demo3" formControlName="fileGrabacion"
                    (change)="setDoc($event,'grabacion')">
            </mat-grid-tile>
            <mat-grid-tile [colspan]=2 [rowspan]=1 class="alinearTI">
            </mat-grid-tile>

            <!-- __________________________________________CREAR_USUARIO__________________________________________ -->
            <mat-grid-tile [colspan]=21 [rowspan]=2>
                <button type="button" class="botonPDF" title="Exportar Contrato En PDF" title="EXPORTAR CONTRATO EN PDF"
                    (click)="exportarContratoMovilEnPdf('ptv_conMobile_'+apellido1+'_'+apellido2+'_'+nombre)">EXPORTAR
                    CONTRATO PDF
                </button>
            </mat-grid-tile>

        </form>
    </mat-grid-list>
</div>
