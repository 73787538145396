<div class="formbox">

    <div class="contentLoad" *ngIf="load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>

    <div class="cabecera">
        FIJO - EXPORTACIONES
    </div>
    <div class="cabecera2">
        <select class="form-control contratoSelect" name="contratos" 
        (change)="changeContrato($event.target.value)" >
            <option value="0">SELECCIONE UN CONTRATO:</option>
            <option value={{contrato.Contrato}} *ngFor="let contrato of contratos">{{contrato.ContratoOficial}} - {{contrato.Localidad}} {{contrato.alias ? '('+contrato.alias+')' : '' }}</option>
        </select>
    </div>
    <div class="divTabla" *ngIf="listExportaciones">
        <!-- DATATABLE -->
        <div id="datatablefijoexport">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable
                [dtOptions]="dtOptions" id="dt">
                <thead style="font-family: MontserratBold; text-align: center;">
                    <tr>
                        <th>LINEA</th>
                        <th>CONTRATO</th>
                        <th>OPERADOR</th>
                        <th>CLIENTE</th>
                        <th>HORA</th>
                        <th>VENTANA</th>
                        <th>ESTADO</th>
                    </tr>
                </thead>
                <tbody style="font-family: MontserratRegular; text-align: center;" *ngIf="listExportaciones">
                    <tr *ngFor="let linea of listExportaciones ">
                        <td>{{linea.num}}</td>
                        <td>{{linea.cttof}}</td>
                        <td>{{(linea.ope)?linea.ope:'NONE'}}</td>
                        <td>{{linea.cli}}</td>
                        <td>{{timeTransform(linea.hora)}}</td>
                        <td>{{dateTransform(linea.vent)}}</td>
                        <td *ngIf="linea.est=='E'" class="ejecutada">EJECUTADA</td>
                        <td *ngIf="linea.est=='P'" class="planificada">PLANIFICADA</td>
                        <td *ngIf="linea.est!='P' &&  linea.est!='E' " class="noejecutada">NO EJECUTADA</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>