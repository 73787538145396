<div class="wrapper_content">

    <div id="dataCoincidencias" class="tableContainer" style="border-collapse: collapse;width: 100%;margin: auto; height: auto;  background-color: #ffffff;">

        <table *ngIf="coincidencias.length!=0" class="table table-striped table-bordered table-sm row-border hover" style="background-color: #ffffff;" datatable [dtOptions]="dtOptions" id="dt">
            <thead class="tableHeader font-family: MontserratBold; text-transform: uppercase;">
                <tr>
                    <th>
                        TIPO
                    </th>
                    <th>
                        NOMBRE
                    </th>
                </tr>
            </thead>
            <tbody style="text-align: center;" >
                <tr *ngFor="let coincidencia of coincidencias" (click)="seleccionaCalle(coincidencia)" [ngClass]="calleSeleccionada==coincidencia? 'calleSelected' : 'rowCalle'" >
                    <td >{{coincidencia.calle.substring(0, coincidencia.calle.indexOf(' '))}}</td>
                    <td>{{coincidencia.calle.substring(coincidencia.calle.indexOf(' '))}}</td>
                </tr>
            </tbody>
        </table>
        <div class="wrapperButtons">
            <div *ngIf="coincidencias" mat-dialog-close class="button cancelar" >CANCELAR</div>
            <div *ngIf="calleSeleccionada" (click)="confirmarCalle()" class="button siguiente" >SIGUIENTE</div>
        </div>
    </div>
</div>