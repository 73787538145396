<div class="formbox">

    <div class="contentLoad" *ngIf="load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>


    <div class="cabecera">
        GRUPO COMPARTIDO - BAJA NÚMERO
    </div>
    <div class="cabecera2">
        <select class="form-control" name="contratos" class="contratoSelect" (change)="changeContratos($event.target.value)">
            <option value="0">SELECCIONE UN CONTRATO:</option>
            <option value={{d.cof}}|{{d.con}} *ngFor="let d of data2">{{d.des}} - {{d.cof}} {{d.alias ? '('+d.alias+')' : '' }}</option>
        </select>
    </div>
    <div class="divTabla" *ngIf="list">
        <!-- SELECT TARIFA FAMILIAR -->

        <div class="customRow container" style="width: 95%; margin-top: 2em; background-color: white;" *ngIf="list">
            <div id="dataTableBajasGrupo" *ngIf="list" style="zoom: 90%;">

                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" id="dt" *ngIf="list">
                    <thead style="font-family: MontserratBold;">
                        <tr>
                            <th>PETICION-CABECERA</th>
                            <th>CONTRATO</th>
                            <th>TITULAR</th>
                            <th>NUMERO</th>
                            <th>NUEVA TARIFA</th>
                            <th>ACCION</th>
                        </tr>
                    </thead>
                    <tbody style="font-family: MontserratRegular;text-align: center;" *ngIf="list">

                        <tr *ngFor="let linea of list let i=index">
                            <td [ngClass]="!linea.TARIFA? 'grupo': 'columna'">
                                {{linea.PETICION}}
                            </td>


                            <td [ngClass]="!linea.TARIFA? 'grupo': 'columna'">{{(linea.TARIFA)?contratoOficial:''}}</td>
                            <td [ngClass]="!linea.TARIFA? 'grupo': 'columna'"> {{linea.TITULAR}}</td>
                            <td [ngClass]="!linea.TARIFA? 'grupo': 'columna'">{{linea.NUMERO}}</td>
                            <!-- <td [ngClass]="!linea.TARIFA? 'grupo': 'columna'">{{linea.TARIFA}}</td> -->
                            <td [ngClass]="!linea.TARIFA? 'grupo': 'columna'">
                                <select [hidden]="!linea.TARIFA" id="{{i}}" class="form-control" name="contratos" class="contratoF action" (change)="changeTarifa($event.target.value)">
                                        <option value="0" >Elige la tarifa</option>
                                        <option value="{{tarifa.des.trim()}}|{{linea.PETICION}}" *ngFor="let tarifa of tarifasUpdate" >{{tarifa.nom.trim()}}
                                        </option>
                                </select>
                            </td>


                            <td [ngClass]="!linea.TARIFA? 'grupo': 'columna'"> <button class="btnCreateGroup action" (click)="darDeBaja(linea.NUMERO)" *ngIf="linea.NUMERO">DAR DE BAJA</button></td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>


    <div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
        <label>.</label>
    </div>
</div>
