<div *ngIf="this.carga" id="loading"></div>
<div *ngIf="this.carga" id="loadingImagenDiv">
    <mat-grid-list cols=3 rowHeight=40em>
        <mat-grid-tile>
        </mat-grid-tile>
        <mat-grid-tile>
            <img id="loading-image" src="assets/img/loading2.gif" alt="Loading..." />
            <label id="textCarga">{{ this.textoCarga }}</label>
        </mat-grid-tile>
        <mat-grid-tile>
        </mat-grid-tile>
    </mat-grid-list>
</div>
<div class="formularioCabecera">
    <table style="margin-left: 4em; margin-right: 4em; width: 100%;">
        <tbody>
            <tr>
                <td class="tituloCabecera">FIBRA - CREAR SUSCRIPCIÓN (FIBRA MARCA BLANCA)</td>
                <td>
                    <a class="" routerLink="/create-account-white-brand" title="Volver a comprobar cobertura">
                        <div class="volverBtn">
                        </div>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div #divPrincipal class="divPrincipal">
    <form [formGroup]="whiteBrandForm" class="whiteBrandForm">
        <mat-grid-list cols="2" rowHeight="2.5em" class="datos">
            <mat-grid-tile [colspan]=2 class="titulo">
                <label>DATOS USUARIO</label>
            </mat-grid-tile>
            <mat-grid-tile>
                <select #tipoDocumento (change)="seleccionado(4001, $event.target.value)" name="" id="documento" formControlName="tipoDocumento">
                    <option value="">Seleccione el tipo de documento</option>
                    <option value="N">NIF</option>
                    <option value="NIE">NIE</option>
                    <option value="P">Pasaporte</option>
                    <option value="C">CIF</option>
                </select>
            </mat-grid-tile>
            <mat-grid-tile>
                <input #documentoInput class="documentoInput" (keyup)="comprobarDoc($event.target)" (input)="limpiarEspaciosBlancos('documentoInput')" type="text" placeholder="DNI/NIE/CIF" formControlName="documentoInput">
            </mat-grid-tile>
            <mat-grid-tile>
                <select #seleccionTipoCliente (change)="seleccionado(4002, $event.target.value)" name="" id="tipoCliente" formControlName="tipoCliente" (input)="compruebaInputEscrito($event.target)">
                    <option value="">Seleccione el tipo cliente</option>
                    <option value="{{ item.valor }}" *ngFor="let item of listTipoCliente">{{ item.texto }}</option>
                </select>
            </mat-grid-tile>
            <mat-grid-tile>
                <select name="" id="paisPredeterminado" disabled *ngIf="!esExtrangero&&paises.length">
                    <option value="{{ paises[8].codi }}|{{ paises[8].desc }}">{{ paises[8].desc }}</option>
                    <option value="{{ item.codi }}|{{ item.desc }}" *ngFor="let item of paises">{{ item.desc }}</option>
                </select>
                <select (change)="seleccionado(4003, $event.target.value)" name="" id="pais" formControlName="pais" *ngIf="esExtrangero" (input)="compruebaInputEscrito($event.target)">
                    <option value="" selected>Seleccione su país de origen</option>
                    <option value="{{ item.codi }}|{{ item.desc }}" *ngFor="let item of paises">{{ item.desc }}</option>
                </select>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="!isCIF">
                <input #nombre type="text" placeholder="NOMBRE" formControlName="nombre" (input)="compruebaInputEscrito($event.target)">
            </mat-grid-tile>
            <mat-grid-tile *ngIf="isCIF">
                <input #nombre type="text" placeholder="RAZÓN SOCIAL" formControlName="nombre" (input)="compruebaInputEscrito($event.target)">
            </mat-grid-tile>
            <mat-grid-tile>
                <input #telefono type="text" placeholder="TELÉFONO" (keyup)="comprobarTelefono($event.target)" (input)="limpiarEspaciosBlancos('telefono'); maxCaracteres('telefono', 9); noLetras('telefono');" formControlName="telefono">
            </mat-grid-tile>
            <mat-grid-tile *ngIf="!isCIF">
                <input #primerApellido type="text" placeholder="1º APELLIDO" formControlName="primerApellido" (input)="compruebaInputEscrito($event.target)">
            </mat-grid-tile>
            <mat-grid-tile>
                <input #telefono type="text" placeholder="TELÉFONO SECUNDARIO" formControlName="tlf2" (keyup)="comprobarTelefono($event.target)" (input)="limpiarEspaciosBlancos('telefono'); maxCaracteres('telefono', 9); noLetras('telefono');">
            </mat-grid-tile>
            <mat-grid-tile *ngIf="!isCIF">
                <input #segundoApellido type="text" placeholder="2º APELLIDO" formControlName="segundoApellido" (input)="compruebaInputEscrito($event.target)">
            </mat-grid-tile>
            <mat-grid-tile>
                <input #email type="text" placeholder="EMAIL" (keyup)="comprobarEmail($event.target)" formControlName="email">
            </mat-grid-tile>
            
            <!-- <mat-grid-tile [rowspan]=2 [colspan]=2>
                <mat-form-field>
                    <input #fechaNacimiento readonly="true" matInput [matDatepicker]="picker" formControlName="fechaNacimiento" id="fechaNacimiento" (dateChange)="compruebaInputEscrito($event.targetElement)">
                    <mat-placeholder>FECHA DE NACIMIENTO</mat-placeholder>
                    <mat-datepicker-toggle matSuffix [for]="picker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker startView="year" [startAt]="startDate"></mat-datepicker>
                </mat-form-field>
            </mat-grid-tile> -->
            <mat-grid-tile *ngIf="!isCIF" [rowspan]=2 [colspan]=2>
                <mat-form-field>
                    <input #fechaNacimiento readonly="true" matInput [matDatepicker]="fnacimiento" formControlName="fechaNacimiento" id="fechaNacimiento" (dateChange)="compruebaInputEscrito($event.targetElement)">
                    <mat-placeholder>FECHA DE NACIMIENTO</mat-placeholder>
                    <mat-datepicker-toggle matSuffix [for]="fnacimiento">
                    </mat-datepicker-toggle>
                    <mat-datepicker #fnacimiento startView="year" [startAt]="startDate"></mat-datepicker>
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list *ngIf="isCIF" cols="2" rowHeight="2.5em">
            <mat-grid-tile [colspan]=2 class="titulo">
                <label>DATOS ADICIONALES</label>
            </mat-grid-tile>
            <mat-grid-tile>
                <input type="text" id="codActividad" name="codActividad" placeholder="CÓDIGO DE ACTIVIDAD" formControlName="codActividad">
            </mat-grid-tile>
            <mat-grid-tile>
                <input type="text" id="nApoderado" name="nombreApoderado" placeholder="NOMBRE COMPLETO DEL APODERADO" formControlName="nombreApoderado">
            </mat-grid-tile>
            <mat-grid-tile>
                <input type="text" id="nifApoderado" name="nifApoderado" placeholder="NIF DEL APODERADO" formControlName="nifApoderado">
            </mat-grid-tile>
            <mat-grid-tile>
                <input type="text" id="notaria" name="notaria" placeholder="NOTARIA" formControlName="notaria">
            </mat-grid-tile>
            <mat-grid-tile>
                <input type="text" id="protocolo" name="protocolo" placeholder="PROTOCOLO" formControlName="protocolo">
            </mat-grid-tile>
            <mat-grid-tile [rowspan]=2 [colspan]=2>
                <mat-form-field>
                    <input #fechaNacimiento readonly="true" matInput [matDatepicker]="fnacimiento" formControlName="fechaNacimiento" id="fechaNacimiento" (dateChange)="compruebaInputEscrito($event.targetElement)">
                    <mat-placeholder>NACIMIENTO DEL APODERADO</mat-placeholder>
                    <mat-datepicker-toggle matSuffix [for]="fnacimiento">
                    </mat-datepicker-toggle>
                    <mat-datepicker #fnacimiento startView="year" [startAt]="startDate"></mat-datepicker>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [rowspan]=2 [colspan]=2>
                <mat-form-field>
                    <input #fechaOder readonly="true" formControlName="fPoder" matInput [matDatepicker]="foder" id="fPoder" (dateChange)="compruebaInputEscrito($event.targetElement)">
                    <mat-placeholder>FECHA DEL PODER</mat-placeholder>
                    <mat-datepicker-toggle matSuffix [for]="foder">
                    </mat-datepicker-toggle>
                    <mat-datepicker #foder startView="year" [startAt]="startDate"></mat-datepicker>
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="2.5em" class="direccion">
            <mat-grid-tile [colspan]=2 class="titulo">
                <label>DIRECCIÓN</label>
            </mat-grid-tile>
            <mat-grid-tile>
                <input type="text" disabled="disable" value="PROVINCIA: {{ this.datosDireccion.provincia }}" >
            </mat-grid-tile>
            <mat-grid-tile>
                <input type="text" disabled="disable" value="MUNICIPIO: {{ this.datosDireccion.municipio }}">
            </mat-grid-tile>
            <mat-grid-tile>
                <input type="text" placeholder="CÓDIGO POSTAL" formaControlName="codPostal">
            </mat-grid-tile>
            <mat-grid-tile>
                <input type="text" disabled="disable" value="{{ this.datosDireccion.tipoVia }}: {{ this.datosDireccion.calle }}">
            </mat-grid-tile>
            <mat-grid-tile>
                <input type="text" disabled="disable" value="NÚMERO: {{ this.datosDireccion.numero }}">
            </mat-grid-tile>
            <mat-grid-tile>
                <input type="checkbox" name="bis" id="bis" (click)="toogleBis()"><label style="margin-left: 10px;" for="bis">Bis</label>
            </mat-grid-tile>
            <mat-grid-tile>
                <input type="text" placeholder="BLOQUE" formaControlName="bloque">
            </mat-grid-tile>
            <mat-grid-tile *ngIf="this.datosDireccion.planta.length">
                <input type="text" disabled="disable" value="PLANTA: {{ this.datosDireccion.planta }}">
            </mat-grid-tile>
            <mat-grid-tile *ngIf="this.datosDireccion.puerta.length">
                <input type="text" disabled="disable" value="PUERTA: {{ this.datosDireccion.puerta }}">
            </mat-grid-tile>
            <mat-grid-tile *ngIf="this.datosDireccion.portal.length">
                <input type="text" disabled="disable" value="PORTAL: {{ this.datosDireccion.portal }}">
            </mat-grid-tile>
            <mat-grid-tile *ngIf="this.datosDireccion.escalera.length">
                <input type="text" disabled="disable" value="ESCALERA: {{ this.datosDireccion.escalera }}">
            </mat-grid-tile>
            <mat-grid-tile *ngIf="this.datosDireccion.local.length">
                <input type="text" disabled="disable" value="LOCAL: {{ this.datosDireccion.local }}">
            </mat-grid-tile>
            <mat-grid-tile>
                <input type="text" placeholder="TIPO DE VIVIENDA" formaControlName="tvivienda">
            </mat-grid-tile>
        </mat-grid-list>
        <textarea name="" id="" cols="30" rows="10" placeholder="Breve descripción sobre la direción aportada(Opcional)" formControlName="aclarador" (input)="compruebaInputEscrito($event.target)"></textarea>
        
        <div *ngIf="this.whiteBrandForm.valid && paisValido; else mensaje" class="finalizarDiv" >
            <button  class="btnFinalizar" (click)="crearCliente()">FINALIZAR SUSCRIPCIÓN</button>
            <div style="width: 20px; height: 20px; margin-top: 20px;"></div>
        </div>
        <ng-template #mensaje>
            <div class="mensaje">
                <label>IMPORTANTE: Si no aparece el botón de finalización, compruebe que todos los campos obligatorios estén rellenos correctamente.</label>  
            </div>
            <div style="width: 20px; height: 20px; margin-top: 20px;"></div>
        </ng-template>  
    </form>
</div>