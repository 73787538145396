<div class="formbox">

    <!-- <div *ngIf="repositoryService.logoOperatorBase64">
        <img [src]="repositoryService.logoOperatorBase64" alt="">
    </div> -->
    <div class="contentLoad" *ngIf="load">
        <img class="loading" src="assets/img/loading2.gif">
    </div>
    <div class="form1">
        <div class="containerTelf" style="display: grid;">
            <label for="telefono" style="text-align: center;margin-bottom: 0.5em;">INTRODUCE EL NÚMERO MOVIL</label>
            <input class="findNumber" type="text" name="telefono" id="telefono" maxlength="9" minlength="9" class="form-control " oninput="this.value=this.value.replace(/[^0-9]/g,'');" (input)="findNumber($event.target.value)">
        </div>

        <form [formGroup]="formFiles" style="display: grid;" *ngIf="clienteEncontrado" class="formInput">
            <label for="file1" class="label">*Documento de Identidad:</label>
            <input class="archivoF" type="file" name="file1" id="demo1" formControlName="fileDocIdentidad" (change)="setDoc($event, 'fileDocIdentidad')">

            <label for="file2" class="label">*Copia del Contrato:</label>
            <input class="archivoF" type="file" name="file2" id="demo2" formControlName="fileCopia" (change)="setDoc($event, 'fileCopia')">

            <label for="file1" class="label">Grabación (opcional):</label>
            <input class="archivoF" type="file" name="file3" id="demo3" formControlName="fileGrabacion" (change)="setDoc($event, 'fileGrabacion')">
        </form>
    </div>
</div>