import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NormaliceAddres } from 'src/app/model/NormalizedAddress';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    listCoincidencias;
    lislocales;
    localSeleccionado;
    load;
    accessToken: string;
    codProvinciaSelected: number;
    codMunicipioSelected: number;
    numero: number;
    calleSeleccionada = null;
    _prepareCoverage = {
        street: null,
        settlementType: null,
        Tipo: null
    };
    datosDireccion={};

    public _normalizedAddress: NormaliceAddres;
    constructor(private cookie: CookieService, private http: HttpClient, private router: Router) {}
    localSelected(lSelect) {
        this.localSeleccionado = lSelect;
        this._normalizedAddress = lSelect;
    }

    cerrarAccion() {
        this.lislocales = null;
    }

    /**
     * Funcion encargada de lanzar la peticion hacia el enpoint \coverage 
     * con ello comprobaremos si poseemos cobertura de internet en dicha zona
     * @param position posicion del array en el que se encuentra el domicilio
     */
    siguiente() {
        this.showLoading();
        const headers={
            "accept": "application/json",
            "Authorization": "Bearer "+this.accessToken
        };
        const enpoint=environment.getCoberturaBitstream+this.codProvinciaSelected+"/"+this.codMunicipioSelected+"/"+this.calleSeleccionada.codCalle+"/"+this.numero;
        this.http.get(enpoint, {headers}).subscribe((res: any)=>{
            this.hiddeloading();
            if(res.status)
                Swal.fire({
                    title: res.message,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#00b1aa',
                    confirmButtonText: 'Confirmar',
                }).then(()=>{
                    this.router.navigateByUrl('/create-subcription-white-brand', { 
                        state: [this.datosDireccion, this.accessToken, {
                            'codCalle': this.calleSeleccionada.codCalle,
                            'codProvincia': this.codProvinciaSelected,
                            'codMunicipio': this.codMunicipioSelected
                        }],
                    });
                    this.cerrarAccion();
                });
            else 
                Swal.fire({
                    title: res.message,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#00b1aa',
                    confirmButtonText: 'Confirmar',
                });
                
        },(error)=>{
            this.hiddeloading();
            console.error(error.error);
            Swal.fire({
                title: 'ERROR',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#00b1aa',
                confirmButtonText: 'Ok',
                text: 'Compruebe que todos los datos sean correctos'
            });
        });
    }
        /**
         * Mostrar el progressbar 
         */
    showLoading() {
            this.load = true
        }
        /**
         * Ocultar el progressbar
         */
    hiddeloading() {
        this.load = false
    }


    /**
     * Comparacion de la calle seleccionada con el resto
     * 
     * @param coincidencia 
     */
    coincidenciaSeleccionada(coincidencia) {
        this.calleSeleccionada = coincidencia;
        this._prepareCoverage = {
            street: coincidencia.calle,
            settlementType: coincidencia.tipo,
            Tipo: coincidencia.calle.substring(0, coincidencia.calle.indexOf(' '))
        };
    }
}