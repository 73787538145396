<div *ngIf="this.load || modalservice.load" id="loading">
    <mat-grid-list cols=3 rowHeight=40em>
        <mat-grid-tile>

        </mat-grid-tile>
        <mat-grid-tile>
            <img id="loading-image" src="assets/img/loading2.gif" alt="Loading..." />
        </mat-grid-tile>
        <mat-grid-tile>

        </mat-grid-tile>
    </mat-grid-list>

</div>

<div *ngIf="modalservice.listCoincidencias" class="localList">
    <app-modal-coincidencias [modalService]="modalservice"></app-modal-coincidencias>
</div>
<div *ngIf="modalservice.lislocales" class="localList">
    <app-modal-domicilios [modalService]="modalservice"></app-modal-domicilios>
</div>

<div class="formbox">

    <!-- <img *ngIf="load" id="loading-image" src="assets/img/loading2.gif" alt="Loading..." /> -->
    <div class="checkCoverage">

        <form class="custom-form" method="post" [formGroup]="accountForm">
            <div class="titlePage">
                <label>CREAR SUSCRIPTOR (FIBRA)</label>
            </div>


            <h2 style="text-align: center;" class="titleCheckCobertura">
                Comprobar Cobertura
            </h2>

            <!-- ----------------------------------COUNTRY  ------------------------------------------------- -->

            <div class="country_box box" *ngIf="displayHidden">

                <select name="country" id="country" class="select-form-box" disabled="disabled"
                    formControlName="country">
                    <option value="España">España</option>
                    <!-- <option value="{{option.value}}" *ngFor="let option of dataOptions" >{{option.clave}}</option> -->
                </select>
                <input type="text" name="county" id="county" placeholder="Comunidad Autonoma" required="required"
                    class="form-control" disabled="disabled" value="{{comunidadAutonoma}}" />
                <!-- <input type="text" name="country" id="country" placeholder="Pais" required="required" class="form-control" value="España" readonly="readonly" /> -->
            </div>

            <!-- ----------------------------------STATE  ------------------------------------------------- -->
            <div class="state_box box">
                <label for="state">Provincia:</label>
                <select name="state" id="state" class="select-form-box"
                    (change)="changeProvinciaSelected($event.target.value)" formControlName="country">
                    <option value="0">PROVINCIA</option>
                    <option value="{{option.codi}}|{{option.comu}}|{{option.desc}}"
                        *ngFor="let option of  listProvincias">{{option.desc}}</option>
                </select>
                <!-- <input type="text" name="state" id="state" placeholder="Ciudad" required="required" class="form-control" /> -->
            </div>
            <!-- ----------------------------------CITY  ------------------------------------------------- -->
            <div class="city_box box" *ngIf="_prepareCoverage.state">
                <label for="state">Población:</label>
                <select name="city" id="city" class="select-form-box" disabled="{{listPueblos ? '': 'disabled'}}"
                    (change)="changePoblacionSelected($event.target.value)" formControlName="county">
                    <option value="0">POBLACIÓN</option>
                    <option value="{{option.CodMunicipio}}|{{option.Municipio}}" *ngFor="let option of listPueblos">
                        {{option.Municipio}}</option>
                </select>
                <!-- <input type="text" name="city" id="city" placeholder="Población" required="required" class="form-control" /> -->
            </div>
            <!-- ----------------------------------BUSCAR COINCIDENCIA  ------------------------------------------------- -->
            <div class="checkvia_box box" *ngIf="_prepareCoverage.city">
                <label for="_street">Buscador:</label>
                <input type="text" name="_street" id="street" placeholder="INTRODUZCA EL NOMBRE DE LA VÍA"
                    required="required" class="form-control" formControlName="street" />
                <button type="submit" (click)="buscarCoincidenciasLaunch()">Buscar Coincidencias</button>
            </div>
            <!-- ----------------------------------STREET  ------------------------------------------------- -->
            <div class="streetSelected_box box" *ngIf="_prepareCoverage.city">
                <label for="_street">Calle:</label>
                <input type="text" name="_street" id="_street" placeholder="Calle" required="required"
                    value="{{this.modalservice._prepareCoverage.street}}" class="form-control" readonly="readonly" />
            </div>
            <!-- ----------------------------------POSTALCODE & NUMBER  ------------------------------------------------- -->
            <div class="postalCode_box box" *ngIf="_prepareCoverage.city">
                <table style="width: 100%;">
                    <tr>
                        <td>
                            <div>
                                <label for="postalCode">CÓDIGO POSTAL:</label>
                                <input type="number" name="postalCode" id="_postalCode" placeholder="CÓDIGO POSTAL"
                                    required="required"
                                    disabled="{{this.modalservice._prepareCoverage.street ? '': 'disabled'}}"
                                    class="form-control" formControlName="postalCode" 
                                    min="0"/>
                            </div>
                        </td>
                        <td>
                            <div>
                                <label for="number">NÚMERO:</label>
                                <input type="number" name="number" id="_number" placeholder="NÚMERO" required="required"
                                    class="form-control" formControlName="number" min="0" />
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

            <!-- ----------------------------------SUBMIT  ------------------------------------------------- -->
            <div class="submit_box box" *ngIf="this.modalservice._prepareCoverage.street">
                <button type="submit" class="submit" (click)="normalizedAddress()">Siguiente</button>
            </div>
        </form>
    </div>

</div>

<div style="height: 10em; color: rgba(0, 0, 0, 0); background-color: #ebeff2;">
    <label>.</label>
</div>