import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class CDRService {

    constructor(private sanitizer: DomSanitizer, private http: HttpClient, private cookies: CookieService, private router: Router) { }
    listCDR: any = null; //Lista de CDR
    loading = false;
    /**
     * Método encargado de devolver el listado de archivos CDR disponibles en el FTP
     */
    async getListCdr() {

        let tercero = this.cookies.get("tercero");
        // let cuarto = this.cookies.get("cuarto");
        const body = {
            "tercero": tercero,
            // "cuarto":cuarto 
        }
        const headers = new HttpHeaders({
            "user": this.cookies.get("user"),
            "token": this.cookies.get("token"),
            "pass": this.cookies.get("pass")

        });



        this.loading = true
        const url = environment.getListCdr

        // Swal.fire({
        //   title:"CDR"
        // })

        this.listCDR = [];
        try {
            this.http.post(url, body, { headers }).subscribe((res: any) => {
                this.loading = false
                if (res == "No existen archivos") {
                    Swal.fire({
                        // title:"CDR"
                        icon: "warning",
                        text: "No existen CDR."
                    })
                    return;

                }
                this.router.navigate(['cdr-page'])
                res.forEach(element => {
                    this.listCDR.push({ name: element.name, dateCreate: this.formatDate(element.dateCreate), dateMod: this.formatDate(element.dateMod), base64: null, definitivo: element.definitivo });
                });
                // this.listCDR=res;


            }, error => {
                this.loading = false
                Swal.fire({
                    // title:"CDR"
                    icon: "warning",
                    text: "No existen CDR."
                })


            })
        } catch {
            this.loading = false
        }

    }

    /**
     * Método encargado de retornar el fichero en base64 
     * @param nombreArchivoConExtension 
     */
    async getCdr(nombreArchivoConExtension: string) {
        let tercero = this.cookies.get("tercero");

        const body = {
            "tercero": tercero,
            "nombreFicheroConExtension": nombreArchivoConExtension
        }
        const headers = new HttpHeaders({
            "user": this.cookies.get("user"),
            "token": this.cookies.get("token"),
            "pass": this.cookies.get("pass")

        });

        const url = environment.getCdr


        try {
            this.loading = true
            this.http.post(url, body, { headers }).subscribe((res: any) => {

                this.loading = false

                const linkSource = "data:application/zip;base64," + res.fileBase64;

                let pdfBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(linkSource);
                var a = document.createElement("a"); //Create <a>
                a.href = linkSource; //Image Base64 Goes here
                a.download = nombreArchivoConExtension; //File name Here
                a.click(); //Downloaded file
                this.updateListCdr(nombreArchivoConExtension, pdfBase64);


            }, error => {
                this.loading = false
                console.log(error);
            })
        } catch {
            this.loading = false
        }



    }

    updateListCdr(name, base64) {
        this.listCDR.forEach(element => {
            if (element.name == name) {
                element.base64 = base64;

            }

        });
    }

    formatDate(dateString: string): string {

        return dateString.split("-")[2] + "/" + dateString.split("-")[1] + "/" + dateString.split("-")[0];
    }


}