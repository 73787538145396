import { Component, OnInit, Inject } from '@angular/core';
import { rejects } from 'assert';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { FileUpload } from 'primeng/fileupload';
import { ContratosService } from 'src/app/services/contratos.service';
import { PeticionServiceService } from 'src/app/services/peticion-service.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-subir-documentacion',
  templateUrl: './subir-documentacion.component.html',
  styleUrls: ['./subir-documentacion.component.css']
})
export class SubirDocumentacionComponent implements OnInit {
  mensaje: boolean = true
  base64: any
  extension: any
  nombre: any
  ficheros: any
  loading: boolean
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private peticionesSvc: PeticionServiceService,
    private dialog: MatDialogRef<SubirDocumentacionComponent>) {
    this.dialog.disableClose = true;
  }

  ngOnInit(): void {
    this.loading = false
  }

  async subirArchivo($event) {
    try {
    let fallos = "";
    this.loading = true;

    if ($event.files.length < 1) {
      throw new Error("No se ha seleccionado ningún archivo para subir.");
    }

    const archivo = $event.files[0]; // Tomar el primer archivo
    const nombre = archivo.name;
    const extension = nombre.split('.').pop(); // Obtener la extensión del nombre del archivo

    const base64Content = await this.parseBase64(archivo);

    const fichero = {
      contrato: this.data.contrato,
      nombre: nombre,
      extension: extension,
      content: base64Content
    };

    const url = environment.subirDocumentoMarcaBlanca;
    await this.peticionesSvc.peticionMarca(url, "post", {}, fichero);


    Swal.fire({
      title: 'Correcto',
      icon: 'success',
      text: "El archivo ha sido subido correctamente",
    });
  } catch (error) {
    console.error("Error al subir archivo:", error);
    Swal.fire({
      title: 'Error',
      icon: 'error',
      text: "Error al subir archivo: " + error.message,
    });
  } finally {
    this.loading = false;
    this.dialog.close();
  }
  }


  async parseBase64(archivo) {

    let promise = new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          const base64String = reader.result.substring(reader.result.indexOf(',') + 1);
          resolve(base64String);
      } else {
          reject(new Error('El resultado no es una cadena'));
      }

      }

      reader.readAsDataURL(archivo)
    })

    return promise;
  }
  ocultaMensaje($event) {
    this.mensaje = false

  }

  eliminarArchivo() {
    this.mensaje = false
  }

}
